import { Notification } from "./NotificationService.types";
// import profilePic from "../assets/images/users/avatar-1.jpg";
// import avatar4 from "../assets/images/users/avatar-4.jpg";

export const DUMMY_NOTIFICATION: Notification[] = [
    {
      id: 1,
      message: "Cristina Pride",
      subText: "Hi, How are you? What about our next meeting",
      // avatar: profilePic,
    },
    {
      id: 2,
      message: "Caleb Flakelar commented on Admin",
      subText: "1 min ago",
      icon: "uil uil-comment-message",
      bgColor: "primary",
    },
    {
      id: 3,
      message: "Karen Robinson",
      subText: "Wow ! this admin looks good and awesome design",
      // avatar: avatar4,
    },
    {
      id: 4,
      message: "New user registered.",
      subText: "5 hours ago",
      icon: "uil uil-user-plus",
      bgColor: "warning",
    },
    {
      id: 5,
      message: "Caleb Flakelar commented on Admin",
      subText: "1 min ago",
      icon: "uil uil-comment-message",
      bgColor: "info",
    },
    {
      id: 6,
      message: "Carlos Crouch liked Admin",
      subText: "13 days ago",
      icon: "uil uil-heart",
      bgColor: "secondary",
    },
  ];