import React from "react";
import analytics from "../document/docImages/analytics.jpeg";

interface AnalyticProps {
  // Define any props if needed
}

const SellerFulfilledOrder: React.FC = () => (
  <div>
    <h2>Seller Fulfilled Order</h2>
    <p>
      This section provides information about the orders that the seller is
      responsible for fulfilling. It's divided into different statuses:
    </p>
    <ul>
      <li>Pending: Orders that have been placed but not yet processed.</li>
      <li>Processing: Orders that are being processed for delivery.</li>
      <li>Shipped: Orders that have been shipped to customers.</li>
      <li>
        Cancelled: Orders that have been cancelled by either the seller or the
        buyer.
      </li>
      <li>Delivered: Orders that have been delivered to customers.</li>
    </ul>
    <img src={analytics} alt="Revenue" style={{ maxWidth: "100%", height: "auto" }} />
  </div>
);

const ProductListing: React.FC = () => (
  <div>
    <h2>Product Listing</h2>
    <p>
      This section tracks the status of the seller's product listings.
    </p>
    <ul>
      <li>
        QC_IN_PROGRESS: Products that are currently undergoing quality control.
      </li>
      <li>QC_ERROR: Products that have failed quality control.</li>
      <li>
        LIVE: Products that have passed quality control and are listed live for
        customers to purchase.
      </li>
      <li>DRAFT: Product listings that are in the draft phase and not yet live.</li>
    </ul>
    <img src={analytics} alt="Revenue" style={{ maxWidth: "100%", height: "auto" }} />
  </div>
);

const Revenue: React.FC = () => (
  <div>
    <h2>Revenue</h2>
    <p>
      This area of the dashboard appears to be a graph displaying the seller's
      revenue over a period of time. However, the graph indicates that there
      has been no revenue (0k) from January 10 to January 16.
    </p>
    <img src={analytics} alt="Revenue" style={{ maxWidth: "100%", height: "auto" }} />
  </div>
);

const Analytics: React.FC<AnalyticProps> = () => {
  return (
    <div>
      <h1 style={{ fontSize: "2rem" }}>Analytic</h1>
      <div>
        <SellerFulfilledOrder />
        <ProductListing />
        <Revenue />
      </div>
    </div>
  );
};

export default Analytics;
