import React, { useEffect, useState } from "react";
import {
  Alert,
  Button,
  Col,
  FormGroup,
  FormLabel,
  FormSelect,
  Row,
  Table,
} from "react-bootstrap";
import { Deal, DealResponse, getAllDeals, joinDeal } from "../dealsApi";
import TwoBallsLoader from "components/Loaders/TwoBallsLoader";

const AllDeals: React.FC = () => {
  const [deals, setDeals] = useState<Deal[]>([]);
  const [page, setPage] = useState<number>(1);
  const [status, setStatus] = useState<string>("Upcoming");
  const [perPage, setPerPage] = useState<number>(10);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const fetchAllDeals = async () => {
      setLoading(true);

      try {
        const response: DealResponse = await getAllDeals(page, status, perPage);
        const { status: responseStatus, msg, data, total_pages } = response;
        if (responseStatus === "success") {
          setDeals(data);
          setTotalPages(total_pages);
        } else {
          console.error(msg);
        }
      } catch (error) {
        console.error("Error occurred:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchAllDeals();
  }, [page, status, perPage]);

  const handlePageChange = (selectedPage: number) => {
    setPage(selectedPage);
  };

  const handleStatusChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setPage(1);
    setStatus(e.target.value);
  };

  const handlePerPageChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setPage(1);
    setPerPage(Number(e.target.value));
  };

  const paginationItems = [];
  for (let i = 1; i <= totalPages; i++) {
    paginationItems.push(
      <li
        key={i}
        className={`page-item ${i === page ? "active" : ""}`}
        onClick={() => handlePageChange(i)}
      >
        <span className="page-link">{i}</span>
      </li>
    );
  }

  const handlePrevClick = () => {
    setPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleNextClick = () => {
    setPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };
  const formatDateString = (dateString: string) => {
    const date = new Date(dateString);
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const year = date.getFullYear();

    return `${month}/${day}/${year}`;
  };

  const handleJoinDeals = async (dealId: string) => {
    try {
      const result = await joinDeal(dealId);
      if (result.success) {
        console.log(result.message);
      } else {
        console.error(result.message);
      }
    } catch (error) {
      console.error("Error occurred while joining the deal:", error);
    }
  };

  return (
    <div>
      <h3>All Deals</h3>
      <Row className="px-2">
        <Col md={6} className="mb-3">
          <FormGroup className="mb-3">
            <FormLabel>Status</FormLabel>
            <FormSelect onChange={handleStatusChange} value={status}>
              <option value="Upcoming">Upcoming</option>
              <option value="Expired">Expired</option>
              <option value="Active">Active</option>
            </FormSelect>
          </FormGroup>
        </Col>
        <Col md={6} className="mb-3">
          <FormGroup className="mb-3">
            <FormLabel>Deals Per Page</FormLabel>
            <FormSelect onChange={handlePerPageChange} value={perPage}>
              <option value={10}>10</option>
              <option value={20}>20</option>
            </FormSelect>
          </FormGroup>
        </Col>
      </Row>
      {loading && <TwoBallsLoader />}
      {!loading && deals.length === 0 && (
        <Alert variant="info">No deals found.</Alert>
      )}
      {deals.length > 0 && (
        <Table striped bordered hover responsive className="mt-4">
          <thead>
            <tr>
              <th> Title</th>
              <th> Description</th>
              <th> Occasion</th>
              <th>Show</th>
              <th>Start Date</th>
              <th>End Date</th>
              <th>Status</th>
              <th>Active</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {deals.map((deal) => (
              <tr key={deal.deals_id}>
                <td>{deal.dukkandaar_deal.deal_title}</td>
                <td style={{ wordBreak: "break-word", maxWidth: "20px" }}>
                  {deal.dukkandaar_deal.deal_description}
                </td>
                <td>{deal.dukkandaar_deal.deal_occasion}</td>
                <td>{deal.dukkandaar_deal.show.toString()}</td>
                <td>{formatDateString(deal.start_date)}</td>
                <td>{formatDateString(deal.end_date)}</td>
                <td>{deal.status}</td>
                <td>{deal.active.toString()}</td>
                {deal.status === "Upcoming" && (
                  <div className="d-flex justify-content-center align-items-center">
                    <Button
                      className="btn btn-primary"
                      onClick={() => handleJoinDeals(deal.deals_id)}
                    >
                      Join
                    </Button>
                  </div>
                )}{" "}
              </tr>
            ))}
          </tbody>
        </Table>
      )}
      <nav>
        <ul className="pagination">
          <li
            className={`page-item ${page === 1 ? "disabled" : ""}`}
            onClick={handlePrevClick}
          >
            <span className="page-link">Prev</span>
          </li>
          {paginationItems}
          <li
            className={`page-item ${page === totalPages ? "disabled" : ""}`}
            onClick={handleNextClick}
          >
            <span className="page-link">Next</span>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default AllDeals;
