import React from "react";
import logoSm from "../assets/images/logo-sm.png";
import addimg from "../assets/images/addimg.png";
import { inputsearch } from "../pages/other/Profile/OtherDetails/data";
import { useTranslation } from "react-i18next";

const FindProduct = () => {
  const { t } = useTranslation();
  return (
    <div className="px-2">
      <div className="p-2" style={{ position: "sticky", top: "0px" }}>
        <div className="d-flex align-items-center justify-content-start position-relative">
          <span className="d-flex align-items-center justify-content-start gap-2">
            <img src={logoSm} alt="" height="24" className="cursor-pointer" />

            <span
              style={{
                color: "black",
                fontSize: "30px",
                fontWeight: "bold",
                letterSpacing: "0.3px",
                cursor: "pointer",
              }}
            >
              Dukandar
            </span>
          </span>
        </div>
      </div>

      <div
        className="d-flex align-items-center justify-content-center p-4 m-1"
        style={{
          borderRadius: "10px",
          border: "1px solid #ABABAB",
          background: "#FFF",
        }}
      >
        <img src={addimg} alt="" />
      </div>

      <div className="fw-bold p-2">To begin adding products</div>

      <div className="h2 p-2">
        {t("Find your products in Dukkandaar's catalog")}
      </div>
      <input
        type="text"
        placeholder="Products name, UPC, EAN, ISBN or ASIN"
        style={{ ...inputsearch, background: "#F6F6F6" }}
      />
      <div style={{ color: "#4A93FF" }}>
        I’m adding a product not sold on Dukkandaar
      </div>
      <div style={{ color: "#4A93FF" }}>
        I’m adding a product not sold on Dukkandaar
      </div>
    </div>
  );
};

export default FindProduct;
