const baseUrl = process.env.REACT_APP_BASE_URL;

export interface DukkandaarDeal {
  dukkandaar_deals_id: string;
  deal_title: string;
  deal_description: string;
  deal_occasion: string;
  show: boolean;
}

export interface Deal {
  deals_id: string;
  dukkandaar_deals_id: string;
  start_date: string;
  end_date: string;
  status: string;
  active: boolean;
  deactivated_by: string | null;
  deactivate_reason: string | null;
  deal_added_by: string;
  deal_added_by_seller_id: string | null;
  deal_updated_by_seller_id: string | null;
  deal_added_by_admin_id: string;
  deal_updated_by_admin_id: string | null;
  createdAt: string;
  updatedAt: string;
  dukkandaar_deal: DukkandaarDeal;
}

export interface DealResponse {
  status: string;
  total_items: number;
  per_page: number;
  total_pages: number;
  current_page: number;
  data: Deal[];
  msg?: string;
}


interface DealItem {
  seller_product_variant: string;
  discount_type: string;
  discount_value: number;
}

interface DealData {
  dukkandaar_deals_id: string;
  start_date: string;
  end_date: string;
  dealItems: DealItem[];
}


export const getAllDeals = async (
  page: number,
  status: string,
  show: number
): Promise<DealResponse> => {
  try {
    const token = localStorage.getItem("token") || "";
    const headers: HeadersInit = {
      "Content-Type": "application/json",
      token: token,
    };

    const response = await fetch(
      `${baseUrl}/get_all_deals/${page}?status=${status}&show=${show}`,
      {
        headers: headers,
      }
    );

    return await response.json();
  } catch (error) {
    console.error("Error occurred:", error);
    throw new Error("Failed to fetch all deals");
  }
};

export const getMyDeals = async (
  page: number,
  status: string,
  show: number
): Promise<DealResponse> => {
  try {
    const token = localStorage.getItem("token") || "";
    const headers: HeadersInit = {
      "Content-Type": "application/json",
      token: token,
    };

    const response = await fetch(
      `${baseUrl}/get_my_deals/${page}?status=${status}&show=${show}`,
      {
        headers: headers,
      }
    );

    return await response.json();
  } catch (error) {
    console.error("Error occurred:", error);
    throw new Error("Failed to fetch my deals");
  }
};

export const getDukkandaarSellerDeals = async () => {
  try {
    const token = localStorage.getItem("token") || "";
    const headers: HeadersInit = {
      "Content-Type": "application/json",
      token: token,
    };
    const response = await fetch(`${baseUrl}/get_all_dukkandaar_seller_deals`, {
      headers: headers,
    });
    if (!response.ok) {
      throw new Error("Failed to fetch deals");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching deals:", error);
    throw error;
  }
};


export const createDeal = async (dealData:DealData) => {
  try {
    const token = localStorage.getItem("token") || "";
    const headers: HeadersInit = {
      "Content-Type": "application/json",
      token: token,
    };
    const response = await fetch(`${baseUrl}/create_deal`, {
      method: "POST",
      headers:headers,
      body: JSON.stringify(dealData),
    });

    if (!response.ok) {
      throw new Error(`Failed to create deal: ${response.statusText}`);
    }

    const responseData = await response.json();
    console.log("Deal created successfully:", responseData);
    return responseData; 
  } catch (error) {
    console.error("Error creating deal:", error);
    throw error;
  }
};

export const joinDeal = async (dealId:string) => {
  try {
    const token = localStorage.getItem("token") || "";
    const headers: HeadersInit = {
      "Content-Type": "application/json",
      token: token,
    };
    const response = await fetch(`${baseUrl}/join_deal/${dealId}`, {
      method: 'POST',
      headers:headers,
   
    });

    const responseData = await response.json();

    if (responseData.status === 'success') {
      return { success: true, message: 'Deal joined successfully' };
    } else {
      return { success: false, message: `Failed to join deal: ${responseData.msg}` };
    }
  } catch (error) {
    return { success: false, message: `Error joining deal: ${error}` };
  }
};


