import React from "react";
import { deleteicon, leftarrow } from "../data";
import { useNavigate } from "react-router-dom";
import searchicon from "../../../../assets/images/searchlogo.png";
import scann from "../../../../assets/images/scann.png";

const AddListing = () => {
  const navigate = useNavigate();
  return (
    <>
      <div
        className="d-flex align-items-center justify-content-between "
        style={{ background: "#E6EDFF", position: "sticky", top: "0px" }}
      >
        <div
          className="d-flex align-items-center gap-2 py-2 px-3 cursor-pointer"
          onClick={() => navigate(-1)}
        >
          {leftarrow}
          Search a Brand, FSN
        </div>
        <div className="d-flex align-items-center gap-3 py-2 px-3 cursor-pointer">
          <img src={scann} alt="" />
        </div>
      </div>
      <div
        className="d-flex align-items-center justify-content-center flex-column shadow-lg p-3"
        style={{
          position: "fixed",
          bottom: "50px",
          width: "100vw",
          borderRadius: "12px",
        }}
      >
        <p className="text-center text-black-50">
          OR <br /> CREATE A NEW DUKAANDAAR LISITNG
        </p>
        <button
          className="create-btn"
          onClick={() => navigate("/apps/AddProduct/createlisting")}
        >
          Create New Listing
        </button>
      </div>
    </>
  );
};

export default AddListing;
