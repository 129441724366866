import React from "react";

const BackToTop = () => {
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  return (
    <div
      className="position-fixed cursor-pointer d-flex d-sm-none"
      style={{
        left: "0px",
        bottom: "0px",
        zIndex: 999,
        height: "50px",
        background: "#353535",
        borderRadius: "10px 10px 0 0",
        width: "100vw",
        color: "white",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",

        fontWeight: "bold",
        fontSize: "20px",
      }}
      onClick={scrollToTop}
    >
      Back To Top
    </div>
  );
};

export default BackToTop;
