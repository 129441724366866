import Loader from "components/Loader";
import { useParams } from "react-router-dom";
import React, { useEffect, useState, useRef } from "react";
import plusIcon from "../../../assets/images/plus.svg";
import { useNavigate } from "react-router-dom";
import "../../../../src/assets/styles.css";
import { toast } from "react-toastify";
import {
  ImageData,
  Product,
  ProductDetailField,
  ProductImage,
  ProductOtherField,
  ProductSizeInvtFields,
  ProductVariation,
  ProductsData,
  productdetailfieldsName,
} from "./product";
import set from "lodash/set";
import noContent from "../../../assets/images/graphics/no-content.png";
import { Alert, Button } from "react-bootstrap";
import crossIcon from "../../../assets/images/cross.svg";
import TwoBallsLoader from "components/Loaders/TwoBallsLoader";

const OVERRIDE_FIELDS_MAP: Record<string, string> = {
  product_name: "product_name_n",
  comment: "product_description_n",
};

function getFieldKey(key: string) {
  return OVERRIDE_FIELDS_MAP[key] || key;
}

interface FormInputProps {
  id: string;
  name: string;
  identifier: string;
  type: string;
  required: boolean;
  description: string;
  placeholder: string;
  options: [] | null;
  value?: any;
  changeValue: any;
}
const GetFormInput: React.FC<FormInputProps> = ({
  id,
  name,
  identifier,
  type,
  required,
  description,
  placeholder,
  options,
  value,
  changeValue,
}) => {
  if (type === "text") {
    return (
      <div className="w-50 px-2 py-1">
        <div className="d-flex align-items-center position-relative">
          <h5>{name}</h5>
          {required && <h6 className="text-danger ms-2">*</h6>}
          <div className="tooltip-btn ms-2">
            <h6 className="rounded-circle border border-primary text-primary px-1">
              i
            </h6>
            <div className="position-absolute bottom-100 tooltip-text">
              {description}
            </div>
          </div>
        </div>
        <input
          type="text"
          name={identifier}
          id={id}
          className="w-100 border border-secondary p-1"
          placeholder={placeholder}
          required={required}
          value={value}
          onChange={(e) => changeValue(e.target.value)}
        />
      </div>
    );
  } else if (type === "select") {
    return (
      <div className="w-50 px-2 py-1">
        <div className="d-flex align-items-center position-relative">
          <h5>{name}</h5>
          {required && <h6 className="text-danger ms-2">*</h6>}
          <div className="tooltip-btn ms-2">
            <h6 className="rounded-circle border border-primary text-primary px-1">
              i
            </h6>
            <div className="position-absolute bottom-100 tooltip-text">
              {description}
            </div>
          </div>
        </div>
        <select
          name={identifier}
          id={id}
          className="w-100 border border-secondary p-1"
          value={value}
          onChange={(e) => changeValue(e.target.value)}
          required={required}
        >
          <option value="">Select</option>
          {options?.map((value) => (
            <option value={value} key={value}>
              {value}
            </option>
          ))}
        </select>
      </div>
    );
  } else if (type === "number") {
    return (
      <div className="w-50 px-2 py-1">
        <div className="d-flex align-items-center position-relative">
          <h5>{name}</h5>
          {required && <h6 className="text-danger ms-2">*</h6>}
          <div className="tooltip-btn ms-2">
            <h6 className="rounded-circle border border-primary text-primary px-1">
              i
            </h6>
            <div className="position-absolute bottom-100 tooltip-text">
              {description}
            </div>
          </div>
        </div>
        <input
          type="number"
          name={identifier}
          id={id}
          className="w-100 border border-secondary p-1"
          placeholder={placeholder}
          required={required}
          value={value}
          onChange={(e) => changeValue(e.target.value)}
        />
      </div>
    );
  } else if (type === "textarea") {
    return (
      <div className="w-100 px-2 py-1">
        <div className="d-flex align-items-center position-relative">
          <h5>{name}</h5>
          {required && <h6 className="text-danger ms-2">*</h6>}
          <div className="tooltip-btn ms-2">
            <h6 className="rounded-circle border border-primary text-primary px-1">
              i
            </h6>
            <div className="position-absolute bottom-100 tooltip-text">
              {description}
            </div>
          </div>
        </div>
        <textarea
          name={identifier}
          id={id}
          placeholder={placeholder}
          required={required}
          className="w-100 border border-secondary p-1"
          rows={5}
          value={value}
          onChange={(e) => changeValue(e.target.value)}
        ></textarea>
      </div>
    );
  } else {
    return <div>...</div>;
  }
};
interface FormTableInputProps {
  id: string;
  name: string;
  identifier: string;
  type: string;
  required: boolean;
  placeholder: string;
  value: any;
  changeValue: any;
}
const GetTableFormInput: React.FC<FormTableInputProps> = ({
  id,
  name,
  identifier,
  type,
  required,
  placeholder,
  value,
  changeValue,
}) => {
  const isSellerPrice = identifier === "seller_price";
  const isDisabled = !isSellerPrice;
  if (type === "text") {
    return (
      <div className="w-100">
        <input
          type="text"
          name={identifier}
          id={id}
          className="w-100 border border-secondary"
          placeholder={placeholder}
          required={required}
          value={value}
          readOnly={!isSellerPrice}
          disabled={isDisabled}
          onChange={(e) => changeValue(e.target.value)}
          style={{ minWidth: "150px" }}
        />
      </div>
    );
  } else if (type === "number") {
    return (
      <div className="w-100">
        <input
          type="number"
          name={identifier}
          id={id}
          className="w-100 border border-secondary"
          placeholder={placeholder}
          required={required}
          value={value}
          readOnly={!isSellerPrice}
          disabled={isDisabled}
          onChange={(e) => changeValue(Number(e.target.value))}
          style={{ minWidth: "150px" }}
        />
      </div>
    );
  } else {
    return <div>...</div>;
  }
};

const EditProduct: React.FC = ({}) => {
  const [showSizeOptions, setShowSizeOptions] = useState<boolean>(false);

  const [selectedVariations, selectVariations] = useState<any>([]);
  const [selectedFullVariations, selectFullVariation] = useState<any>([]);
  const [variationsData, setVariationsData] = useState<any>([]);
  const navigate = useNavigate();

  const reset = () => {
    setShowSizeOptions(false);
    selectVariations([]);
    selectFullVariation([]);
    setVariationsData([]);
  };

  const [formData, setForm] = useState<any>(null);

  const { dsin, catalog_id } =
    useParams<{ dsin: string; catalog_id: string }>();

  const [productData, setProductData] = useState<Product | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchProductData = async () => {
      try {
        const apiUrl = `${process.env.REACT_APP_BASE_URL}/prd_lst/get_single_product/${dsin}/${catalog_id}`;
        const token = localStorage.getItem("token");

        if (!token) {
          throw new Error("Token not found in local storage");
        }

        const myHeaders = new Headers();
        myHeaders.append("token", token);
        myHeaders.append("content-type", "application/json");

        const response = await fetch(apiUrl, {
          method: "GET",
          headers: myHeaders,
        });

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        setProductData(data.product_data);

        selectVariations(
          data.product_data?.product_variants
            .map((v: any) => v.variation_value)
            .flat(2)
        );

        selectFullVariation(
          data.product_data?.product_variants.map((item: any) => {
            const sellerProductVariant = item.seller_products_variants[0];
            return {
              variation_values_id:
                sellerProductVariant?.seller_products_variants_id,
              variation_id: item.product_variants_id,
              name: item.variation_value,
              sku: item.variation_value_sku,
            };
          })
        );

        setVariationsData(
          data.product_data?.product_variants.map(
            (item: any, index: number) => {
              const variationsData = item.seller_products_variants.map(
                (sellerProductVariant: any) => {
                  return {
                    variation_values_id:
                      sellerProductVariant.seller_products_variants_id,
                    variation_id: item.product_variants_id,
                    name: item.variation_value,
                    sku: item.variation_value_sku,
                  };
                }
              );
              return variationsData;
            }
          )
        );
      } catch (error) {
        console.error("Error fetching product data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchProductData();
  }, [dsin, catalog_id]);

  useEffect(() => {
    if (formData === null && productData && productData.category_4_id) {
      const token: string | null = localStorage.getItem("token");
      var raw = JSON.stringify({
        category_4_id: productData.category_4_id,
      });

      var requestOptions: any = {
        method: "POST",
        headers: {
          token: token,
          "Content-Type": "application/json",
        },
        body: raw,
        redirect: "follow",
      };

      fetch(
        `${process.env.REACT_APP_BASE_URL}/prd_lst/fetchProductlistform`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          setForm(result);
        })
        .catch((error) => console.log("error", error));
    }
  }, [formData, productData]);

  const imageInputRef = useRef<any>({});

  const toggleVariation = (variation: any) => {
    if (selectedVariations.includes(variation.name)) {
      let arr: any, arr2: any;
      arr = selectedVariations.filter(function (selected_variation: any) {
        return selected_variation !== variation.name;
      });
      arr2 = selectedFullVariations.filter(function (selected_variation: any) {
        return selected_variation.name !== variation.name;
      });
      selectVariations((x: any) => [...arr]);
      selectFullVariation((x: any) => [...arr2]);
    } else {
      let arr = [...selectedVariations],
        arr2 = [...selectedFullVariations];
      arr.push(variation.name);
      arr2.push(variation);
      selectVariations((x: any) => [...arr]);
      selectFullVariation((x: any) => [...arr2]);
    }
  };

  const getVariations = (variationsData: any) => {
    let pvArr: any = [];
    variationsData.forEach((vd: any) => {
      let attributesArr: any = [];
      formData.productvariationdatafields.forEach((field: any, i: number) => {
        const attributes = {
          name: field.productVarFields.name,
          identifier: field.productVarFields.identifier,
          value: "",
        };
        attributesArr = [...attributesArr, Object.assign({}, attributes)];
      });
      let pv = {};
      if (vd.sku === "free-size") {
        pv = {
          product_variants_id: vd.product_variants_id || "",
          product_id: vd.product_id || "",
          dsin: vd.dsin || "",
          catalog_id: vd.catalog_id || "",
          variation_name: "Free Size",
          variation_value: "Free Size",
          variation_name_sku: "free-size",
          variation_value_sku: "free-size",
          sku: "free-size",
          product_mrp: "",
          seller_products_variants: [
            {
              seller_products_variants_id: vd.seller_products_variants_id || "",
              seller_products_id: vd.seller_products_id || "",
              seller_id: vd.seller_id || "",
              supplier_sku_id: vd.supplier_sku_id || "",
              product_variants_id: vd.product_variants_id || "",
              price: "",
              inventory: "",
              variation_attributes_attri: attributesArr,
              createdAt: vd.createdAt || "",
              seller_product: {
                seller_products_id: vd.seller_product?.seller_products_id || "",
                supplier_product_id:
                  vd.seller_product?.supplier_product_id || "",
                seller: {
                  seller_name: vd.seller_product?.seller?.seller_name || "",
                  shop_name: vd.seller_product?.seller?.shop_name || "",
                  verified: vd.seller_product?.seller?.verified || false,
                  activity_status:
                    vd.seller_product?.seller?.activity_status || false,
                  account_status:
                    vd.seller_product?.seller?.account_status || false,
                },
              },
            },
          ],
        };
      } else {
        pv = {
          product_variants_id: vd.product_variants_id || "",
          product_id: vd.product_id || "",
          dsin: vd.dsin || "",
          catalog_id: vd.catalog_id || "",
          variation_name: "Size",
          variation_value: vd.name || "",
          variation_name_sku: "size",
          variation_value_sku: vd.sku || "",
          sku: `${vd.sku || ""}-${vd.name || ""}`,
          product_mrp: "",
          seller_products_variants: [
            {
              seller_products_variants_id: vd.seller_products_variants_id || "",
              seller_products_id: vd.seller_products_id || "",
              seller_id: vd.seller_id || "",
              supplier_sku_id: vd.supplier_sku_id || "",
              product_variants_id: vd.product_variants_id || "",
              price: "",
              inventory: "",
              variation_attributes_attri: attributesArr,
              createdAt: vd.createdAt || "",
              seller_product: {
                seller_products_id: vd.seller_product?.seller_products_id || "",
                supplier_product_id:
                  vd.seller_product?.supplier_product_id || "",
                seller: {
                  seller_name: vd.seller_product?.seller?.seller_name || "",
                  shop_name: vd.seller_product?.seller?.shop_name || "",
                  verified: vd.seller_product?.seller?.verified || false,
                  activity_status:
                    vd.seller_product?.seller?.activity_status || false,
                  account_status:
                    vd.seller_product?.seller?.account_status || false,
                },
              },
            },
          ],
        };
      }
      pvArr.push(pv);
    });

    if (productData && productData.product_variants) {
      setProductData((prevData: any) => {
        const oldProductVariants = [...prevData.product_variants];

        const productVariants = pvArr.map((item: any) => {
          const existingIndex = oldProductVariants.findIndex(
            (v) => v.variation_value === item.variation_value
          );

          if (existingIndex === -1) {
            return item;
          } else {
            return oldProductVariants[existingIndex];
          }
        });
        return {
          ...prevData,
          product_variants: productVariants,
        };
      });
    } else {
      console.error("productData or product_variants is null");
    }
  };

  const applyVariations = (e: any) => {
    e.preventDefault();
    setShowSizeOptions(false);
    setVariationsData(selectedFullVariations);
    getVariations(selectedFullVariations);
  };
  const resetVariations = (e: any) => {
    e.preventDefault();
    setShowSizeOptions(false);
    selectVariations([]);
    setVariationsData([]);
    selectFullVariation([]);
  };

  useEffect(() => {
    if (
      formData !== null &&
      formData.productsizeinvtfields !== undefined &&
      productData !== null
    ) {
      let arr: any = [];
      if (
        productData.products_images &&
        productData.products_images.length > 0
      ) {
        productData.products_images.forEach((img: any) => {
          let prdct_details_fields: any = [];
          let prdct_size_invt_fields: any = {};
          let prdct_other_fields: any = [];
          formData.productdetailfields.forEach((pd: any) => {
            let field = {
              name: pd.product_details_field.name,
              identifier: pd.product_details_field.identifier,
              value: "",
            };
            prdct_details_fields = [
              ...prdct_details_fields,
              Object.assign({}, field),
            ];
          });
          formData?.productsizeinvtfields.forEach((pd: any) => {
            prdct_size_invt_fields[pd.identifier] = "";
          });
          formData.productotherfields.forEach((pd: any) => {
            let field = {
              name: pd.product_other_field.name,
              identifier: pd.product_other_field.identifier,
              value: "",
            };
            prdct_other_fields = [
              ...prdct_other_fields,
              Object.assign({}, field),
            ];
          });
          let product = {
            category_4_id: formData.category_data.category_4_id,
            type: "submit",
            products_data: {
              productdetailfields: [...prdct_details_fields],
              productsizeinvtfields: prdct_size_invt_fields,
              product_variations: [],
              productotherfields: [...prdct_other_fields],
              image_data: {
                front_image_url: img,
                other_images: [],
              },
            },
          };
          arr = [...arr, Object.assign({}, product)];
        });
      }
    }
  }, [formData, productData]);

  const discardListing = (e: any) => {
    e.preventDefault();
    navigate(-1);
  };

  const mapProductDataForApi = (productData: Product): ProductsData => {
    if (!productData) {
      throw new Error("Product data is null");
    }

    const productDetailFields: ProductDetailField[] =
      productData.product_details_attr;

    const productdetailfields: ProductDetailField[] =
      productdetailfieldsName.map((field, index) => {
        const value = productDetailFields[index];

        field.value = typeof value === "object" ? value.value : value;

        return field;
      });

    const productSizeInvtFields: ProductSizeInvtFields = {
      product_weight_in_gms: productData.product_weight_in_gms.toString(),
      supplier_product_id: productData.uploaded_by_seller_id,
      product_name: productData.product_name_n.toString(),
      comment: productData.product_description_n.toString(),
    };

    const sellerProductsVariantsIds: string[] =
      productData.product_variants.map(
        (variant) =>
          variant.seller_products_variants[0].seller_products_variants_id
      );

    const productVariations: ProductVariation[] = sellerProductsVariantsIds.map(
      (sellerProductsVariantsId, index) => ({
        seller_products_variants_id: sellerProductsVariantsId,
        seller_price:
          productData.product_variants[index]?.seller_products_variants[0]
            ?.price || 0,
      })
    );

    const productOtherFields: ProductOtherField[] =
      productData.other_details_attri;

    const productImages: ProductImage[] = productData.products_images;
    const frontImage = productImages.find(
      (img: ProductImage) => img.is_front_img
    );

    const imageData: ImageData = {
      front_image_url: frontImage ? frontImage.url : "",
      other_images: productImages
        .filter((img: ProductImage) => !img.is_front_img)
        .map((img: ProductImage) => img.url),
    };

    return {
      productdetailfields: productdetailfields,
      productsizeinvtfields: productSizeInvtFields,
      product_variations: productVariations,
      productotherfields: productOtherFields,
      image_data: imageData,
    };
  };

  const submitListing = async (e: React.FormEvent) => {
    e.preventDefault();
    const token: string | null = localStorage.getItem("token");
    if (productData === null) {
      console.error("Product data is null");
      return;
    }
    try {
      const mappedProductData = mapProductDataForApi(productData);

      const requestOptions: RequestInit = {
        method: "POST",
        headers: {
          token: token || "",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          category_4_id: formData.category_data.category_4_id,
          product_id: productData?.product_id || null,
          dsin: productData?.dsin || null,
          catalog_id: productData?.catalog_id || null,
          edit_image: true,
          products_data: mappedProductData,
        }),
        redirect: "follow",
      };

      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/prd_lst/edit_live_catalog`,
        requestOptions
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const result = await response.json();

      if (result.status === "success") {
        toast.success(result.msg);
        reset();
        navigate("/apps/AddProduct");
      } else {
        toast.error(result.msg);
      }
    } catch (error) {
      console.error("Error submitting listing:", error);
      toast.error("An error occurred while submitting the listing");
    }
  };

  const handleInputFileChange = (event: any) => {
    const objectURL = URL.createObjectURL(event.target.files[0]);
    let img = new Image();
    img.src = objectURL;
    img.onload = function () {
      if (img.width === 800 || img.height === 800) {
        var formdata = new FormData();
        formdata.append("file", event.target.files[0], objectURL);

        var requestOptions: RequestInit = {
          method: "POST",
          body: formdata,
          redirect: "follow",
        };

        fetch(
          "https://marketplaceapi.quadbtech.com/api/v1/testing/upload_image_prd_lst",
          requestOptions
        )
          .then((response) => response.json())
          .then((result) => {
            if (result.status === "success") {
              setProductData((prevProductData: any) => {
                prevProductData.products_images.push(result);
                return { ...prevProductData };
              });
            }
          })
          .catch((error) => console.log("error", error));
      } else {
        window.alert("Image should be 800 x 800 px only.");
      }
    };
  };


  const deleteImage = (img: any, i: number) => {
    setProductData((prevProductData: any) => {
      const frontImage = prevProductData.products_images.find(
        (item: any) => item.is_front_img
      );

      let updatedImages = prevProductData.products_images
        .filter((item: any) => !item.is_front_img)
        .filter((item: any, index: number) => index !== i);

      if (frontImage) {
        updatedImages.push(frontImage);
      }

      return {
        ...prevProductData,
        products_images: updatedImages,
      };
    });
    
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      url: img.url,
    });

    var requestOptions: RequestInit = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      "https://marketplaceapi.quadbtech.com/api/v1/testing/del_upload_image_prd_lst",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === "success") {
          toast.success("deleted successfully")
        }
      })
      .catch((error) => console.log("error", error));
  };

  const handleImageChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const file = event.target.files?.[0];

    if (file) {
      const objectURL = URL.createObjectURL(file);

      let img = new Image();
      img.src = objectURL;

      img.onload = function () {
        if (img.width === 800 && img.height === 800) {
          var formdata = new FormData();
          formdata.append("file", file, objectURL);

          var requestOptions: RequestInit = {
            method: "POST",
            body: formdata,
            redirect: "follow",
          };

          fetch(
            "https://marketplaceapi.quadbtech.com/api/v1/testing/upload_image_prd_lst",
            requestOptions
          )
            .then((response) => response.json())
            .then((result) => {
              if (result.status === "success") {
                setProductData((prevProductData: any) => {
                  const frontImage = prevProductData.products_images.find(
                    (img: any) => img.is_front_img
                  );

                  if (frontImage) {
                    frontImage.url = result.url;
                  } else {
                    console.error(
                      "Front image not found in products_images array"
                    );
                  }

                  return { ...prevProductData };
                });
              }
            })
            .catch((error) => console.log("error", error));
        } else {
          window.alert("Image should be 800 x 800 px only.");
        }
      };
    }
  };


  return formData && productData != null && !loading ? (
    <>
      <div className="d-flex align-items-center gap-2 overflow-auto">
        <div role="button">
          <div className="product-main-image">
            <div className="w-100 h-100 border border-secondary rounded-1 bg-light position-relative">
              <img
                src={
                  productData?.products_images?.find(
                    (img: any) => img.is_front_img
                  )?.url || ""
                }
                className="w-100 h-100"
                alt=""
              />
            </div>
          </div>
          <div className={`text-center ${"bg-success text-white"}`}>
            <b>Product </b>
          </div>
        </div>
      </div>
      <form>
        <h4 className="text-base font-semibold bg-light p-2 rounded">
          Product Images
        </h4>
        <div className="d-flex align-items-center gap-2 overflow-auto">
          <div>
            <div className="product-main-image">
              <div className="w-100 h-100 border border-secondary rounded-1 bg-light position-relative">
                <img
                  src={
                    productData?.products_images?.find(
                      (img: any) => img.is_front_img
                    )?.url || ""
                  }
                  className="w-100 h-100"
                  alt=""
                />
              </div>
            </div>
            <div className={`text-center bg-light text-black`}>
              <b>Main Image</b>
            </div>
            <div className="text-center">
              <label
                htmlFor="changeImage"
                className="bg-secondary fs-6 fs-md-4 fw-bold p-1/2 text-white cursor-pointer rounded-pill px-1"
              >
                Change
              </label>
              <input
                type="file"
                id="changeImage"
                className="visually-hidden"
                onChange={handleImageChange}
              />
            </div>
          </div>
          {productData?.products_images?.length > 0 &&
            productData?.products_images
              .filter((item: any) => !item.is_front_img)
              .map((img: any, i: number) => (
                <div key={i}>
                  <div className="product-main-image">
                    <div className="w-100 h-100 border border-secondary rounded-1 bg-light position-relative">
                      <div
                        className="position-absolute top-0 end-0 bg-white rounded-circle cross-catalog-image"
                        onClick={() => deleteImage(img, i)}
                      >
                        <img src={crossIcon} className="w-100" alt="" />
                      </div>
                      <img src={img.url} className="w-100 h-100" alt="" />
                    </div>
                  </div>
                  <div className={`text-center bg-light text-black`}>
                    <b>Image {i + 1}</b>
                  </div>
                </div>
              ))}
          <div role="button" onClick={() => imageInputRef.current.click()}>
            <div className="product-main-image">
              <div className="w-100 h-100 border border-primary rounded-1 d-flex justify-content-center align-items-center">
                <img src={plusIcon} className="w-50" alt="" />
              </div>
            </div>
            <div className={`text-center bg-light text-black`}>
              <b>Add Image</b>
              <input
                type="file"
                className="d-none"
                ref={imageInputRef}
                onChange={handleInputFileChange}
              />
            </div>
          </div>
        </div>

        <h4 className="text-base font-semibold bg-light p-2 rounded">
          Product, Size and Inventory
        </h4>
        <div className="d-flex align-items-center flex-wrap mb-3">
          {formData &&
            formData.productsizeinvtfields.map((field: any) => (
              <GetFormInput
                key={field.product_size_inventory_fields_id}
                id={field.product_size_inventory_fields_id}
                name={field.name}
                identifier={field.identifier}
                type={field.input_types}
                required={field.required}
                description={field.description}
                placeholder={field.placeholder}
                options={field.options || []}
                value={
                  productData
                    ? (productData as any)[getFieldKey(field.identifier)] || ""
                    : ""
                }
                changeValue={(e: any) => {
                  if (productData) {
                    let updatedProductData = { ...productData } as any;
                    updatedProductData[getFieldKey(field.identifier)] = e;
                    setProductData(updatedProductData);
                  }
                }}
              />
            ))}

          <div className="w-50 px-2 py-1">
            <div className="d-flex align-items-center">
              <h5>Size</h5>
            </div>
            <div
              className="w-100 border border-secondary p-1"
              role="button"
              onClick={() => {
                setShowSizeOptions((showSizeOptions) => !showSizeOptions);
              }}
            >
              Select Size
            </div>
            <div
              className={`position-relative w-100 ${
                showSizeOptions ? "" : "d-none"
              }`}
            >
              <div
                className="border border-secondary position-absolute w-100 bg-white zindex-top overflow-auto"
                style={{ maxHeight: "200px" }}
              >
                <div className="d-flex align-items-center flex-wrap ">
                  <div className="m-2 d-flex align-items-center">
                    <input
                      type="checkbox"
                      checked={selectedVariations.includes(
                        formData.productvariationsfields[1].variation.name
                      )}
                      onChange={() =>
                        toggleVariation(
                          formData.productvariationsfields[1].variation
                        )
                      }
                      disabled={true}
                    />
                    <h6 className="ms-1">
                      {formData.productvariationsfields[1].variation.name}
                    </h6>
                  </div>
                  {formData.productvariationsfields[0].variation.variation_values.map(
                    (variation: any) => (
                      <div
                        className="m-2 d-flex align-items-center"
                        key={variation.name}
                      >
                        <input
                          type="checkbox"
                          checked={selectedVariations.includes(variation.name)}
                          onChange={() => toggleVariation(variation)}
                          disabled={true}
                        />
                        <h6 className="m-2">{variation.name}</h6>
                      </div>
                    )
                  )}
                </div>
                <div className="d-flex flex-column flex-sm-row gap-1 px-1 mb-2">
                  <button
                    className="btn btn-danger w-100"
                    onClick={(e) => resetVariations(e)}
                    disabled={true}
                  >
                    Reset
                  </button>
                  <button
                    className="btn btn-primary w-100"
                    onClick={(e) => applyVariations(e)}
                    disabled={true}
                  >
                    Apply
                  </button>
                </div>
              </div>
            </div>
          </div>
          {variationsData.length > 0 && (
            <div className="w-100 px-2 py-1 overflow-auto">
              <table className="table table-bordered ">
                <thead>
                  <tr>
                    <th>Size</th>
                    {formData.productvariationdatafields.map(
                      (field: any, i: number) => (
                        <th className="text-nowrap" key={i}>
                          {field.productVarFields.name}
                        </th>
                      )
                    )}
                    {formData.productpricetaxfields.map(
                      (field: any, i: number) => (
                        <th className="text-nowrap" key={i}>
                          {field.name}
                        </th>
                      )
                    )}
                  </tr>
                </thead>
                <tbody>
                  {productData?.product_variants?.length > 0 &&
                    productData.product_variants.map(
                      (variation: any, i: number) => {
                        return (
                          <tr key={i}>
                            <td className="text-center font-weight-bold">
                              {variation.variation_value}
                            </td>
                            {formData.productvariationdatafields.map(
                              (field: any, j: number) => (
                                <td key={j}>
                                  <select
                                    className="w-100"
                                    name={field.productVarFields.name}
                                    id={field.productVarFields.identifier}
                                    value={
                                      variation?.seller_products_variants?.[0]
                                        ?.variation_attributes_attri?.[j]?.value
                                    }
                                    onChange={(
                                      e: React.ChangeEvent<HTMLSelectElement>
                                    ) => {
                                      const updatedProductData = {
                                        ...productData,
                                      };
                                      const newValue = e.target.value;
                                      set(
                                        updatedProductData,
                                        `product_variants[${i}].seller_products_variants[0].variation_attributes_attri[${j}].value`,
                                        newValue
                                      );
                                      setProductData(updatedProductData);
                                    }}
                                    disabled
                                  >
                                    <option value="">Select</option>
                                    {field.productVarFields.options.map(
                                      (value: any) => (
                                        <option value={value} key={value}>
                                          {value}
                                        </option>
                                      )
                                    )}
                                  </select>
                                </td>
                              )
                            )}
                            {formData.productpricetaxfields.map(
                              (field: any, k: number) => (
                                <td className="" key={k}>
                                  <GetTableFormInput
                                    id={field.identitfier}
                                    name={field.name}
                                    identifier={field.identifier}
                                    type={field.input_types}
                                    required={field.required}
                                    placeholder={field.placeholder}
                                    value={
                                      field.identifier === "inventory"
                                        ? (
                                            productData?.product_variants?.[i]
                                              ?.seller_products_variants?.[0] as any
                                          )?.inventory || ""
                                        : field.identifier === "supplier_sku_id"
                                        ? (
                                            productData?.product_variants?.[i]
                                              ?.seller_products_variants?.[0] as any
                                          )?.supplier_sku_id || ""
                                        : field.identifier === "product_mrp"
                                        ? (productData?.product_variants?.[i]
                                            .product_mrp as any)
                                        : field.identifier === "seller_price"
                                        ? (productData?.product_variants?.[i]
                                            .seller_products_variants?.[0]
                                            ?.price as any)
                                        : (
                                            productData?.product_variants?.[i]
                                              ?.seller_products_variants?.[0] as any
                                          )[field.identifier]
                                    }
                                    changeValue={(e: any) => {
                                      setProductData((prevProductData: any) => {
                                        const updatedProductData = {
                                          ...prevProductData,
                                        };

                                        if (
                                          !updatedProductData.product_variants
                                        ) {
                                          updatedProductData.product_variants =
                                            [];
                                        }

                                        if (
                                          !updatedProductData.product_variants[
                                            i
                                          ]
                                        ) {
                                          updatedProductData.product_variants[
                                            i
                                          ] = {};
                                        }

                                        if (
                                          field.identifier === "product_mrp"
                                        ) {
                                          updatedProductData.product_variants[
                                            i
                                          ].product_mrp = e;
                                        } else if (
                                          field.identifier === "seller_price"
                                        ) {
                                          if (
                                            !updatedProductData
                                              .product_variants[i]
                                              .seller_products_variants
                                          ) {
                                            updatedProductData.product_variants[
                                              i
                                            ].seller_products_variants = [];
                                          }

                                          if (
                                            !updatedProductData
                                              .product_variants[i]
                                              .seller_products_variants[0]
                                          ) {
                                            updatedProductData.product_variants[
                                              i
                                            ].seller_products_variants[0] = {};
                                          }

                                          updatedProductData.product_variants[
                                            i
                                          ].seller_products_variants[0].price =
                                            e;
                                        } else {
                                          set(
                                            updatedProductData,
                                            `product_variants[${i}].seller_products_variants[0].${field.identifier}`,
                                            e
                                          );
                                        }

                                        return updatedProductData;
                                      });
                                    }}
                                  />
                                </td>
                              )
                            )}
                          </tr>
                        );
                      }
                    )}
                </tbody>
              </table>
            </div>
          )}
        </div>
        <h4 className="text-base font-semibold bg-light p-2 rounded">
          Product Details
        </h4>
        <div className="d-flex align-items-center flex-wrap mb-3">
          {formData.productdetailfields.map((field: any, i: number) => (
            <GetFormInput
              key={field.product_details_fields_id}
              id={field.product_details_fields_id}
              name={field.product_details_field.name}
              identifier={field.product_details_field.identifier}
              type={field.product_details_field.input_types}
              required={field.product_details_field.required}
              description={field.product_details_field.description}
              placeholder={field.product_details_field.placeholder}
              options={field.product_details_field.options || []}
              value={productData?.product_details_attr[i]?.value}
              changeValue={(e: any) => {
                let pd = productData;
                pd.product_details_attr[i] = e;
                setProductData((prevProductData: any) => {
                  let updatedProductData = { ...prevProductData };

                  let updatedDetailsAttr = [
                    ...updatedProductData.product_details_attr,
                  ];

                  updatedDetailsAttr[i] = e;

                  updatedProductData.product_details_attr = updatedDetailsAttr;

                  return updatedProductData;
                });
              }}
            />
          ))}
        </div>
        <h4 className="text-base font-semibold bg-light p-2 rounded">
          Other Attributes
        </h4>
        <div className="d-flex align-items-center flex-wrap mb-3">
          {formData.productotherfields.map((field: any, i: number) => (
            <GetFormInput
              key={field.product_other_fields_id}
              id={field.product_other_fields_id}
              name={field.product_other_field.name}
              identifier={field.product_other_field.identifier}
              type={field.product_other_field.input_types}
              required={field.product_other_field.required}
              description={field.product_other_field.description}
              placeholder={field.product_other_field.placeholder}
              options={field.product_other_field.options || []}
              value={productData.other_details_attri[i].value}
              changeValue={(e: any) => {
                setProductData((prevProductData: any) => {
                  let updatedProductData = { ...prevProductData };

                  let updatedOtherDetailsAttri = [
                    ...updatedProductData.other_details_attri,
                  ];

                  updatedOtherDetailsAttri[i].value = e;

                  updatedProductData.other_details_attri =
                    updatedOtherDetailsAttri;

                  return updatedProductData;
                });
              }}
            />
          ))}
        </div>
        <div className="d-flex justify-content-center align-items-center gap-3 flex-wrap mb-4">
          <button
            className="btn btn-danger fs-4 px-4 fw-bolder"
            onClick={(e) => discardListing(e)}
          >
            Discard Listing
          </button>
          <button
            className="btn btn-success fs-4 px-4 fw-bolder"
            onClick={(e) => submitListing(e)}
          >
            Submit Listing
          </button>
        </div>
      </form>
    </>
  ) : (
    <div>
      {loading && <TwoBallsLoader />}
      {!loading && productData === null ? (
        <Alert variant="danger" className="text-center">
          <Alert.Heading className="text-2xl font-bold mb-4">
            Product Not Found
          </Alert.Heading>
          <p className="text-gray-600 mb-6">
            We're sorry, but the product you are looking for could not be found.
            Please check the details and try again.
          </p>
          <span role="img" aria-label="Emoji" className="text-4xl mb-2">
            😞
          </span>
          <img
            src={noContent}
            alt="no content"
            className="w-16 h-16 mx-auto mt-4"
          />
          <hr />
          <div className="d-flex justify-content-end">
            <Button
              variant="outline-danger"
              className="px-4 py-2"
              onClick={() => {
                navigate(-1);
              }}
            >
              Go Back
            </Button>
          </div>
        </Alert>
      ) : null}
    </div>
  );
};

export default EditProduct;
