import React from "react";
import { Col, Row } from "react-bootstrap";
import { graycheck, greencheck, orangecheck } from "../data";
import addimg from "../../../../assets/images/addimg.png";
import { useNavigate } from "react-router-dom";

// interface input {
//   background?: string;
//   height?: string;
//   borderRadius?: string;
//   width?: string;
// }

const inputsearch = {
  //   background: "#F7F7F7",
  height: "36px",
  borderRadius: "18px",
  width: "csl(100% - 20px)",
  border: "1px solid gray",
  margin: "10px 0",
  padding: "10px 20px",
  boxShadow: "0px 2px 5px lightgray",
  whiteSpace: "nowrap",
} as React.CSSProperties;
const inputspan = {
  //   background: "#F7F7F7",
  height: "36px",
  borderRadius: "18px",
  width: "100%",
  border: "1px solid gray",
  // margin: "5px 0 10px 0",
  padding: "10px 20px",
  boxShadow: "0px 2px 5px lightgray",
  whiteSpace: "nowrap",
} as React.CSSProperties;
const textarea = {
  //   background: "#F7F7F7",
  maxHeight: "80px",
  minHeight: "80px",
  borderRadius: "18px",
  width: "csl(100% - 20px)",
  border: "1px solid gray",
  margin: "10px 0",
  padding: "10px 20px",
  boxShadow: "0px 2px 5px lightgray",
} as React.CSSProperties;

const mybtn = {
  background: "#FFD814",
  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
  borderRadius: "18px",
  color: "black",
} as React.CSSProperties;

const ProductInfo = ({ setshowlistingform }: any) => {
  const navigate = useNavigate();
  const products = Array(6).fill("Product");
  return (
    <Row className="mx-2">
      <div className="d-flex align-items-center justify-content-between py-1">
        <div>{greencheck} Select Vertical</div>
        <div>{greencheck} Select Brand</div>
        <div>{orangecheck} Product info</div>
      </div>
      <div className="d-flex align-items-center justify-content-between gap-3 overflow-auto py-2">
        <div
          style={{
            color: "#697475",
            fontSize: "12px",
            whiteSpace: "nowrap",
            fontWeight: "bold",
          }}
        >
          PRODUCT IMAGES
        </div>
        <div
          style={{
            color: "#697475",
            fontSize: "12px",
            whiteSpace: "nowrap",
            fontWeight: "bold",
          }}
        >
          PRICE, STOCK AND SHIPPING
        </div>
        <div
          style={{
            color: "#697475",
            fontSize: "12px",
            whiteSpace: "nowrap",
            fontWeight: "bold",
          }}
        >
          PRODUCT DESCRIPTION
        </div>
      </div>
      <div className="d-flex align-items-center justify-content-between py-2">
        <div className="fw-bold">Sales Package View</div>
        <div className="fw-bold text-primary">View Guideline</div>
      </div>
      <div
        className="d-flex align-items-center justify-content-center"
        style={{
          borderRadius: "10px",
          height: "150px",
          border: "2px solid #FFF",
          background: "#ABABAB",
        }}
      >
        <img
          src={addimg}
          alt=""
          style={{
            height: "45px",
            width: "45px",
          }}
        />
      </div>
      <button style={mybtn} className="btn my-2 px-3 w-100">
        Upload Photo
      </button>
      <div className="d-flex gap-2 p-2">
        {products.map((_, i) => {
          return (
            <div
              key={`${i}-productlisting`}
              className="p-1"
              style={{
                borderRadius: "7px",
                background: "#D9D9D9",
              }}
            >
              <div
                style={{
                  fontSize: "9px",
                  textAlign: "center",
                }}
              >
                {" "}
                Asymmetric Top
              </div>
              <div>
                <img src={addimg} alt="" />
              </div>
            </div>
          );
        })}
      </div>
      <Col
        md={6}
        className="p-2"
        // style={{ boxShadow: "-2px -2px 3px lightgray", borderRadius: "18px" }}
      >
        <div className="d-flex align-items-center justify-content-between">
          <h2 className="mt-3">Listing Details</h2>
        </div>
        <Row>
          <label htmlFor="skuid" className="w-100 my-2">
            Seller SKU ID
          </label>
          <input
            type="text"
            id="skuid"
            style={{ ...inputspan, minWidth: "100px", fontSize: "12px" }}
          />
        </Row>

        <Row>
          <label className="w-100 my-2">Listing Status</label>

          <div className="d-flex align-items-center justify-content-start gap-2 flex-sm-nowrap pb-2 pt-1">
            <span className="form-check">
              <input
                className="form-check-input"
                type="radio"
                name="flexRadioDefault"
                id="flexRadioDefault1"
              />
              <label
                className="form-check-label text-black-50"
                htmlFor="flexRadioDefault1"
              >
                Active
              </label>
            </span>
            <span className="form-check">
              <input
                className="form-check-input"
                type="radio"
                name="flexRadioDefault"
                id="flexRadioDefault1"
              />
              <label
                className="form-check-label text-black-50"
                htmlFor="flexRadioDefault1"
              >
                Inactive
              </label>
            </span>
          </div>
        </Row>

        <Row>
          <label htmlFor="title" className="w-100 my-2">
            Title
          </label>
          <textarea
            name="title"
            id="title"
            // className=""
            style={textarea}
            // placeholder="Enter title"
          ></textarea>
        </Row>

        <Row>
          <label className="w-100 my-2">Listing Status</label>

          <div className="d-flex align-items-start justify-content-start gap-1 flex-sm-nowrap py-2">
            <span
              className="d-flex align-items-center justify-content-center cursor-pointer"
              style={{ ...inputspan, minWidth: "100px", fontSize: "12px" }}
            >
              Fulfillment by
            </span>
            <span
              className="d-flex align-items-center justify-content-center cursor-pointer"
              style={{ ...inputspan, minWidth: "100px", fontSize: "12px" }}
            >
              Procurement Type
            </span>
            <span
              className="d-flex align-items-center justify-content-center cursor-pointer"
              style={{ ...inputspan, minWidth: "100px", fontSize: "12px" }}
            >
              Procurement SLA
            </span>
          </div>
        </Row>

        <Row>
          <label htmlFor="stockavailability" className="w-100 my-2">
            Stock Availability
          </label>
          <input
            type="text"
            id="stockavailability"
            style={{ ...inputspan, minWidth: "100px", fontSize: "12px" }}
            // className=""
            // placeholder="Enter SKU ID"
          />
        </Row>

        <Row>
          <label className="w-100 my-2">Packaging Details</label>

          <div className="d-flex align-items-center justify-content-start gap-2 py-2 overflow-auto">
            <span
              className="d-flex align-items-center justify-content-center cursor-pointer"
              style={{ ...inputspan, minWidth: "100px", fontSize: "12px" }}
            >
              Weight
            </span>
            <span
              className="d-flex align-items-center justify-content-center cursor-pointer"
              style={{ ...inputspan, minWidth: "100px", fontSize: "12px" }}
            >
              Length
            </span>
            <span
              className="d-flex align-items-center justify-content-center cursor-pointer"
              style={{ ...inputspan, minWidth: "100px", fontSize: "12px" }}
            >
              Breadth
            </span>
            <span
              className="d-flex align-items-center justify-content-center cursor-pointer"
              style={{ ...inputspan, minWidth: "100px", fontSize: "12px" }}
            >
              Height
            </span>
          </div>
        </Row>

        <Row>
          <label className="w-100 my-2">
            Shipping Charges
            <div className="text-black-50 fw-lighter">
              (If membership has been taken hen there is no chargers)
            </div>
          </label>

          <div className="d-flex align-items-center justify-content-start gap-2 flex-sm-nowrap">
            <span
              className="d-flex align-items-center justify-content-center cursor-pointer"
              style={{ ...inputspan, minWidth: "100px", fontSize: "12px" }}
            >
              Local
            </span>
            <span
              className="d-flex align-items-center justify-content-center cursor-pointer"
              style={{ ...inputspan, minWidth: "100px", fontSize: "12px" }}
            >
              Zonal
            </span>
            <span
              className="d-flex align-items-center justify-content-center cursor-pointer"
              style={{ ...inputspan, minWidth: "100px", fontSize: "12px" }}
            >
              National
            </span>
          </div>
        </Row>

        <Row>
          <label className="w-100 my-2">Price Details</label>

          <div className="d-flex align-items-center justify-content-start gap-2 flex-sm-nowrap">
            <span
              className="d-flex align-items-center justify-content-center cursor-pointer"
              style={{ ...inputspan, minWidth: "100px", fontSize: "12px" }}
            >
              MRP
            </span>
            <span
              className="d-flex align-items-center justify-content-center cursor-pointer"
              style={{ ...inputspan, minWidth: "100px", fontSize: "12px" }}
            >
              Your Price
            </span>
          </div>
        </Row>

        <Row>
          <label htmlFor="wholesale" className="w-100 my-2">
            Wholesale
          </label>
          <input
            type="text"
            id="wholesale"
            style={{ ...inputspan, minWidth: "100px", fontSize: "12px" }}
            // className=""
            placeholder="%Off Business Price"
          />
        </Row>

        <Row>
          <label className="w-100 my-2">Tax Details</label>

          <div className="d-flex align-items-center justify-content-start gap-2 flex-sm-nowrap">
            <span
              className="d-flex align-items-center justify-content-center cursor-pointer"
              style={{ ...inputspan, minWidth: "100px", fontSize: "12px" }}
            >
              HSL
            </span>
            <span
              className="d-flex align-items-center justify-content-center cursor-pointer"
              style={{ ...inputspan, minWidth: "100px", fontSize: "12px" }}
            >
              Luxury Cess
            </span>
            <span
              className="d-flex align-items-center justify-content-center cursor-pointer"
              style={{ ...inputspan, minWidth: "100px", fontSize: "12px" }}
            >
              Tax Code
            </span>
          </div>
        </Row>

        <Row>
          <label className="w-100 my-2">Manufacturing Details</label>

          <div className="d-flex align-items-center justify-content-start gap-2 flex-sm-nowrap">
            <span
              className="d-flex align-items-center justify-content-center cursor-pointer w-25"
              style={{ ...inputspan, minWidth: "100px", fontSize: "12px" }}
            >
              Country Origin
            </span>
          </div>

          <textarea
            name="importerdetails"
            id="importerdetails"
            // className=""
            style={textarea}
            placeholder="Importer Details"
          ></textarea>
          <textarea
            name="packerdetails"
            id="packerdetails"
            // className=""
            style={textarea}
            placeholder="Packer Details"
          ></textarea>
        </Row>

        <button
          className="btn my-2 px-3 w-100"
          style={mybtn}
          onClick={() => navigate("/apps/AddProduct/ProductDetail")}
        >
          Sent for QC
        </button>
      </Col>
    </Row>
  );
};
export default ProductInfo;
