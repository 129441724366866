import React from "react";
import { Col } from "react-bootstrap";
import Analytics from "./Analytics";
import Products from "./Products";
import DocOrders from "./DocOrders";
import DocSettings from "./DocSettings";
import DocInventory from "./DocInventory";
import DocAdvertising from "./DocAdvertising";
import DocContactUs from "./DocContactUs";

interface RightSectionProps {
  selectedItem: string | null;
}

interface ItemData {
  name: string;
  component?: React.FC;
}

const itemData: ItemData[] = [
  {
    name: "Analytic",
    component: Analytics,
  },
  {
    name: "Products",
    component: Products,
  },
  {
    name: "Orders",
    component: DocOrders,
  },
  {
    name: "Settings",
    component: DocSettings,
  },

  {
    name: "Inventory",
    component: DocInventory,
  },
  {
    name: "Advertising",
    component: DocAdvertising,
  },
  {
    name: "Contact Us",
    component: DocContactUs,
  },
];

const DcoRightSection: React.FC<RightSectionProps> = ({ selectedItem }) => {
  const selectedItemData = itemData.find((item) => item.name === selectedItem);

  return (
    <Col style={{ padding: "20px" }}>
      {selectedItemData && selectedItemData.component && (
        <>
          <selectedItemData.component />
        </>
      )}
    </Col>
  );
};

export default DcoRightSection;
