import React from "react";

interface SettingsProps {
  // Define any props if needed
}

const Step1: React.FC = () => (
  <div>
    <h2>Step 1: Navigate to Settings</h2>
    <p>
      You begin by selecting the 'Settings' option from the sidebar menu, which
      is indicated by a gear icon. This is typically where you can configure
      various aspects of your account and operations.
    </p>
  </div>
);

const Step2: React.FC = () => (
  <div>
    <h2>Step 2: Account Management</h2>
    <p>
      Account Management: The first card under settings is labelled 'Account
      Management' with an icon of a person. This is where you would manage
      account-specific information such as your name, contact details, and
      possibly your profile photo.
    </p>
  </div>
);

const Step3: React.FC = () => (
  <div>
    <h2>Step 3: Bank Details</h2>
    <p>
      Bank Details: The next card is for 'Bank Details', represented by a credit
      card icon. In this section, you would manage your banking information,
      such as your account number and routing information, which the platform
      would use for transactions and payouts.
    </p>
  </div>
);

const Step4: React.FC = () => (
  <div>
    <h2>Step 4: Billing Addresses</h2>
    <p>
      Billing Addresses: The third card with an envelope icon is for 'Billing
      Addresses'. This setting is likely where you input or update the address
      information for billing purposes, such as where you receive invoices or
      credit card statements.
    </p>
  </div>
);

const Step5: React.FC = () => (
  <div>
    <h2>Step 5: Shipping Addresses</h2>
    <p>
      Shipping Addresses: The fourth card, also labelled 'Addresses' but with a
      truck icon, suggests a place to manage your shipping addresses. This could
      be the return address displayed on packages or the primary location from
      which you ship your products.
    </p>
  </div>
);

const Step6: React.FC = () => (
  <div>
    <h2>Step 6: Return Addresses</h2>
    <p>
      Return Addresses: The 'Return Addresses' card with a U-turn arrow icon is
      where you would specify where returned items should be sent. This is
      critical for handling returns efficiently and ensuring they go to the
      correct location for processing.
    </p>
  </div>
);

const Step7: React.FC = () => (
  <div>
    <h2>Step 7: PickUp Addresses</h2>
    <p>
      PickUp Addresses: The final card, labelled 'PickUp Addresses' with a
      pickup truck icon, is presumably where you would set addresses for product
      pickups, perhaps by a courier service or for customer returns.
    </p>
  </div>
);

const DocSettings: React.FC<SettingsProps> = () => {
  return (
    <div>
      <h1>Settings</h1>
      <Step1 />
      <Step2 />
      <Step3 />
      <Step4 />
      <Step5 />
      <Step6 />
      <Step7 />
    </div>
  );
};

export default DocSettings;
