const downarrowsm = (
  <svg
    width="13"
    height="13"
    viewBox="0 0 23 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.6635 12.5235C12.3549 12.8286 11.9364 13 11.5 13C11.0636 13 10.6451 12.8286 10.3365 12.5235L0.46198 2.75822C0.162195 2.45126 -0.00368749 2.04014 6.22135e-05 1.6134C0.00381191 1.18666 0.176893 0.778456 0.482028 0.476696C0.787163 0.174937 1.19994 0.00376975 1.63145 6.15254e-05C2.06295 -0.0036467 2.47867 0.160401 2.78907 0.456871L11.5 9.07146L20.2109 0.456871C20.5213 0.160401 20.937 -0.0036467 21.3686 6.15254e-05C21.8001 0.00376975 22.2128 0.174937 22.518 0.476696C22.8231 0.778456 22.9962 1.18666 22.9999 1.6134C23.0037 2.04014 22.8378 2.45126 22.538 2.75822L12.6635 12.5235Z"
      fill="#1E1E1E"
    />
  </svg>
);

interface Order {
  products: string;
  price: string;
  img: string;
  orderid: string;
}

const Spfdata: Order[] = [
  {
    products: "Product 1",
    orderid: "123",
    price: "Rs.1,000",
    img: "https://marketplace.quadbtech.com/static/media/dress7.2615f9ee5969d980bb6d.png",
  },
  {
    products: "Product 1",
    orderid: "1234",
    price: "Rs.1,000",
    img: "https://marketplace.quadbtech.com/static/media/dress7.2615f9ee5969d980bb6d.png",
  },
  {
    products: "Product 2",
    orderid: "456",
    price: "Rs.2,000",
    img: "https://marketplace.quadbtech.com/static/media/dress7.2615f9ee5969d980bb6d.png",
  },
  {
    products: "Product 3",
    orderid: "789",
    price: "Rs.3,000",
    img: "https://marketplace.quadbtech.com/static/media/dress7.2615f9ee5969d980bb6d.png",
  },
];

interface spf {
  orderid: string;
  id:number;
  img:string
}

const Safedata: spf[] = [
  {
    id:1,
    orderid: "123",
    img: "https://marketplace.quadbtech.com/static/media/dress7.2615f9ee5969d980bb6d.png",
  },
 
];
const yellowbtn = {
  background: "#FFD814",
  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
  borderRadius: "119px",
  color: "black",
  padding: "5px 15px",
  //   width: "350px",
  fontWeight: "bold",
  //   marginBottom: "25px",
  //   fontWeight:"bold"
} as React.CSSProperties;
const dropdowndiv = {
  background: "#F3F3F4",
  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
  borderRadius: "119px",
  color: "black",
  padding: "5px 15px",
  cursor: "pointer",
} as React.CSSProperties;
const search = {
  background: "#F4F4F4",
  borderRadius: "18px",
};


export { downarrowsm, Spfdata, Safedata,yellowbtn,dropdowndiv,search };
