export const getSellerNotifications = async (
  pageNumber: number,
  showCount: number,
  token: string
): Promise<any> => {
  const response = await fetch(
    `${process.env.REACT_APP_BASE_URL}/get_seller_notification/${pageNumber}?show=${showCount}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "token": `${token}`,
      },
    }
  );

  if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
  }

  return response.json();
};
