import {
  FETCH_DATA_REQUEST,
  FETCH_DATA_SUCCESS,
  FETCH_DATA_FAILURE,
  FETCH_SELLER_REQUEST,
  FETCH_SELLER_SUCCESS,
} from "./action";
import axios, { AxiosResponse } from "axios";
import { all, fork, put, takeEvery } from "redux-saga/effects";

function* loginApi(action: any): Generator<any, void, any> {
  try {
    const { email, password } = action.payload;
    const loginUrl = `${process.env.REACT_APP_BASE_URL}/seller_login`;

    const loginResponse: any = yield fetch(loginUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email, password }),
    });

    if (!loginResponse.ok) {
      throw new Error("Failed to login");
    }

    const loginData = yield loginResponse.json();
    const token = loginData.token;

    localStorage.setItem("token", token);

    let inforesponse: any = yield fetch(loginUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        token: token,
      },
    });

    inforesponse = yield inforesponse.json();

    if (inforesponse.status === "Success") {
      const secretKey = process.env.REACT_APP_SECRET_KEY;
      const ciphertext = inforesponse.data;
      const bytes = CryptoJS.AES.decrypt(ciphertext, secretKey!);
      const plaintext = bytes.toString(CryptoJS.enc.Utf8);
      inforesponse = JSON.parse(plaintext);
    }

    yield put({ type: FETCH_DATA_SUCCESS, payload: inforesponse });
  } catch (error: any) {
    console.error("Error in loginApi", error);
    yield put({ type: FETCH_DATA_FAILURE, payload: error.message });
  }
}
function* sellerInfo(): Generator<any, void, Response> {
  try {
    const token: string | null = localStorage.getItem("token");
    let response: any = yield fetch(
      `${process.env.REACT_APP_BASE_URL}/seller_login`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          token: token!,
        },
      }
    );
    if (response?.status === "Success") {
      const secretKey: any = process.env.REACT_APP_SECRET_KEY;
      const ciphertext: any = response?.data;

      const bytes = CryptoJS.AES.decrypt(ciphertext, secretKey);
      const plaintext = bytes.toString(CryptoJS.enc.Utf8);
      response = JSON.parse(plaintext);
      console.log("dt");
      // return dt;
    }
    yield put({ type: FETCH_SELLER_SUCCESS, payload: response });
  } catch (error: any) {
    console.error("Error in loginApi", error);

    yield put({ type: FETCH_DATA_FAILURE, payload: error.message });
  }
}

function* watchLoginApiSaga() {
  yield takeEvery(FETCH_DATA_REQUEST, loginApi);
}

function* watchSellerInfoSaga() {
  yield takeEvery(FETCH_SELLER_REQUEST, sellerInfo);
}

function* loginApiSaga() {
  yield all([fork(watchLoginApiSaga), fork(watchSellerInfoSaga)]);
}

export default loginApiSaga;
