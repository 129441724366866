import React from "react";
import { Col, Row, Table } from "react-bootstrap";
// import { FiCircle } from "react-icons/fi";
import emptyimg from "../../../assets/images/emptyimg.png";
import { yellowbtn } from "../data";

function CourierPartner() {
  return (
    <Row className="px-2">
      <Col md={8}>
        <h5>Customer Return Courier Partner Performance</h5>
        <p>
          <span
            style={{
              borderRadius: "50%",
              height: "5px",
              width: "5px",
              background: "gray",
              display: "inline-block",
              verticalAlign: "middle",
            }}
          ></span>{" "}
          Decide the courier partner based on the details given below in the
          table. <br />
          <span
            style={{
              borderRadius: "50%",
              height: "5px",
              width: "5px",
              background: "gray",
              display: "inline-block",
              verticalAlign: "middle",
            }}
          ></span>{" "}
          Use set preference to define your sequence of preferred courier
          partners. <br />
          <span
            style={{
              borderRadius: "50%",
              height: "5px",
              width: "5px",
              background: "gray",
              display: "inline-block",
              verticalAlign: "middle",
            }}
          ></span>{" "}
          Return wil be done using your defined preferred courier partner from
          this list, depending on their availability and capacity determined by
          Dukkandaar.
        </p>
        <div>
          To know more visit{" "}
          <span className="text-primary cursor-pointer">FAQs</span>
        </div>
      </Col>
      <Col md={4}>
        <img src={emptyimg} alt="" className="w-50 h-100" />
      </Col>
      <Row
        className="py-1 my-2"
        style={{
          borderTop: "1px solid #CECECE",
          borderBottom: "1px solid #CECECE",
        }}
      >
        <Col md={8}>
          <h5>My Courier Partner for Customer Returns</h5>
          <p>Last 1 month’s customer return performance in your PIN Code</p>
        </Col>
        <Col
          md={4}
          className="d-flex align-items-center justify-content-evenly"
        >
          <span className="text-primary cursor-pointer">View rate Card</span>
          <button className="btn" style={{ ...yellowbtn }}>
            Set My Choice
          </button>
        </Col>
      </Row>
      <div style={{ overflowX: "auto" }}>
        <Table striped bordered hover size="sm">
          <thead>
            <tr>
              <th className="text-nowrap">Preference</th>
              <th className="text-nowrap">Courier Partner</th>
              <th className="text-nowrap">Reverse Shipping Chargers</th>
              <th className="text-nowrap">Avg Return Times (Days)</th>
              <th className="text-nowrap">Claims Raised</th>
              <th className="text-nowrap">Courier Partner Claim Approval%</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="text-nowrap">Dukkandaar Recommended</td>
              <td className="text-nowrap">Xpressbees</td>
              <td className="text-nowrap">Rs.115 for 1st 500gm</td>
              <td className="text-nowrap">0.0</td>
              <td className="text-nowrap">N/A</td>
              <td className="text-nowrap">N/A</td>
            </tr>
            <tr>
              <td className="text-nowrap">Dukkandaar Recommended</td>
              <td className="text-nowrap">Xpressbees</td>
              <td className="text-nowrap">Rs.115 for 1st 500gm</td>
              <td className="text-nowrap">0.0</td>
              <td className="text-nowrap">N/A</td>
              <td className="text-nowrap">N/A</td>
            </tr>
            <tr>
              <td className="text-nowrap">Dukkandaar Recommended</td>
              <td className="text-nowrap">Xpressbees</td>
              <td className="text-nowrap">Rs.115 for 1st 500gm</td>
              <td className="text-nowrap">0.0</td>
              <td className="text-nowrap">N/A</td>
              <td className="text-nowrap">N/A</td>
            </tr>
            <tr>
              <td className="text-nowrap">Dukkandaar Recommended</td>
              <td className="text-nowrap">Xpressbees</td>
              <td className="text-nowrap">Rs.115 for 1st 500gm</td>
              <td className="text-nowrap">0.0</td>
              <td className="text-nowrap">N/A</td>
              <td className="text-nowrap">N/A</td>
            </tr>
            <tr>
              <td className="text-nowrap">Dukkandaar Recommended</td>
              <td className="text-nowrap">Xpressbees</td>
              <td className="text-nowrap">Rs.115 for 1st 500gm</td>
              <td className="text-nowrap">0.0</td>
              <td className="text-nowrap">N/A</td>
              <td className="text-nowrap">N/A</td>
            </tr>
          </tbody>
        </Table>
      </div>
    </Row>
  );
}

export default CourierPartner;
