// LeftSection.tsx
import React from "react";
import { Badge, Card, OverlayTrigger, Spinner, Tooltip } from "react-bootstrap";
import { MAX_CATEGORY_LENGTH } from "./InventoryProduct";
import { FaTag } from "react-icons/fa";
import { useTranslation } from "react-i18next";

interface ProductVariant {
  name: string;
  value: string;
  identifier: string;
}

interface SellerProductsVariant {
  seller_products_variants_id: string;
  inventory: number;
  dukkandar_price: number;
  seller_price: number;
  variation_attributes: Record<string, Record<string, string>>;
  variation_attributes_attri: ProductVariant[];
}

interface ProductVariantDetails {
  product_variants_id: string;
  dsin: string;
  catalog_id: string;
  variation_name: string;
  variation_value: string;
  variation_name_sku: string;
  variation_value_sku: string;
  sku: string;
  product_mrp: number;
}

interface ProductDetails {
  product_id: string;
  category_4: Category4Details;
  product_name_n: string;
  status: string;
  active: boolean;
  blocked: boolean;
  is_pause: boolean;
  uploaded_by: string;
  products_images: Array<{
    url: string;
  }>;
}

interface SellerDetails {
  seller_name: string;
  shop_name: string;
}
interface Category4Details {
  category_4_id: string;
  name: string;
}
interface ModifiedInventoryProduct {
  seller_products_id: string;
  catalog_id: string;
  product_name_n: string;
  category_4: Category4Details;
  product: ProductDetails;
  seller: SellerDetails;
  seller_products_variants: SellerProductsVariant[];
}

interface LeftSectionProps {
  items: ModifiedInventoryProduct[];
  onCardClick: (id: string) => void;
  selectedCard: string | null;
  loading: boolean;
}

const LeftSection: React.FC<LeftSectionProps> = ({
  items,
  onCardClick,
  selectedCard,
  loading,
}) => {
  const { t } = useTranslation();

  const renderTooltip = (category: string) => (
    <Tooltip id="button-tooltip">{category}</Tooltip>
  );
  return (
    <div className="left-section-container">
      <h4>{t("Inventory List")}</h4>
      {loading ? (
        <div
          className="text-center d-flex align-items-start justify-content-center"
          style={{ height: "100vh" }}
        >
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      ) : (
        <div className="cards-container">
          {items
            .filter((item: any) => item.seller_products_variants.length > 0)
            .map((item) => (
              <Card
                key={item.seller_products_id}
                className={`card ${
                  selectedCard === item.seller_products_id ? "selected" : ""
                }`}
                onClick={() => onCardClick(item.seller_products_id)}
                style={{
                  cursor: "pointer",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                  border: "1px solid #dee2e6",
                  borderRadius: "8px",
                  marginBottom: "16px",
                  backgroundColor:
                    selectedCard === item.seller_products_id
                      ? "#e0f7fa"
                      : "white",
                  transition: "background-color 0.3s ease",
                  marginRight: "5%",
                }}
              >
                <Card.Body className="d-flex flex-sm-column flex-xl-row justify-content-between">
                  <Card.Img
                    style={{
                      width: "64px",
                      height: "64px",
                      borderRadius: "0.5rem",
                      border:"1px solid rgba(0,0,0,0.1)"
                    }}
                    variant="top"
                    src={item.product.products_images[0].url || ""}
                  />
                  <div className="mx-2" >
                    <Card.Title className="ellipsis-tooltip mt-0">
                      {item.product.product_name_n}
                    </Card.Title>{" "}
                    <Card.Text className="mb-1">
                      <strong>Catalog Id:</strong> {item.catalog_id}
                    </Card.Text>
                    <Card.Text className="d-flex" style={{ maxWidth: "200px" }}>
                      <strong>Category:</strong>
                      <OverlayTrigger
                        placement="top"
                        overlay={renderTooltip(item.product.category_4.name)}
                        trigger="hover"
                      >
                        <div>
                          <FaTag
                            data-testid={item.product.category_4.name}
                            className="ms-2"
                            style={{ cursor: "pointer" }}
                          />
                        </div>
                      </OverlayTrigger>
                    </Card.Text>
                  </div>
                </Card.Body>
              </Card>
            ))}
        </div>
      )}
    </div>
  );
};

export default LeftSection;
