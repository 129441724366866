import React, { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { FiChevronDown } from "react-icons/fi";

interface DropdownMenuProps {
  label: string;
  options: string[];
  selectedValue: string | null;
  onValueChange: (value: string) => void;
}

const DropdownMenu: React.FC<DropdownMenuProps> = ({
  label,
  options,
  selectedValue,
  onValueChange,
}) => {
  const handleValueChange = (value: string) => {
    onValueChange(value);
  };

  return (
    <Dropdown className="w-100 mb-2 mb-sm-0">
      <Dropdown.Toggle
        variant="light"
        id={`dropdown-${label}`}
        className="w-100"
      >
        {selectedValue ? selectedValue : label} <FiChevronDown />
      </Dropdown.Toggle>
      <Dropdown.Menu
        className="w-100"
        style={{ maxHeight: "200px", overflowY: "auto", overflowX: "hidden" }}
      >
        {options.map((option, index) => (
          <Dropdown.Item
            key={index}
            onClick={() => handleValueChange(option)}
            active={option === selectedValue}
          >
            {option}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default DropdownMenu;
