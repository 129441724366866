import i18n from "i18next";
import detector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";


// import translationEs from "./locales/es/translation.json";
import translationEn from "./locales/en/translation.json";
import translationHn from "./locales/hi/translation.json";
import translationpa from "./locales/pa/translation.json";

//translations
const resources = {
  pa: {
    translation: translationpa,
  },
  en: {
    translation: translationEn,
  },
  hi: {
    translation: translationHn,
  },
};
const storedLanguage = localStorage.getItem("language");

i18n
  .use(detector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: storedLanguage || "en", // Set the language from LocalStorage or default to "en"
    fallbackLng: "en", // use en if detected lng is not available

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

  // Listen for language changes and persist the new language to LocalStorage
i18n.on("languageChanged", (lng) => {
  localStorage.setItem("language", lng);
});

export default i18n;
