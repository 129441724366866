import { combineReducers } from "redux";

import Auth from "./auth/reducers";
import Layout from "./layout/reducers";
import authReducer from "./newAuth/reducer";

export default combineReducers({
  Auth,
  Layout,
  authReducer,
});
