import React, { useState } from "react";
import { Form, Button, Container, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

const ContactUsPage: React.FC = () => {
  const { t } = useTranslation(); // Initializing useTranslation hook

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });
  const [loading, setLoading] = useState(false);

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);

    const token = localStorage.getItem("token");
    const apiUrl = `${process.env.REACT_APP_BASE_URL}/contact_us`;

    fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        token: token || "",
      },
      body: JSON.stringify(formData),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          toast.success(
            `${data.msg ? data.msg : "Message sent successfully!"}`,
            { position: toast.POSITION.TOP_RIGHT }
          );
          setFormData({
            name: "",
            email: "",
            subject: "",
            message: "",
          });
        } else {
          toast.error(
            `${
              data.msg ? data.msg : "Failed to send message. Please try again."
            }`,
            {
              position: toast.POSITION.TOP_RIGHT,
            }
          );
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        toast.error("An error occurred. Please try again later.", {
          position: toast.POSITION.TOP_RIGHT,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Container className="contact-container" style={containerStyle}>
      <h1 className="text-center">{t("Contact Us")}</h1>
      <div className="border-line"></div>

      <Form onSubmit={handleSubmit} style={formStyle}>
        <Form.Group controlId="formName">
          <Form.Label>{t("Name")}</Form.Label>{" "}
          <Form.Control
            type="text"
            placeholder={t("enter your name")}

            name="name"
            value={formData.name}
            onChange={handleInputChange}
            required
          />
        </Form.Group>

        <Form.Group controlId="formEmail">
          <Form.Label>{t("Email Address")}</Form.Label>{" "}
          <Form.Control
            type="email"
            placeholder={t("enter your email")}
            name="email"
            value={formData.email}
            onChange={handleInputChange}
            required
          />
        </Form.Group>

        <Form.Group controlId="formSubject">
          <Form.Label>{t("Subject")}</Form.Label>

          <Form.Control
            type="text"
            placeholder={t("enter the subject")}
            name="subject"
            value={formData.subject}
            onChange={handleInputChange}
            required
          />
        </Form.Group>

        <Form.Group controlId="formMessage">
          <Form.Label>{t("Message")}</Form.Label>

          <Form.Control
            as="textarea"
            rows={4}
            placeholder={t("enter your message")}
            name="message"
            value={formData.message}
            onChange={handleInputChange}
            required
            style={{ maxHeight: "200px", resize: "vertical" }}
          />
        </Form.Group>

        <Button
          className="my-2"
          variant="primary"
          type="submit"
          disabled={loading}
          style={buttonStyle}
        >
          {loading ? (
            <>
              {t("submitting...")}{" "}
              <Spinner animation="border" variant="dark" size="sm" />
            </>
          ) : (
            t("submit")
          )}
        </Button>
      </Form>
    </Container>
  );
};

export default ContactUsPage;

const containerStyle = {
  background: "#42414033",
  borderRadius: "10px",
  padding: "20px",
};

const formStyle = {
  maxWidth: "600px",
  margin: "auto",
  background: "white",
  borderColor: "transparent",
  backdropFilter: "blur(10px)",
  transition: "background-color 0.3s ease-in-out",
  padding: "13px",
  borderRadius: "10px",
};

const buttonStyle = {
  backgroundColor: "#4caf50",
  borderColor: "#4caf50",
};
