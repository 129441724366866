
import React from "react";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const CouponHeader: React.FC = () => {
  const navigate = useNavigate();

  return (
    <div className="d-flex justify-content-between align-items-center pb-2 mx-2">
      <span style={{ fontSize: "2em", color: "black", fontWeight: 500 }}>
        Coupon
      </span>
      <div className="d-flex gap-2">
        <Button
          variant="primary"
          onClick={() => {
            navigate(-1);
          }}
        >
          Back
        </Button>
      </div>
    </div>
  );
};

export default CouponHeader;
