export const FETCH_DATA_REQUEST = "FETCH_DATA_REQUEST";
export const FETCH_DATA_SUCCESS = "FETCH_DATA_SUCCESS";
export const FETCH_DATA_FAILURE = "FETCH_DATA_FAILURE";

export const FETCH_SELLER_REQUEST = "FETCH_SELLER_INFO";
export const FETCH_SELLER_SUCCESS = "FETCH_SELLER_INFO";

export const fetchDataRequest = (email: any, password: any) => ({
  type: FETCH_DATA_REQUEST,
  payload: { email, password },
});
export const fetchSellerinfo = () => ({
  type: FETCH_SELLER_REQUEST,
});
