import React from "react";
import { tableData } from "../orders/data";
import searchlogo from "../../../assets/images/searchlogo.png";
import { Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const MobBill = () => {
  const { t } = useTranslation();

  return (
    <div className="p-2">
      <div
        className="p-2"
        style={{
          borderRadius: "10px",
          border: "1px solid #ABABAB",
          background: "#F6F6F6",
        }}
      >
        <div>Courier : Delivery</div>
        <div className="fw-bold">Supplier Name : Prakash Sales</div>
        <div className="fw-bold">Date : 03 Jun, 2021</div>
      </div>

      <Table
        // striped
        bordered
        hover
        size="sm"
        responsive
        className="h-100 my-2 overflow-hidden shadow m-1"
        style={{
          borderRadius: "18px",
          border: "1px solid gray",
          background: "#EFEEF3",
        }}
      >
        <thead>
          <tr>
            <th className="p-2">#</th>
            <th>{t("STATUS")}</th>
        <th>{t("PRODUCTS")}</th>
        <th>{t("CUSTOMER")}</th>
        <th>{t("DATE")}</th>
        <th>{t("Price")}</th>
          </tr>
        </thead>
        <tbody>
          {tableData.map((item) => {
            return (
              <tr key={item.id} className="cursor-pointer">
                <td className="text-nowrap p-2">{item.id}</td>
                <td className="text-nowrap p-2">{item.status}</td>
                <td className="text-nowrap p-2">{item.products}</td>
                <td className="text-nowrap p-2">{item.customer}</td>
                <td className="text-nowrap p-2">{item.date}</td>
                <td className="text-nowrap p-2">{item.price}</td>
                <td className="text-nowrap p-2">
                  <img src={searchlogo} alt="" />
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};

export default MobBill;
