import React, { useState } from "react";
import { leftarrow } from "../AddProduct/data";
import { downloadsvg } from "./data";
import { useNavigate } from "react-router-dom";

const MobOrder = () => {
  const navigate = useNavigate();
  const [myTabs, setmyTabs] = useState<{
    upcoming: boolean;
    pending: boolean;
    ship: boolean;
    completed: boolean;
  }>({
    upcoming: true,
    pending: false,
    ship: false,
    completed: false,
  });
  return (
    <>
      <div
        className="position-absolute bg-white"
        style={{
          top: "70px",
          left: "0",
          height: "100vh",
          width: "100vw",
        }}
      >
        <div
          className="d-flex align-items-center justify-content-between "
          style={{ background: "#E6EDFF", position: "sticky", top: "0px" }}
        >
          <div
            className="d-flex align-items-center gap-2 py-2 px-3 cursor-pointer"
            onClick={() => navigate(-1)}
          >
            {leftarrow}
            Orders
          </div>
          <div className="d-flex align-items-center gap-3 py-2 px-3 cursor-pointer">
            {downloadsvg}
          </div>
        </div>
        <div
          className="d-flex align-items-center justify-content-start p-2 shadow gap-2"
          style={{ background: "#F6F6F6", borderBottom: "1px solid #D9D9D9" }}
        >
          <div
            className="cursor-pointer"
            onClick={() =>
              setmyTabs({
                upcoming: true,
                pending: false,
                ship: false,
                completed: false,
              })
            }
            style={
              myTabs.upcoming
                ? {
                    borderBottom: "2px solid orange",
                    fontSize: "12px",
                    fontWeight: "bold",
                  }
                : { borderBottom: "2px solid transparent", fontSize: "12px" }
            }
          >
            UPCOMING
          </div>
          <div
            className="cursor-pointer"
            onClick={() =>
              setmyTabs({
                upcoming: false,
                pending: true,
                ship: false,
                completed: false,
              })
            }
            style={
              myTabs.pending
                ? {
                    borderBottom: "2px solid orange",
                    fontSize: "12px",
                    fontWeight: "bold",
                  }
                : { borderBottom: "2px solid transparent", fontSize: "12px" }
            }
          >
            PENDING
          </div>
          <div
            className="cursor-pointer"
            onClick={() =>
              setmyTabs({
                upcoming: false,
                pending: false,
                ship: true,
                completed: false,
              })
            }
            style={
              myTabs.ship
                ? {
                    borderBottom: "2px solid orange",
                    fontSize: "12px",
                    fontWeight: "bold",
                  }
                : { borderBottom: "2px solid transparent", fontSize: "12px" }
            }
          >
            SHIP
          </div>
          <div
            className="cursor-pointer"
            onClick={() =>
              setmyTabs({
                upcoming: false,
                pending: false,
                ship: false,
                completed: true,
              })
            }
            style={
              myTabs.completed
                ? {
                    borderBottom: "2px solid orange",
                    fontSize: "12px",
                    fontWeight: "bold",
                  }
                : { borderBottom: "2px solid transparent", fontSize: "12px" }
            }
          >
            COMPLETED
          </div>
        </div>
        {myTabs.upcoming && <div className="p-2">Up Coming</div>}
        {myTabs.pending && <div className="p-2">Pending</div>}
        {myTabs.ship && <div className="p-2">Ship</div>}
        {myTabs.completed && <div className="p-2">Completed</div>}
      </div>
    </>
  );
};

export default MobOrder;
