import React, { useEffect, useRef, useState, Suspense } from "react";

import AllRoutes from "./routes/Routes";

import { configureFakeBackend } from "./helpers";

// Themes
// For Default import Theme.scss
import "./assets/scss/Theme.scss";
import ErrorBoundary from "./ErrorBoundary";
import { Navigate } from "react-router-dom";
import MyContextProvider from "./pages/other/Profile/OtherDetails/Mycontext";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { NotificationProvider } from "services/NotificationService";

// For Dark import Theme-Dark.scss
// import './assets/scss/Theme-Dark.scss';

// configure fake backend
configureFakeBackend();

const App = () => {
  const defaultOnErrorFn = useRef(window.onerror);
  const [redirectToLogin, setRedirectToLogin] = useState<boolean>(false);

  useEffect(() => {
    // console.log("hello");
    window.onerror = (message) => {
      window.alert(`window.onerror: ${message}`);
    };
    window.onerror = (...args) => {
      // console.log("helloDFCV ", args[0]);
      if (args[0] === "ResizeObserver loop limit exceeded") {
        // console.log(
        //   "helloDFCV ",
        //   args[0] === "ResizeObserver loop limit exceeded"
        // );
        return true;
      } else {
        defaultOnErrorFn.current && defaultOnErrorFn.current(...args);
      }
    };
    return () => {
      window.onerror = defaultOnErrorFn.current;
    };
  }, []);
  const handleRedirectToLogin = () => {
    setRedirectToLogin(true);
  };

  if (redirectToLogin) {
    return (
      <Navigate
        to={{
          pathname: "auth/login",
          // search: "next=" + '/',
        }}
      />
    );
  }

  return (
    <>
      <Suspense fallback="loading">
        <React.Fragment>
          <ErrorBoundary>
            <MyContextProvider>
              <NotificationProvider>
                <AllRoutes />
                <ToastContainer />
              </NotificationProvider>
            </MyContextProvider>
          </ErrorBoundary>
        </React.Fragment>
      </Suspense>
    </>
  );
};

export default App;
