import React, { MouseEvent, ReactNode, useEffect, useState } from "react";
import {
  Alert,
  Col,
  Container,
  FormControl,
  InputGroup,
  Nav,
  Pagination,
  Row,
  Tab,
} from "react-bootstrap";

import { useTranslation } from "react-i18next";
import "../../assets/scss/custom/Inventory/inventory.scss";
import RightSection from "./RightSection";
import LeftSection from "./LeftSection";
import {
  Category,
  InventoryProduct,
  TYPES_PARAMS_ARRAY,
} from "./InventoryProduct";
import DropdownMenu from "components/Inventory/TypesDropdownMenu";
import ActivationPendingListing from "./ActivationPendingListing";
import { toast } from "react-toastify";
import { ImCancelCircle } from "react-icons/im";

function InventoryDashboard() {
  const { t } = useTranslation();
  const [filterData, setFilterData] = useState([]) as any;
  const [searchQuery, setSearchQuery] = useState<string>("");

  const [searchInput, setSearchInput] = useState<string>("");
  const [selectedProduct, setSelectedProduct] =
    useState<InventoryProduct | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [selectedTab, setSelectedTab] = useState<string>("all");
  const [selectedType, setSelectedType] = useState<string | null>(null);
  const [selectedCategory, setSelectedCategory] = useState<string | null>(null);
  const [categories, setCategories] = useState<Category[]>([]);

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [perPage, setPerPage] = useState<number>(10);
  const [totalItems, setTotalItems] = useState<number>(0);

  useEffect(() => {
    const fetchCategories = async () => {
      setLoading(true);
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          throw new Error("Token not found in local storage");
        }

        const apiUrl = `${process.env.REACT_APP_BASE_URL}/prd_lst/fetch_all_categories`;

        const response = await fetch(apiUrl, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            token: token,
          },
        });

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();

        if (Array.isArray(data?.data)) {
          setCategories(data.data);
        } else {
          console.error("Invalid data format. Expected an array.");
        }
      } catch (error) {
        console.error("Error fetching statuses:", error);
      }
    };

    fetchCategories();
  }, []);

  const handleSearch = () => {
    setSearchQuery(searchInput);
  };

  const handleTabChange = (key: string | null) => {
    if (key) {
      setSelectedTab(key);
      setSelectedProduct(null);
    }
  };

  const renderNoCatalogsAlert = () => (
    <Alert variant="info" className="text-center">
      <Alert.Heading>No Catalogs 😕</Alert.Heading>
      <p>
        Upload catalogs through our website on the desktop. Once done, you can
        view and manage them here.
      </p>
    </Alert>
  );
  const renderActivationPendingListing = () => <ActivationPendingListing />;

  const renderCatalogContent = (label: string) => (
    <Container fluid>
      <Row className="align-items-stretch">
        <Col sm={8}>
          <h4>Catalog: {label}</h4>
        </Col>
        <Col sm={4} className="d-flex justify-content-end">
          {renderPagination()}
        </Col>
      </Row>

      <div className="dummy-tab-content">
        <Row>
          <Col sm={3}>
            <LeftSection
              items={filterData}
              onCardClick={handleCardClick}
              selectedCard={selectedProduct?.seller_products_id || null}
              loading={loading}
            />
          </Col>
          <Col sm={9}>
            <RightSection
              selectedItem={selectedProduct}
              loading={loading}
              setSelectedItem={setSelectedProduct}
              selectedTab={selectedTab}
            />
          </Col>
        </Row>
      </div>
    </Container>
  );

  const handleCardClick = (productId: string) => {
    const selectedProduct: InventoryProduct | null =
      filterData.find(
        (product: InventoryProduct) => product.seller_products_id === productId
      ) || null;
    setSelectedProduct(selectedProduct);
  };

  useEffect(() => {
    if (selectedTab !== "activationpending") {
      fetchData();
    } else {
      setFilterData([]);
    }
  }, [selectedTab, selectedType, selectedCategory, searchQuery]);

  const fetchData = async () => {
    const token = localStorage.getItem("token");
    if (!token) {
      toast.error("please login again");
      window.location.href = "/seller";
      return;
    }

    const myHeaders = new Headers();
    myHeaders.append("token", token!);

    try {
      const response = await fetch(
        `${
          process.env.REACT_APP_BASE_URL
        }/prd_lst/get_inventory_products/${currentPage}?state=${selectedTab}&type=${
          selectedType || "all"
        }&category_id=${selectedCategory || ""}&search=${encodeURIComponent(
          searchQuery
        )}`,
        {
          method: "POST",
          headers: myHeaders,
        }
      );

      const data = await response.json();
      console.log(data);
      if (data.status === "success") {
        setFilterData(data.data);
        setTotalItems(data.total_items || 0);
        setPerPage(data.per_page || 10);
      } else if (
        data?.status === "failure" &&
        data?.msg === "No products Available!"
      ) {
        if (data.data === "Invalid Token!") {
          toast.error("session expired");
          window.location.href = "/seller";
        }
        setFilterData([]);
      } else {
        console.error("API error:", data);
      }
    } catch (error) {
      setFilterData([]);
      console.error("Fetch error:", error);
    } finally {
      setLoading(false);
      setSelectedProduct(null);
    }
  };

  console.log({ filterData });

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const renderPagination = () => {
    const totalPages = Math.ceil(totalItems / perPage);

    return (
      <Pagination>
        <Pagination.Prev
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
        />
        {Array.from({ length: totalPages }, (_, index) => (
          <Pagination.Item
            key={index + 1}
            active={index + 1 === currentPage}
            onClick={() => handlePageChange(index + 1)}
          >
            {index + 1}
          </Pagination.Item>
        ))}
        <Pagination.Next
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
        />
      </Pagination>
    );
  };

  const handleDropdownChange = (value: string, dropdownType: string) => {
    if (dropdownType === "type") {
      setSelectedType(value);
    } else if (dropdownType === "category") {
      setSelectedCategory(value);
    }
    setSelectedProduct(null);
  };

  return (
    <Row className="inventory-dashboard">
      <Col md={12}>
        <div className="d-flex justify-content-between m-0 flex-column flex-sm-row py-1">
          <h6 style={{ fontSize: "25px" }}>{t("Inventory")} </h6>
        </div>
      </Col>
      <Col md={12} style={{ paddingBottom: "10px" }}>
        <Tab.Container
          id="inventory-tabs"
          defaultActiveKey="all"
          activeKey={selectedTab}
          onSelect={handleTabChange}
        >
          <Row>
            <Col md={8}>
              <Nav
                variant="pills"
                className="inventory-tabs-list flex-row overflow-auto"
                style={{ flexWrap: "nowrap" }}
              >
                {[
                  { name: "All", navigate: "/dashboard/blocked" },
                  { name: "Paused", navigate: "/dashboard/paused" },
                  { name: "Active", navigate: "/dashboard/active" },
                  { name: "Blocked", navigate: "/dashboard/blocked" },
                  {
                    name: "activationpending",
                    navigate: "/dashboard/activationPending",
                  },
                ].map((item, index) => (
                  <Nav.Item key={index + "-inventory-tab"}>
                    <Nav.Link
                      eventKey={item.name.toLowerCase()}
                      className={`inventory-tab-link cursor-pointer text-left ${
                        selectedTab === item.name.toLowerCase()
                          ? "selected"
                          : ""
                      }`}
                      style={
                        selectedTab === item.name.toLowerCase()
                          ? {
                              fontWeight: "bold",
                              borderBottom: "2px solid #347AE2",
                            }
                          : { width: "fit-content" }
                      }
                    >
                      {item.name === "activationpending"
                        ? "Activation Pending"
                        : t(item.name)}
                    </Nav.Link>
                  </Nav.Item>
                ))}
              </Nav>
            </Col>
            {selectedTab !== "activationpending" && (
              <Row className="mt-2">
                <Col md={6}>
                  <div className="d-flex flex-row gap-2">
                    <DropdownMenu
                      label="Select Status"
                      options={TYPES_PARAMS_ARRAY}
                      selectedValue={selectedType}
                      onValueChange={(value) =>
                        handleDropdownChange(value, "type")
                      }
                    />
                    <DropdownMenu
                      label="Select Category"
                      options={categories.map((category) => category.name)}
                      selectedValue={selectedCategory}
                      onValueChange={(value) =>
                        handleDropdownChange(value, "category")
                      }
                    />
                  </div>
                </Col>
                <Col md={6}>
                  <InputGroup className="search-container p-1 gap-0 w-100">
                    <FormControl
                      type="text"
                      name="inventory-search"
                      id="inventory-search"
                      value={searchInput}
                      onChange={(e: any) => setSearchInput(e.target.value)}
                      placeholder={t("Order ID, ASIN, RMA, SAFE-T ID")}
                      className="app-search"
                    />
                    <InputGroup.Text
                      className="search-icon d-flex align-items-center justify-content-center cursor-pointer "
                      onClick={handleSearch}
                    >
                      search
                    </InputGroup.Text>
                  </InputGroup>
                </Col>
              </Row>
            )}

            <Row className="w-auto">
              <Col>
                {selectedTab !== "activationpending" && selectedType && (
                  <CancelBadge
                    value={selectedType}
                    onClick={() => setSelectedType(null)}
                  />
                )}
              </Col>
              <Col>
                {selectedCategory && (
                  <CancelBadge
                    value={selectedCategory}
                    onClick={() => setSelectedCategory(null)}
                  />
                )}
              </Col>
              <Col>
                {searchQuery && (
                  <CancelBadge
                    value={searchQuery}
                    onClick={() => setSearchQuery("")}
                  />
                )}
              </Col>
            </Row>

            <Col sm={12}>
              <Tab.Content>
                {[
                  { key: "all", label: "All" },
                  { key: "paused", label: "Paused" },
                  { key: "active", label: "Active" },
                  { key: "blocked", label: "Blocked" },
                  { key: "activationpending", label: "Activation Pending" },
                ].map((tab) => (
                  <Tab.Pane key={tab.key} eventKey={tab.key}>
                    {filterData.length === 0 &&
                    !loading &&
                    selectedTab !== "activationpending"
                      ? renderNoCatalogsAlert()
                      : selectedTab === "activationpending"
                      ? renderActivationPendingListing()
                      : renderCatalogContent(tab.label)}{" "}
                  </Tab.Pane>
                ))}
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </Col>
    </Row>
  );
}

export default InventoryDashboard;

interface CancelBadgeProps {
  value: ReactNode;
  onClick: (event: MouseEvent<SVGSVGElement>) => void;
}

const CancelBadge: React.FC<CancelBadgeProps> = ({ value, onClick }) => (
  <span className="badge bg-secondary p-1 align-items-center rounded-pill">
    {value}
    <ImCancelCircle
      onClick={onClick}
      className="mx-1"
      style={{ cursor: "pointer" }}
      size={14}
    />
  </span>
);
