import React, { useContext, useState } from "react";
import { Context } from "../Mycontext";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Col, Row, Toast } from "react-bootstrap";
import { toast } from "react-toastify";

const inputsearch = {
  height: "36px",
  borderRadius: "18px",
  width: "100%",
  border: "1px solid gray",
  margin: "10px 0",
  padding: "10px 20px",
  boxShadow: "0px 2px 5px lightgray",
  whiteSpace: "nowrap",
} as React.CSSProperties;

const toastStyles = {
  position: "fixed",
  bottom: "1rem",
  right: "1rem",
  zIndex: 9999,
} as React.CSSProperties;

const ChangePasswordForm: React.FC = ({}) => {
  const [step, setStep] = useState(1);
  const [otp, setOtp] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [showSuccessToast, setShowSuccessToast] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [showErrorToast, setShowErrorToast] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const { LoginData } = useContext(Context);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const maskEmail = (email: string | undefined): string | undefined => {
    if (!email) return "";
    const [username, domain] = email.split("@");
    const maskedUsername = username.charAt(0) + "*".repeat(username.length - 1);
    return `${maskedUsername}@${domain}`;
  };

  const handleContinue = async () => {
    if (step === 1) {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          // Display an error toast
          setErrorMessage("User token not found in AsyncStorage");
          setShowErrorToast(true);
          return;
        }
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("token", token);
        const response = await fetch(
          `${process.env.REACT_APP_BASE_URL}/pass_send_otp`,
          {
            method: "POST",
            headers: myHeaders,
            body: JSON.stringify({}),
          }
        );

        if (!response.ok) {
          setErrorMessage(`API call error: ${response.status}`);
          setShowErrorToast(true);
          return;
        }

        toast.success("OTP sent successfully");
        // setShowSuccessToast(true);
        setStep(2);
      } catch (error) {
        // Display an error toast
        setErrorMessage(`Error sending OTP: ${error}`);
        setShowErrorToast(true);
      }
    } else if (step === 2) {
      try {
        if (otp && newPassword && newPassword === confirmPassword) {
          const userToken = localStorage.getItem("token");
          const requestBody = {
            password: newPassword,
            OTP: otp,
          };

          const response = await fetch(
            "https://marketplaceapi.quadbtech.com/api/v1/sellerapp/editpassword",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                token: userToken || "", // Provide a default value for userToken if it's null
              },
              body: JSON.stringify(requestBody),
            }
          );

          if (!response.ok) {
            // Display an error toast
            setErrorMessage(`HTTP error! Status: ${response.status}`);
            setShowErrorToast(true);
            return;
          }

          // Display a success toast
          setSuccessMessage("Password changed successfully");
          setShowSuccessToast(true);
          setOtp("");
          setNewPassword("");
          setConfirmPassword("");
        } else {
          // Display an error toast
          setErrorMessage("Password not matched");
          setShowErrorToast(true);
        }
      } catch (error) {
        // Display an error toast
        setErrorMessage(`Error changing password: ${error}`);
        setShowErrorToast(true);
      }
    }
  };

  const handleGoBack = () => {
    if (step === 2) {
      setStep(1);
    } else if (step === 1) {
      navigate(-1);
    }
  };

  return (
   <Row className="p-3 p-md-0">
     <Col md={6}>
      <Toast
        show={showSuccessToast}
        onClose={() => setShowSuccessToast(false)}
        delay={3000}
        autohide
        style={toastStyles}
        bg="success"
      >
        <Toast.Header>
          <strong className="mr-auto text-white">Success</strong>{" "}
        </Toast.Header>
        <Toast.Body>{successMessage}</Toast.Body>
      </Toast>

      <Toast
        show={showErrorToast}
        onClose={() => setShowErrorToast(false)}
        delay={3000}
        autohide
        style={toastStyles}
        bg="danger"
      >
        <Toast.Header >
          <strong className="mr-auto text-black">Error</strong>{" "}
        </Toast.Header>
        <Toast.Body>{errorMessage}</Toast.Body>
      </Toast>

      <div>
        <button className="btn btn-primary" onClick={handleGoBack}>
          Back
        </button>
        <h4 className="heading">
          {step === 1
            ? "We'll send you a code to your email address"
            : "Enter OTP and New Password"}
        </h4>
        {step === 1 ? (
          <div className="emailContainer">
            <p className="emailText">We can send a login code to:</p>
            <p className="email">{maskEmail(LoginData?.email)}</p>
          </div>
        ) : (
          <>
            <label htmlFor="bankName" className="w-100 mt-2 px-2">
              {t("Enter OTP")}
            </label>
            <input
              type="text"
              placeholder="Enter OTP"
              value={otp}
              style={inputsearch}
              onChange={(e) => setOtp(e.target.value)}
            />
            <label htmlFor="bankName" className="w-100 mt-2 px-2">
              {t("Enter new password")}
            </label>
            <input
              type="password"
              placeholder="Enter new password"
              value={newPassword}
              style={inputsearch}
              onChange={(e) => setNewPassword(e.target.value)}
            />
            <label htmlFor="bankName" className="w-100 mt-2 px-2">
              {t("Confirm new password")}
            </label>
            <input
              type="password"
              placeholder="Confirm new password"
              value={confirmPassword}
              style={inputsearch}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
          </>
        )}
        <button className="btn btn-warning" onClick={handleContinue}>
          Continue
        </button>
      </div>
    </Col>
   </Row>
  );
};

export default ChangePasswordForm;
