import React from "react";
import sellerTier from "../assets/images/graphics/sellerTier.png";
import largeimg from "../assets/images/largeimg.png";
import "../assets/scss/custom/components/_rightsidrbarnew.scss";
import { useTranslation } from "react-i18next";
import thumbnail1 from "../assets/images/graphics/1.jpg";
import thumbnail2 from "../assets/images/graphics/2.jpg";
import thumbnail3 from "../assets/images/graphics/3.jpg";
import { FaInfoCircle } from "react-icons/fa";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

function RightSidebarNew() {
  const { t } = useTranslation();
const navigate=useNavigate();

  const downloadGuide = () => {
    navigate('/doc')
    // const guideUrl = "https://docs.google.com/document/d/1HUKPYDDr8EQwVmLrwW0wm6lHfv2_w4JoKpoHgmJL8Hg/edit";
    // window.open(guideUrl, "_blank");
  };

  const renderTooltip = (props: any) => (
    <Tooltip id="button-tooltip" {...props}>
      complete guide for dukkandaar's
    </Tooltip>
  );

  return (
    <>
      <div className="rightsidrbarnew mt-2 flex-wrap">
        <h3>{t("Seller Tier")}</h3>
        <div className="d-flex flex-col flex-xl-row gap-2">
          <img src={sellerTier} alt="" className="rounded" />
          <p className="ml-2" style={{ wordBreak: "break-word" }}>
            {t(
              "Here is the information about all your orders Here is the information about all your orders"
            )}
          </p>
        </div>
        <div className=" mt-2">
          <h6>
            {t("Learn how to process your orders?")}
            <OverlayTrigger
              placement="right"
              delay={{ show: 250, hide: 400 }}
              overlay={renderTooltip}
            >
              <button
                onClick={downloadGuide}
                className="btn btn-link text-primary btn-sm p-1"
              >
                {" "}
                <FaInfoCircle className=" mx-1" />
                {t("Go to Dukkandaar's guide")}
              </button>
            </OverlayTrigger>
          </h6>
        </div>
      </div>
      <div className="rightsidrbarnew">
        <h3>{t("Tutorial videos")}</h3>
        {[thumbnail1, thumbnail2, thumbnail3].map((thumbnail, i) => (
          <div key={i} className="m-2 youtube-thumbnail">
            <img src={thumbnail} alt="" className="rounded w-100" />
          </div>
        ))}
      </div>
    </>
  );
}

export default RightSidebarNew;
