import React from "react";

interface OrdersProps {
  // Define any props if needed
}

const Step1: React.FC = () => (
  <div>
    <h2>Step 1: Accessing the Orders Section</h2>
    <p>
      Navigating to Orders: Upon logging into the platform, you would navigate
      to the "ORDERS" section, which is likely found on the dashboard or main
      menu of the seller's account.
    </p>
  </div>
);

const Step2: React.FC = () => (
  <div>
    <h2>Step 2: Understanding Order Status Categories</h2>
    <p>
      Status Tabs: Across the top of the orders section, there are several tabs:
      "All," "Pending," "Processing," "Cancelled," "Shipped," and "Delivered."
      Each tab represents a different status of the orders you have received:
    </p>
    <ul>
      <li>All: This tab would show all orders regardless of their status.</li>
      <li>
        Pending: Orders that have been placed but not yet processed would
        appear under this tab.
      </li>
      <li>
        Processing: Once an order is being handled, packed, or prepared for
        shipment, it would move to this status.
      </li>
      <li>
        Cancelled: Any orders that have been cancelled by either the buyer or
        the seller would be listed here.
      </li>
      <li>
        Shipped: Orders that have been dispatched and are on their way to the
        customer would fall under this category.
      </li>
      <li>
        Delivered: Successfully completed orders that have reached the customer
        are shown here.
      </li>
    </ul>
  </div>
);

const Step3: React.FC = () => (
  <div>
    <h2>Step 3: Viewing Order Details</h2>
    <p>
      Order List: Below the status tabs, you would normally see a list of orders
      with details such as order ID, customer name, date of order, status, and
      other relevant information. However, the message "No orders yet. Keep
      checking this section from time to time." indicates that there are
      currently no orders to display.
    </p>
  </div>
);

const Step4: React.FC = () => (
  <div>
    <h2>Step 4: Managing Orders</h2>
    <p>
      Order Actions: If there were orders listed, you would typically have the
      ability to click on an order to view more details, process the order,
      update the status, or perform other actions such as cancelling or
      initiating a return.
    </p>
  </div>
);

const Step5: React.FC = () => (
  <div>
    <h2>Step 5: Monitoring Orders</h2>
    <p>
      Notification Message: The message prompts the seller to routinely check
      this section, suggesting that the platform does not currently have any
      orders for the seller, but this may change as customers place orders.
    </p>
  </div>
);

const DocOrders: React.FC<OrdersProps> = () => {
  return (
    <div>
      <h1>Orders</h1>
      <Step1 />
      <Step2 />
      <Step3 />
      <Step4 />
      <Step5 />
    </div>
  );
};

export default DocOrders;
