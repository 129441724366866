import React, { useEffect, useState } from "react";
import {
  Card,
  Form,
  Table,
  Pagination,
  Button,
  Col,
  Alert,
  Modal,
} from "react-bootstrap";
import {
  Coupon,
  CouponFilter,
  CouponResponse,
  deactivateCoupon,
  getCoupons,
} from "./couponListingApi";
import { toast } from "react-toastify";
import { BiInfoCircle } from "react-icons/bi";
import CouponHeader from "./CouponHeader";
import { useNavigate } from "react-router-dom";

const CreateCoupon: React.FC = () => {
  const [filter, setFilter] = useState<CouponFilter>({
    status: "",
    search: "",
    type: "",
  });
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [coupons, setCouponsList] = useState<Coupon[]>([]);
  const [deactivateCouponId, setDeactivateCouponId] = useState<string | null>(
    null
  );
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [perPage, setPerPage] = useState<number>(10);
  const navigate = useNavigate();

  const loadCoupons = async (page: number, filter: CouponFilter) => {
    try {
      const response = await getCoupons(page, filter);
      const { status, msg, data, total_items, per_page } =
        response as CouponResponse;
      if (status === "failure") {
        toast.error(msg);
        setCouponsList([]);
      } else {
        setCouponsList(data);
        setTotalCount(total_items);
        setPerPage(per_page);
      }
    } catch (error) {
      console.error("Error occurred:", error);
      toast.error("Failed to fetch coupons");
    }
  };

  useEffect(() => {
    loadCoupons(currentPage, filter);
  }, [currentPage, filter]);

  const handleFilterChange = (
    newFilter: string,
    filterKey: keyof CouponFilter
  ) => {
    setFilter((prevFilter) => ({
      ...prevFilter,
      [filterKey]: newFilter,
    }));
  };

  const handleConfirmDeactivation = async () => {
    try {
      if (!deactivateCouponId) {
        return;
      }
      const response = await deactivateCoupon(deactivateCouponId);
      const { status, msg } = response;

      if (status === "success") {
        toast.success(msg);
        setDeactivateCouponId(null);
        loadCoupons(currentPage, filter);
        setShowConfirmationModal(false);
      } else {
        toast.error(msg);
        setShowConfirmationModal(false);
      }
    } catch (error) {
      console.error("Error occurred:", error);
      toast.error("Failed to deactivate coupon");
    }
  };

  const formatDateString = (dateString: string) => {
    const date = new Date(dateString);
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const year = date.getFullYear();

    return `${month}/${day}/${year}`;
  };

  const handleDeactivateCoupon = (couponId: string) => {
    setDeactivateCouponId(couponId);
    setShowConfirmationModal(true);
  };

  const handleEditCoupon = (couponId: string) => {
    if (!couponId) {
      return;
    }
    navigate(`/dashboard/inventory/sellercoupons/edit-coupon/${couponId}`);
  };

  const totalPages = Math.ceil(totalCount / perPage);

  return (
    <div className="px-2">
      <CouponHeader />

      <Card
        border="primary"
        className="mb-4"
        style={{
          borderWidth: "3px",
          borderTopLeftRadius: "20px",
          borderBottomLeftRadius: "20px",
        }}
      >
        <Card.Body>
          <div className="d-flex align-items-center">
            <BiInfoCircle size={20} color="blue" className="me-2" />
            <Card.Text className="fw-bold">
              We have a new Coupon management page! Go to the new experience to
              create coupons. Any previously created active or upcoming coupons
              are only visible here.
            </Card.Text>
          </div>
        </Card.Body>
      </Card>

      <Form className="mb-3">
        <Col md={6} sm={3}>
          <Form.Control
            type="text"
            placeholder="Search by coupons title or status..."
            value={filter.search}
            onChange={(e) => {
              handleFilterChange(e.target.value, "search");
            }}
          />
        </Col>
      </Form>

      <Form
        className="mb-3 d-flex overflow-auto px-1 mx-2"
        style={{ whiteSpace: "nowrap" }}
      >
        {["All", "Running", "Expired", "Submitted", "Blocked"].map(
          (filterOption, index) => (
            <Form.Check
              key={index}
              type="radio"
              label={`${filterOption} `}
              checked={filter.status === filterOption}
              onChange={() =>
                handleFilterChange(
                  filterOption === "All" ? "" : filterOption,
                  "status"
                )
              }
              className="me-2"
            />
          )
        )}
      </Form>
      <Form.Group className="mb-3 me-2">
        <Form.Label>Type</Form.Label>
        <Form.Control
          as="select"
          value={filter.type}
          onChange={(e) => handleFilterChange(e.target.value, "type")}
        >
          <option value="">All </option>
          <option value="Month">Month</option>
          <option value="Week">Week</option>
          <option value="Year">Year</option>
        </Form.Control>
      </Form.Group>
      {!coupons || coupons.length === 0 ? (
        <Alert variant="danger" className="text-center mt-4">
          <h4>
            <span role="img" aria-label="Warning Emoji">
              ⚠️
            </span>{" "}
            Oops! No data found.
          </h4>
          <p>"The best way to predict the future is to create it."</p>
        </Alert>
      ) : (
        <Table striped bordered hover responsive className="mb-4">
          <thead>
            <tr>
              <th>Coupon Title</th>
              <th>Status</th>
              <th>Start Date</th>
              <th>End Date</th>
              <th>Redemption Limit</th>
              <th>Discount</th>
              {/* <th>Spend</th> */}
              <th>Clips</th>
              <th>Redeemed</th>
              <th>{filter.status === "Blocked" ? "Reason" : "Action"}</th>
            </tr>
          </thead>
          <tbody>
            {Array.isArray(coupons) &&
              coupons.map((coupon) => (
                <tr key={coupon.coupon_id}>
                  <td>{coupon.coupon_title}</td>

                  <td>{coupon.status}</td>
                  <td>{formatDateString(coupon.start_date)}</td>
                  <td>{formatDateString(coupon.end_date)}</td>
                  <td>{coupon.redemption_limit}</td>
                  <td>
                    {coupon.is_money_off === true ? (
                      <span>₹{coupon.amount}</span>
                    ) : (
                      <span>{coupon.discount}%</span>
                    )}
                  </td>
                  {/* <td>{coupon.total_spend}</td> */}
                  <td>{coupon.clips}</td>
                  <td>{coupon.redeemed}</td>

                  <td>
                    {coupon.status === "Blocked" ? (
                      <span>Reason: {coupon.reviewed_comment}</span>
                    ) : (
                      <div className="d-flex justify-content-center align-items-left gap-2">
                        {coupon.status === "Submitted" ? (
                          <Button
                            variant="primary"
                            style={{ padding: "5px 10px" }}
                            onClick={() => handleEditCoupon(coupon.coupon_id)}
                          >
                            Edit
                          </Button>
                        ) : (
                          <Button
                            variant="primary"
                            style={{ padding: "5px 10px" }}
                            disabled
                            aria-readonly
                          >
                            Edit
                          </Button>
                        )}

                        {(coupon.status === "Submitted" ||
                          coupon.status === "Running") && (
                          <Button
                            variant="danger"
                            style={{ padding: "5px 10px" }}
                            onClick={() =>
                              handleDeactivateCoupon(coupon.coupon_id)
                            }
                          >
                            Deactivate
                          </Button>
                        )}
                        <Button
                          variant="warning"
                          style={{ padding: "5px 10px" }}
                          onClick={() =>
                            navigate(
                              `/pages/coupons/sellercoupons/view-coupon/${coupon.coupon_id}`
                            )
                          }
                        >
                          View
                        </Button>
                      </div>
                    )}
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      )}

      <Pagination>
        {Array.from({ length: totalPages }, (_, index) => (
          <Pagination.Item
            key={index + 1}
            active={index + 1 === currentPage}
            onClick={() => setCurrentPage(index + 1)}
          >
            {index + 1}
          </Pagination.Item>
        ))}
      </Pagination>
      <Modal
        show={showConfirmationModal}
        onHide={() => setShowConfirmationModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirm Deactivation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to deactivate this coupon?
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowConfirmationModal(false)}
          >
            Cancel
          </Button>
          <Button variant="primary" onClick={handleConfirmDeactivation}>
            Yes, Deactivate
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default CreateCoupon;
