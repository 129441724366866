import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getSingleCoupon, SingleCoupon } from "./couponListingApi";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import TwoBallsLoader from "components/Loaders/TwoBallsLoader";

const ViewSingleCoupon: React.FC = () => {
  const { couponId } = useParams<{ couponId: string }>();
  const [coupon, setCoupon] = useState<SingleCoupon | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const fetchCoupon = async () => {
      setLoading(true);

      try {
        if (couponId) {
          const response = await getSingleCoupon(couponId);
          setCoupon(response?.data);
        }
      } catch (error) {
        console.error("Error fetching coupon:", error);
      } finally {
        setLoading(false);
      }
    };

    if (couponId) {
      fetchCoupon();
    }
  }, [couponId]);

  const formatDate = (dateString: string) => {
    const options: Intl.DateTimeFormatOptions = {
      year: "numeric",
      month: "long",
      day: "numeric",
    };

    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  return (
    <Container>
      {loading && <TwoBallsLoader />}
      {coupon && (
        <div>
          <h2 className="mb-4">Coupon Details</h2>
          <Card className="mb-4">
            <Card.Body
              style={{
                border: "2px solid #007bff",
                borderRadius: "8px",
                padding: "16px",
              }}
            >
              <table className="table">
                <tbody>
                  <tr>
                    <td style={{ fontWeight: "bold" }}>Coupon Code:</td>
                    <td>{coupon.coupon_code}</td>
                  </tr>
                  <tr>
                    <td style={{ fontWeight: "bold" }}>Coupon Title:</td>
                    <td>{coupon.coupon_title}</td>
                  </tr>
                  <tr>
                    <td style={{ fontWeight: "bold" }}>Discount:</td>
                    <td>{coupon.discount}%</td>
                  </tr>
                  <tr>
                    <td style={{ fontWeight: "bold" }}>Start Date:</td>
                    <td>{formatDate(coupon.start_date)}</td>
                  </tr>
                  <tr>
                    <td style={{ fontWeight: "bold" }}>End Date:</td>
                    <td>{formatDate(coupon.end_date)}</td>
                  </tr>
                  <tr>
                    <td style={{ fontWeight: "bold" }}>Target Customer:</td>
                    <td>{coupon.target_customer}</td>
                  </tr>
                  <tr>
                    <td style={{ fontWeight: "bold" }}>Status:</td>
                    <td>{coupon.status}</td>
                  </tr>
                  <tr>
                    <td style={{ fontWeight: "bold" }}>Total Spend:</td>
                    <td>{coupon.total_spend}</td>
                  </tr>
                </tbody>
              </table>
            </Card.Body>
          </Card>

          <h3 className="mt-4 mb-4">Product Information</h3>
          <Card className="mb-4">
            <Card.Body>
              <Row>
                <Col md={3}>
                  <div>
                    <img
                      src={coupon.product.products_images[0]?.url}
                      alt="Product"
                      className="img-fluid"
                      style={{
                        maxWidth: "100px",
                        maxHeight: "100px",
                        border: "2px solid gray",
                        padding: "16px",
                        borderRadius: "8px",
                      }}
                    />
                    <div className="price-highlight d-flex flex-wrap justify-content-start align-items-start  mt-2 gap-2">
                      <strong style={{ fontSize: "1rem", color: "#007bff" }}>
                        Price:
                      </strong>
                      <span
                        style={{
                          fontSize: "0.8rem",
                          color: "#00000",
                          fontWeight: "bold",

                        }}
                      >
                        ₹{coupon.seller_products_variant.price}
                      </span>
                    </div>
                  </div>
                </Col>

                <Col md={9}>
                  <Row>
                    <Col>
                      <strong style={{ fontSize: "1.5em", color: "#000000" }}>
                        {coupon.product.product_name.en}
                      </strong>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <strong style={{ fontSize: "1.5em" }}>
                        Product Description:
                      </strong>
                      <span style={{ fontSize: "1.5em" }}>
                        {coupon.product.product_description.en}
                      </span>
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <strong style={{ fontSize: "1.5em", marginRight: "2px" }}>
                        Inventory:
                      </strong>
                      <span style={{ fontSize: "1.5em" }}>
                        {coupon.seller_products_variant.inventory}
                      </span>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Card.Body>
          </Card>

          <h3 className="mt-4 mb-4">Additional Details</h3>
          <Card className="mb-4">
            <Card.Body>
              <Row>
                <Col md={4}>
                  <strong>Created By:</strong>
                </Col>
                <Col md={8}>{coupon.created_by}</Col>
              </Row>
              <Row>
                <Col md={4}>
                  <strong>Redemption Limit:</strong>
                </Col>
                <Col md={8}>{coupon.redemption_limit}</Col>
              </Row>
              <Row>
                <Col md={4}>
                  <strong>Clips:</strong>
                </Col>
                <Col md={8}>{coupon.clips}</Col>
              </Row>
              <Row>
                <Col md={4}>
                  <strong>Redeemed:</strong>
                </Col>
                <Col md={8}>{coupon.redeemed}</Col>
              </Row>
            </Card.Body>
          </Card>
        </div>
      )}
      {!loading && !coupon && <p className="mt-4">No content to display.</p>}
    </Container>
  );
};

export default ViewSingleCoupon;
