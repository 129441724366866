import React, { useContext, useState } from "react";
import { Form, Button } from "react-bootstrap";
import { toast } from "react-toastify";
import { Context } from "../Mycontext";
import { Navigate, useNavigate } from "react-router-dom";

interface EditProfileDetailsProps {
  // Add any props if needed
}

const EditProfileDetails: React.FC<EditProfileDetailsProps> = () => {
  const { LoginData } = useContext(Context);
  const navigate=useNavigate()
  const [formData, setFormData] = useState({
    shop_name: LoginData?.shop_name || "",
    seller_name: LoginData?.seller_name || "",
    dob: LoginData?.dob || "",
    gender: LoginData?.gender || "",
  });

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const token = localStorage.getItem("token");

    if (!token) {
      window.location.href = "/seller";
      console.error("Token not found in local storage");
      return;
    }

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/edit_profile`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            token: token,
          },
          body: JSON.stringify(formData),
        }
      );

      if (response.ok) {
        toast.success("Profile details updated successfully");
      } else {
        toast.error("Failed to update profile details");
      }
    } catch (error) {
      toast.error("Error occurred while updating profile details");
      console.error("Error:", error);
    }
  };

  const handleGoBack=()=>{
    navigate('/pages/profile')
  }
  return (
    <div className="p-2">
      <button className="btn btn-primary mx-2" onClick={handleGoBack}>
        Back
      </button>
      <h4 className="mx-2">Edit Profile Details</h4>
      <Form onSubmit={handleSubmit} className="p-2">
        <Form.Group controlId="shopName">
          <Form.Label>Shop Name:</Form.Label>
          <Form.Control
            type="text"
            name="shop_name"
            value={formData.shop_name}
            onChange={handleInputChange}
          />
        </Form.Group>

        <Form.Group controlId="sellerName">
          <Form.Label>Seller Name:</Form.Label>
          <Form.Control
            type="text"
            name="seller_name"
            value={formData.seller_name}
            onChange={handleInputChange}
          />
        </Form.Group>

        <Form.Group controlId="dob">
          <Form.Label>Date of Birth:</Form.Label>
          <Form.Control
            type="date"
            name="dob"
            value={formData.dob}
            onChange={handleInputChange}
          />
        </Form.Group>

        <Form.Group controlId="gender">
          <Form.Label>Gender:</Form.Label>
          <Form.Control
            as="select"
            name="gender"
            value={formData.gender}
            onChange={handleInputChange}
          >
            <option value="male">Male</option>
            <option value="female">Female</option>
          </Form.Control>
        </Form.Group>

        <Button variant="primary" type="submit" className="mt-3">
          Submit
        </Button>
      </Form>
    </div>
  );
};

export default EditProfileDetails;
