import React from "react";

const LoadingScreen = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
      }}
    >
      <style>
        {`
          @keyframes spin {
            to {
              transform: rotate(360deg);
            }
          }
        `}
      </style>
      <div
        style={{
          width: "50px",
          height: "50px",
          borderRadius: "50%",
          border: "5px solid lightgray",
          borderTop: "5px solid rgb(255, 213, 0)",
          borderLeft: "5px solid rgb(255, 213, 0)",
          animation: "spin 1s infinite linear",
        }}
      ></div>
      {/* <p>Loading...</p> */}
    </div>
  );
};

export default LoadingScreen;
