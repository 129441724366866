interface Category {
  name: string;
  subcategories: Subcategory[];
}

interface Subcategory {
  name: string;
  subcategories?: Subcategory[];
}

const categories: Category[] = [
  {
    name: "Fashion",
    subcategories: [
      {
        name: "Men Fashion",
        subcategories: [
          {
            name: "Clothing",
            subcategories: [
              {
                name: "Shirts",
                subcategories: [
                  {
                    name: "Dress Shirts",
                    subcategories: [
                      { name: "Shirts" },
                      { name: "Pants" },
                      { name: "Suits" },
                    ],
                  },
                  {
                    name: "T-Shirts",
                    subcategories: [
                      {
                        name: "Graphic T-Shirts",
                        subcategories: [
                          { name: "Shirts" },
                          { name: "Pants" },
                          { name: "Suits" },
                        ],
                      },
                      {
                        name: "Plain T-Shirts",
                        subcategories: [
                          { name: "Shirts" },
                          { name: "Pants" },
                          { name: "Suits" },
                        ],
                      },
                    ],
                  },
                  {
                    name: "Polo Shirts",
                    subcategories: [
                      { name: "Shirts" },
                      { name: "Pants" },
                      { name: "Suits" },
                    ],
                  },
                ],
              },
              {
                name: "Pants",
                subcategories: [
                  {
                    name: "Jeans",
                    subcategories: [
                      { name: "Shirts" },
                      { name: "Pants" },
                      { name: "Suits" },
                    ],
                  },
                  {
                    name: "Chinos",
                    subcategories: [
                      { name: "Shirts" },
                      { name: "Pants" },
                      { name: "Suits" },
                    ],
                  },
                ],
              },
              {
                name: "Suits",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
            ],
          },
          {
            name: "Shoes",
            subcategories: [
              {
                name: "Sneakers",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
              {
                name: "Boots",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
              {
                name: "Dress Shoes",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
            ],
          },
          {
            name: "Accessories",
            subcategories: [
              {
                name: "Belts",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
              {
                name: "Ties",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
              {
                name: "Hats",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
              {
                name: "Jewelry",
                subcategories: [
                  {
                    name: "Necklaces",
                    subcategories: [
                      {
                        name: "Pendant Necklaces",
                        subcategories: [
                          { name: "Shirts" },
                          { name: "Pants" },
                          { name: "Suits" },
                        ],
                      },
                      {
                        name: "Choker Necklaces",
                        subcategories: [
                          { name: "Shirts" },
                          { name: "Pants" },
                          { name: "Suits" },
                        ],
                      },
                    ],
                  },
                  {
                    name: "Bracelets",
                    subcategories: [
                      {
                        name: "Bangle Bracelets",
                        subcategories: [
                          { name: "Shirts" },
                          { name: "Pants" },
                          { name: "Suits" },
                        ],
                      },
                      {
                        name: "Cuff Bracelets",
                        subcategories: [
                          { name: "Shirts" },
                          { name: "Pants" },
                          { name: "Suits" },
                        ],
                      },
                    ],
                  },
                  {
                    name: "Earrings",
                    subcategories: [
                      { name: "Shirts" },
                      { name: "Pants" },
                      { name: "Suits" },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        name: "Men Fashion",
        subcategories: [
          {
            name: "Clothing",
            subcategories: [
              {
                name: "Shirts",
                subcategories: [
                  {
                    name: "Dress Shirts",
                    subcategories: [
                      { name: "Shirts" },
                      { name: "Pants" },
                      { name: "Suits" },
                    ],
                  },
                  {
                    name: "T-Shirts",
                    subcategories: [
                      {
                        name: "Graphic T-Shirts",
                        subcategories: [
                          { name: "Shirts" },
                          { name: "Pants" },
                          { name: "Suits" },
                        ],
                      },
                      {
                        name: "Plain T-Shirts",
                        subcategories: [
                          { name: "Shirts" },
                          { name: "Pants" },
                          { name: "Suits" },
                        ],
                      },
                    ],
                  },
                  {
                    name: "Polo Shirts",
                    subcategories: [
                      { name: "Shirts" },
                      { name: "Pants" },
                      { name: "Suits" },
                    ],
                  },
                ],
              },
              {
                name: "Pants",
                subcategories: [
                  {
                    name: "Jeans",
                    subcategories: [
                      { name: "Shirts" },
                      { name: "Pants" },
                      { name: "Suits" },
                    ],
                  },
                  {
                    name: "Chinos",
                    subcategories: [
                      { name: "Shirts" },
                      { name: "Pants" },
                      { name: "Suits" },
                    ],
                  },
                ],
              },
              {
                name: "Suits",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
            ],
          },
          {
            name: "Shoes",
            subcategories: [
              {
                name: "Sneakers",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
              {
                name: "Boots",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
              {
                name: "Dress Shoes",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
            ],
          },
          {
            name: "Accessories",
            subcategories: [
              {
                name: "Belts",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
              {
                name: "Ties",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
              {
                name: "Hats",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
              {
                name: "Jewelry",
                subcategories: [
                  {
                    name: "Necklaces",
                    subcategories: [
                      {
                        name: "Pendant Necklaces",
                        subcategories: [
                          { name: "Shirts" },
                          { name: "Pants" },
                          { name: "Suits" },
                        ],
                      },
                      {
                        name: "Choker Necklaces",
                        subcategories: [
                          { name: "Shirts" },
                          { name: "Pants" },
                          { name: "Suits" },
                        ],
                      },
                    ],
                  },
                  {
                    name: "Bracelets",
                    subcategories: [
                      {
                        name: "Bangle Bracelets",
                        subcategories: [
                          { name: "Shirts" },
                          { name: "Pants" },
                          { name: "Suits" },
                        ],
                      },
                      {
                        name: "Cuff Bracelets",
                        subcategories: [
                          { name: "Shirts" },
                          { name: "Pants" },
                          { name: "Suits" },
                        ],
                      },
                    ],
                  },
                  {
                    name: "Earrings",
                    subcategories: [
                      { name: "Shirts" },
                      { name: "Pants" },
                      { name: "Suits" },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        name: "Men Fashion",
        subcategories: [
          {
            name: "Clothing",
            subcategories: [
              {
                name: "Shirts",
                subcategories: [
                  {
                    name: "Dress Shirts",
                    subcategories: [
                      { name: "Shirts" },
                      { name: "Pants" },
                      { name: "Suits" },
                    ],
                  },
                  {
                    name: "T-Shirts",
                    subcategories: [
                      {
                        name: "Graphic T-Shirts",
                        subcategories: [
                          { name: "Shirts" },
                          { name: "Pants" },
                          { name: "Suits" },
                        ],
                      },
                      {
                        name: "Plain T-Shirts",
                        subcategories: [
                          { name: "Shirts" },
                          { name: "Pants" },
                          { name: "Suits" },
                        ],
                      },
                    ],
                  },
                  {
                    name: "Polo Shirts",
                    subcategories: [
                      { name: "Shirts" },
                      { name: "Pants" },
                      { name: "Suits" },
                    ],
                  },
                ],
              },
              {
                name: "Pants",
                subcategories: [
                  {
                    name: "Jeans",
                    subcategories: [
                      { name: "Shirts" },
                      { name: "Pants" },
                      { name: "Suits" },
                    ],
                  },
                  {
                    name: "Chinos",
                    subcategories: [
                      { name: "Shirts" },
                      { name: "Pants" },
                      { name: "Suits" },
                    ],
                  },
                ],
              },
              {
                name: "Suits",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
            ],
          },
          {
            name: "Shoes",
            subcategories: [
              {
                name: "Sneakers",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
              {
                name: "Boots",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
              {
                name: "Dress Shoes",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
            ],
          },
          {
            name: "Accessories",
            subcategories: [
              {
                name: "Belts",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
              {
                name: "Ties",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
              {
                name: "Hats",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
              {
                name: "Jewelry",
                subcategories: [
                  {
                    name: "Necklaces",
                    subcategories: [
                      {
                        name: "Pendant Necklaces",
                        subcategories: [
                          { name: "Shirts" },
                          { name: "Pants" },
                          { name: "Suits" },
                        ],
                      },
                      {
                        name: "Choker Necklaces",
                        subcategories: [
                          { name: "Shirts" },
                          { name: "Pants" },
                          { name: "Suits" },
                        ],
                      },
                    ],
                  },
                  {
                    name: "Bracelets",
                    subcategories: [
                      {
                        name: "Bangle Bracelets",
                        subcategories: [
                          { name: "Shirts" },
                          { name: "Pants" },
                          { name: "Suits" },
                        ],
                      },
                      {
                        name: "Cuff Bracelets",
                        subcategories: [
                          { name: "Shirts" },
                          { name: "Pants" },
                          { name: "Suits" },
                        ],
                      },
                    ],
                  },
                  {
                    name: "Earrings",
                    subcategories: [
                      { name: "Shirts" },
                      { name: "Pants" },
                      { name: "Suits" },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        name: "Men Fashion",
        subcategories: [
          {
            name: "Clothing",
            subcategories: [
              {
                name: "Shirts",
                subcategories: [
                  {
                    name: "Dress Shirts",
                    subcategories: [
                      { name: "Shirts" },
                      { name: "Pants" },
                      { name: "Suits" },
                    ],
                  },
                  {
                    name: "T-Shirts",
                    subcategories: [
                      {
                        name: "Graphic T-Shirts",
                        subcategories: [
                          { name: "Shirts" },
                          { name: "Pants" },
                          { name: "Suits" },
                        ],
                      },
                      {
                        name: "Plain T-Shirts",
                        subcategories: [
                          { name: "Shirts" },
                          { name: "Pants" },
                          { name: "Suits" },
                        ],
                      },
                    ],
                  },
                  {
                    name: "Polo Shirts",
                    subcategories: [
                      { name: "Shirts" },
                      { name: "Pants" },
                      { name: "Suits" },
                    ],
                  },
                ],
              },
              {
                name: "Pants",
                subcategories: [
                  {
                    name: "Jeans",
                    subcategories: [
                      { name: "Shirts" },
                      { name: "Pants" },
                      { name: "Suits" },
                    ],
                  },
                  {
                    name: "Chinos",
                    subcategories: [
                      { name: "Shirts" },
                      { name: "Pants" },
                      { name: "Suits" },
                    ],
                  },
                ],
              },
              {
                name: "Suits",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
            ],
          },
          {
            name: "Shoes",
            subcategories: [
              {
                name: "Sneakers",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
              {
                name: "Boots",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
              {
                name: "Dress Shoes",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
            ],
          },
          {
            name: "Accessories",
            subcategories: [
              {
                name: "Belts",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
              {
                name: "Ties",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
              {
                name: "Hats",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
              {
                name: "Jewelry",
                subcategories: [
                  {
                    name: "Necklaces",
                    subcategories: [
                      {
                        name: "Pendant Necklaces",
                        subcategories: [
                          { name: "Shirts" },
                          { name: "Pants" },
                          { name: "Suits" },
                        ],
                      },
                      {
                        name: "Choker Necklaces",
                        subcategories: [
                          { name: "Shirts" },
                          { name: "Pants" },
                          { name: "Suits" },
                        ],
                      },
                    ],
                  },
                  {
                    name: "Bracelets",
                    subcategories: [
                      {
                        name: "Bangle Bracelets",
                        subcategories: [
                          { name: "Shirts" },
                          { name: "Pants" },
                          { name: "Suits" },
                        ],
                      },
                      {
                        name: "Cuff Bracelets",
                        subcategories: [
                          { name: "Shirts" },
                          { name: "Pants" },
                          { name: "Suits" },
                        ],
                      },
                    ],
                  },
                  {
                    name: "Earrings",
                    subcategories: [
                      { name: "Shirts" },
                      { name: "Pants" },
                      { name: "Suits" },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        name: "Men Fashion",
        subcategories: [
          {
            name: "Clothing",
            subcategories: [
              {
                name: "Shirts",
                subcategories: [
                  {
                    name: "Dress Shirts",
                    subcategories: [
                      { name: "Shirts" },
                      { name: "Pants" },
                      { name: "Suits" },
                    ],
                  },
                  {
                    name: "T-Shirts",
                    subcategories: [
                      {
                        name: "Graphic T-Shirts",
                        subcategories: [
                          { name: "Shirts" },
                          { name: "Pants" },
                          { name: "Suits" },
                        ],
                      },
                      {
                        name: "Plain T-Shirts",
                        subcategories: [
                          { name: "Shirts" },
                          { name: "Pants" },
                          { name: "Suits" },
                        ],
                      },
                    ],
                  },
                  {
                    name: "Polo Shirts",
                    subcategories: [
                      { name: "Shirts" },
                      { name: "Pants" },
                      { name: "Suits" },
                    ],
                  },
                ],
              },
              {
                name: "Pants",
                subcategories: [
                  {
                    name: "Jeans",
                    subcategories: [
                      { name: "Shirts" },
                      { name: "Pants" },
                      { name: "Suits" },
                    ],
                  },
                  {
                    name: "Chinos",
                    subcategories: [
                      { name: "Shirts" },
                      { name: "Pants" },
                      { name: "Suits" },
                    ],
                  },
                ],
              },
              {
                name: "Suits",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
            ],
          },
          {
            name: "Shoes",
            subcategories: [
              {
                name: "Sneakers",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
              {
                name: "Boots",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
              {
                name: "Dress Shoes",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
            ],
          },
          {
            name: "Accessories",
            subcategories: [
              {
                name: "Belts",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
              {
                name: "Ties",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
              {
                name: "Hats",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
              {
                name: "Jewelry",
                subcategories: [
                  {
                    name: "Necklaces",
                    subcategories: [
                      {
                        name: "Pendant Necklaces",
                        subcategories: [
                          { name: "Shirts" },
                          { name: "Pants" },
                          { name: "Suits" },
                        ],
                      },
                      {
                        name: "Choker Necklaces",
                        subcategories: [
                          { name: "Shirts" },
                          { name: "Pants" },
                          { name: "Suits" },
                        ],
                      },
                    ],
                  },
                  {
                    name: "Bracelets",
                    subcategories: [
                      {
                        name: "Bangle Bracelets",
                        subcategories: [
                          { name: "Shirts" },
                          { name: "Pants" },
                          { name: "Suits" },
                        ],
                      },
                      {
                        name: "Cuff Bracelets",
                        subcategories: [
                          { name: "Shirts" },
                          { name: "Pants" },
                          { name: "Suits" },
                        ],
                      },
                    ],
                  },
                  {
                    name: "Earrings",
                    subcategories: [
                      { name: "Shirts" },
                      { name: "Pants" },
                      { name: "Suits" },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    name: "Fashion1",
    subcategories: [
      {
        name: "Men Fashion",
        subcategories: [
          {
            name: "Clothing",
            subcategories: [
              {
                name: "Shirts",
                subcategories: [
                  {
                    name: "Dress Shirts",
                    subcategories: [
                      { name: "Shirts" },
                      { name: "Pants" },
                      { name: "Suits" },
                    ],
                  },
                  {
                    name: "T-Shirts",
                    subcategories: [
                      {
                        name: "Graphic T-Shirts",
                        subcategories: [
                          { name: "Shirts" },
                          { name: "Pants" },
                          { name: "Suits" },
                        ],
                      },
                      {
                        name: "Plain T-Shirts",
                        subcategories: [
                          { name: "Shirts" },
                          { name: "Pants" },
                          { name: "Suits" },
                        ],
                      },
                    ],
                  },
                  {
                    name: "Polo Shirts",
                    subcategories: [
                      { name: "Shirts" },
                      { name: "Pants" },
                      { name: "Suits" },
                    ],
                  },
                ],
              },
              {
                name: "Pants",
                subcategories: [
                  {
                    name: "Jeans",
                    subcategories: [
                      { name: "Shirts" },
                      { name: "Pants" },
                      { name: "Suits" },
                    ],
                  },
                  {
                    name: "Chinos",
                    subcategories: [
                      { name: "Shirts" },
                      { name: "Pants" },
                      { name: "Suits" },
                    ],
                  },
                ],
              },
              {
                name: "Suits",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
            ],
          },
          {
            name: "Shoes",
            subcategories: [
              {
                name: "Sneakers",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
              {
                name: "Boots",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
              {
                name: "Dress Shoes",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
            ],
          },
          {
            name: "Accessories",
            subcategories: [
              {
                name: "Belts",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
              {
                name: "Ties",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
              {
                name: "Hats",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
              {
                name: "Jewelry",
                subcategories: [
                  {
                    name: "Necklaces",
                    subcategories: [
                      {
                        name: "Pendant Necklaces",
                        subcategories: [
                          { name: "Shirts" },
                          { name: "Pants" },
                          { name: "Suits" },
                        ],
                      },
                      {
                        name: "Choker Necklaces",
                        subcategories: [
                          { name: "Shirts" },
                          { name: "Pants" },
                          { name: "Suits" },
                        ],
                      },
                    ],
                  },
                  {
                    name: "Bracelets",
                    subcategories: [
                      {
                        name: "Bangle Bracelets",
                        subcategories: [
                          { name: "Shirts" },
                          { name: "Pants" },
                          { name: "Suits" },
                        ],
                      },
                      {
                        name: "Cuff Bracelets",
                        subcategories: [
                          { name: "Shirts" },
                          { name: "Pants" },
                          { name: "Suits" },
                        ],
                      },
                    ],
                  },
                  {
                    name: "Earrings",
                    subcategories: [
                      { name: "Shirts" },
                      { name: "Pants" },
                      { name: "Suits" },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        name: "Men Fashion",
        subcategories: [
          {
            name: "Clothing",
            subcategories: [
              {
                name: "Shirts",
                subcategories: [
                  {
                    name: "Dress Shirts",
                    subcategories: [
                      { name: "Shirts" },
                      { name: "Pants" },
                      { name: "Suits" },
                    ],
                  },
                  {
                    name: "T-Shirts",
                    subcategories: [
                      {
                        name: "Graphic T-Shirts",
                        subcategories: [
                          { name: "Shirts" },
                          { name: "Pants" },
                          { name: "Suits" },
                        ],
                      },
                      {
                        name: "Plain T-Shirts",
                        subcategories: [
                          { name: "Shirts" },
                          { name: "Pants" },
                          { name: "Suits" },
                        ],
                      },
                    ],
                  },
                  {
                    name: "Polo Shirts",
                    subcategories: [
                      { name: "Shirts" },
                      { name: "Pants" },
                      { name: "Suits" },
                    ],
                  },
                ],
              },
              {
                name: "Pants",
                subcategories: [
                  {
                    name: "Jeans",
                    subcategories: [
                      { name: "Shirts" },
                      { name: "Pants" },
                      { name: "Suits" },
                    ],
                  },
                  {
                    name: "Chinos",
                    subcategories: [
                      { name: "Shirts" },
                      { name: "Pants" },
                      { name: "Suits" },
                    ],
                  },
                ],
              },
              {
                name: "Suits",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
            ],
          },
          {
            name: "Shoes",
            subcategories: [
              {
                name: "Sneakers",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
              {
                name: "Boots",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
              {
                name: "Dress Shoes",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
            ],
          },
          {
            name: "Accessories",
            subcategories: [
              {
                name: "Belts",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
              {
                name: "Ties",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
              {
                name: "Hats",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
              {
                name: "Jewelry",
                subcategories: [
                  {
                    name: "Necklaces",
                    subcategories: [
                      {
                        name: "Pendant Necklaces",
                        subcategories: [
                          { name: "Shirts" },
                          { name: "Pants" },
                          { name: "Suits" },
                        ],
                      },
                      {
                        name: "Choker Necklaces",
                        subcategories: [
                          { name: "Shirts" },
                          { name: "Pants" },
                          { name: "Suits" },
                        ],
                      },
                    ],
                  },
                  {
                    name: "Bracelets",
                    subcategories: [
                      {
                        name: "Bangle Bracelets",
                        subcategories: [
                          { name: "Shirts" },
                          { name: "Pants" },
                          { name: "Suits" },
                        ],
                      },
                      {
                        name: "Cuff Bracelets",
                        subcategories: [
                          { name: "Shirts" },
                          { name: "Pants" },
                          { name: "Suits" },
                        ],
                      },
                    ],
                  },
                  {
                    name: "Earrings",
                    subcategories: [
                      { name: "Shirts" },
                      { name: "Pants" },
                      { name: "Suits" },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        name: "Men Fashion",
        subcategories: [
          {
            name: "Clothing",
            subcategories: [
              {
                name: "Shirts",
                subcategories: [
                  {
                    name: "Dress Shirts",
                    subcategories: [
                      { name: "Shirts" },
                      { name: "Pants" },
                      { name: "Suits" },
                    ],
                  },
                  {
                    name: "T-Shirts",
                    subcategories: [
                      {
                        name: "Graphic T-Shirts",
                        subcategories: [
                          { name: "Shirts" },
                          { name: "Pants" },
                          { name: "Suits" },
                        ],
                      },
                      {
                        name: "Plain T-Shirts",
                        subcategories: [
                          { name: "Shirts" },
                          { name: "Pants" },
                          { name: "Suits" },
                        ],
                      },
                    ],
                  },
                  {
                    name: "Polo Shirts",
                    subcategories: [
                      { name: "Shirts" },
                      { name: "Pants" },
                      { name: "Suits" },
                    ],
                  },
                ],
              },
              {
                name: "Pants",
                subcategories: [
                  {
                    name: "Jeans",
                    subcategories: [
                      { name: "Shirts" },
                      { name: "Pants" },
                      { name: "Suits" },
                    ],
                  },
                  {
                    name: "Chinos",
                    subcategories: [
                      { name: "Shirts" },
                      { name: "Pants" },
                      { name: "Suits" },
                    ],
                  },
                ],
              },
              {
                name: "Suits",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
            ],
          },
          {
            name: "Shoes",
            subcategories: [
              {
                name: "Sneakers",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
              {
                name: "Boots",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
              {
                name: "Dress Shoes",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
            ],
          },
          {
            name: "Accessories",
            subcategories: [
              {
                name: "Belts",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
              {
                name: "Ties",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
              {
                name: "Hats",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
              {
                name: "Jewelry",
                subcategories: [
                  {
                    name: "Necklaces",
                    subcategories: [
                      {
                        name: "Pendant Necklaces",
                        subcategories: [
                          { name: "Shirts" },
                          { name: "Pants" },
                          { name: "Suits" },
                        ],
                      },
                      {
                        name: "Choker Necklaces",
                        subcategories: [
                          { name: "Shirts" },
                          { name: "Pants" },
                          { name: "Suits" },
                        ],
                      },
                    ],
                  },
                  {
                    name: "Bracelets",
                    subcategories: [
                      {
                        name: "Bangle Bracelets",
                        subcategories: [
                          { name: "Shirts" },
                          { name: "Pants" },
                          { name: "Suits" },
                        ],
                      },
                      {
                        name: "Cuff Bracelets",
                        subcategories: [
                          { name: "Shirts" },
                          { name: "Pants" },
                          { name: "Suits" },
                        ],
                      },
                    ],
                  },
                  {
                    name: "Earrings",
                    subcategories: [
                      { name: "Shirts" },
                      { name: "Pants" },
                      { name: "Suits" },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        name: "Men Fashion",
        subcategories: [
          {
            name: "Clothing",
            subcategories: [
              {
                name: "Shirts",
                subcategories: [
                  {
                    name: "Dress Shirts",
                    subcategories: [
                      { name: "Shirts" },
                      { name: "Pants" },
                      { name: "Suits" },
                    ],
                  },
                  {
                    name: "T-Shirts",
                    subcategories: [
                      {
                        name: "Graphic T-Shirts",
                        subcategories: [
                          { name: "Shirts" },
                          { name: "Pants" },
                          { name: "Suits" },
                        ],
                      },
                      {
                        name: "Plain T-Shirts",
                        subcategories: [
                          { name: "Shirts" },
                          { name: "Pants" },
                          { name: "Suits" },
                        ],
                      },
                    ],
                  },
                  {
                    name: "Polo Shirts",
                    subcategories: [
                      { name: "Shirts" },
                      { name: "Pants" },
                      { name: "Suits" },
                    ],
                  },
                ],
              },
              {
                name: "Pants",
                subcategories: [
                  {
                    name: "Jeans",
                    subcategories: [
                      { name: "Shirts" },
                      { name: "Pants" },
                      { name: "Suits" },
                    ],
                  },
                  {
                    name: "Chinos",
                    subcategories: [
                      { name: "Shirts" },
                      { name: "Pants" },
                      { name: "Suits" },
                    ],
                  },
                ],
              },
              {
                name: "Suits",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
            ],
          },
          {
            name: "Shoes",
            subcategories: [
              {
                name: "Sneakers",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
              {
                name: "Boots",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
              {
                name: "Dress Shoes",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
            ],
          },
          {
            name: "Accessories",
            subcategories: [
              {
                name: "Belts",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
              {
                name: "Ties",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
              {
                name: "Hats",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
              {
                name: "Jewelry",
                subcategories: [
                  {
                    name: "Necklaces",
                    subcategories: [
                      {
                        name: "Pendant Necklaces",
                        subcategories: [
                          { name: "Shirts" },
                          { name: "Pants" },
                          { name: "Suits" },
                        ],
                      },
                      {
                        name: "Choker Necklaces",
                        subcategories: [
                          { name: "Shirts" },
                          { name: "Pants" },
                          { name: "Suits" },
                        ],
                      },
                    ],
                  },
                  {
                    name: "Bracelets",
                    subcategories: [
                      {
                        name: "Bangle Bracelets",
                        subcategories: [
                          { name: "Shirts" },
                          { name: "Pants" },
                          { name: "Suits" },
                        ],
                      },
                      {
                        name: "Cuff Bracelets",
                        subcategories: [
                          { name: "Shirts" },
                          { name: "Pants" },
                          { name: "Suits" },
                        ],
                      },
                    ],
                  },
                  {
                    name: "Earrings",
                    subcategories: [
                      { name: "Shirts" },
                      { name: "Pants" },
                      { name: "Suits" },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        name: "Men Fashion",
        subcategories: [
          {
            name: "Clothing",
            subcategories: [
              {
                name: "Shirts",
                subcategories: [
                  {
                    name: "Dress Shirts",
                    subcategories: [
                      { name: "Shirts" },
                      { name: "Pants" },
                      { name: "Suits" },
                    ],
                  },
                  {
                    name: "T-Shirts",
                    subcategories: [
                      {
                        name: "Graphic T-Shirts",
                        subcategories: [
                          { name: "Shirts" },
                          { name: "Pants" },
                          { name: "Suits" },
                        ],
                      },
                      {
                        name: "Plain T-Shirts",
                        subcategories: [
                          { name: "Shirts" },
                          { name: "Pants" },
                          { name: "Suits" },
                        ],
                      },
                    ],
                  },
                  {
                    name: "Polo Shirts",
                    subcategories: [
                      { name: "Shirts" },
                      { name: "Pants" },
                      { name: "Suits" },
                    ],
                  },
                ],
              },
              {
                name: "Pants",
                subcategories: [
                  {
                    name: "Jeans",
                    subcategories: [
                      { name: "Shirts" },
                      { name: "Pants" },
                      { name: "Suits" },
                    ],
                  },
                  {
                    name: "Chinos",
                    subcategories: [
                      { name: "Shirts" },
                      { name: "Pants" },
                      { name: "Suits" },
                    ],
                  },
                ],
              },
              {
                name: "Suits",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
            ],
          },
          {
            name: "Shoes",
            subcategories: [
              {
                name: "Sneakers",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
              {
                name: "Boots",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
              {
                name: "Dress Shoes",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
            ],
          },
          {
            name: "Accessories",
            subcategories: [
              {
                name: "Belts",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
              {
                name: "Ties",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
              {
                name: "Hats",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
              {
                name: "Jewelry",
                subcategories: [
                  {
                    name: "Necklaces",
                    subcategories: [
                      {
                        name: "Pendant Necklaces",
                        subcategories: [
                          { name: "Shirts" },
                          { name: "Pants" },
                          { name: "Suits" },
                        ],
                      },
                      {
                        name: "Choker Necklaces",
                        subcategories: [
                          { name: "Shirts" },
                          { name: "Pants" },
                          { name: "Suits" },
                        ],
                      },
                    ],
                  },
                  {
                    name: "Bracelets",
                    subcategories: [
                      {
                        name: "Bangle Bracelets",
                        subcategories: [
                          { name: "Shirts" },
                          { name: "Pants" },
                          { name: "Suits" },
                        ],
                      },
                      {
                        name: "Cuff Bracelets",
                        subcategories: [
                          { name: "Shirts" },
                          { name: "Pants" },
                          { name: "Suits" },
                        ],
                      },
                    ],
                  },
                  {
                    name: "Earrings",
                    subcategories: [
                      { name: "Shirts" },
                      { name: "Pants" },
                      { name: "Suits" },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    name: "Fashion2",
    subcategories: [
      {
        name: "Men Fashion",
        subcategories: [
          {
            name: "Clothing",
            subcategories: [
              {
                name: "Shirts",
                subcategories: [
                  {
                    name: "Dress Shirts",
                    subcategories: [
                      { name: "Shirts" },
                      { name: "Pants" },
                      { name: "Suits" },
                    ],
                  },
                  {
                    name: "T-Shirts",
                    subcategories: [
                      {
                        name: "Graphic T-Shirts",
                        subcategories: [
                          { name: "Shirts" },
                          { name: "Pants" },
                          { name: "Suits" },
                        ],
                      },
                      {
                        name: "Plain T-Shirts",
                        subcategories: [
                          { name: "Shirts" },
                          { name: "Pants" },
                          { name: "Suits" },
                        ],
                      },
                    ],
                  },
                  {
                    name: "Polo Shirts",
                    subcategories: [
                      { name: "Shirts" },
                      { name: "Pants" },
                      { name: "Suits" },
                    ],
                  },
                ],
              },
              {
                name: "Pants",
                subcategories: [
                  {
                    name: "Jeans",
                    subcategories: [
                      { name: "Shirts" },
                      { name: "Pants" },
                      { name: "Suits" },
                    ],
                  },
                  {
                    name: "Chinos",
                    subcategories: [
                      { name: "Shirts" },
                      { name: "Pants" },
                      { name: "Suits" },
                    ],
                  },
                ],
              },
              {
                name: "Suits",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
            ],
          },
          {
            name: "Shoes",
            subcategories: [
              {
                name: "Sneakers",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
              {
                name: "Boots",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
              {
                name: "Dress Shoes",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
            ],
          },
          {
            name: "Accessories",
            subcategories: [
              {
                name: "Belts",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
              {
                name: "Ties",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
              {
                name: "Hats",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
              {
                name: "Jewelry",
                subcategories: [
                  {
                    name: "Necklaces",
                    subcategories: [
                      {
                        name: "Pendant Necklaces",
                        subcategories: [
                          { name: "Shirts" },
                          { name: "Pants" },
                          { name: "Suits" },
                        ],
                      },
                      {
                        name: "Choker Necklaces",
                        subcategories: [
                          { name: "Shirts" },
                          { name: "Pants" },
                          { name: "Suits" },
                        ],
                      },
                    ],
                  },
                  {
                    name: "Bracelets",
                    subcategories: [
                      {
                        name: "Bangle Bracelets",
                        subcategories: [
                          { name: "Shirts" },
                          { name: "Pants" },
                          { name: "Suits" },
                        ],
                      },
                      {
                        name: "Cuff Bracelets",
                        subcategories: [
                          { name: "Shirts" },
                          { name: "Pants" },
                          { name: "Suits" },
                        ],
                      },
                    ],
                  },
                  {
                    name: "Earrings",
                    subcategories: [
                      { name: "Shirts" },
                      { name: "Pants" },
                      { name: "Suits" },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        name: "Men Fashion",
        subcategories: [
          {
            name: "Clothing",
            subcategories: [
              {
                name: "Shirts",
                subcategories: [
                  {
                    name: "Dress Shirts",
                    subcategories: [
                      { name: "Shirts" },
                      { name: "Pants" },
                      { name: "Suits" },
                    ],
                  },
                  {
                    name: "T-Shirts",
                    subcategories: [
                      {
                        name: "Graphic T-Shirts",
                        subcategories: [
                          { name: "Shirts" },
                          { name: "Pants" },
                          { name: "Suits" },
                        ],
                      },
                      {
                        name: "Plain T-Shirts",
                        subcategories: [
                          { name: "Shirts" },
                          { name: "Pants" },
                          { name: "Suits" },
                        ],
                      },
                    ],
                  },
                  {
                    name: "Polo Shirts",
                    subcategories: [
                      { name: "Shirts" },
                      { name: "Pants" },
                      { name: "Suits" },
                    ],
                  },
                ],
              },
              {
                name: "Pants",
                subcategories: [
                  {
                    name: "Jeans",
                    subcategories: [
                      { name: "Shirts" },
                      { name: "Pants" },
                      { name: "Suits" },
                    ],
                  },
                  {
                    name: "Chinos",
                    subcategories: [
                      { name: "Shirts" },
                      { name: "Pants" },
                      { name: "Suits" },
                    ],
                  },
                ],
              },
              {
                name: "Suits",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
            ],
          },
          {
            name: "Shoes",
            subcategories: [
              {
                name: "Sneakers",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
              {
                name: "Boots",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
              {
                name: "Dress Shoes",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
            ],
          },
          {
            name: "Accessories",
            subcategories: [
              {
                name: "Belts",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
              {
                name: "Ties",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
              {
                name: "Hats",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
              {
                name: "Jewelry",
                subcategories: [
                  {
                    name: "Necklaces",
                    subcategories: [
                      {
                        name: "Pendant Necklaces",
                        subcategories: [
                          { name: "Shirts" },
                          { name: "Pants" },
                          { name: "Suits" },
                        ],
                      },
                      {
                        name: "Choker Necklaces",
                        subcategories: [
                          { name: "Shirts" },
                          { name: "Pants" },
                          { name: "Suits" },
                        ],
                      },
                    ],
                  },
                  {
                    name: "Bracelets",
                    subcategories: [
                      {
                        name: "Bangle Bracelets",
                        subcategories: [
                          { name: "Shirts" },
                          { name: "Pants" },
                          { name: "Suits" },
                        ],
                      },
                      {
                        name: "Cuff Bracelets",
                        subcategories: [
                          { name: "Shirts" },
                          { name: "Pants" },
                          { name: "Suits" },
                        ],
                      },
                    ],
                  },
                  {
                    name: "Earrings",
                    subcategories: [
                      { name: "Shirts" },
                      { name: "Pants" },
                      { name: "Suits" },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        name: "Men Fashion",
        subcategories: [
          {
            name: "Clothing",
            subcategories: [
              {
                name: "Shirts",
                subcategories: [
                  {
                    name: "Dress Shirts",
                    subcategories: [
                      { name: "Shirts" },
                      { name: "Pants" },
                      { name: "Suits" },
                    ],
                  },
                  {
                    name: "T-Shirts",
                    subcategories: [
                      {
                        name: "Graphic T-Shirts",
                        subcategories: [
                          { name: "Shirts" },
                          { name: "Pants" },
                          { name: "Suits" },
                        ],
                      },
                      {
                        name: "Plain T-Shirts",
                        subcategories: [
                          { name: "Shirts" },
                          { name: "Pants" },
                          { name: "Suits" },
                        ],
                      },
                    ],
                  },
                  {
                    name: "Polo Shirts",
                    subcategories: [
                      { name: "Shirts" },
                      { name: "Pants" },
                      { name: "Suits" },
                    ],
                  },
                ],
              },
              {
                name: "Pants",
                subcategories: [
                  {
                    name: "Jeans",
                    subcategories: [
                      { name: "Shirts" },
                      { name: "Pants" },
                      { name: "Suits" },
                    ],
                  },
                  {
                    name: "Chinos",
                    subcategories: [
                      { name: "Shirts" },
                      { name: "Pants" },
                      { name: "Suits" },
                    ],
                  },
                ],
              },
              {
                name: "Suits",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
            ],
          },
          {
            name: "Shoes",
            subcategories: [
              {
                name: "Sneakers",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
              {
                name: "Boots",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
              {
                name: "Dress Shoes",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
            ],
          },
          {
            name: "Accessories",
            subcategories: [
              {
                name: "Belts",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
              {
                name: "Ties",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
              {
                name: "Hats",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
              {
                name: "Jewelry",
                subcategories: [
                  {
                    name: "Necklaces",
                    subcategories: [
                      {
                        name: "Pendant Necklaces",
                        subcategories: [
                          { name: "Shirts" },
                          { name: "Pants" },
                          { name: "Suits" },
                        ],
                      },
                      {
                        name: "Choker Necklaces",
                        subcategories: [
                          { name: "Shirts" },
                          { name: "Pants" },
                          { name: "Suits" },
                        ],
                      },
                    ],
                  },
                  {
                    name: "Bracelets",
                    subcategories: [
                      {
                        name: "Bangle Bracelets",
                        subcategories: [
                          { name: "Shirts" },
                          { name: "Pants" },
                          { name: "Suits" },
                        ],
                      },
                      {
                        name: "Cuff Bracelets",
                        subcategories: [
                          { name: "Shirts" },
                          { name: "Pants" },
                          { name: "Suits" },
                        ],
                      },
                    ],
                  },
                  {
                    name: "Earrings",
                    subcategories: [
                      { name: "Shirts" },
                      { name: "Pants" },
                      { name: "Suits" },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        name: "Men Fashion",
        subcategories: [
          {
            name: "Clothing",
            subcategories: [
              {
                name: "Shirts",
                subcategories: [
                  {
                    name: "Dress Shirts",
                    subcategories: [
                      { name: "Shirts" },
                      { name: "Pants" },
                      { name: "Suits" },
                    ],
                  },
                  {
                    name: "T-Shirts",
                    subcategories: [
                      {
                        name: "Graphic T-Shirts",
                        subcategories: [
                          { name: "Shirts" },
                          { name: "Pants" },
                          { name: "Suits" },
                        ],
                      },
                      {
                        name: "Plain T-Shirts",
                        subcategories: [
                          { name: "Shirts" },
                          { name: "Pants" },
                          { name: "Suits" },
                        ],
                      },
                    ],
                  },
                  {
                    name: "Polo Shirts",
                    subcategories: [
                      { name: "Shirts" },
                      { name: "Pants" },
                      { name: "Suits" },
                    ],
                  },
                ],
              },
              {
                name: "Pants",
                subcategories: [
                  {
                    name: "Jeans",
                    subcategories: [
                      { name: "Shirts" },
                      { name: "Pants" },
                      { name: "Suits" },
                    ],
                  },
                  {
                    name: "Chinos",
                    subcategories: [
                      { name: "Shirts" },
                      { name: "Pants" },
                      { name: "Suits" },
                    ],
                  },
                ],
              },
              {
                name: "Suits",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
            ],
          },
          {
            name: "Shoes",
            subcategories: [
              {
                name: "Sneakers",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
              {
                name: "Boots",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
              {
                name: "Dress Shoes",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
            ],
          },
          {
            name: "Accessories",
            subcategories: [
              {
                name: "Belts",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
              {
                name: "Ties",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
              {
                name: "Hats",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
              {
                name: "Jewelry",
                subcategories: [
                  {
                    name: "Necklaces",
                    subcategories: [
                      {
                        name: "Pendant Necklaces",
                        subcategories: [
                          { name: "Shirts" },
                          { name: "Pants" },
                          { name: "Suits" },
                        ],
                      },
                      {
                        name: "Choker Necklaces",
                        subcategories: [
                          { name: "Shirts" },
                          { name: "Pants" },
                          { name: "Suits" },
                        ],
                      },
                    ],
                  },
                  {
                    name: "Bracelets",
                    subcategories: [
                      {
                        name: "Bangle Bracelets",
                        subcategories: [
                          { name: "Shirts" },
                          { name: "Pants" },
                          { name: "Suits" },
                        ],
                      },
                      {
                        name: "Cuff Bracelets",
                        subcategories: [
                          { name: "Shirts" },
                          { name: "Pants" },
                          { name: "Suits" },
                        ],
                      },
                    ],
                  },
                  {
                    name: "Earrings",
                    subcategories: [
                      { name: "Shirts" },
                      { name: "Pants" },
                      { name: "Suits" },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        name: "Men Fashion",
        subcategories: [
          {
            name: "Clothing",
            subcategories: [
              {
                name: "Shirts",
                subcategories: [
                  {
                    name: "Dress Shirts",
                    subcategories: [
                      { name: "Shirts" },
                      { name: "Pants" },
                      { name: "Suits" },
                    ],
                  },
                  {
                    name: "T-Shirts",
                    subcategories: [
                      {
                        name: "Graphic T-Shirts",
                        subcategories: [
                          { name: "Shirts" },
                          { name: "Pants" },
                          { name: "Suits" },
                        ],
                      },
                      {
                        name: "Plain T-Shirts",
                        subcategories: [
                          { name: "Shirts" },
                          { name: "Pants" },
                          { name: "Suits" },
                        ],
                      },
                    ],
                  },
                  {
                    name: "Polo Shirts",
                    subcategories: [
                      { name: "Shirts" },
                      { name: "Pants" },
                      { name: "Suits" },
                    ],
                  },
                ],
              },
              {
                name: "Pants",
                subcategories: [
                  {
                    name: "Jeans",
                    subcategories: [
                      { name: "Shirts" },
                      { name: "Pants" },
                      { name: "Suits" },
                    ],
                  },
                  {
                    name: "Chinos",
                    subcategories: [
                      { name: "Shirts" },
                      { name: "Pants" },
                      { name: "Suits" },
                    ],
                  },
                ],
              },
              {
                name: "Suits",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
            ],
          },
          {
            name: "Shoes",
            subcategories: [
              {
                name: "Sneakers",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
              {
                name: "Boots",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
              {
                name: "Dress Shoes",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
            ],
          },
          {
            name: "Accessories",
            subcategories: [
              {
                name: "Belts",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
              {
                name: "Ties",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
              {
                name: "Hats",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
              {
                name: "Jewelry",
                subcategories: [
                  {
                    name: "Necklaces",
                    subcategories: [
                      {
                        name: "Pendant Necklaces",
                        subcategories: [
                          { name: "Shirts" },
                          { name: "Pants" },
                          { name: "Suits" },
                        ],
                      },
                      {
                        name: "Choker Necklaces",
                        subcategories: [
                          { name: "Shirts" },
                          { name: "Pants" },
                          { name: "Suits" },
                        ],
                      },
                    ],
                  },
                  {
                    name: "Bracelets",
                    subcategories: [
                      {
                        name: "Bangle Bracelets",
                        subcategories: [
                          { name: "Shirts" },
                          { name: "Pants" },
                          { name: "Suits" },
                        ],
                      },
                      {
                        name: "Cuff Bracelets",
                        subcategories: [
                          { name: "Shirts" },
                          { name: "Pants" },
                          { name: "Suits" },
                        ],
                      },
                    ],
                  },
                  {
                    name: "Earrings",
                    subcategories: [
                      { name: "Shirts" },
                      { name: "Pants" },
                      { name: "Suits" },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    name: "Fashion3",
    subcategories: [
      {
        name: "Men Fashion",
        subcategories: [
          {
            name: "Clothing",
            subcategories: [
              {
                name: "Shirts",
                subcategories: [
                  {
                    name: "Dress Shirts",
                    subcategories: [
                      { name: "Shirts" },
                      { name: "Pants" },
                      { name: "Suits" },
                    ],
                  },
                  {
                    name: "T-Shirts",
                    subcategories: [
                      {
                        name: "Graphic T-Shirts",
                        subcategories: [
                          { name: "Shirts" },
                          { name: "Pants" },
                          { name: "Suits" },
                        ],
                      },
                      {
                        name: "Plain T-Shirts",
                        subcategories: [
                          { name: "Shirts" },
                          { name: "Pants" },
                          { name: "Suits" },
                        ],
                      },
                    ],
                  },
                  {
                    name: "Polo Shirts",
                    subcategories: [
                      { name: "Shirts" },
                      { name: "Pants" },
                      { name: "Suits" },
                    ],
                  },
                ],
              },
              {
                name: "Pants",
                subcategories: [
                  {
                    name: "Jeans",
                    subcategories: [
                      { name: "Shirts" },
                      { name: "Pants" },
                      { name: "Suits" },
                    ],
                  },
                  {
                    name: "Chinos",
                    subcategories: [
                      { name: "Shirts" },
                      { name: "Pants" },
                      { name: "Suits" },
                    ],
                  },
                ],
              },
              {
                name: "Suits",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
            ],
          },
          {
            name: "Shoes",
            subcategories: [
              {
                name: "Sneakers",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
              {
                name: "Boots",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
              {
                name: "Dress Shoes",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
            ],
          },
          {
            name: "Accessories",
            subcategories: [
              {
                name: "Belts",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
              {
                name: "Ties",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
              {
                name: "Hats",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
              {
                name: "Jewelry",
                subcategories: [
                  {
                    name: "Necklaces",
                    subcategories: [
                      {
                        name: "Pendant Necklaces",
                        subcategories: [
                          { name: "Shirts" },
                          { name: "Pants" },
                          { name: "Suits" },
                        ],
                      },
                      {
                        name: "Choker Necklaces",
                        subcategories: [
                          { name: "Shirts" },
                          { name: "Pants" },
                          { name: "Suits" },
                        ],
                      },
                    ],
                  },
                  {
                    name: "Bracelets",
                    subcategories: [
                      {
                        name: "Bangle Bracelets",
                        subcategories: [
                          { name: "Shirts" },
                          { name: "Pants" },
                          { name: "Suits" },
                        ],
                      },
                      {
                        name: "Cuff Bracelets",
                        subcategories: [
                          { name: "Shirts" },
                          { name: "Pants" },
                          { name: "Suits" },
                        ],
                      },
                    ],
                  },
                  {
                    name: "Earrings",
                    subcategories: [
                      { name: "Shirts" },
                      { name: "Pants" },
                      { name: "Suits" },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        name: "Men Fashion",
        subcategories: [
          {
            name: "Clothing",
            subcategories: [
              {
                name: "Shirts",
                subcategories: [
                  {
                    name: "Dress Shirts",
                    subcategories: [
                      { name: "Shirts" },
                      { name: "Pants" },
                      { name: "Suits" },
                    ],
                  },
                  {
                    name: "T-Shirts",
                    subcategories: [
                      {
                        name: "Graphic T-Shirts",
                        subcategories: [
                          { name: "Shirts" },
                          { name: "Pants" },
                          { name: "Suits" },
                        ],
                      },
                      {
                        name: "Plain T-Shirts",
                        subcategories: [
                          { name: "Shirts" },
                          { name: "Pants" },
                          { name: "Suits" },
                        ],
                      },
                    ],
                  },
                  {
                    name: "Polo Shirts",
                    subcategories: [
                      { name: "Shirts" },
                      { name: "Pants" },
                      { name: "Suits" },
                    ],
                  },
                ],
              },
              {
                name: "Pants",
                subcategories: [
                  {
                    name: "Jeans",
                    subcategories: [
                      { name: "Shirts" },
                      { name: "Pants" },
                      { name: "Suits" },
                    ],
                  },
                  {
                    name: "Chinos",
                    subcategories: [
                      { name: "Shirts" },
                      { name: "Pants" },
                      { name: "Suits" },
                    ],
                  },
                ],
              },
              {
                name: "Suits",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
            ],
          },
          {
            name: "Shoes",
            subcategories: [
              {
                name: "Sneakers",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
              {
                name: "Boots",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
              {
                name: "Dress Shoes",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
            ],
          },
          {
            name: "Accessories",
            subcategories: [
              {
                name: "Belts",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
              {
                name: "Ties",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
              {
                name: "Hats",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
              {
                name: "Jewelry",
                subcategories: [
                  {
                    name: "Necklaces",
                    subcategories: [
                      {
                        name: "Pendant Necklaces",
                        subcategories: [
                          { name: "Shirts" },
                          { name: "Pants" },
                          { name: "Suits" },
                        ],
                      },
                      {
                        name: "Choker Necklaces",
                        subcategories: [
                          { name: "Shirts" },
                          { name: "Pants" },
                          { name: "Suits" },
                        ],
                      },
                    ],
                  },
                  {
                    name: "Bracelets",
                    subcategories: [
                      {
                        name: "Bangle Bracelets",
                        subcategories: [
                          { name: "Shirts" },
                          { name: "Pants" },
                          { name: "Suits" },
                        ],
                      },
                      {
                        name: "Cuff Bracelets",
                        subcategories: [
                          { name: "Shirts" },
                          { name: "Pants" },
                          { name: "Suits" },
                        ],
                      },
                    ],
                  },
                  {
                    name: "Earrings",
                    subcategories: [
                      { name: "Shirts" },
                      { name: "Pants" },
                      { name: "Suits" },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        name: "Men Fashion",
        subcategories: [
          {
            name: "Clothing",
            subcategories: [
              {
                name: "Shirts",
                subcategories: [
                  {
                    name: "Dress Shirts",
                    subcategories: [
                      { name: "Shirts" },
                      { name: "Pants" },
                      { name: "Suits" },
                    ],
                  },
                  {
                    name: "T-Shirts",
                    subcategories: [
                      {
                        name: "Graphic T-Shirts",
                        subcategories: [
                          { name: "Shirts" },
                          { name: "Pants" },
                          { name: "Suits" },
                        ],
                      },
                      {
                        name: "Plain T-Shirts",
                        subcategories: [
                          { name: "Shirts" },
                          { name: "Pants" },
                          { name: "Suits" },
                        ],
                      },
                    ],
                  },
                  {
                    name: "Polo Shirts",
                    subcategories: [
                      { name: "Shirts" },
                      { name: "Pants" },
                      { name: "Suits" },
                    ],
                  },
                ],
              },
              {
                name: "Pants",
                subcategories: [
                  {
                    name: "Jeans",
                    subcategories: [
                      { name: "Shirts" },
                      { name: "Pants" },
                      { name: "Suits" },
                    ],
                  },
                  {
                    name: "Chinos",
                    subcategories: [
                      { name: "Shirts" },
                      { name: "Pants" },
                      { name: "Suits" },
                    ],
                  },
                ],
              },
              {
                name: "Suits",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
            ],
          },
          {
            name: "Shoes",
            subcategories: [
              {
                name: "Sneakers",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
              {
                name: "Boots",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
              {
                name: "Dress Shoes",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
            ],
          },
          {
            name: "Accessories",
            subcategories: [
              {
                name: "Belts",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
              {
                name: "Ties",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
              {
                name: "Hats",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
              {
                name: "Jewelry",
                subcategories: [
                  {
                    name: "Necklaces",
                    subcategories: [
                      {
                        name: "Pendant Necklaces",
                        subcategories: [
                          { name: "Shirts" },
                          { name: "Pants" },
                          { name: "Suits" },
                        ],
                      },
                      {
                        name: "Choker Necklaces",
                        subcategories: [
                          { name: "Shirts" },
                          { name: "Pants" },
                          { name: "Suits" },
                        ],
                      },
                    ],
                  },
                  {
                    name: "Bracelets",
                    subcategories: [
                      {
                        name: "Bangle Bracelets",
                        subcategories: [
                          { name: "Shirts" },
                          { name: "Pants" },
                          { name: "Suits" },
                        ],
                      },
                      {
                        name: "Cuff Bracelets",
                        subcategories: [
                          { name: "Shirts" },
                          { name: "Pants" },
                          { name: "Suits" },
                        ],
                      },
                    ],
                  },
                  {
                    name: "Earrings",
                    subcategories: [
                      { name: "Shirts" },
                      { name: "Pants" },
                      { name: "Suits" },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        name: "Men Fashion",
        subcategories: [
          {
            name: "Clothing",
            subcategories: [
              {
                name: "Shirts",
                subcategories: [
                  {
                    name: "Dress Shirts",
                    subcategories: [
                      { name: "Shirts" },
                      { name: "Pants" },
                      { name: "Suits" },
                    ],
                  },
                  {
                    name: "T-Shirts",
                    subcategories: [
                      {
                        name: "Graphic T-Shirts",
                        subcategories: [
                          { name: "Shirts" },
                          { name: "Pants" },
                          { name: "Suits" },
                        ],
                      },
                      {
                        name: "Plain T-Shirts",
                        subcategories: [
                          { name: "Shirts" },
                          { name: "Pants" },
                          { name: "Suits" },
                        ],
                      },
                    ],
                  },
                  {
                    name: "Polo Shirts",
                    subcategories: [
                      { name: "Shirts" },
                      { name: "Pants" },
                      { name: "Suits" },
                    ],
                  },
                ],
              },
              {
                name: "Pants",
                subcategories: [
                  {
                    name: "Jeans",
                    subcategories: [
                      { name: "Shirts" },
                      { name: "Pants" },
                      { name: "Suits" },
                    ],
                  },
                  {
                    name: "Chinos",
                    subcategories: [
                      { name: "Shirts" },
                      { name: "Pants" },
                      { name: "Suits" },
                    ],
                  },
                ],
              },
              {
                name: "Suits",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
            ],
          },
          {
            name: "Shoes",
            subcategories: [
              {
                name: "Sneakers",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
              {
                name: "Boots",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
              {
                name: "Dress Shoes",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
            ],
          },
          {
            name: "Accessories",
            subcategories: [
              {
                name: "Belts",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
              {
                name: "Ties",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
              {
                name: "Hats",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
              {
                name: "Jewelry",
                subcategories: [
                  {
                    name: "Necklaces",
                    subcategories: [
                      {
                        name: "Pendant Necklaces",
                        subcategories: [
                          { name: "Shirts" },
                          { name: "Pants" },
                          { name: "Suits" },
                        ],
                      },
                      {
                        name: "Choker Necklaces",
                        subcategories: [
                          { name: "Shirts" },
                          { name: "Pants" },
                          { name: "Suits" },
                        ],
                      },
                    ],
                  },
                  {
                    name: "Bracelets",
                    subcategories: [
                      {
                        name: "Bangle Bracelets",
                        subcategories: [
                          { name: "Shirts" },
                          { name: "Pants" },
                          { name: "Suits" },
                        ],
                      },
                      {
                        name: "Cuff Bracelets",
                        subcategories: [
                          { name: "Shirts" },
                          { name: "Pants" },
                          { name: "Suits" },
                        ],
                      },
                    ],
                  },
                  {
                    name: "Earrings",
                    subcategories: [
                      { name: "Shirts" },
                      { name: "Pants" },
                      { name: "Suits" },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        name: "Men Fashion",
        subcategories: [
          {
            name: "Clothing",
            subcategories: [
              {
                name: "Shirts",
                subcategories: [
                  {
                    name: "Dress Shirts",
                    subcategories: [
                      { name: "Shirts" },
                      { name: "Pants" },
                      { name: "Suits" },
                    ],
                  },
                  {
                    name: "T-Shirts",
                    subcategories: [
                      {
                        name: "Graphic T-Shirts",
                        subcategories: [
                          { name: "Shirts" },
                          { name: "Pants" },
                          { name: "Suits" },
                        ],
                      },
                      {
                        name: "Plain T-Shirts",
                        subcategories: [
                          { name: "Shirts" },
                          { name: "Pants" },
                          { name: "Suits" },
                        ],
                      },
                    ],
                  },
                  {
                    name: "Polo Shirts",
                    subcategories: [
                      { name: "Shirts" },
                      { name: "Pants" },
                      { name: "Suits" },
                    ],
                  },
                ],
              },
              {
                name: "Pants",
                subcategories: [
                  {
                    name: "Jeans",
                    subcategories: [
                      { name: "Shirts" },
                      { name: "Pants" },
                      { name: "Suits" },
                    ],
                  },
                  {
                    name: "Chinos",
                    subcategories: [
                      { name: "Shirts" },
                      { name: "Pants" },
                      { name: "Suits" },
                    ],
                  },
                ],
              },
              {
                name: "Suits",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
            ],
          },
          {
            name: "Shoes",
            subcategories: [
              {
                name: "Sneakers",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
              {
                name: "Boots",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
              {
                name: "Dress Shoes",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
            ],
          },
          {
            name: "Accessories",
            subcategories: [
              {
                name: "Belts",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
              {
                name: "Ties",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
              {
                name: "Hats",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
              {
                name: "Jewelry",
                subcategories: [
                  {
                    name: "Necklaces",
                    subcategories: [
                      {
                        name: "Pendant Necklaces",
                        subcategories: [
                          { name: "Shirts" },
                          { name: "Pants" },
                          { name: "Suits" },
                        ],
                      },
                      {
                        name: "Choker Necklaces",
                        subcategories: [
                          { name: "Shirts" },
                          { name: "Pants" },
                          { name: "Suits" },
                        ],
                      },
                    ],
                  },
                  {
                    name: "Bracelets",
                    subcategories: [
                      {
                        name: "Bangle Bracelets",
                        subcategories: [
                          { name: "Shirts" },
                          { name: "Pants" },
                          { name: "Suits" },
                        ],
                      },
                      {
                        name: "Cuff Bracelets",
                        subcategories: [
                          { name: "Shirts" },
                          { name: "Pants" },
                          { name: "Suits" },
                        ],
                      },
                    ],
                  },
                  {
                    name: "Earrings",
                    subcategories: [
                      { name: "Shirts" },
                      { name: "Pants" },
                      { name: "Suits" },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    name: "Fashion4",
    subcategories: [
      {
        name: "Men Fashion",
        subcategories: [
          {
            name: "Clothing",
            subcategories: [
              {
                name: "Shirts",
                subcategories: [
                  {
                    name: "Dress Shirts",
                    subcategories: [
                      { name: "Shirts" },
                      { name: "Pants" },
                      { name: "Suits" },
                    ],
                  },
                  {
                    name: "T-Shirts",
                    subcategories: [
                      {
                        name: "Graphic T-Shirts",
                        subcategories: [
                          { name: "Shirts" },
                          { name: "Pants" },
                          { name: "Suits" },
                        ],
                      },
                      {
                        name: "Plain T-Shirts",
                        subcategories: [
                          { name: "Shirts" },
                          { name: "Pants" },
                          { name: "Suits" },
                        ],
                      },
                    ],
                  },
                  {
                    name: "Polo Shirts",
                    subcategories: [
                      { name: "Shirts" },
                      { name: "Pants" },
                      { name: "Suits" },
                    ],
                  },
                ],
              },
              {
                name: "Pants",
                subcategories: [
                  {
                    name: "Jeans",
                    subcategories: [
                      { name: "Shirts" },
                      { name: "Pants" },
                      { name: "Suits" },
                    ],
                  },
                  {
                    name: "Chinos",
                    subcategories: [
                      { name: "Shirts" },
                      { name: "Pants" },
                      { name: "Suits" },
                    ],
                  },
                ],
              },
              {
                name: "Suits",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
            ],
          },
          {
            name: "Shoes",
            subcategories: [
              {
                name: "Sneakers",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
              {
                name: "Boots",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
              {
                name: "Dress Shoes",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
            ],
          },
          {
            name: "Accessories",
            subcategories: [
              {
                name: "Belts",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
              {
                name: "Ties",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
              {
                name: "Hats",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
              {
                name: "Jewelry",
                subcategories: [
                  {
                    name: "Necklaces",
                    subcategories: [
                      {
                        name: "Pendant Necklaces",
                        subcategories: [
                          { name: "Shirts" },
                          { name: "Pants" },
                          { name: "Suits" },
                        ],
                      },
                      {
                        name: "Choker Necklaces",
                        subcategories: [
                          { name: "Shirts" },
                          { name: "Pants" },
                          { name: "Suits" },
                        ],
                      },
                    ],
                  },
                  {
                    name: "Bracelets",
                    subcategories: [
                      {
                        name: "Bangle Bracelets",
                        subcategories: [
                          { name: "Shirts" },
                          { name: "Pants" },
                          { name: "Suits" },
                        ],
                      },
                      {
                        name: "Cuff Bracelets",
                        subcategories: [
                          { name: "Shirts" },
                          { name: "Pants" },
                          { name: "Suits" },
                        ],
                      },
                    ],
                  },
                  {
                    name: "Earrings",
                    subcategories: [
                      { name: "Shirts" },
                      { name: "Pants" },
                      { name: "Suits" },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        name: "Men Fashion",
        subcategories: [
          {
            name: "Clothing",
            subcategories: [
              {
                name: "Shirts",
                subcategories: [
                  {
                    name: "Dress Shirts",
                    subcategories: [
                      { name: "Shirts" },
                      { name: "Pants" },
                      { name: "Suits" },
                    ],
                  },
                  {
                    name: "T-Shirts",
                    subcategories: [
                      {
                        name: "Graphic T-Shirts",
                        subcategories: [
                          { name: "Shirts" },
                          { name: "Pants" },
                          { name: "Suits" },
                        ],
                      },
                      {
                        name: "Plain T-Shirts",
                        subcategories: [
                          { name: "Shirts" },
                          { name: "Pants" },
                          { name: "Suits" },
                        ],
                      },
                    ],
                  },
                  {
                    name: "Polo Shirts",
                    subcategories: [
                      { name: "Shirts" },
                      { name: "Pants" },
                      { name: "Suits" },
                    ],
                  },
                ],
              },
              {
                name: "Pants",
                subcategories: [
                  {
                    name: "Jeans",
                    subcategories: [
                      { name: "Shirts" },
                      { name: "Pants" },
                      { name: "Suits" },
                    ],
                  },
                  {
                    name: "Chinos",
                    subcategories: [
                      { name: "Shirts" },
                      { name: "Pants" },
                      { name: "Suits" },
                    ],
                  },
                ],
              },
              {
                name: "Suits",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
            ],
          },
          {
            name: "Shoes",
            subcategories: [
              {
                name: "Sneakers",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
              {
                name: "Boots",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
              {
                name: "Dress Shoes",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
            ],
          },
          {
            name: "Accessories",
            subcategories: [
              {
                name: "Belts",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
              {
                name: "Ties",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
              {
                name: "Hats",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
              {
                name: "Jewelry",
                subcategories: [
                  {
                    name: "Necklaces",
                    subcategories: [
                      {
                        name: "Pendant Necklaces",
                        subcategories: [
                          { name: "Shirts" },
                          { name: "Pants" },
                          { name: "Suits" },
                        ],
                      },
                      {
                        name: "Choker Necklaces",
                        subcategories: [
                          { name: "Shirts" },
                          { name: "Pants" },
                          { name: "Suits" },
                        ],
                      },
                    ],
                  },
                  {
                    name: "Bracelets",
                    subcategories: [
                      {
                        name: "Bangle Bracelets",
                        subcategories: [
                          { name: "Shirts" },
                          { name: "Pants" },
                          { name: "Suits" },
                        ],
                      },
                      {
                        name: "Cuff Bracelets",
                        subcategories: [
                          { name: "Shirts" },
                          { name: "Pants" },
                          { name: "Suits" },
                        ],
                      },
                    ],
                  },
                  {
                    name: "Earrings",
                    subcategories: [
                      { name: "Shirts" },
                      { name: "Pants" },
                      { name: "Suits" },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        name: "Men Fashion",
        subcategories: [
          {
            name: "Clothing",
            subcategories: [
              {
                name: "Shirts",
                subcategories: [
                  {
                    name: "Dress Shirts",
                    subcategories: [
                      { name: "Shirts" },
                      { name: "Pants" },
                      { name: "Suits" },
                    ],
                  },
                  {
                    name: "T-Shirts",
                    subcategories: [
                      {
                        name: "Graphic T-Shirts",
                        subcategories: [
                          { name: "Shirts" },
                          { name: "Pants" },
                          { name: "Suits" },
                        ],
                      },
                      {
                        name: "Plain T-Shirts",
                        subcategories: [
                          { name: "Shirts" },
                          { name: "Pants" },
                          { name: "Suits" },
                        ],
                      },
                    ],
                  },
                  {
                    name: "Polo Shirts",
                    subcategories: [
                      { name: "Shirts" },
                      { name: "Pants" },
                      { name: "Suits" },
                    ],
                  },
                ],
              },
              {
                name: "Pants",
                subcategories: [
                  {
                    name: "Jeans",
                    subcategories: [
                      { name: "Shirts" },
                      { name: "Pants" },
                      { name: "Suits" },
                    ],
                  },
                  {
                    name: "Chinos",
                    subcategories: [
                      { name: "Shirts" },
                      { name: "Pants" },
                      { name: "Suits" },
                    ],
                  },
                ],
              },
              {
                name: "Suits",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
            ],
          },
          {
            name: "Shoes",
            subcategories: [
              {
                name: "Sneakers",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
              {
                name: "Boots",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
              {
                name: "Dress Shoes",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
            ],
          },
          {
            name: "Accessories",
            subcategories: [
              {
                name: "Belts",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
              {
                name: "Ties",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
              {
                name: "Hats",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
              {
                name: "Jewelry",
                subcategories: [
                  {
                    name: "Necklaces",
                    subcategories: [
                      {
                        name: "Pendant Necklaces",
                        subcategories: [
                          { name: "Shirts" },
                          { name: "Pants" },
                          { name: "Suits" },
                        ],
                      },
                      {
                        name: "Choker Necklaces",
                        subcategories: [
                          { name: "Shirts" },
                          { name: "Pants" },
                          { name: "Suits" },
                        ],
                      },
                    ],
                  },
                  {
                    name: "Bracelets",
                    subcategories: [
                      {
                        name: "Bangle Bracelets",
                        subcategories: [
                          { name: "Shirts" },
                          { name: "Pants" },
                          { name: "Suits" },
                        ],
                      },
                      {
                        name: "Cuff Bracelets",
                        subcategories: [
                          { name: "Shirts" },
                          { name: "Pants" },
                          { name: "Suits" },
                        ],
                      },
                    ],
                  },
                  {
                    name: "Earrings",
                    subcategories: [
                      { name: "Shirts" },
                      { name: "Pants" },
                      { name: "Suits" },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        name: "Men Fashion",
        subcategories: [
          {
            name: "Clothing",
            subcategories: [
              {
                name: "Shirts",
                subcategories: [
                  {
                    name: "Dress Shirts",
                    subcategories: [
                      { name: "Shirts" },
                      { name: "Pants" },
                      { name: "Suits" },
                    ],
                  },
                  {
                    name: "T-Shirts",
                    subcategories: [
                      {
                        name: "Graphic T-Shirts",
                        subcategories: [
                          { name: "Shirts" },
                          { name: "Pants" },
                          { name: "Suits" },
                        ],
                      },
                      {
                        name: "Plain T-Shirts",
                        subcategories: [
                          { name: "Shirts" },
                          { name: "Pants" },
                          { name: "Suits" },
                        ],
                      },
                    ],
                  },
                  {
                    name: "Polo Shirts",
                    subcategories: [
                      { name: "Shirts" },
                      { name: "Pants" },
                      { name: "Suits" },
                    ],
                  },
                ],
              },
              {
                name: "Pants",
                subcategories: [
                  {
                    name: "Jeans",
                    subcategories: [
                      { name: "Shirts" },
                      { name: "Pants" },
                      { name: "Suits" },
                    ],
                  },
                  {
                    name: "Chinos",
                    subcategories: [
                      { name: "Shirts" },
                      { name: "Pants" },
                      { name: "Suits" },
                    ],
                  },
                ],
              },
              {
                name: "Suits",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
            ],
          },
          {
            name: "Shoes",
            subcategories: [
              {
                name: "Sneakers",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
              {
                name: "Boots",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
              {
                name: "Dress Shoes",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
            ],
          },
          {
            name: "Accessories",
            subcategories: [
              {
                name: "Belts",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
              {
                name: "Ties",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
              {
                name: "Hats",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
              {
                name: "Jewelry",
                subcategories: [
                  {
                    name: "Necklaces",
                    subcategories: [
                      {
                        name: "Pendant Necklaces",
                        subcategories: [
                          { name: "Shirts" },
                          { name: "Pants" },
                          { name: "Suits" },
                        ],
                      },
                      {
                        name: "Choker Necklaces",
                        subcategories: [
                          { name: "Shirts" },
                          { name: "Pants" },
                          { name: "Suits" },
                        ],
                      },
                    ],
                  },
                  {
                    name: "Bracelets",
                    subcategories: [
                      {
                        name: "Bangle Bracelets",
                        subcategories: [
                          { name: "Shirts" },
                          { name: "Pants" },
                          { name: "Suits" },
                        ],
                      },
                      {
                        name: "Cuff Bracelets",
                        subcategories: [
                          { name: "Shirts" },
                          { name: "Pants" },
                          { name: "Suits" },
                        ],
                      },
                    ],
                  },
                  {
                    name: "Earrings",
                    subcategories: [
                      { name: "Shirts" },
                      { name: "Pants" },
                      { name: "Suits" },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        name: "Men Fashion",
        subcategories: [
          {
            name: "Clothing",
            subcategories: [
              {
                name: "Shirts",
                subcategories: [
                  {
                    name: "Dress Shirts",
                    subcategories: [
                      { name: "Shirts" },
                      { name: "Pants" },
                      { name: "Suits" },
                    ],
                  },
                  {
                    name: "T-Shirts",
                    subcategories: [
                      {
                        name: "Graphic T-Shirts",
                        subcategories: [
                          { name: "Shirts" },
                          { name: "Pants" },
                          { name: "Suits" },
                        ],
                      },
                      {
                        name: "Plain T-Shirts",
                        subcategories: [
                          { name: "Shirts" },
                          { name: "Pants" },
                          { name: "Suits" },
                        ],
                      },
                    ],
                  },
                  {
                    name: "Polo Shirts",
                    subcategories: [
                      { name: "Shirts" },
                      { name: "Pants" },
                      { name: "Suits" },
                    ],
                  },
                ],
              },
              {
                name: "Pants",
                subcategories: [
                  {
                    name: "Jeans",
                    subcategories: [
                      { name: "Shirts" },
                      { name: "Pants" },
                      { name: "Suits" },
                    ],
                  },
                  {
                    name: "Chinos",
                    subcategories: [
                      { name: "Shirts" },
                      { name: "Pants" },
                      { name: "Suits" },
                    ],
                  },
                ],
              },
              {
                name: "Suits",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
            ],
          },
          {
            name: "Shoes",
            subcategories: [
              {
                name: "Sneakers",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
              {
                name: "Boots",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
              {
                name: "Dress Shoes",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
            ],
          },
          {
            name: "Accessories",
            subcategories: [
              {
                name: "Belts",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
              {
                name: "Ties",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
              {
                name: "Hats",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
              {
                name: "Jewelry",
                subcategories: [
                  {
                    name: "Necklaces",
                    subcategories: [
                      {
                        name: "Pendant Necklaces",
                        subcategories: [
                          { name: "Shirts" },
                          { name: "Pants" },
                          { name: "Suits" },
                        ],
                      },
                      {
                        name: "Choker Necklaces",
                        subcategories: [
                          { name: "Shirts" },
                          { name: "Pants" },
                          { name: "Suits" },
                        ],
                      },
                    ],
                  },
                  {
                    name: "Bracelets",
                    subcategories: [
                      {
                        name: "Bangle Bracelets",
                        subcategories: [
                          { name: "Shirts" },
                          { name: "Pants" },
                          { name: "Suits" },
                        ],
                      },
                      {
                        name: "Cuff Bracelets",
                        subcategories: [
                          { name: "Shirts" },
                          { name: "Pants" },
                          { name: "Suits" },
                        ],
                      },
                    ],
                  },
                  {
                    name: "Earrings",
                    subcategories: [
                      { name: "Shirts" },
                      { name: "Pants" },
                      { name: "Suits" },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    name: "Fashion5",
    subcategories: [
      {
        name: "Men Fashion",
        subcategories: [
          {
            name: "Clothing",
            subcategories: [
              {
                name: "Shirts",
                subcategories: [
                  {
                    name: "Dress Shirts",
                    subcategories: [
                      { name: "Shirts" },
                      { name: "Pants" },
                      { name: "Suits" },
                    ],
                  },
                  {
                    name: "T-Shirts",
                    subcategories: [
                      {
                        name: "Graphic T-Shirts",
                        subcategories: [
                          { name: "Shirts" },
                          { name: "Pants" },
                          { name: "Suits" },
                        ],
                      },
                      {
                        name: "Plain T-Shirts",
                        subcategories: [
                          { name: "Shirts" },
                          { name: "Pants" },
                          { name: "Suits" },
                        ],
                      },
                    ],
                  },
                  {
                    name: "Polo Shirts",
                    subcategories: [
                      { name: "Shirts" },
                      { name: "Pants" },
                      { name: "Suits" },
                    ],
                  },
                ],
              },
              {
                name: "Pants",
                subcategories: [
                  {
                    name: "Jeans",
                    subcategories: [
                      { name: "Shirts" },
                      { name: "Pants" },
                      { name: "Suits" },
                    ],
                  },
                  {
                    name: "Chinos",
                    subcategories: [
                      { name: "Shirts" },
                      { name: "Pants" },
                      { name: "Suits" },
                    ],
                  },
                ],
              },
              {
                name: "Suits",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
            ],
          },
          {
            name: "Shoes",
            subcategories: [
              {
                name: "Sneakers",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
              {
                name: "Boots",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
              {
                name: "Dress Shoes",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
            ],
          },
          {
            name: "Accessories",
            subcategories: [
              {
                name: "Belts",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
              {
                name: "Ties",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
              {
                name: "Hats",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
              {
                name: "Jewelry",
                subcategories: [
                  {
                    name: "Necklaces",
                    subcategories: [
                      {
                        name: "Pendant Necklaces",
                        subcategories: [
                          { name: "Shirts" },
                          { name: "Pants" },
                          { name: "Suits" },
                        ],
                      },
                      {
                        name: "Choker Necklaces",
                        subcategories: [
                          { name: "Shirts" },
                          { name: "Pants" },
                          { name: "Suits" },
                        ],
                      },
                    ],
                  },
                  {
                    name: "Bracelets",
                    subcategories: [
                      {
                        name: "Bangle Bracelets",
                        subcategories: [
                          { name: "Shirts" },
                          { name: "Pants" },
                          { name: "Suits" },
                        ],
                      },
                      {
                        name: "Cuff Bracelets",
                        subcategories: [
                          { name: "Shirts" },
                          { name: "Pants" },
                          { name: "Suits" },
                        ],
                      },
                    ],
                  },
                  {
                    name: "Earrings",
                    subcategories: [
                      { name: "Shirts" },
                      { name: "Pants" },
                      { name: "Suits" },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        name: "Men Fashion",
        subcategories: [
          {
            name: "Clothing",
            subcategories: [
              {
                name: "Shirts",
                subcategories: [
                  {
                    name: "Dress Shirts",
                    subcategories: [
                      { name: "Shirts" },
                      { name: "Pants" },
                      { name: "Suits" },
                    ],
                  },
                  {
                    name: "T-Shirts",
                    subcategories: [
                      {
                        name: "Graphic T-Shirts",
                        subcategories: [
                          { name: "Shirts" },
                          { name: "Pants" },
                          { name: "Suits" },
                        ],
                      },
                      {
                        name: "Plain T-Shirts",
                        subcategories: [
                          { name: "Shirts" },
                          { name: "Pants" },
                          { name: "Suits" },
                        ],
                      },
                    ],
                  },
                  {
                    name: "Polo Shirts",
                    subcategories: [
                      { name: "Shirts" },
                      { name: "Pants" },
                      { name: "Suits" },
                    ],
                  },
                ],
              },
              {
                name: "Pants",
                subcategories: [
                  {
                    name: "Jeans",
                    subcategories: [
                      { name: "Shirts" },
                      { name: "Pants" },
                      { name: "Suits" },
                    ],
                  },
                  {
                    name: "Chinos",
                    subcategories: [
                      { name: "Shirts" },
                      { name: "Pants" },
                      { name: "Suits" },
                    ],
                  },
                ],
              },
              {
                name: "Suits",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
            ],
          },
          {
            name: "Shoes",
            subcategories: [
              {
                name: "Sneakers",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
              {
                name: "Boots",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
              {
                name: "Dress Shoes",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
            ],
          },
          {
            name: "Accessories",
            subcategories: [
              {
                name: "Belts",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
              {
                name: "Ties",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
              {
                name: "Hats",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
              {
                name: "Jewelry",
                subcategories: [
                  {
                    name: "Necklaces",
                    subcategories: [
                      {
                        name: "Pendant Necklaces",
                        subcategories: [
                          { name: "Shirts" },
                          { name: "Pants" },
                          { name: "Suits" },
                        ],
                      },
                      {
                        name: "Choker Necklaces",
                        subcategories: [
                          { name: "Shirts" },
                          { name: "Pants" },
                          { name: "Suits" },
                        ],
                      },
                    ],
                  },
                  {
                    name: "Bracelets",
                    subcategories: [
                      {
                        name: "Bangle Bracelets",
                        subcategories: [
                          { name: "Shirts" },
                          { name: "Pants" },
                          { name: "Suits" },
                        ],
                      },
                      {
                        name: "Cuff Bracelets",
                        subcategories: [
                          { name: "Shirts" },
                          { name: "Pants" },
                          { name: "Suits" },
                        ],
                      },
                    ],
                  },
                  {
                    name: "Earrings",
                    subcategories: [
                      { name: "Shirts" },
                      { name: "Pants" },
                      { name: "Suits" },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        name: "Men Fashion",
        subcategories: [
          {
            name: "Clothing",
            subcategories: [
              {
                name: "Shirts",
                subcategories: [
                  {
                    name: "Dress Shirts",
                    subcategories: [
                      { name: "Shirts" },
                      { name: "Pants" },
                      { name: "Suits" },
                    ],
                  },
                  {
                    name: "T-Shirts",
                    subcategories: [
                      {
                        name: "Graphic T-Shirts",
                        subcategories: [
                          { name: "Shirts" },
                          { name: "Pants" },
                          { name: "Suits" },
                        ],
                      },
                      {
                        name: "Plain T-Shirts",
                        subcategories: [
                          { name: "Shirts" },
                          { name: "Pants" },
                          { name: "Suits" },
                        ],
                      },
                    ],
                  },
                  {
                    name: "Polo Shirts",
                    subcategories: [
                      { name: "Shirts" },
                      { name: "Pants" },
                      { name: "Suits" },
                    ],
                  },
                ],
              },
              {
                name: "Pants",
                subcategories: [
                  {
                    name: "Jeans",
                    subcategories: [
                      { name: "Shirts" },
                      { name: "Pants" },
                      { name: "Suits" },
                    ],
                  },
                  {
                    name: "Chinos",
                    subcategories: [
                      { name: "Shirts" },
                      { name: "Pants" },
                      { name: "Suits" },
                    ],
                  },
                ],
              },
              {
                name: "Suits",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
            ],
          },
          {
            name: "Shoes",
            subcategories: [
              {
                name: "Sneakers",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
              {
                name: "Boots",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
              {
                name: "Dress Shoes",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
            ],
          },
          {
            name: "Accessories",
            subcategories: [
              {
                name: "Belts",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
              {
                name: "Ties",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
              {
                name: "Hats",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
              {
                name: "Jewelry",
                subcategories: [
                  {
                    name: "Necklaces",
                    subcategories: [
                      {
                        name: "Pendant Necklaces",
                        subcategories: [
                          { name: "Shirts" },
                          { name: "Pants" },
                          { name: "Suits" },
                        ],
                      },
                      {
                        name: "Choker Necklaces",
                        subcategories: [
                          { name: "Shirts" },
                          { name: "Pants" },
                          { name: "Suits" },
                        ],
                      },
                    ],
                  },
                  {
                    name: "Bracelets",
                    subcategories: [
                      {
                        name: "Bangle Bracelets",
                        subcategories: [
                          { name: "Shirts" },
                          { name: "Pants" },
                          { name: "Suits" },
                        ],
                      },
                      {
                        name: "Cuff Bracelets",
                        subcategories: [
                          { name: "Shirts" },
                          { name: "Pants" },
                          { name: "Suits" },
                        ],
                      },
                    ],
                  },
                  {
                    name: "Earrings",
                    subcategories: [
                      { name: "Shirts" },
                      { name: "Pants" },
                      { name: "Suits" },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        name: "Men Fashion",
        subcategories: [
          {
            name: "Clothing",
            subcategories: [
              {
                name: "Shirts",
                subcategories: [
                  {
                    name: "Dress Shirts",
                    subcategories: [
                      { name: "Shirts" },
                      { name: "Pants" },
                      { name: "Suits" },
                    ],
                  },
                  {
                    name: "T-Shirts",
                    subcategories: [
                      {
                        name: "Graphic T-Shirts",
                        subcategories: [
                          { name: "Shirts" },
                          { name: "Pants" },
                          { name: "Suits" },
                        ],
                      },
                      {
                        name: "Plain T-Shirts",
                        subcategories: [
                          { name: "Shirts" },
                          { name: "Pants" },
                          { name: "Suits" },
                        ],
                      },
                    ],
                  },
                  {
                    name: "Polo Shirts",
                    subcategories: [
                      { name: "Shirts" },
                      { name: "Pants" },
                      { name: "Suits" },
                    ],
                  },
                ],
              },
              {
                name: "Pants",
                subcategories: [
                  {
                    name: "Jeans",
                    subcategories: [
                      { name: "Shirts" },
                      { name: "Pants" },
                      { name: "Suits" },
                    ],
                  },
                  {
                    name: "Chinos",
                    subcategories: [
                      { name: "Shirts" },
                      { name: "Pants" },
                      { name: "Suits" },
                    ],
                  },
                ],
              },
              {
                name: "Suits",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
            ],
          },
          {
            name: "Shoes",
            subcategories: [
              {
                name: "Sneakers",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
              {
                name: "Boots",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
              {
                name: "Dress Shoes",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
            ],
          },
          {
            name: "Accessories",
            subcategories: [
              {
                name: "Belts",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
              {
                name: "Ties",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
              {
                name: "Hats",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
              {
                name: "Jewelry",
                subcategories: [
                  {
                    name: "Necklaces",
                    subcategories: [
                      {
                        name: "Pendant Necklaces",
                        subcategories: [
                          { name: "Shirts" },
                          { name: "Pants" },
                          { name: "Suits" },
                        ],
                      },
                      {
                        name: "Choker Necklaces",
                        subcategories: [
                          { name: "Shirts" },
                          { name: "Pants" },
                          { name: "Suits" },
                        ],
                      },
                    ],
                  },
                  {
                    name: "Bracelets",
                    subcategories: [
                      {
                        name: "Bangle Bracelets",
                        subcategories: [
                          { name: "Shirts" },
                          { name: "Pants" },
                          { name: "Suits" },
                        ],
                      },
                      {
                        name: "Cuff Bracelets",
                        subcategories: [
                          { name: "Shirts" },
                          { name: "Pants" },
                          { name: "Suits" },
                        ],
                      },
                    ],
                  },
                  {
                    name: "Earrings",
                    subcategories: [
                      { name: "Shirts" },
                      { name: "Pants" },
                      { name: "Suits" },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        name: "Men Fashion",
        subcategories: [
          {
            name: "Clothing",
            subcategories: [
              {
                name: "Shirts",
                subcategories: [
                  {
                    name: "Dress Shirts",
                    subcategories: [
                      { name: "Shirts" },
                      { name: "Pants" },
                      { name: "Suits" },
                    ],
                  },
                  {
                    name: "T-Shirts",
                    subcategories: [
                      {
                        name: "Graphic T-Shirts",
                        subcategories: [
                          { name: "Shirts" },
                          { name: "Pants" },
                          { name: "Suits" },
                        ],
                      },
                      {
                        name: "Plain T-Shirts",
                        subcategories: [
                          { name: "Shirts" },
                          { name: "Pants" },
                          { name: "Suits" },
                        ],
                      },
                    ],
                  },
                  {
                    name: "Polo Shirts",
                    subcategories: [
                      { name: "Shirts" },
                      { name: "Pants" },
                      { name: "Suits" },
                    ],
                  },
                ],
              },
              {
                name: "Pants",
                subcategories: [
                  {
                    name: "Jeans",
                    subcategories: [
                      { name: "Shirts" },
                      { name: "Pants" },
                      { name: "Suits" },
                    ],
                  },
                  {
                    name: "Chinos",
                    subcategories: [
                      { name: "Shirts" },
                      { name: "Pants" },
                      { name: "Suits" },
                    ],
                  },
                ],
              },
              {
                name: "Suits",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
            ],
          },
          {
            name: "Shoes",
            subcategories: [
              {
                name: "Sneakers",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
              {
                name: "Boots",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
              {
                name: "Dress Shoes",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
            ],
          },
          {
            name: "Accessories",
            subcategories: [
              {
                name: "Belts",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
              {
                name: "Ties",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
              {
                name: "Hats",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
              {
                name: "Jewelry",
                subcategories: [
                  {
                    name: "Necklaces",
                    subcategories: [
                      {
                        name: "Pendant Necklaces",
                        subcategories: [
                          { name: "Shirts" },
                          { name: "Pants" },
                          { name: "Suits" },
                        ],
                      },
                      {
                        name: "Choker Necklaces",
                        subcategories: [
                          { name: "Shirts" },
                          { name: "Pants" },
                          { name: "Suits" },
                        ],
                      },
                    ],
                  },
                  {
                    name: "Bracelets",
                    subcategories: [
                      {
                        name: "Bangle Bracelets",
                        subcategories: [
                          { name: "Shirts" },
                          { name: "Pants" },
                          { name: "Suits" },
                        ],
                      },
                      {
                        name: "Cuff Bracelets",
                        subcategories: [
                          { name: "Shirts" },
                          { name: "Pants" },
                          { name: "Suits" },
                        ],
                      },
                    ],
                  },
                  {
                    name: "Earrings",
                    subcategories: [
                      { name: "Shirts" },
                      { name: "Pants" },
                      { name: "Suits" },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    name: "Fashion6",
    subcategories: [
      {
        name: "Men Fashion",
        subcategories: [
          {
            name: "Clothing",
            subcategories: [
              {
                name: "Shirts",
                subcategories: [
                  {
                    name: "Dress Shirts",
                    subcategories: [
                      { name: "Shirts" },
                      { name: "Pants" },
                      { name: "Suits" },
                    ],
                  },
                  {
                    name: "T-Shirts",
                    subcategories: [
                      {
                        name: "Graphic T-Shirts",
                        subcategories: [
                          { name: "Shirts" },
                          { name: "Pants" },
                          { name: "Suits" },
                        ],
                      },
                      {
                        name: "Plain T-Shirts",
                        subcategories: [
                          { name: "Shirts" },
                          { name: "Pants" },
                          { name: "Suits" },
                        ],
                      },
                    ],
                  },
                  {
                    name: "Polo Shirts",
                    subcategories: [
                      { name: "Shirts" },
                      { name: "Pants" },
                      { name: "Suits" },
                    ],
                  },
                ],
              },
              {
                name: "Pants",
                subcategories: [
                  {
                    name: "Jeans",
                    subcategories: [
                      { name: "Shirts" },
                      { name: "Pants" },
                      { name: "Suits" },
                    ],
                  },
                  {
                    name: "Chinos",
                    subcategories: [
                      { name: "Shirts" },
                      { name: "Pants" },
                      { name: "Suits" },
                    ],
                  },
                ],
              },
              {
                name: "Suits",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
            ],
          },
          {
            name: "Shoes",
            subcategories: [
              {
                name: "Sneakers",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
              {
                name: "Boots",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
              {
                name: "Dress Shoes",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
            ],
          },
          {
            name: "Accessories",
            subcategories: [
              {
                name: "Belts",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
              {
                name: "Ties",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
              {
                name: "Hats",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
              {
                name: "Jewelry",
                subcategories: [
                  {
                    name: "Necklaces",
                    subcategories: [
                      {
                        name: "Pendant Necklaces",
                        subcategories: [
                          { name: "Shirts" },
                          { name: "Pants" },
                          { name: "Suits" },
                        ],
                      },
                      {
                        name: "Choker Necklaces",
                        subcategories: [
                          { name: "Shirts" },
                          { name: "Pants" },
                          { name: "Suits" },
                        ],
                      },
                    ],
                  },
                  {
                    name: "Bracelets",
                    subcategories: [
                      {
                        name: "Bangle Bracelets",
                        subcategories: [
                          { name: "Shirts" },
                          { name: "Pants" },
                          { name: "Suits" },
                        ],
                      },
                      {
                        name: "Cuff Bracelets",
                        subcategories: [
                          { name: "Shirts" },
                          { name: "Pants" },
                          { name: "Suits" },
                        ],
                      },
                    ],
                  },
                  {
                    name: "Earrings",
                    subcategories: [
                      { name: "Shirts" },
                      { name: "Pants" },
                      { name: "Suits" },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        name: "Men Fashion",
        subcategories: [
          {
            name: "Clothing",
            subcategories: [
              {
                name: "Shirts",
                subcategories: [
                  {
                    name: "Dress Shirts",
                    subcategories: [
                      { name: "Shirts" },
                      { name: "Pants" },
                      { name: "Suits" },
                    ],
                  },
                  {
                    name: "T-Shirts",
                    subcategories: [
                      {
                        name: "Graphic T-Shirts",
                        subcategories: [
                          { name: "Shirts" },
                          { name: "Pants" },
                          { name: "Suits" },
                        ],
                      },
                      {
                        name: "Plain T-Shirts",
                        subcategories: [
                          { name: "Shirts" },
                          { name: "Pants" },
                          { name: "Suits" },
                        ],
                      },
                    ],
                  },
                  {
                    name: "Polo Shirts",
                    subcategories: [
                      { name: "Shirts" },
                      { name: "Pants" },
                      { name: "Suits" },
                    ],
                  },
                ],
              },
              {
                name: "Pants",
                subcategories: [
                  {
                    name: "Jeans",
                    subcategories: [
                      { name: "Shirts" },
                      { name: "Pants" },
                      { name: "Suits" },
                    ],
                  },
                  {
                    name: "Chinos",
                    subcategories: [
                      { name: "Shirts" },
                      { name: "Pants" },
                      { name: "Suits" },
                    ],
                  },
                ],
              },
              {
                name: "Suits",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
            ],
          },
          {
            name: "Shoes",
            subcategories: [
              {
                name: "Sneakers",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
              {
                name: "Boots",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
              {
                name: "Dress Shoes",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
            ],
          },
          {
            name: "Accessories",
            subcategories: [
              {
                name: "Belts",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
              {
                name: "Ties",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
              {
                name: "Hats",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
              {
                name: "Jewelry",
                subcategories: [
                  {
                    name: "Necklaces",
                    subcategories: [
                      {
                        name: "Pendant Necklaces",
                        subcategories: [
                          { name: "Shirts" },
                          { name: "Pants" },
                          { name: "Suits" },
                        ],
                      },
                      {
                        name: "Choker Necklaces",
                        subcategories: [
                          { name: "Shirts" },
                          { name: "Pants" },
                          { name: "Suits" },
                        ],
                      },
                    ],
                  },
                  {
                    name: "Bracelets",
                    subcategories: [
                      {
                        name: "Bangle Bracelets",
                        subcategories: [
                          { name: "Shirts" },
                          { name: "Pants" },
                          { name: "Suits" },
                        ],
                      },
                      {
                        name: "Cuff Bracelets",
                        subcategories: [
                          { name: "Shirts" },
                          { name: "Pants" },
                          { name: "Suits" },
                        ],
                      },
                    ],
                  },
                  {
                    name: "Earrings",
                    subcategories: [
                      { name: "Shirts" },
                      { name: "Pants" },
                      { name: "Suits" },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        name: "Men Fashion",
        subcategories: [
          {
            name: "Clothing",
            subcategories: [
              {
                name: "Shirts",
                subcategories: [
                  {
                    name: "Dress Shirts",
                    subcategories: [
                      { name: "Shirts" },
                      { name: "Pants" },
                      { name: "Suits" },
                    ],
                  },
                  {
                    name: "T-Shirts",
                    subcategories: [
                      {
                        name: "Graphic T-Shirts",
                        subcategories: [
                          { name: "Shirts" },
                          { name: "Pants" },
                          { name: "Suits" },
                        ],
                      },
                      {
                        name: "Plain T-Shirts",
                        subcategories: [
                          { name: "Shirts" },
                          { name: "Pants" },
                          { name: "Suits" },
                        ],
                      },
                    ],
                  },
                  {
                    name: "Polo Shirts",
                    subcategories: [
                      { name: "Shirts" },
                      { name: "Pants" },
                      { name: "Suits" },
                    ],
                  },
                ],
              },
              {
                name: "Pants",
                subcategories: [
                  {
                    name: "Jeans",
                    subcategories: [
                      { name: "Shirts" },
                      { name: "Pants" },
                      { name: "Suits" },
                    ],
                  },
                  {
                    name: "Chinos",
                    subcategories: [
                      { name: "Shirts" },
                      { name: "Pants" },
                      { name: "Suits" },
                    ],
                  },
                ],
              },
              {
                name: "Suits",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
            ],
          },
          {
            name: "Shoes",
            subcategories: [
              {
                name: "Sneakers",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
              {
                name: "Boots",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
              {
                name: "Dress Shoes",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
            ],
          },
          {
            name: "Accessories",
            subcategories: [
              {
                name: "Belts",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
              {
                name: "Ties",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
              {
                name: "Hats",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
              {
                name: "Jewelry",
                subcategories: [
                  {
                    name: "Necklaces",
                    subcategories: [
                      {
                        name: "Pendant Necklaces",
                        subcategories: [
                          { name: "Shirts" },
                          { name: "Pants" },
                          { name: "Suits" },
                        ],
                      },
                      {
                        name: "Choker Necklaces",
                        subcategories: [
                          { name: "Shirts" },
                          { name: "Pants" },
                          { name: "Suits" },
                        ],
                      },
                    ],
                  },
                  {
                    name: "Bracelets",
                    subcategories: [
                      {
                        name: "Bangle Bracelets",
                        subcategories: [
                          { name: "Shirts" },
                          { name: "Pants" },
                          { name: "Suits" },
                        ],
                      },
                      {
                        name: "Cuff Bracelets",
                        subcategories: [
                          { name: "Shirts" },
                          { name: "Pants" },
                          { name: "Suits" },
                        ],
                      },
                    ],
                  },
                  {
                    name: "Earrings",
                    subcategories: [
                      { name: "Shirts" },
                      { name: "Pants" },
                      { name: "Suits" },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        name: "Men Fashion",
        subcategories: [
          {
            name: "Clothing",
            subcategories: [
              {
                name: "Shirts",
                subcategories: [
                  {
                    name: "Dress Shirts",
                    subcategories: [
                      { name: "Shirts" },
                      { name: "Pants" },
                      { name: "Suits" },
                    ],
                  },
                  {
                    name: "T-Shirts",
                    subcategories: [
                      {
                        name: "Graphic T-Shirts",
                        subcategories: [
                          { name: "Shirts" },
                          { name: "Pants" },
                          { name: "Suits" },
                        ],
                      },
                      {
                        name: "Plain T-Shirts",
                        subcategories: [
                          { name: "Shirts" },
                          { name: "Pants" },
                          { name: "Suits" },
                        ],
                      },
                    ],
                  },
                  {
                    name: "Polo Shirts",
                    subcategories: [
                      { name: "Shirts" },
                      { name: "Pants" },
                      { name: "Suits" },
                    ],
                  },
                ],
              },
              {
                name: "Pants",
                subcategories: [
                  {
                    name: "Jeans",
                    subcategories: [
                      { name: "Shirts" },
                      { name: "Pants" },
                      { name: "Suits" },
                    ],
                  },
                  {
                    name: "Chinos",
                    subcategories: [
                      { name: "Shirts" },
                      { name: "Pants" },
                      { name: "Suits" },
                    ],
                  },
                ],
              },
              {
                name: "Suits",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
            ],
          },
          {
            name: "Shoes",
            subcategories: [
              {
                name: "Sneakers",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
              {
                name: "Boots",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
              {
                name: "Dress Shoes",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
            ],
          },
          {
            name: "Accessories",
            subcategories: [
              {
                name: "Belts",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
              {
                name: "Ties",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
              {
                name: "Hats",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
              {
                name: "Jewelry",
                subcategories: [
                  {
                    name: "Necklaces",
                    subcategories: [
                      {
                        name: "Pendant Necklaces",
                        subcategories: [
                          { name: "Shirts" },
                          { name: "Pants" },
                          { name: "Suits" },
                        ],
                      },
                      {
                        name: "Choker Necklaces",
                        subcategories: [
                          { name: "Shirts" },
                          { name: "Pants" },
                          { name: "Suits" },
                        ],
                      },
                    ],
                  },
                  {
                    name: "Bracelets",
                    subcategories: [
                      {
                        name: "Bangle Bracelets",
                        subcategories: [
                          { name: "Shirts" },
                          { name: "Pants" },
                          { name: "Suits" },
                        ],
                      },
                      {
                        name: "Cuff Bracelets",
                        subcategories: [
                          { name: "Shirts" },
                          { name: "Pants" },
                          { name: "Suits" },
                        ],
                      },
                    ],
                  },
                  {
                    name: "Earrings",
                    subcategories: [
                      { name: "Shirts" },
                      { name: "Pants" },
                      { name: "Suits" },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        name: "Men Fashion",
        subcategories: [
          {
            name: "Clothing",
            subcategories: [
              {
                name: "Shirts",
                subcategories: [
                  {
                    name: "Dress Shirts",
                    subcategories: [
                      { name: "Shirts" },
                      { name: "Pants" },
                      { name: "Suits" },
                    ],
                  },
                  {
                    name: "T-Shirts",
                    subcategories: [
                      {
                        name: "Graphic T-Shirts",
                        subcategories: [
                          { name: "Shirts" },
                          { name: "Pants" },
                          { name: "Suits" },
                        ],
                      },
                      {
                        name: "Plain T-Shirts",
                        subcategories: [
                          { name: "Shirts" },
                          { name: "Pants" },
                          { name: "Suits" },
                        ],
                      },
                    ],
                  },
                  {
                    name: "Polo Shirts",
                    subcategories: [
                      { name: "Shirts" },
                      { name: "Pants" },
                      { name: "Suits" },
                    ],
                  },
                ],
              },
              {
                name: "Pants",
                subcategories: [
                  {
                    name: "Jeans",
                    subcategories: [
                      { name: "Shirts" },
                      { name: "Pants" },
                      { name: "Suits" },
                    ],
                  },
                  {
                    name: "Chinos",
                    subcategories: [
                      { name: "Shirts" },
                      { name: "Pants" },
                      { name: "Suits" },
                    ],
                  },
                ],
              },
              {
                name: "Suits",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
            ],
          },
          {
            name: "Shoes",
            subcategories: [
              {
                name: "Sneakers",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
              {
                name: "Boots",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
              {
                name: "Dress Shoes",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
            ],
          },
          {
            name: "Accessories",
            subcategories: [
              {
                name: "Belts",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
              {
                name: "Ties",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
              {
                name: "Hats",
                subcategories: [
                  { name: "Shirts" },
                  { name: "Pants" },
                  { name: "Suits" },
                ],
              },
              {
                name: "Jewelry",
                subcategories: [
                  {
                    name: "Necklaces",
                    subcategories: [
                      {
                        name: "Pendant Necklaces",
                        subcategories: [
                          { name: "Shirts" },
                          { name: "Pants" },
                          { name: "Suits" },
                        ],
                      },
                      {
                        name: "Choker Necklaces",
                        subcategories: [
                          { name: "Shirts" },
                          { name: "Pants" },
                          { name: "Suits" },
                        ],
                      },
                    ],
                  },
                  {
                    name: "Bracelets",
                    subcategories: [
                      {
                        name: "Bangle Bracelets",
                        subcategories: [
                          { name: "Shirts" },
                          { name: "Pants" },
                          { name: "Suits" },
                        ],
                      },
                      {
                        name: "Cuff Bracelets",
                        subcategories: [
                          { name: "Shirts" },
                          { name: "Pants" },
                          { name: "Suits" },
                        ],
                      },
                    ],
                  },
                  {
                    name: "Earrings",
                    subcategories: [
                      { name: "Shirts" },
                      { name: "Pants" },
                      { name: "Suits" },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
];

export interface OrdersItemTypes {
  CatalogImage: string;
  Category: string;
  FileID: string;
  CREATEDDate: string;
  Products: string;
  QCStatus: string;
  ACTIONS: string;
}


const orderDetails: OrdersItemTypes[] = [
  {
    CatalogImage: "https://upload.meeshosupplyassets.com/cataloging/1695903322415/chandrayaan-2-1-759.jpg",
    Category: "XYZ123",
    FileID: "XYZ123",
    CREATEDDate: "Nov 28, 2019 10:59 AM",
    Products: "Nov 10, 2019 10:59 AM",
    QCStatus: "QC in Progress",
    ACTIONS: "Delete",
  },
  {
    CatalogImage: "https://upload.meeshosupplyassets.com/cataloging/1695903322415/chandrayaan-2-1-759.jpg",
    Category: "XYZ123",
    FileID: "XYZ123",
    CREATEDDate: "Nov 28, 2019 10:59 AM",
    Products: "Nov 10, 2019 10:59 AM",
    QCStatus: "QC in Progress",
    ACTIONS: "Delete",
  },
  {
    CatalogImage: "https://upload.meeshosupplyassets.com/cataloging/1695903322415/chandrayaan-2-1-759.jpg",
    Category: "XYZ123",
    FileID: "XYZ123",
    CREATEDDate: "Nov 28, 2019 10:59 AM",
    Products: "Nov 10, 2019 10:59 AM",
    QCStatus: "QC in Progress",
    ACTIONS: "Delete",
  },
  {
    CatalogImage: "https://upload.meeshosupplyassets.com/cataloging/1695903322415/chandrayaan-2-1-759.jpg",
    Category: "XYZ123",
    FileID: "XYZ123",
    CREATEDDate: "Nov 28, 2019 10:59 AM",
    Products: "Nov 10, 2019 10:59 AM",
    QCStatus: "QC in Progress",
    ACTIONS: "Delete",
  },
];






const circlex = (
  <svg
    width="15"
    height="15"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="11" cy="11" r="10" stroke="#2A353D" strokeWidth="1.5" />
    <path
      d="M10.992 14H11.001"
      stroke="#2A353D"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11 11L11 7"
      stroke="#2A353D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
const leftarrow = (
  <svg
    width="7"
    height="12"
    viewBox="0 0 7 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.45124 0.248959C6.78318 0.580905 6.78318 1.1191 6.45124 1.45104L2.05228 5.85L6.45124 10.249C6.78318 10.5809 6.78318 11.1191 6.45124 11.451C6.11929 11.783 5.5811 11.783 5.24915 11.451L0.249154 6.45104C-0.0827911 6.11909 -0.0827911 5.5809 0.249154 5.24896L5.24915 0.248959C5.5811 -0.0829865 6.11929 -0.0829864 6.45124 0.248959Z"
      fill="#7C8DB5"
    />
  </svg>
);

const deleteicon = (
  <svg
    width="16"
    height="20"
    viewBox="0 0 16 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Icon">
      <path
        d="M14.79 5C14.3537 5 14 5.3537 14 5.79V14.63C14 16.4912 12.4912 18 10.63 18H4.95C3.0888 18 1.58 16.4912 1.58 14.63V5.79C1.58 5.3537 1.22631 5 0.79 5C0.353695 5 1.30029e-08 5.3537 0 5.79V14.63C0.0274201 17.3719 2.25799 19.5801 5 19.58H10.68C13.4025 19.5529 15.6029 17.3525 15.63 14.63V5.79C15.6304 5.57152 15.5404 5.36262 15.3812 5.21294C15.2221 5.06326 15.008 4.98617 14.79 5Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.79 3.58H0.79C0.353695 3.58 0 3.22631 0 2.79C0 2.35369 0.353695 2 0.79 2H3.44L4.62 0.72C5.07107 0.261411 5.68675 0.00217586 6.33 1.83491e-10H9.25C9.88387 -7.84652e-06 10.4918 0.25179 10.94 0.7L12.14 2H14.79C15.2263 2 15.58 2.35369 15.58 2.79C15.58 3.22631 15.2263 3.58 14.79 3.58ZM9.25 1.58H6.33C6.13008 1.58007 5.93744 1.65499 5.79 1.79L5.6 1.99H9.98L9.79 1.79C9.64364 1.65326 9.45029 1.57807 9.25 1.58Z"
        fill="black"
      />
      <path
        d="M4.04 6.79V9.79C4.04 10.2042 4.37579 10.54 4.79 10.54C5.20421 10.54 5.54 10.2042 5.54 9.79V6.79C5.54 6.37579 5.20421 6.04 4.79 6.04C4.37579 6.04 4.04 6.37579 4.04 6.79Z"
        fill="black"
      />
      <path
        d="M7.04 6.79V14.79C7.04 15.2042 7.37579 15.54 7.79 15.54C8.20421 15.54 8.54 15.2042 8.54 14.79V6.79C8.54 6.37579 8.20421 6.04 7.79 6.04C7.37579 6.04 7.04 6.37579 7.04 6.79Z"
        fill="black"
      />
      <path
        d="M10.04 6.79V9.79C10.04 10.2042 10.3758 10.54 10.79 10.54C11.2042 10.54 11.54 10.2042 11.54 9.79V6.79C11.54 6.37579 11.2042 6.04 10.79 6.04C10.3758 6.04 10.04 6.37579 10.04 6.79Z"
        fill="black"
      />
    </g>
  </svg>
);

const orangecheck = (
  <svg
    width="15"
    height="15"
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14 7.5C14 3.91015 11.0899 1 7.5 1C3.91015 1 1 3.91015 1 7.5C1 11.0899 3.91015 14 7.5 14C11.0899 14 14 11.0899 14 7.5Z"
      stroke="orange"
    />
    <path
      d="M4.90039 7.9875C4.90039 7.9875 5.94039 8.58064 6.46039 9.45C6.46039 9.45 8.02039 6.0375 10.1004 4.9"
      stroke="orange"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
const graycheck = (
  <svg
    width="15"
    height="15"
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14 7.5C14 3.91015 11.0899 1 7.5 1C3.91015 1 1 3.91015 1 7.5C1 11.0899 3.91015 14 7.5 14C11.0899 14 14 11.0899 14 7.5Z"
      stroke="gray"
    />
    <path
      d="M4.90039 7.9875C4.90039 7.9875 5.94039 8.58064 6.46039 9.45C6.46039 9.45 8.02039 6.0375 10.1004 4.9"
      stroke="gray"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
const greencheck = (
  <svg
    width="15"
    height="15"
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14 7.5C14 3.91015 11.0899 1 7.5 1C3.91015 1 1 3.91015 1 7.5C1 11.0899 3.91015 14 7.5 14C11.0899 14 14 11.0899 14 7.5Z"
      stroke="green"
    />
    <path
      d="M4.90039 7.9875C4.90039 7.9875 5.94039 8.58064 6.46039 9.45C6.46039 9.45 8.02039 6.0375 10.1004 4.9"
      stroke="green"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export {
  categories,
  orderDetails,
  circlex,
  leftarrow,
  deleteicon,
  orangecheck,
  graycheck,
  greencheck,
};
