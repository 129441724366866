import React, { useEffect, useState } from "react";
import { Spinner, Table, Pagination } from "react-bootstrap";
import { activationPendingProduct } from "./EditLiveProduct/product";
import { toast } from "react-toastify";

const ActivationPendingListing: React.FC = () => {
  const [products, setProducts] = useState<activationPendingProduct[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalItems, setTotalItems] = useState<number>(0);
  const [perPage, setPerPage] = useState<number>(0);

  // States for API parameters
  const [status, setStatus] = useState<string>("");
  const [search, setSearch] = useState<string>("");
  const [category_4_id, setCategory4Id] = useState<string>("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem("token");

        if (!token) {
          console.error("Token not found in localStorage");
          window.location.href = "/seller";
          return;
        }

        const myHeaders = new Headers();
        myHeaders.append("token", token!);
        myHeaders.append("Content-Type", "application/json");

        const requestOptions = {
          method: "POST",
          headers: myHeaders,
        };
        // Constructing the query parameters conditionally
        const queryParams = new URLSearchParams();
        if (status !== "") {
          queryParams.append("status", status);
        }
        if (search !== "") {
          queryParams.append("search", search);
        }
        if (category_4_id !== "") {
          queryParams.append("category_4_id", category_4_id);
        }
        const url = `${
          process.env.REACT_APP_BASE_URL
        }/prd_lst/get_edit_prdct_requests/${currentPage}?${queryParams.toString()}`;

        const response = await fetch(url, requestOptions);
        const data = await response.json();
        console.log(data.data);
        if (Array.isArray(data?.data)) {
          setProducts(data.data);
          setTotalItems(data.total_items || 0);
          setPerPage(data.per_page || 0);
        } else if (data.data === "Invalid Token!") {
          toast.error("Please login again !");
          window.location.href = "/seller";
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);
  const formatCreatedAt = (createdAt: string): string => {
    const date = new Date(createdAt);
    const formattedDate = date.toISOString().split("T")[0];
    const formattedTime = date.toTimeString().split(" ")[0];
    return `${formattedDate} ${formattedTime}`;
  };

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const renderPagination = () => {
    const totalPages = Math.ceil(totalItems / perPage);

    return (
      <Pagination className="mt-2">
        <Pagination.Prev
          disabled={currentPage === 1}
          onClick={() => handlePageChange(currentPage - 1)}
        />
        {Array.from({ length: totalPages }, (_, index) => (
          <Pagination.Item
            key={index + 1}
            active={index + 1 === currentPage}
            onClick={() => handlePageChange(index + 1)}
          >
            {index + 1}
          </Pagination.Item>
        ))}
        <Pagination.Next
          disabled={currentPage === totalPages}
          onClick={() => handlePageChange(currentPage + 1)}
        />
      </Pagination>
    );
  };

  const renderTable = () => (
    <Table striped bordered hover responsive>
      <thead>
        <tr>
          <th>DSIN</th>
          <th>Catalog ID</th>
          <th>Category</th>
          <th>Product Name</th>
          <th>Product Description</th>
          <th>Status</th>
          <th>Created At</th>
        </tr>
      </thead>
      <tbody>
        {products.map(
          ({
            dsin,
            catalog_id,
            product_id,
            category_4,
            product_name_n,
            product_description_n,
            status,
            createdAt,
          }) => (
            <tr key={product_id}>
              <td>{dsin}</td>
              <td>{catalog_id}</td>
              <td>{category_4.name}</td>
              <td>{product_name_n}</td>
              <td>{product_description_n}</td>
              <td>{status}</td>
              <td>{formatCreatedAt(createdAt)}</td>
            </tr>
          )
        )}
      </tbody>
    </Table>
  );

  return (
    <div>
      <h4>Activation Pending Listing</h4>
      {loading ? (
        <div
          className="text-center d-flex align-items-start justify-content-center"
          style={{ height: "100vh" }}
        >
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      ) : (
        <>
          {renderTable()}
          {renderPagination()}
        </>
      )}
    </div>
  );
};

export default ActivationPendingListing;
