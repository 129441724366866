import React, { useContext, useEffect, useState } from "react";
import {
  INotificationContext,
  INotificationProviderProps,
  Notification,
} from "./NotificationService.types";
import { DUMMY_NOTIFICATION } from "./temp";
import { Socket, io } from "socket.io-client";
import { Context } from "pages/other/Profile/OtherDetails/Mycontext";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
const notificationSound = require("../../assets/music/audio.mp3");

const NotificationContext = React.createContext({} as INotificationContext);

export const useNotification = () => React.useContext(NotificationContext);

export const NotificationProvider: React.FC<INotificationProviderProps> = ({
  children,
}) => {
  const [notifications] = useState<Notification[]>(DUMMY_NOTIFICATION);
  const [socket, setSocket] = useState<Socket | null>(null);
  const { LoginData } = useContext(Context);
  const audio = new Audio(notificationSound);
  const navigate=useNavigate()

  useEffect(() => {
    if (LoginData) {
      const socketInit = io(`${process.env.REACT_APP_BASE_URL_SOCKET}`);

      setSocket(socketInit);

      return () => {
        socketInit.disconnect();
      };
    }
  }, [LoginData, setSocket]);

  useEffect(() => {
    if (LoginData && socket !== null) {
      socket.emit("new_user", {
        userId: LoginData && LoginData.seller_id,
        name: LoginData && LoginData.seller_name,
        email: LoginData && LoginData.email,
      });
      socket &&
        socket.on("get_notification", (data) => {
          audio.play();

          toast.info(data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            onClick: () => {
              navigate("/pages/notification");
            },
          });
        });
    }
  }, [socket, LoginData]);

  console.log({ LoginData, socket });

  return (
    <NotificationContext.Provider value={{ notifications }}>
      {children}
    </NotificationContext.Provider>
  );
};
