import React, { useContext, useState } from "react";
import { Context } from "../Mycontext";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Col, Row, Toast } from "react-bootstrap";
import { toast } from "react-toastify";

const inputStyles = {
  height: "36px",
  borderRadius: "18px",
  width: "100%",
  border: "1px solid gray",
  margin: "10px 0",
  padding: "10px 20px",
  boxShadow: "0px 2px 5px lightgray",
  whiteSpace: "nowrap",
} as React.CSSProperties;

const toastStyles = {
  position: "fixed",
  bottom: "1rem",
  right: "1rem",
  zIndex: 9999,
} as React.CSSProperties;

const ChangeEmail: React.FC = ({}) => {
  const [step, setStep] = useState(1);
  const [otp, setOtp] = useState("");
  const [newEmail, setNewEmail] = useState("");

  const [showSuccessToast, setShowSuccessToast] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [showErrorToast, setShowErrorToast] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const { LoginData } = useContext(Context);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const maskEmail = (email: string | undefined): string | undefined => {
    if (!email) return "";
    const [username, domain] = email.split("@");
    const maskedUsername = username.charAt(0) + "*".repeat(username.length - 1);
    return `${maskedUsername}@${domain}`;
  };

  const handleContinue = async () => {
    if (step === 1) {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          setErrorMessage("User token not found in AsyncStorage");
          setShowErrorToast(true);
          return;
        }
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("token", token);
        const response = await fetch(
          `${process.env.REACT_APP_BASE_URL}/email_sendOtp`,
          {
            method: "POST",
            headers: myHeaders,
          }
        );

        if (!response.ok) {
          setErrorMessage(`API call error: ${response.status}`);
          setShowErrorToast(true);
          return;
        }

        toast.success("OTP sent successfully");
        // setShowSuccessToast(true);
        setStep(2);
      } catch (error) {
        setErrorMessage(`Error sending OTP: ${error}`);
        setShowErrorToast(true);
      }
    } else if (step === 2) {
      try {
        if (otp) {
          const userToken = localStorage.getItem("token");
          const requestBody = {
            email: newEmail,
            OTP: otp,
          };

          const response = await fetch(
            `${process.env.REACT_APP_BASE_URL}/edit_email`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                token: userToken || "",
              },
              body: JSON.stringify(requestBody),
            }
          );
          const responseData = await response.json();

          if (responseData.status === "success") {
            setSuccessMessage("Email changed successfully");
            navigate("/pages/profile");
            setShowSuccessToast(true);
            setOtp("");
            setNewEmail("");
          } else {
            setShowErrorToast(true);

            if (responseData.status === "failure") {
              if (responseData.msg === "Incorrect OTP!") {
                setErrorMessage("Incorrect OTP. Please try again.");
              } else {
                setErrorMessage(`Error: ${responseData.msg}`);
              }
            } else {
              setErrorMessage(`HTTP error! Status: ${response.status}`);
            }
          }
        } else {
          setErrorMessage("OTP is required");
          setShowErrorToast(true);
        }
      } catch (error) {
        setErrorMessage(`Error changing email: ${error}`);
        setShowErrorToast(true);
      }
    }
  };

  const handleGoBack = () => {
    if (step === 2) {
      setStep(1);
    } else if (step === 1) {
      navigate(-1);
    }
  };

  return (
    <Row className="p-3 p-md-0">
      <Col md={6}>
        <Toast
          show={showSuccessToast}
          onClose={() => setShowSuccessToast(false)}
          delay={3000}
          autohide
          style={toastStyles}
          bg="success"
        >
          <Toast.Header>
            <strong className="mr-auto text-white">Success</strong>{" "}
          </Toast.Header>
          <Toast.Body>{successMessage}</Toast.Body>
        </Toast>

        <Toast
          show={showErrorToast}
          onClose={() => setShowErrorToast(false)}
          delay={3000}
          autohide
          style={toastStyles}
          bg="danger"
        >
          <Toast.Header>
            <strong className="mr-auto text-black">Error</strong>{" "}
          </Toast.Header>
          <Toast.Body>{errorMessage}</Toast.Body>
        </Toast>

        <div className="p-2">
          <button className="btn btn-primary" onClick={handleGoBack}>
            Back
          </button>
          <h4 className="heading">
            {step === 1
              ? "We'll send you a code to your email address"
              : "Enter OTP and New Email"}
          </h4>
          {step === 1 ? (
            <div className="emailContainer">
              <p className="emailText">We can send a login code to:</p>
              <p className="email">{maskEmail(LoginData?.email)}</p>
            </div>
          ) : (
            <>
              <label htmlFor="newEmail" className="w-100 mt-2 px-2">
                {t("Enter New Email")}
              </label>
              <input
                type="email"
                placeholder="Enter New Email"
                value={newEmail}
                style={inputStyles}
                onChange={(e) => setNewEmail(e.target.value)}
              />
              <label htmlFor="otp" className="w-100 mt-2 px-2">
                {t("Enter OTP")}
              </label>
              <input
                type="text"
                placeholder="Enter OTP"
                value={otp}
                style={inputStyles}
                onChange={(e) => setOtp(e.target.value)}
              />
            </>
          )}
          <button className="btn btn-warning" onClick={handleContinue}>
            Continue
          </button>
        </div>
      </Col>
    </Row>
  );
};

export default ChangeEmail;
