import React, { useState } from "react";
import NotificationCard from "./NotificationCard";
import "./notification.scss";

interface NotificationListingProps {
  notifications: {
    notification_id: string;
    message: string;
    module_id: string;
    createdAt: string;
  }[];
  onPageChange: (newPage: number) => void;
  totalItems: number;
  itemsPerPage: number;
}

const NotificationListing: React.FC<NotificationListingProps> = ({
  notifications,
  onPageChange,
  totalItems,
  itemsPerPage,
}) => {
  const [currentPage, setCurrentPage] = useState(1);

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
    onPageChange(newPage);
  };

  return (
    <>
      <div className="pagination mb-2 mx-2">
        {Array.from(
          { length: Math.ceil(totalItems / itemsPerPage) },
          (_, index) => (
            <span
              key={index}
              onClick={() => handlePageChange(index + 1)}
              className={index + 1 === currentPage ? "active" : ""}
            >
              {index + 1}
            </span>
          )
        )}
      </div>
      <div className="notification-listing">
        {notifications.map((notification) => (
          <NotificationCard
            key={notification.notification_id}
            notification={notification}
          />
        ))}
      </div>
    </>
  );
};

export default NotificationListing;
