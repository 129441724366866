const bell = (
  <svg
    width="21"
    height="22"
    viewBox="0 0 21 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.02992 13.394C0.817268 14.7471 1.768 15.6862 2.93205 16.1542C7.39481 17.9486 13.6052 17.9486 18.0679 16.1542C19.232 15.6862 20.1827 14.7471 19.9701 13.394C19.8394 12.5625 19.1932 11.8701 18.7144 11.194C18.0873 10.2975 18.025 9.31971 18.0249 8.27941C18.0249 4.2591 14.6559 1 10.5 1C6.34413 1 2.97513 4.2591 2.97513 8.27941C2.97503 9.31971 2.91272 10.2975 2.28561 11.194C1.80684 11.8701 1.16061 12.5625 1.02992 13.394Z"
      stroke="#2A353D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.5 20C8.29613 20.6219 9.34747 21 10.5 21C11.6525 21 12.7039 20.6219 13.5 20"
      stroke="#2A353D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const downloadsvg = (
  <svg
    width="18"
    height="17"
    viewBox="0 0 18 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9 11L9 1M9 11C8.29977 11 6.99153 9.0057 6.5 8.5M9 11C9.70023 11 11.0085 9.0057 11.5 8.5"
      stroke="#2A353D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17 13C17 15.482 16.482 16 14 16H4C1.518 16 1 15.482 1 13"
      stroke="#2A353D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export { bell, downloadsvg };
