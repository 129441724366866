import React from "react";

interface ProductsProps {
  // Define any props if needed
}

const Step1: React.FC = () => (
  <div>
    <h2>Step 1: Start Adding a Single Listing</h2>
    <p>
      Access the Dashboard: You are initially on the main dashboard page where
      you can manage your products. The dashboard provides two main options:
      "Have unique products to sell?" and "List existing products on
      Dukkandaar."
    </p>
    <p>
      Click on 'ADD A SINGLE LISTING': To add a new product, you would start by
      clicking the red "ADD A SINGLE LISTING" button. This is a clear call to
      action for the user to initiate the process of listing a new item.
    </p>
  </div>
);

const Step2: React.FC = () => (
  <div>
    <h2>Step 2: Select Product Category</h2>
    <p>
      Category Selection Panel: After clicking the "ADD A SINGLE LISTING"
      button, you are taken to a new interface where the first step is to
      "Select Category."
    </p>
    <p>
      Category Path: The interface shows a breadcrumb path (e.g., Men Fashion /
      Inner & Sleepwear / Boxers & Loungewear / Boxers) indicating the category
      hierarchy. This helps sellers to place their product in the right
      category, ensuring it reaches the appropriate audience.
    </p>
    <p>
      Category Choices: You can browse through various categories such as Men
      Fashion, Women Fashion, etc., and select the one that best fits your
      product.
    </p>
  </div>
);

const Step3: React.FC = () => (
  <div>
    <h2>Step 3: Upload Product Images</h2>
    <p>
      Upload Interface: After selecting the appropriate category, you move on
      to the "Product Images" step where you can upload images for your
      product. There's usually a button or link to upload images, as indicated
      by the "Upload Product Images" prompt.
    </p>
    <p>
      Image Requirements: Platforms often have specific requirements for images
      in terms of size, format, and quality, and it's important to adhere to
      these to ensure your product is presented in the best possible way.
    </p>
  </div>
);

const Step4: React.FC = () => (
  <div>
    <h2>Step 4: Enter Product Details</h2>
    <p>
      Detail Input: The next step involves entering your product details. This
      will typically include a title, description, price, stock quantity, and
      other relevant specifications. The interface will have fields and
      drop-down menus to help you enter this information.
    </p>
    <p>
      Additional Information: Depending on the platform, you might also need to
      add shipping details, return policies, and any other seller-specific
      information.
    </p>
  </div>
);

const Step5: React.FC = () => (
  <div>
    <h2>Step 5: Review and Submit</h2>
    <p>
      Review Listing: Before submitting, you would review all the information
      you've entered to ensure it's accurate and complete.
    </p>
    <p>
      Submit Listing: Finally, you would click a submit button to send your
      listing for platform review or to go live immediately, depending on the
      site's process.
    </p>
  </div>
);

const Step6: React.FC = () => (
  <div>
    <h2>Step 6: Listing Confirmation</h2>
    <p>
      Confirmation: After submission, there would typically be a confirmation
      screen or message informing you that the listing process is complete or
      that the listing is under review.
    </p>
  </div>
);

const Step7: React.FC = () => (
  <div>
    <h2>Step 1: Accessing Listings in Progress</h2>
    <p>
      When you land on this page, you are presented with a dashboard where you
      can manage your product listings. The section titled "Listings in
      Progress" suggests that this part of the dashboard is used to manage
      products that have been added but may not yet be finalized or live on the
      marketplace.
    </p>
  </div>
);

const Step8: React.FC = () => (
  <div>
    <h2>Step 2: Adding a Single Listing</h2>
    <p>
      "ADD A SINGLE LISTING" Button: This prominent red button is designed to
      catch your eye. Clicking this would likely start the process of adding a
      new product listing to the platform.
    </p>
  </div>
);

const Step9: React.FC = () => (
  <div>
    <h2>Step 3: Using Custom Filters</h2>
    <p>
      Custom Filter: The "Custom Filter" box appears to allow you to enter
      specific search terms to filter your listings. This could be used to
      quickly find products by name, description, or other metadata.
    </p>
    <p>
      Filter by Status: This dropdown menu allows you to filter the listings by
      their status in the system, such as "Live," "Draft," "Pending," etc.
    </p>
    <p>
      Filter by Type: This filter could allow you to sort your products by
      type, possibly separating different forms of listings like auctions,
      buy-it-now, classifieds, etc.
    </p>
    <p>
      Filter by Category: Here you can narrow down your listings to show only
      products from specific categories like "Apparel," "Electronics," "Home
      Goods," etc.
    </p>
  </div>
);

const Step10: React.FC = () => (
  <div>
    <h2>Step 4: Reviewing the Listings Table</h2>
    <p>
      S.no (Serial Number): This column is for reference and order, providing a
      simple way to differentiate between products.
    </p>
    <p>
      Catalogue Image: Shows a small thumbnail image of the product for easy
      visual identification.
    </p>
    <p>
      Category: Indicates the category under which the product is listed. This
      helps in understanding the type of product at a glance without needing to
      view detailed information.
    </p>
    <p>
      DSIN: Likely stands for a unique identifier assigned to each product
      listing, similar to a Stock Keeping Unit (SKU).
    </p>
    <p>
      Created At: Displays the date and time when each product listing was
      created, which helps track when the item was added to the system.
    </p>
    <p>
      QC Status: This column shows the quality control or review status of the
      product. "LIVE" indicates that the product has passed the necessary
      checks and is visible to customers on the marketplace. "DRAFT" suggests
      the listing is incomplete or awaiting further action before going live.
    </p>
    <p>
      ACTIONS: This area provides actionable steps you can take for each
      product. The visible option is "Edit," which would allow you to change
      the details of the listing if needed.
    </p>
  </div>
);

const Step11: React.FC = () => (
  <div>
    <h2>Step 5: Managing Listings</h2>
    <p>
      Edit a Listing: By clicking "Edit" under the ACTIONS column, you can
      modify details of the product listing, such as the title, description,
      price, images, etc.
    </p>
    <p>
      Search Functionality: The search bar at the top right, labelled "Search
      By brand DSIN," allows you to quickly find listings using the DSIN or
      perhaps the brand name.
    </p>
  </div>
);

const Products: React.FC<ProductsProps> = () => {
  return (
    <div>
      <h1>Products</h1>
      <Step1 />
      <Step2 />
      <Step3 />
      <Step4 />
      <Step5 />
      <Step6 />
      <Step7 />
      <Step8 />
      <Step9 />
      <Step10 />
      <Step11 />
    </div>
  );
};

export default Products;
