const baseUrl = process.env.REACT_APP_BASE_URL;

export interface CouponResponse {
  status: string;
  msg: string;
  data: Coupon[];
  per_page: number;
  total_items: number;
}

export interface Coupon {
  coupon_id: string;
  coupon_code: string;
  is_money_off: boolean;
  is_per_off: boolean;
  amount: number | null;
  discount: number;
  multi_redemption: boolean;
  redemption_limit: number;
  total_spend: number;
  target_customer: string;
  start_date: string;
  end_date: string;
  product_id: string;
  dsin: string;
  seller_products_variants_id: string;
  created_by: string;
  coupon_title: string;
  created_by_id: string | null;
  created_by_seller_id: string;
  clips: number;
  redeemed: number;
  status: string;
  reviewed_by: string | null;
  reviewed_comment: string | null;
  createdAt: string;
  updatedAt: string;
}
export interface SingleCoupon {
  coupon_id: string;
  coupon_code: string;
  is_money_off: boolean;
  is_per_off: boolean;
  amount: null | number;
  discount: number;
  multi_redemption: boolean;
  redemption_limit: number;
  total_spend: number;
  target_customer: string;
  start_date: string;
  end_date: string;
  product_id: string;
  dsin: string;
  seller_products_variants_id: string;
  created_by: string;
  coupon_title: string;
  created_by_seller_id: string;
  clips: number;
  redeemed: number;
  status: string;
  reviewed_by: string;
  reviewed_comment: string;
  createdAt: string;
  updatedAt: string;
  product: {
    product_id: string;
    dsin: string;
    catalog_id: string;
    category_4_id: string;
    product_name: {
      en: string;
      hi: string;
      pa: string;
    };
    product_description: {
      en: string;
      hi: string;
      pa: string;
    };
    createdAt: string;
    products_images: Array<{ url: string }>;
  };
  seller_products_variant: {
    seller_products_variants_id: string;
    price: number;
    inventory: number;
    supplier_sku_id: string;
  };
}



export interface CouponFilter {
  status: string;
  search: string;
  type: string;
}

export const getCoupons = async (
  page: number,
  filter: CouponFilter
): Promise<CouponResponse> => {
  const token = localStorage.getItem("token");

  try {
    const response = await fetch(
      `${baseUrl}/get_all_coupons/${page}?status=${filter.status}&search=${filter.search}&type=${filter.type}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          token: token || "",
        },
      }
    );

    if (response.ok) {
      const data = await response.json();
      return data as CouponResponse;
    } else {
      console.error("Failed to fetch coupons");
      throw new Error("Failed to fetch coupons");
    }
  } catch (error) {
    console.error("Error occurred:", error);
    throw new Error("Failed to fetch coupons");
  }
};

export const deactivateCoupon = async (couponId: string) => {
  const token = localStorage.getItem("token");

  const response = await fetch(`${baseUrl}/deactivate_coupon/${couponId}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      token: token || "",
    },
  });

  return await response.json();
};

export const getSingleCoupon = async (couponId: string) => {
  const url = `${baseUrl}/get_single_coupon/${couponId}`;
  const token = localStorage.getItem("token");

  try {
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        token: token || "",
      },
    });

    if (!response.ok) {
      throw new Error(`Failed to fetch single coupon: ${response.statusText}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching single coupon:", error);
    throw error;
  }
};
