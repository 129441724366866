import React from "react";
import { Col, Row } from "react-bootstrap";
import { downarrowsm } from "../data";
import { useTranslation } from "react-i18next";

function Overview() {
  const {t}=useTranslation()

  return (
    <>
      {" "}
      <Row>
        <Col md={8}>
          <span className="fw-bold" style={{ marginRight: "10px" }}>
            Summary
          </span>
          <span className="cursor-pointer">Last Month {downarrowsm}</span>
        </Col>
        <Col md={4}>
          <div
            className="d-flex align-items-center justify-content-between"
            style={{ border: "1px solid #697475", borderRadius: "10px" }}
          >
            <div
              className="flex-grow-1 p-1"
              style={{ borderRight: "1px solid #697475" }}
            >
              Customer Return
            </div>
            <div
              className="flex-grow-1 p-1"
              style={{ borderRight: "1px solid #697475" }}
            >
              Courier Return (RTO)
            </div>
            <div className="flex-grow-1 p-1">Dual Pricing</div>
          </div>
        </Col>
        <Col md={12} style={{ borderBottom: "1px solid #CECECE" }}>
          <div className="d-flex align-items-center justify-content-center p-5">
            <h4>                  {t("No data as of now")}
</h4>
          </div>
        </Col>
      </Row>
      <Row className="py-2">
        <Col md={8}>
          <span className="fw-bold" style={{ marginRight: "10px" }}>
          Product Performance
          </span>
       
        </Col>
        <Col md={4}>
          <div className="text-end">Sort by</div>
        </Col>
        <Col md={12} style={{ borderBottom: "1px solid #CECECE" }}>
          <div className="d-flex align-items-center justify-content-center p-5">
            <h4>                  {t("No data as of now")}
</h4>
          </div>
        </Col>
      </Row>
    </>
  );
}

export default Overview;
