import React, { useState, useEffect } from "react";
import { Form, Row, Col, Button, FormControl } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { createDeal, getDukkandaarSellerDeals } from "./dealsApi";
import { toast } from "react-toastify";

interface DealItem {
  seller_product_variant: string;
  discount_type: string;
  discount_value: number;
}

interface DealForm {
  dukkandaar_deals_id: string;
  start_date: string;
  end_date: string;
  dealItems: DealItem[];
}

const AddDealForm: React.FC = () => {
  const location = useLocation();
  const { state } = location;
  const variantId = state?.variantId;
  const productName = state?.productName;

  const [deals, setDeals] = useState<any[]>([]);
  const [form, setForm] = useState<DealForm>({
    dukkandaar_deals_id: "",
    start_date: "",
    end_date: "",
    dealItems: [
      {
        seller_product_variant: variantId,
        discount_type: "Fixed",
        discount_value: 2000,
      },
    ],
  });

  useEffect(() => {
    const fetchDeals = async () => {
      try {
        const response = await getDukkandaarSellerDeals();
        setDeals(response.data);
      } catch (error) {
        console.error("Error fetching deals:", error);
      }
    };

    fetchDeals();
  }, []);

  const handleDealChange: React.ChangeEventHandler<HTMLSelectElement> = (e) => {
    const selectedDealId = e.target.value;

    const selectedDeal = deals.find(
      (deal) => deal.dukkandaar_deals_id === selectedDealId
    );

    if (selectedDeal) {
      setForm((prevForm) => ({
        ...prevForm,
        dukkandaar_deals_id: selectedDeal.dukkandaar_deals_id,
        start_date: form.start_date,
        end_date: form.end_date,
      }));
    }
  };

  const handleDealItemChange = (
    index: number,
    field: keyof DealItem,
    value: string | number
  ) => {
    const updatedDealItems = [...form.dealItems];
    updatedDealItems[index] = {
      ...updatedDealItems[index],
      [field]: value,
    };

    setForm({
      ...form,
      dealItems: updatedDealItems,
    });
  };

  const addDealItem = () => {
    setForm({
      ...form,
      dealItems: [
        ...form.dealItems,
        {
          seller_product_variant: "",
          discount_type: "Fixed",
          discount_value: 2000,
        },
      ],
    });
  };

  const removeDealItem = (index: number) => {
    const updatedDealItems = [...form.dealItems];
    updatedDealItems.splice(index, 1);

    setForm({
      ...form,
      dealItems: updatedDealItems,
    });
  };

  const handleSubmit = async () => {
    try {
      const response = await createDeal(form);

      if (response && response.status === "success") {
        toast.success("Deal created successfully!");
      } else {
        toast.error("Failed to create deal. Please try again.");
      }
    } catch (error) {
      console.error("Error creating deal:", error);
      toast.error(
        "An error occurred while creating the deal. Please try again."
      );
    }
  };

  const renderSelectOptions = () => {
    return deals.map((deal) => (
      <option key={deal.dukkandaar_deals_id} value={deal.dukkandaar_deals_id}>
        {deal.deal_title}
      </option>
    ));
  };

  const handleStartDateChange: React.ChangeEventHandler<HTMLInputElement> = (
    e
  ) => {
    const newStartDate = e.target.value;

    setForm((prevForm) => ({
      ...prevForm,
      start_date: newStartDate,
    }));
  };

  const handleEndDateChange: React.ChangeEventHandler<HTMLInputElement> = (
    e
  ) => {
    const newEndDate = e.target.value;

    setForm((prevForm) => ({
      ...prevForm,
      end_date: newEndDate,
    }));
  };

  return (
    <Form>
      <Form.Group as={Row} className="mb-3">
        <Form.Label column sm="2">
          Select Deal
        </Form.Label>
        <Col sm="10">
          <select value={form.dukkandaar_deals_id} onChange={handleDealChange}>
            <option value="">Select a Deal</option>
            {renderSelectOptions()}
          </select>
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-3">
        <Form.Label column sm="2">
          Start Date
        </Form.Label>
        <Col sm="10">
          <Form.Control
            type="date"
            value={form.start_date}
            onChange={handleStartDateChange}
          />
        </Col>
      </Form.Group>

      <Form.Group as={Row} className="mb-3">
        <Form.Label column sm="2">
          End Date
        </Form.Label>
        <Col sm="10">
          <Form.Control
            type="date"
            value={form.end_date}
            onChange={handleEndDateChange}
          />
        </Col>
      </Form.Group>

      <Form.Group as={Row} className="mb-3">
        <Form.Label column sm="2">
          Deal Items
        </Form.Label>
        <Col sm="10">
          {form.dealItems.map((item, index) => (
            <Row key={index} className="mb-2">
              <Col sm="4">
                <Form.Control
                  type="text"
                  placeholder="Seller Product Variant"
                  value={productName}
                  disabled
                  onChange={(e) =>
                    handleDealItemChange(
                      index,
                      "seller_product_variant",
                      e.target.value
                    )
                  }
                />
              </Col>
              <Col sm="3">
                <Form.Control
                  as="select"
                  value={item.discount_type}
                  onChange={(e) =>
                    handleDealItemChange(index, "discount_type", e.target.value)
                  }
                >
                  <option value="Fixed">Fixed</option>
                  <option value="Percentage">Percentage</option>
                </Form.Control>
              </Col>
              <Col sm="3">
                <Form.Control
                  type="number"
                  placeholder="Discount Value"
                  value={item.discount_value}
                  onChange={(e) =>
                    handleDealItemChange(
                      index,
                      "discount_value",
                      Number(e.target.value)
                    )
                  }
                />
              </Col>
              {/* <Col sm="2">
                <Button variant="danger" onClick={() => removeDealItem(index)}>
                  Remove
                </Button>
              </Col> */}
            </Row>
          ))}
          {/* <Button variant="primary" onClick={addDealItem}>
            Add Deal Item
          </Button> */}
        </Col>
      </Form.Group>

      <Button variant="primary" onClick={handleSubmit}>
        Submit
      </Button>
    </Form>
  );
};

export default AddDealForm;
