import React from "react";
import { Container, Card, Row, Col, Button } from "react-bootstrap";
import { FaCartPlus, FaPercent, FaClock, FaCheck } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const DisClaimer = () => {
  const steps = [
    {
      heading: "Add products to your coupon",
      subheading:
        "You can feature up to 200 products in one coupon. While adding multiple products, selecting within the same sub-category or product group will help provide a better customer experience.",
      icon: <FaCartPlus size={50} />,
    },
    {
      heading: "Set discount amount and budget",
      subheading:
        "You can offer percentage or money off discounts. For a better customer experience, we require the discount to be between 1% and 50% of your lowest price for the product in the last 30 days. The budget you set will be utilized as customers redeem your coupon. We will deactivate your coupon when your budget is fully utilized.",
      icon: <FaPercent size={50} />,
    },
    {
      heading: "Schedule and target your coupon",
      subheading: "You can set a duration up to 3 months for your coupon.",
      icon: <FaClock size={50} />,
    },
    {
      heading: "Review and submit",
      subheading:
        "One final check to see if everything looks good before you submit your coupon!",
      icon: <FaCheck size={50} />,
    },
  ];

  const navigate=useNavigate()

  const handleNavigate=()=>{
    navigate('/pages/coupons/sellercoupons/create-coupon')
  }

  return (
    <Container className="mb-4">
      <div className="d-flex justify-content-between align-items-center pb-2 mx-2">
        <span style={{ fontSize: "2em", color: "black", fontWeight: 500 }}>
          Coupon
        </span>
        <Button variant="primary" onClick={handleNavigate}>Go to coupons list</Button>
      </div>
      
      <Card className="mb-4" style={{ background: "rgb(236,236,236)" }}>
        <Card.Body>
          <Card.Title
            className="text-center fw-bold"
            style={{ fontSize: "1.5rem" }}
          >
            Offer discount on a single product or a set of products
          </Card.Title>
          <Row>
       
            <Col md={12}>
              <strong>Merchandising:</strong>
              <p>
                Enjoy automatic merchandising for your coupon through Dukkandaar.com.
                Customers can discover coupons on Coupons home page, in search
                results, on product detail pages, on the offer listings page and
                in their carts.
              </p>
              <strong>Performance Tracking:</strong>
              <p>
                Monitor and track the performance of your coupon in real time.
              </p>
              <strong>Targeting:</strong>
              <p>
                Make your coupon discoverable by only a customer segment of your
                choice.
              </p>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      <Card
        className="mb-4"
        style={{ background: "orange", padding: "20px", color: "white" }}
      >
        <Card.Title
          className="text-center text-whtie"
          style={{ fontSize: "1.3rem" }}
        >
          How to get started with Coupons
        </Card.Title>
      </Card>

      {steps.map((step, index) => (
        <Card key={index} className="mb-4">
          <Card.Body>
            <Row className="text-center">
              <Col md={6} className="d-grid place-content-center ">
                <div style={{ fontSize: "2em" }}>{step.icon}</div>
              </Col>

              <Col md={6} style={{textAlign:"left"}}>
                <Card.Title>{step.heading}</Card.Title>
                <Card.Text>{step.subheading}</Card.Text>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      ))}

      <div className="text-center fw-bold">
        <Button variant="primary" onClick={handleNavigate}>See your coupons</Button>
      </div>
    </Container>
  );
};

export default DisClaimer;
