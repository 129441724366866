import React, { useEffect, useState, useRef } from "react";
import plusIcon from "../../../../assets/images/plus.svg";
import { useLocation, useNavigate } from "react-router-dom";
import "../../../../../src/assets/styles.css";
import { FaTrash } from "react-icons/fa";
import { toast } from "react-toastify";
import set from "lodash/set";
import { NEWPRODUCTTEMPLATEDATA } from "./EmptyproductDataTemplate";
import TwoBallsLoader from "components/Loaders/TwoBallsLoader";
import { Alert, Button } from "react-bootstrap";
import noContent from "../../../../assets/images/graphics/no-content.png";

const OVERRIDE_FIELDS_MAP: Record<string, string> = {
  product_name: "product_name_n",
  comment: "product_description_n",
};

function getFieldKey(key: string) {
  return OVERRIDE_FIELDS_MAP[key] || key;
}

interface FormInputProps {
  id: string;
  name: string;
  identifier: string;
  type: string;
  required: boolean;
  description: string;
  placeholder: string;
  options: [] | null;
  value?: any;
  changeValue: any;
}
const GetFormInput: React.FC<FormInputProps> = ({
  id,
  name,
  identifier,
  type,
  required,
  description,
  placeholder,
  options,
  value,
  changeValue,
}) => {
  if (type === "text") {
    return (
      <div className="w-50 px-2 py-1">
        <div className="d-flex align-items-center position-relative">
          <h5>{name}</h5>
          {required && <h6 className="text-danger ms-2">*</h6>}
          <div className="tooltip-btn ms-2">
            <h6 className="rounded-circle border border-primary text-primary px-1">
              i
            </h6>
            <div className="position-absolute bottom-100 tooltip-text">
              {description}
            </div>
          </div>
        </div>
        <input
          type="text"
          name={identifier}
          id={id}
          className="w-100 border border-secondary p-1"
          placeholder={placeholder}
          required={required}
          value={value}
          onChange={(e) => changeValue(e.target.value)}
        />
      </div>
    );
  } else if (type === "select") {
    return (
      <div className="w-50 px-2 py-1">
        <div className="d-flex align-items-center position-relative">
          <h5>{name}</h5>
          {required && <h6 className="text-danger ms-2">*</h6>}
          <div className="tooltip-btn ms-2">
            <h6 className="rounded-circle border border-primary text-primary px-1">
              i
            </h6>
            <div className="position-absolute bottom-100 tooltip-text">
              {description}
            </div>
          </div>
        </div>
        <select
          name={identifier}
          id={id}
          className="w-100 border border-secondary p-1"
          value={value}
          onChange={(e) => changeValue(e.target.value)}
          required={required}
        >
          <option value="">Select</option>
          {options?.map((value) => (
            <option value={value} key={value}>
              {value}
            </option>
          ))}
        </select>
      </div>
    );
  } else if (type === "number") {
    return (
      <div className="w-50 px-2 py-1">
        <div className="d-flex align-items-center position-relative">
          <h5>{name}</h5>
          {required && <h6 className="text-danger ms-2">*</h6>}
          <div className="tooltip-btn ms-2">
            <h6 className="rounded-circle border border-primary text-primary px-1">
              i
            </h6>
            <div className="position-absolute bottom-100 tooltip-text">
              {description}
            </div>
          </div>
        </div>
        <input
          type="number"
          name={identifier}
          id={id}
          className="w-100 border border-secondary p-1"
          placeholder={placeholder}
          required={required}
          value={value}
          onChange={(e) => changeValue(e.target.value)}
        />
      </div>
    );
  } else if (type === "textarea") {
    return (
      <div className="w-100 px-2 py-1">
        <div className="d-flex align-items-center position-relative">
          <h5>{name}</h5>
          {required && <h6 className="text-danger ms-2">*</h6>}
          <div className="tooltip-btn ms-2">
            <h6 className="rounded-circle border border-primary text-primary px-1">
              i
            </h6>
            <div className="position-absolute bottom-100 tooltip-text">
              {description}
            </div>
          </div>
        </div>
        <textarea
          name={identifier}
          id={id}
          placeholder={placeholder}
          required={required}
          className="w-100 border border-secondary p-1"
          rows={5}
          value={value}
          onChange={(e) => changeValue(e.target.value)}
        ></textarea>
      </div>
    );
  } else {
    return <div>...</div>;
  }
};
interface FormTableInputProps {
  id: string;
  name: string;
  identifier: string;
  type: string;
  required: boolean;
  placeholder: string;
  value: any;
  changeValue: any;
}
const GetTableFormInput: React.FC<FormTableInputProps> = ({
  id,
  name,
  identifier,
  type,
  required,
  placeholder,
  value,
  changeValue,
}) => {
  if (type === "text") {
    return (
      <div className="w-100">
        <input
          type="text"
          name={identifier}
          id={id}
          className="w-100 border border-secondary"
          placeholder={placeholder}
          required={required}
          value={value}
          onChange={(e) => changeValue(e.target.value)}
        />
      </div>
    );
  } else if (type === "number") {
    return (
      <div className="w-100">
        <input
          type="number"
          name={identifier}
          id={id}
          className="w-100 border border-secondary"
          placeholder={placeholder}
          required={required}
          value={value}
          onChange={(e) => changeValue(Number(e.target.value))}
        />
      </div>
    );
  } else {
    return <div>...</div>;
  }
};

const EditProductDetails: React.FC = () => {
  const location = useLocation();

  const [showSizeOptions, setShowSizeOptions] = useState<boolean>(false);
  const catelogId = location.state?.id;
  const category4Id = location.state?.category4Id;

  const [product_data, setProductData] = useState<any>([]);
  const [selectedForm, selectForm] = useState<number>(0);
  const [formChanged, setFormChanged] = useState(false);

  const [selectedVariations, selectVariations] = useState<any>([]);
  const [selectedFullVariations, selectFullVariation] = useState<any>([]);
  const [variationsData, setVariationsData] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(true);

  console.log({ product_data });

  const navigate = useNavigate();

  const reset = () => {
    setShowSizeOptions(false);
    selectForm(0);
    setFormChanged(false);
    selectVariations([]);
    selectFullVariation([]);
    setVariationsData([]);
  };

  const [formData, setForm] = useState<any>(null);

  useEffect(() => {
    if (formData === null && category4Id) {
      const token: string | null = localStorage.getItem("token");
      var raw = JSON.stringify({
        category_4_id: category4Id,
      });

      var requestOptions: any = {
        method: "POST",
        headers: {
          token: token,
          "Content-Type": "application/json",
        },
        body: raw,
        redirect: "follow",
      };

      fetch(
        "https://marketplaceapi.quadbtech.com/api/v1/seller/prd_lst/fetchProductlistform",
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          setForm(result);
        })
        .catch((error) => console.log("error", error))
        .finally(() => {
          setLoading(false);
        });
    }
  }, [formData, category4Id]);

  useEffect(() => {
    const token = localStorage.getItem("token");

    const headers: Record<string, string> = {
      "Content-Type": "application/json",
    };

    if (token) {
      headers["token"] = token;
    }

    const apiUrl = `${process.env.REACT_APP_BASE_URL}/prd_lst/fetch_single_catalog/${catelogId}`;

    fetch(apiUrl, {
      method: "GET",
      headers: headers,
      redirect: "follow",
    })
      .then((response) => response.json())
      .then((result) => {
        if (result.status === "success" || result.status === "Success") {
          setProductData(result?.product_data);

          selectVariations(
            result?.product_data[selectedForm]?.product_variants
              .map((v: any) => v.variation_value)
              .flat(2)
          );

          selectFullVariation(
            result.product_data[selectedForm]?.product_variants.map(
              (item: any) => {
                const sellerProductVariant = item.seller_products_variants[0];
                return {
                  variation_values_id:
                    sellerProductVariant.seller_products_variants_id,
                  variation_id: item.product_variants_id,
                  name: item.variation_value,
                  sku: item.variation_value_sku,
                };
              }
            )
          );

          setVariationsData(
            result.product_data[selectedForm]?.product_variants.flatMap(
              (item: any) => {
                return item.seller_products_variants.map(
                  (sellerProductVariant: any) => {
                    return {
                      variation_values_id:
                        sellerProductVariant.seller_products_variants_id,
                      variation_id: item.product_variants_id,
                      name: item.variation_value,
                      sku: item.variation_value_sku,
                    };
                  }
                );
              }
            )
          );
        }
      })
      .catch((error) => console.error("error", error))
      .finally(() => {
        setLoading(false);
      });
  }, [catelogId, selectedForm]);

  const imageInputRef = useRef<any>({});
  const imageInputRefNewProduct = useRef<any>({});

  const toggleVariation = (variation: any) => {
    if (selectedVariations.includes(variation.name)) {
      let arr: any, arr2: any;
      arr = selectedVariations.filter(function (selected_variation: any) {
        return selected_variation !== variation.name;
      });
      arr2 = selectedFullVariations.filter(function (selected_variation: any) {
        return selected_variation.name !== variation.name;
      });
      selectVariations((x: any) => [...arr]);
      selectFullVariation((x: any) => [...arr2]);
    } else {
      let arr = [...selectedVariations],
        arr2 = [...selectedFullVariations];
      arr.push(variation.name);
      arr2.push(variation);
      selectVariations((x: any) => [...arr]);
      selectFullVariation((x: any) => [...arr2]);
    }
  };

  const getVariations = (variationsData: any) => {
    let pvArr: any = [];
    variationsData.forEach((vd: any) => {
      let attributesArr: any = [];
      formData.productvariationdatafields.forEach((field: any, i: number) => {
        const attributes = {
          name: field.productVarFields.name,
          identifier: field.productVarFields.identifier,
          value: "",
        };
        attributesArr = [...attributesArr, Object.assign({}, attributes)];
      });
      let pv = {};
      if (vd.sku === "free-size") {
        pv = {
          product_variants_id: vd.product_variants_id || "",
          product_id: vd.product_id || "",
          dsin: vd.dsin || "",
          catalog_id: vd.catalog_id || "",
          variation_name: "Free Size",
          variation_value: "Free Size",
          variation_name_sku: "free-size",
          variation_value_sku: "free-size",
          sku: "free-size",
          product_mrp: "",
          seller_products_variants: [
            {
              seller_products_variants_id: vd.seller_products_variants_id || "",
              seller_products_id: vd.seller_products_id || "",
              seller_id: vd.seller_id || "",
              supplier_sku_id: vd.supplier_sku_id || "",
              product_variants_id: vd.product_variants_id || "",
              price: "",
              inventory: "",
              variation_attributes_attri: attributesArr,
              createdAt: vd.createdAt || "",
              seller_product: {
                seller_products_id: vd.seller_product?.seller_products_id || "",
                supplier_product_id:
                  vd.seller_product?.supplier_product_id || "",
                seller: {
                  seller_name: vd.seller_product?.seller?.seller_name || "",
                  shop_name: vd.seller_product?.seller?.shop_name || "",
                  verified: vd.seller_product?.seller?.verified || false,
                  activity_status:
                    vd.seller_product?.seller?.activity_status || false,
                  account_status:
                    vd.seller_product?.seller?.account_status || false,
                },
              },
            },
          ],
        };
      } else {
        pv = {
          product_variants_id: vd.product_variants_id || "",
          product_id: vd.product_id || "",
          dsin: vd.dsin || "",
          catalog_id: vd.catalog_id || "",
          variation_name: "Size",
          variation_value: vd.name || "",
          variation_name_sku: "size",
          variation_value_sku: vd.sku || "",
          sku: `${vd.sku || ""}-${vd.name || ""}`,
          product_mrp: "",
          seller_products_variants: [
            {
              seller_products_variants_id: vd.seller_products_variants_id || "",
              seller_products_id: vd.seller_products_id || "",
              seller_id: vd.seller_id || "",
              supplier_sku_id: vd.supplier_sku_id || "",
              product_variants_id: vd.product_variants_id || "",
              price: "",
              inventory: "",
              variation_attributes_attri: attributesArr,
              createdAt: vd.createdAt || "",
              seller_product: {
                seller_products_id: vd.seller_product?.seller_products_id || "",
                supplier_product_id:
                  vd.seller_product?.supplier_product_id || "",
                seller: {
                  seller_name: vd.seller_product?.seller?.seller_name || "",
                  shop_name: vd.seller_product?.seller?.shop_name || "",
                  verified: vd.seller_product?.seller?.verified || false,
                  activity_status:
                    vd.seller_product?.seller?.activity_status || false,
                  account_status:
                    vd.seller_product?.seller?.account_status || false,
                },
              },
            },
          ],
        };
      }
      pvArr.push(Object.assign({}, pv));
    });

    let pd = product_data;

    if (pd && pd[selectedForm].product_variants) {
      setProductData((prevData: any) => {
        const oldProductVariants = [...prevData[selectedForm].product_variants];
        const productVariants = pvArr.map((item: any) => {
          const existingIndex = oldProductVariants.findIndex(
            (v) => v.variation_value === item.variation_value
          );

          if (existingIndex === -1) {
            return item;
          } else {
            return oldProductVariants[existingIndex];
          }
        });

        prevData[selectedForm].product_variants = productVariants;
        return [...prevData];
      });
    } else {
      console.error("productData or product_variants is null");
    }
  };

  const applyVariations = (e: any) => {
    e.preventDefault();
    setShowSizeOptions(false);
    setVariationsData(selectedFullVariations);
    getVariations(selectedFullVariations);
  };
  const resetVariations = (e: any) => {
    e.preventDefault();
    setShowSizeOptions(false);
    selectVariations([]);
    setVariationsData([]);
    selectFullVariation([]);
    let pd = product_data;
    pd[selectedForm].product_variants = [];
  };

  useEffect(() => {
    if (formData !== null && formData.productsizeinvtfields !== undefined) {
      let arr: any = [];
      if (
        product_data.products_images &&
        product_data.products_images.length > 0
      ) {
        product_data.products_images.forEach((img: any) => {
          let prdct_details_fields: any = [];
          let prdct_size_invt_fields: any = {};
          let prdct_other_fields: any = [];
          formData.productdetailfields.forEach((pd: any) => {
            let field = {
              name: pd.product_details_field.name,
              identifier: pd.product_details_field.identifier,
              value: "",
            };
            prdct_details_fields = [
              ...prdct_details_fields,
              Object.assign({}, field),
            ];
          });
          formData?.productsizeinvtfields.forEach((pd: any) => {
            prdct_size_invt_fields[pd.identifier] = "";
          });
          formData.productotherfields.forEach((pd: any) => {
            let field = {
              name: pd.product_other_field.name,
              identifier: pd.product_other_field.identifier,
              value: "",
            };
            prdct_other_fields = [
              ...prdct_other_fields,
              Object.assign({}, field),
            ];
          });
          let product = {
            category_4_id: formData.category_data.category_4_id,
            type: "submit",
            products_data: {
              productdetailfields: [...prdct_details_fields],
              productsizeinvtfields: prdct_size_invt_fields,
              product_variations: [],
              productotherfields: [...prdct_other_fields],
              image_data: {
                front_image_url: img,
                other_images: [],
              },
            },
          };
          arr = [...arr, Object.assign({}, product)];
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData]);

  const changeForm = (i: any) => {
    if (product_data[i].product_variations?.length > 0) {
      let arr1: any = [],
        arr2: any = [];
      product_data[i].product_variations.forEach((pv: any) => {
        arr1.push(pv.variation_value);
        let x = {
          name: pv.variation_value,
          sku: pv.variation_value_sku,
        };
        arr2.push(x);
      });
      selectVariations((x: any) => [...arr1]);
      selectFullVariation((x: any) => [...arr2]);
      setVariationsData((x: any) => [...arr2]);
      selectForm(i);
      setShowSizeOptions(false);
    } else {
      selectVariations([]);
      selectFullVariation([]);
      setVariationsData([]);
      selectForm(i);
      setShowSizeOptions(false);
    }
  };

  const discardListing = (e: any) => {
    e.preventDefault();
    navigate(-1);
  };

  interface ProductDetailField {
    name: string;
    identifier: string;
    value: string;
  }

  const saveListing = (e: any) => {
    e.preventDefault();
    const token: string | null = localStorage.getItem("token");
    let pr: any = [];
    product_data.forEach((product: any) =>
      pr.push(Object.assign({}, product.products_data))
    );
    var raw = JSON.stringify({
      category_4_id: formData.category_data.category_4_id,
      type: "draft",
      products_data: pr,
    });

    var requestOptions: any = {
      method: "POST",
      headers: {
        token: token,
        "Content-Type": "application/json",
      },
      body: raw,
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_BASE_URL}/prd_lst/submit_product_lst_form`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => toast.info(result?.msg))
      .catch((error) => toast.error("error", error));
  };
  interface ProductDetailAttribute {
    name: string;
    identifier: string;
    value: string;
  }

  interface ProductOtherAttribute {
    name: string;
    identifier: string;
    value: string;
  }

  interface VariationAttribute {
    name: string;
    value: string;
    identifier: string;
  }

  interface ProductVariation {
    variation_value: string;
    variation_name: string;
    variation_name_sku: string;
    variation_value_sku: string;
    inventory: number;
    seller_price: number;
    supplier_sku_id: string;
    product_mrp: number;
    variation_attributes: VariationAttribute[];
  }

  interface ProductImage {
    url: string;
    is_front_img: boolean;
  }

  interface ImageData {
    front_image_url: string;
    other_images: string[];
  }

  interface ProductDataForApi {
    product_id: string;
    dsin: string;
    productdetailfields: ProductDetailAttribute[];
    productsizeinvtfields: {
      product_weight_in_gms: string;
      supplier_product_id: string;
      product_name: string;
      comment: string;
    };
    product_variations: ProductVariation[];
    productotherfields: ProductOtherAttribute[];
    image_data: ImageData;
  }

  const productdetailfieldsName: ProductDetailField[] = [
    { name: "Fabric", identifier: "fabric", value: "" },
    { name: "Fit/Shape", identifier: "fit_shape", value: "" },
    { name: "Net Quantity (N)", identifier: "multipack", value: "" },
    { name: "Neck", identifier: "neck", value: "" },
    { name: "Occasion", identifier: "occasion", value: "" },
    { name: "Pattern", identifier: "pattern", value: "" },
    {
      name: "Print Or Pattern Type",
      identifier: "print_or_pattern_type",
      value: "",
    },
    { name: "Sleeve Length", identifier: "sleeve_length", value: "" },
    { name: "COUNTRY OF ORIGIN", identifier: "country_of_origin", value: "" },
    {
      name: "Manufacturer Details",
      identifier: "manufacturer_details",
      value: "",
    },
    { name: "Packer Details", identifier: "packer_details", value: "" },
  ];

  const mapProductDataForApi = (productData: any[]): ProductDataForApi[] => {
    if (!productData || !Array.isArray(productData)) {
      console.error("Invalid productData:", productData);
      return [];
    }
    return productData.map((product) => {
      const {
        product_id,
        dsin,
        product_details_attr,
        other_details_attri,
        product_variants,
        products_images,
        product_weight_in_gms,
        product_name_n,
        product_description_n,
      } = product;

      // Map product details attributes
      const productdetailfields: ProductDetailField[] =
        productdetailfieldsName.map((field, index) => {
          const value = product_details_attr[index];

          // Check if the value is an object, if yes, extract the 'value' property
          field.value = typeof value === "object" ? value.value : value;

          return field;
        });

      // Map other details attributes
      const productotherfields: ProductOtherAttribute[] =
        other_details_attri.map(({ name, identifier, value }: any) => ({
          name,
          identifier,
          value,
        }));

      console.log({ product_variants });
      // Map product variants
      const product_variations: ProductVariation[] = product_variants.map(
        (variant: any) => {
          const {
            variation_value,
            variation_name,
            variation_name_sku,
            variation_value_sku,

            product_mrp,
            seller_products_variants,
          } = variant;

          // Default values in case seller_products_variants or its elements are undefined
          let seller_price = "";
          let supplier_sku_id = "";
          let inventory = "";

          // Check if seller_products_variants is an array with at least one element
          if (
            seller_products_variants &&
            Array.isArray(seller_products_variants) &&
            seller_products_variants.length > 0
          ) {
            const firstVariant = seller_products_variants[0];

            // Extract values from the first element of seller_products_variants
            seller_price = firstVariant.price || "";
            supplier_sku_id = firstVariant.supplier_sku_id || "";
            inventory = firstVariant.inventory || "";
          }

          // Map variation attributes
          const variation_attributes: VariationAttribute[] =
            seller_products_variants[0].variation_attributes_attri.map(
              ({ name, value, identifier }: any) => ({
                name,
                value,
                identifier,
              })
            );

          return {
            variation_value,
            variation_name,
            variation_name_sku,
            variation_value_sku,
            inventory,
            seller_price,
            supplier_sku_id,
            product_mrp,
            variation_attributes,
          };
        }
      );

      // Map product images
      const image_data: ImageData = {
        front_image_url:
          products_images.find((image: any) => image.is_front_img)?.url || "",
        other_images: products_images
          .filter((image: any) => !image.is_front_img)
          .map((image: any) => image.url),
      };

      return {
        product_id,
        dsin,
        productdetailfields,
        productsizeinvtfields: {
          product_weight_in_gms,
          supplier_product_id: dsin,
          product_name: product_name_n,
          comment: product_description_n,
        },
        product_variations,
        productotherfields,
        image_data,
      };
    });
  };

  const submitListing = (e: any) => {
    e.preventDefault();
    const token: string | null = localStorage.getItem("token");

    // Map product data to the desired format
    const mappedProductData = mapProductDataForApi(product_data);

    var raw = JSON.stringify({
      category_4_id: formData.category_data.category_4_id,
      type: "submit",
      catalog_id: catelogId,
      products_data: mappedProductData,
    });

    var requestOptions: any = {
      method: "POST",
      headers: {
        token: token,
        "Content-Type": "application/json",
      },
      body: raw,
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_BASE_URL}/prd_lst/edit_draft_catalog`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === "success") {
          toast.success(result.msg);
          reset();
          navigate("/apps/AddProduct");
        } else {
          toast.error(result.msg);
        }
      })
      .catch((error) => console.log("error", error));
  };

  const handleInputFileChange = (event: any) => {
    const objectURL = URL.createObjectURL(event.target.files[0]);
    let img = new Image();
    img.src = objectURL;
    img.onload = function () {
      if (img.width === 800 || img.height === 800) {
        var formdata = new FormData();
        formdata.append("file", event.target.files[0], objectURL);

        var requestOptions: RequestInit = {
          method: "POST",
          body: formdata,
          redirect: "follow",
        };

        fetch(
          "https://marketplaceapi.quadbtech.com/api/v1/testing/upload_image_prd_lst",
          requestOptions
        )
          .then((response) => response.json())
          .then((result) => {
            if (result.status === "success") {
              let pd = [...product_data];
              let current_product_data = pd[selectedForm];
              let imagesArr = [...current_product_data.products_images];
              imagesArr.push(result);
              current_product_data.products_images = imagesArr;
              setProductData((product_data: any) => [...pd]);
            }
          })
          .catch((error) => console.log("error", error));
      } else {
        window.alert("Image should be 800 x 800 px only.");
      }
    };
  };

  const handleInputFileChangeNewProduct = (event: any) => {
    const objectURL = URL.createObjectURL(event.target.files[0]);
    let img = new Image();
    img.src = objectURL;
    img.onload = function () {
      if (img.width === 800 || img.height === 800) {
        var formdata = new FormData();
        formdata.append("file", event.target.files[0], objectURL);

        var requestOptions: RequestInit = {
          method: "POST",
          body: formdata,
          redirect: "follow",
        };

        fetch(
          "https://marketplaceapi.quadbtech.com/api/v1/testing/upload_image_prd_lst",
          requestOptions
        )
          .then((response) => response.json())
          .then((result) => {
            if (result.status === "success") {
              setProductData((prevData: any) => {
                const updatedProductsImages =
                  NEWPRODUCTTEMPLATEDATA.products_images.map((image) => {
                    if (image.is_front_img) {
                      return { ...image, url: result.url };
                    }
                    return image;
                  });
                const updatedTemplate = {
                  ...NEWPRODUCTTEMPLATEDATA,
                  products_images: updatedProductsImages,
                  product_details_attr: formData.productdetailfields.map(
                    (item: ProductDetailAttribute) => {
                      return {
                        name: item.name,
                        value: "",
                        identifier: item.identifier,
                      };
                    }
                  ),
                  other_details_attri: formData.productotherfields.map(
                    (item: ProductOtherAttribute) => {
                      return {
                        name: item.name,
                        value: "",
                        identifier: item.identifier,
                      };
                    }
                  ),
                };
                return [...prevData, updatedTemplate];
              });
            }
          })
          .catch((error) => console.log("error", error));
      } else {
        window.alert("Image should be 800 x 800 px only.");
      }
    };
  };

  const handleDeleteClick = async () => {
    if (
      !product_data[selectedForm]?.product_id &&
      !product_data[selectedForm]?.dsin
    ) {
      const updatedProductData = [...product_data];
      updatedProductData.splice(selectedForm, 1);

      setProductData(updatedProductData);
      selectForm(0);
    } else {
      const token = localStorage.getItem("token") || "";
      const url = `${process.env.REACT_APP_BASE_URL}/prd_lst/delete_catalog_prdct`;

      const payload = {
        product_id: product_data[selectedForm]?.product_id,
        dsin: product_data[selectedForm]?.dsin,
        catalog_id: catelogId,
      };

      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("token", token);

      try {
        const response = await fetch(url, {
          method: "POST",
          headers: myHeaders,
          body: JSON.stringify(payload),
        });

        if (!response.ok) {
          throw new Error("Failed to delete product");
        }

        const updatedProductData = [...product_data];
        updatedProductData.splice(selectedForm, 1);

        setProductData(updatedProductData);

        toast.success("Product deleted successfully");
      } catch (error) {
        toast.error("Failed to delete product");
        console.error(error);
      }
    }
  };

  const handleImageChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const file = event.target.files?.[0];

    if (file) {
      const objectURL = URL.createObjectURL(file);

      let img = new Image();
      img.src = objectURL;

      img.onload = function () {
        if (img.width === 800 && img.height === 800) {
          var formdata = new FormData();
          formdata.append("file", file, objectURL);

          var requestOptions: RequestInit = {
            method: "POST",
            body: formdata,
            redirect: "follow",
          };

          fetch(
            "https://marketplaceapi.quadbtech.com/api/v1/testing/upload_image_prd_lst",
            requestOptions
          )
            .then((response) => response.json())
            .then((result) => {
              if (result.status === "success") {
                setProductData((prevProductData: any) => {
                  const frontImageIndex = prevProductData[
                    selectedForm
                  ]?.products_images.findIndex((img: any) => img.is_front_img);

                  console.log({ frontImageIndex });

                  if (frontImageIndex !== -1) {
                    alert("bro");
                    prevProductData[selectedForm].products_images[
                      frontImageIndex
                    ].url = result.url;
                    return [...prevProductData];
                  } else {
                    console.error(
                      "Front image not found in products_images array"
                    );
                    toast.error(
                      "Front image not found in products_images array"
                    );
                    return prevProductData;
                  }
                });
              }
            })
            .catch((error) => console.log("error", error));
        } else {
          window.alert("Image should be 800 x 800 px only.");
        }
      };
    }
  };

  return formData && !loading && product_data.length > 0 ? (
    <>
      <div className="d-flex align-items-center gap-2 overflow-auto">
        {product_data.map((product: any, i: number) => (
          <div key={i} role="button" onClick={() => changeForm(i)}>
            <div className="product-main-image">
              <div className="w-100 h-100 border border-secondary rounded-1 bg-light position-relative">
                <img
                  src={
                    product?.products_images?.find(
                      (img: any) => img.is_front_img
                    )?.url || ""
                  }
                  className="w-100 h-100"
                  alt=""
                />
              </div>
            </div>
            <div
              className={`text-center ${
                selectedForm === i
                  ? "bg-success text-white"
                  : "bg-light text-black"
              }`}
            >
              <b>Product {i + 1}</b>
            </div>
          </div>
        ))}
        <div
          role="button"
          onClick={() => imageInputRefNewProduct.current.click()}
        >
          <div className="product-main-image">
            <div className="w-100 h-100 border border-primary rounded-1 d-flex justify-content-center align-items-center">
              <img src={plusIcon} className="w-50" alt="" />
            </div>
          </div>
          <div className={`text-center bg-light text-black`}>
            <b>Add new Product</b>
            <input
              type="file"
              className="d-none"
              ref={imageInputRefNewProduct}
              onChange={handleInputFileChangeNewProduct}
            />
          </div>
        </div>{" "}
      </div>
      <form>
        <h4 className="text-base font-semibold bg-light p-2 rounded">
          Product Images
        </h4>
        <div className="d-flex align-items-center gap-2 overflow-auto">
          <div>
            <div className="product-main-image">
              <div className="w-100 h-100 border border-secondary rounded-1 bg-light position-relative">
                <img
                  src={
                    product_data[selectedForm]?.products_images?.find(
                      (img: any) => img.is_front_img
                    )?.url || ""
                  }
                  className="w-100 h-100"
                  alt=""
                />
              </div>
            </div>
            <div className={`text-center bg-light text-black`}>
              <b>Main Image</b>
            </div>
            <div className="text-center">
              <label
                htmlFor="changeImage"
                className="bg-secondary fs-6 fs-md-4 fw-bold p-1/2 text-white cursor-pointer rounded-pill px-1"
              >
                Change
              </label>
              <input
                type="file"
                id="changeImage"
                className="visually-hidden"
                onChange={handleImageChange}
              />
            </div>
          </div>
          {product_data[selectedForm]?.products_images
            .filter((item: any) => !item.is_front_img)
            .map((img: any, i: number) => (
              <div key={i}>
                <div className="product-main-image">
                  <div className="w-100 h-100 border border-secondary rounded-1 bg-light position-relative">
                    <img src={img.url} className="w-100 h-100" alt="" />
                  </div>
                </div>
                <div className={`text-center bg-light text-black`}>
                  <b>Image {i + 1}</b>
                </div>
              </div>
            ))}
          <div role="button" onClick={() => imageInputRef.current.click()}>
            <div className="product-main-image">
              <div className="w-100 h-100 border border-primary rounded-1 d-flex justify-content-center align-items-center">
                <img src={plusIcon} className="w-50" alt="" />
              </div>
            </div>
            <div className={`text-center bg-light text-black`}>
              <b>Add Image</b>
              <input
                type="file"
                className="d-none"
                ref={imageInputRef}
                onChange={handleInputFileChange}
              />
            </div>
          </div>
        </div>
        {Array.isArray(product_data) && product_data.length > 1 && (
          <div className="d-flex justify-content-end mt-3">
            <button className="btn btn-danger" onClick={handleDeleteClick}>
              <FaTrash className="mr-2" /> Delete this product from catalog
            </button>
          </div>
        )}
        <h4 className="text-base font-semibold bg-light p-2 rounded">
          Product, Size and Inventory
        </h4>
        <div className="d-flex align-items-center flex-wrap mb-3">
          {formData &&
            formData.productsizeinvtfields.map((field: any) => (
              <GetFormInput
                key={field.product_size_inventory_fields_id}
                id={field.product_size_inventory_fields_id}
                name={field.name}
                identifier={field.identifier}
                type={field.input_types}
                required={field.required}
                description={field.description}
                placeholder={field.placeholder}
                options={field.options || []}
                value={
                  product_data[selectedForm] &&
                  product_data[selectedForm][getFieldKey(field.identifier)]
                }
                changeValue={(e: any) => {
                  let pd = product_data;
                  pd[selectedForm][getFieldKey(field.identifier)] = e;
                  setProductData((productData: any) => [...pd]);
                }}
              />
            ))}
          <div className="w-50 px-2 py-1">
            <div className="d-flex align-items-center">
              <h5>Size</h5>
            </div>
            <div
              className="w-100 border border-secondary p-1"
              role="button"
              onClick={() => {
                setShowSizeOptions((showSizeOptions) => !showSizeOptions);
              }}
            >
              Select Size
            </div>
            <div
              className={`position-relative w-100 ${
                showSizeOptions ? "" : "d-none"
              }`}
            >
              <div className="border border-secondary position-absolute w-100 bg-white zindex-top overflow-auto"                  style={{ maxHeight: "200px" }}
>
                <div className="d-flex align-items-center flex-wrap ">
                  <div className="m-2 d-flex align-items-center">
                    <input
                      type="checkbox"
                      checked={selectedVariations.includes(
                        formData?.productvariationsfields[1]?.variation.name ||
                          ""
                      )}
                      onChange={() =>
                        toggleVariation(
                          formData.productvariationsfields[1].variation
                        )
                      }
                    />
                    <h6 className="ms-1">
                      {formData.productvariationsfields[1]?.variation.name ||
                        ""}
                    </h6>
                  </div>
                  {formData.productvariationsfields[0].variation.variation_values.map(
                    (variation: any) => (
                      <div
                        className="m-2 d-flex align-items-center"
                        key={variation.name}
                      >
                        <input
                          type="checkbox"
                          checked={selectedVariations.includes(variation.name)}
                          onChange={() => toggleVariation(variation)}
                        />
                        <h6 className="m-2">{variation.name}</h6>
                      </div>
                    )
                  )}
                </div>
                <div className="d-flex flex-column flex-sm-row gap-1 px-1 mb-2">
                  <button
                    className="btn btn-danger w-100"
                    onClick={(e) => resetVariations(e)}
                  >
                    Reset
                  </button>
                  <button
                    className="btn btn-primary w-100"
                    onClick={(e) => applyVariations(e)}
                  >
                    Apply
                  </button>
                </div>
              </div>
            </div>
          </div>
          {variationsData.length > 0 && (
            <div className="w-100 px-2 py-1 overflow-auto">
              <table className="table table-bordered ">
                <thead>
                  <tr>
                    <th>Size</th>
                    {formData.productvariationdatafields.map(
                      (field: any, i: number) => (
                        <th className="" key={i}>
                          {field.productVarFields.name}
                        </th>
                      )
                    )}
                    {formData.productpricetaxfields.map(
                      (field: any, i: number) => (
                        <th className="" key={i}>
                          {field.name}
                        </th>
                      )
                    )}
                  </tr>
                </thead>
                <tbody>
                  {product_data[selectedForm].product_variants.length > 0 &&
                    product_data[selectedForm].product_variants.map(
                      (variation: any, i: number) => {
                        return (
                          <tr key={i}>
                            <td className="text-center font-weight-bold">
                              {variation.variation_value}
                            </td>
                            {formData.productvariationdatafields.map(
                              (field: any, j: number) => (
                                <td key={j}>
                                  <select
                                    className="w-100"
                                    name={field.productVarFields.name}
                                    id={field.productVarFields.identifier}
                                    value={
                                      variation?.seller_products_variants?.[0]
                                        ?.variation_attributes_attri?.[j]?.value
                                    }
                                    onChange={(e: any) => {
                                      setProductData((prevProductData: any) => {
                                        const updatedProductData = [
                                          ...prevProductData,
                                        ];
                                        // Assuming product_data is a clone of the state that you want to modify
                                        const product = {
                                          ...updatedProductData[selectedForm],
                                        };

                                        // Update the specific values based on your structure
                                        product.product_variants[
                                          i
                                        ].seller_products_variants[0].variation_attributes_attri[
                                          j
                                        ].value = e.target.value;

                                        // Update the specific element in the array
                                        updatedProductData[selectedForm] =
                                          product;

                                        return updatedProductData;
                                      });
                                    }}
                                  >
                                    <option value="">Select</option>
                                    {field.productVarFields.options.map(
                                      (value: any) => (
                                        <option value={value} key={value}>
                                          {value}
                                        </option>
                                      )
                                    )}
                                  </select>
                                </td>
                              )
                            )}
                            {formData.productpricetaxfields.map(
                              (field: any, k: number) => (
                                <td className="" key={k}>
                                  <GetTableFormInput
                                    id={field.identitfier}
                                    name={field.name}
                                    identifier={field.identifier}
                                    type={field.input_types}
                                    required={field.required}
                                    placeholder={field.placeholder}
                                    value={
                                      field.identifier === "inventory"
                                        ? (
                                            product_data[selectedForm]
                                              .product_variants?.[i]
                                              ?.seller_products_variants?.[0] as any
                                          )?.inventory || ""
                                        : field.identifier === "supplier_sku_id"
                                        ? (
                                            product_data[selectedForm]
                                              .product_variants?.[i]
                                              ?.seller_products_variants?.[0] as any
                                          )?.supplier_sku_id || ""
                                        : field.identifier === "product_mrp"
                                        ? (product_data[selectedForm]
                                            ?.product_variants?.[i]
                                            .product_mrp as any)
                                        : field.identifier === "seller_price"
                                        ? (product_data[selectedForm]
                                            ?.product_variants?.[i]
                                            .seller_products_variants?.[0]
                                            ?.price as any)
                                        : (
                                            product_data[selectedForm]
                                              ?.product_variants?.[i]
                                              .seller_products_variants?.[0] as any
                                          )[field.identifier]
                                    }
                                    changeValue={(e: any) => {
                                      setProductData((prevProductData: any) => {
                                        const updatedProductData = [
                                          ...prevProductData,
                                        ];

                                        if (
                                          !updatedProductData[selectedForm]
                                            ?.product_variants
                                        ) {
                                          updatedProductData[
                                            selectedForm
                                          ].product_variants = [];
                                        }

                                        if (
                                          !updatedProductData[selectedForm]
                                            ?.product_variants[i]
                                        ) {
                                          updatedProductData[
                                            selectedForm
                                          ].product_variants[i] = {};
                                        }

                                        if (
                                          field.identifier === "product_mrp"
                                        ) {
                                          updatedProductData[
                                            selectedForm
                                          ].product_variants[i].product_mrp = e;
                                        } else if (
                                          field.identifier === "seller_price"
                                        ) {
                                          if (
                                            !updatedProductData[selectedForm]
                                              ?.product_variants[i]
                                              .seller_products_variants
                                          ) {
                                            updatedProductData[
                                              selectedForm
                                            ].product_variants[
                                              i
                                            ].seller_products_variants = [];
                                          }

                                          if (
                                            !updatedProductData[selectedForm]
                                              ?.product_variants[i]
                                              .seller_products_variants[0]
                                          ) {
                                            updatedProductData[
                                              selectedForm
                                            ].product_variants[
                                              i
                                            ].seller_products_variants[0] = {};
                                          }

                                          updatedProductData[
                                            selectedForm
                                          ].product_variants[
                                            i
                                          ].seller_products_variants[0].price =
                                            e;
                                        } else {
                                          set(
                                            updatedProductData[selectedForm],
                                            `product_variants[${i}].seller_products_variants[0].${field.identifier}`,
                                            e
                                          );
                                        }

                                        return updatedProductData;
                                      });
                                    }}
                                  />
                                </td>
                              )
                            )}
                          </tr>
                        );
                      }
                    )}
                </tbody>
              </table>
            </div>
          )}
        </div>
        <h4 className="text-base font-semibold bg-light p-2 rounded">
          Product Details
        </h4>
        <div className="d-flex align-items-center flex-wrap mb-3">
          {formData.productdetailfields.map((field: any, i: number) => (
            <GetFormInput
              key={field.product_details_fields_id}
              id={field.product_details_fields_id}
              name={field.product_details_field.name}
              identifier={field.product_details_field.identifier}
              type={field.product_details_field.input_types}
              required={field.product_details_field.required}
              description={field.product_details_field.description}
              placeholder={field.product_details_field.placeholder}
              options={field.product_details_field.options || []}
              value={
                (product_data[selectedForm] &&
                  product_data[selectedForm].product_details_attr[i]?.value) ||
                ""
              }
              changeValue={(e: any) => {
                let pd = product_data;
                pd[selectedForm].product_details_attr[i].value = e;
                setProductData((productData: any) =>
                  JSON.parse(JSON.stringify([...pd]))
                );
              }}
            />
          ))}
        </div>
        <h4 className="text-base font-semibold bg-light p-2 rounded">
          Other Attributes
        </h4>
        <div className="d-flex align-items-center flex-wrap mb-3">
          {formData.productotherfields.map((field: any, i: number) => (
            <GetFormInput
              key={field.product_other_fields_id}
              id={field.product_other_fields_id}
              name={field.product_other_field.name}
              identifier={field.product_other_field.identifier}
              type={field.product_other_field.input_types}
              required={field.product_other_field.required}
              description={field.product_other_field.description}
              placeholder={field.product_other_field.placeholder}
              options={field.product_other_field.options || []}
              value={
                (product_data[selectedForm] &&
                  product_data[selectedForm].other_details_attri[i]?.value) ||
                ""
              }
              changeValue={(e: any) => {
                let pd = product_data;
                pd[selectedForm].other_details_attri[i].value = e;
                setProductData((productData: any) =>
                  JSON.parse(JSON.stringify([...pd]))
                );
              }}
            />
          ))}
        </div>
        <div className="d-flex justify-content-center align-items-center gap-3 flex-wrap">
          <button
            className="btn btn-danger fs-4 px-4 fw-bolder"
            onClick={(e) => discardListing(e)}
          >
            Discard Listing
          </button>
          <button
            className="btn btn-primary fs-4 px-4 fw-bolder"
            onClick={(e) => saveListing(e)}
          >
            Save Listing
          </button>
          <button
            className="btn btn-success fs-4 px-4 fw-bolder"
            onClick={(e) => submitListing(e)}
          >
            Submit Listing
          </button>
        </div>
      </form>
    </>
  ) : (
    <div>
      {loading && <TwoBallsLoader />}
      {!loading && !product_data ? (
        <Alert variant="danger" className="text-center">
          <Alert.Heading className="text-2xl font-bold mb-4">
            Product Not Found
          </Alert.Heading>
          <p className="text-gray-600 mb-6">
            We're sorry, but the product you are looking for could not be found.
            Please check the details and try again.
          </p>
          <span role="img" aria-label="Emoji" className="text-4xl mb-2">
            😞
          </span>
          <img
            src={noContent}
            alt="no content"
            className="w-16 h-16 mx-auto mt-4"
          />
          <hr />
          <div className="d-flex justify-content-end">
            <Button
              variant="outline-danger"
              className="px-4 py-2"
              onClick={() => {
                navigate(-1);
              }}
            >
              Go Back
            </Button>
          </div>
        </Alert>
      ) : null}
    </div>
  );
};

export default EditProductDetails;
