interface Order {
  products: string;
  price: string;
  img: string;
}

const orderData: Order[] = [
  {
    products:
      'ASUS ROG Zephyrus Duo 16 Dual Screen Laptop, 16" (40.64 cm)UHD+/FHD+ 120Hz/240Hz/3ms, AMD Ryzen 9 6900HX, 16GB RTX 3080 Ti,Gaming Laptop (32GB/2TB SSD/Win 11/Office/Black/2.55 Kg),GX650RXZ-LB226WS',
    price: "RS 56,000",
    img: "https://marketplace.quadbtech.com/static/media/dress7.2615f9ee5969d980bb6d.png",
  },
  {
    products:
      'ASUS ROG Zephyrus Duo 16 Dual Screen Laptop, 16" (40.64 cm)UHD+/FHD+ 120Hz/240Hz/3ms, AMD Ryzen 9 6900HX, 16GB RTX 3080 Ti,Gaming Laptop (32GB/2TB SSD/Win 11/Office/Black/2.55 Kg),GX650RXZ-LB226WS',
    price: "RS 58,000",
    img: "https://marketplace.quadbtech.com/static/media/dress7.2615f9ee5969d980bb6d.png",
  },
];

const tableData = [
  {
    id: 1,
    status: "PENDING",
    products:
      'ASUS ROG Zephyrus Duo 16 Dual Screen Laptop, 16" (40.64 cm) UHD+/FHz...',
    customer: "MR.ABC",
    date: "12/2/2018",
    price: "RS 56.000",
    img: "https://marketplace.quadbtech.com/static/media/dress7.2615f9ee5969d980bb6d.png",
  },
  {
    id: 2,
    status: "PENDING",
    products:
      'ASUS ROG Zephyrus Duo 16 Dual Screen Laptop, 16" (40.64 cm) UHD+/FHz...',
    customer: "MR.ABC",
    date: "12/2/2018",
    price: "RS 56.000",
    img: "https://marketplace.quadbtech.com/static/media/dress7.2615f9ee5969d980bb6d.png",
  },
  {
    id: 3,
    status: "PENDING",
    products:
      'ASUS ROG Zephyrus Duo 16 Dual Screen Laptop, 16" (40.64 cm) UHD+/FHz...',
    customer: "MR.ABC",
    date: "12/2/2018",
    price: "RS 56.000",
    img: "https://marketplace.quadbtech.com/static/media/dress7.2615f9ee5969d980bb6d.png",
  },
  {
    id: 4,
    status: "PENDING",
    products:
      'ASUS ROG Zephyrus Duo 16 Dual Screen Laptop, 16" (40.64 cm) UHD+/FHz...',
    customer: "MR.ABC",
    date: "12/2/2018",
    price: "RS 56.000",
    img: "https://marketplace.quadbtech.com/static/media/dress7.2615f9ee5969d980bb6d.png",
  },
  {
    id: 5,
    status: "PENDING",
    products:
      'ASUS ROG Zephyrus Duo 16 Dual Screen Laptop, 16" (40.64 cm) UHD+/FHz...',
    customer: "MR.ABC",
    date: "12/2/2018",
    price: "RS 56.000",
    img: "https://marketplace.quadbtech.com/static/media/dress7.2615f9ee5969d980bb6d.png",
  },
  {
    id: 6,
    status: "PENDING",
    products:
      'ASUS ROG Zephyrus Duo 16 Dual Screen Laptop, 16" (40.64 cm) UHD+/FHz...',
    customer: "MR.ABC",
    date: "12/2/2018",
    price: "RS 56.000",
    img: "https://marketplace.quadbtech.com/static/media/dress7.2615f9ee5969d980bb6d.png",
  },
];

const downarrow = (
  <svg
    width="23"
    height="13"
    viewBox="0 0 23 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.6635 12.5235C12.3549 12.8286 11.9364 13 11.5 13C11.0636 13 10.6451 12.8286 10.3365 12.5235L0.46198 2.75822C0.162195 2.45126 -0.00368749 2.04014 6.22135e-05 1.6134C0.00381191 1.18666 0.176893 0.778456 0.482028 0.476696C0.787163 0.174937 1.19994 0.00376975 1.63145 6.15254e-05C2.06295 -0.0036467 2.47867 0.160401 2.78907 0.456871L11.5 9.07146L20.2109 0.456871C20.5213 0.160401 20.937 -0.0036467 21.3686 6.15254e-05C21.8001 0.00376975 22.2128 0.174937 22.518 0.476696C22.8231 0.778456 22.9962 1.18666 22.9999 1.6134C23.0037 2.04014 22.8378 2.45126 22.538 2.75822L12.6635 12.5235Z"
      fill="#1E1E1E"
    />
  </svg>
);
const downarrowsm = (
  <svg
    width="13"
    height="13"
    viewBox="0 0 23 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.6635 12.5235C12.3549 12.8286 11.9364 13 11.5 13C11.0636 13 10.6451 12.8286 10.3365 12.5235L0.46198 2.75822C0.162195 2.45126 -0.00368749 2.04014 6.22135e-05 1.6134C0.00381191 1.18666 0.176893 0.778456 0.482028 0.476696C0.787163 0.174937 1.19994 0.00376975 1.63145 6.15254e-05C2.06295 -0.0036467 2.47867 0.160401 2.78907 0.456871L11.5 9.07146L20.2109 0.456871C20.5213 0.160401 20.937 -0.0036467 21.3686 6.15254e-05C21.8001 0.00376975 22.2128 0.174937 22.518 0.476696C22.8231 0.778456 22.9962 1.18666 22.9999 1.6134C23.0037 2.04014 22.8378 2.45126 22.538 2.75822L12.6635 12.5235Z"
      fill="#1E1E1E"
    />
  </svg>
);
const rightarrow = (
  <svg
    width="13"
    height="23"
    viewBox="0 0 13 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.5235 10.3365C12.8286 10.6451 13 11.0636 13 11.5C13 11.9364 12.8286 12.3549 12.5235 12.6635L2.75822 22.538C2.45126 22.8378 2.04014 23.0037 1.6134 22.9999C1.18666 22.9962 0.778456 22.8231 0.476696 22.518C0.174937 22.2128 0.00376977 21.8001 6.15454e-05 21.3686C-0.00364668 20.937 0.160401 20.5213 0.456871 20.2109L9.07146 11.5L0.456871 2.78907C0.160401 2.47867 -0.00364644 2.06296 6.1788e-05 1.63145C0.00377002 1.19994 0.174937 0.787163 0.476697 0.482029C0.778456 0.176893 1.18666 0.0038128 1.6134 6.29612e-05C2.04014 -0.00368688 2.45126 0.162195 2.75822 0.461981L12.5235 10.3365Z"
      fill="#1E1E1E"
    />
  </svg>
);

interface Orderdetails {
  sku: string;
  productId: string;
  date: Date;
  id: number;
  name: string;
  email: string;
}

const orderDetails: Orderdetails[] = [
  {
    id: 1,
    sku: "ABC123",
    productId: "12345",
    date: new Date("2022-01-01"),
    name: "john deo",
    email: "johndeo@example.com",
  },
  {
    id: 2,
    sku: "DEF456",
    productId: "67890",
    date: new Date("2022-02-01"),
    name: "Olivia",
    email: "olivia@example.com",
  },
  // {
  //   id: 3,
  //   sku: "GHI789",
  //   productId: "24680",
  //   date: new Date("2022-03-01"),
  //   name: "saurabh",
  //   email: "saurabh@example.com",
  // },
  // {
  //   id: 4,
  //   sku: "RHI709",
  //   productId: "54380",
  //   date: new Date("2022-04-01"),
  //   name: "Carter",
  //   email: "Carter@example.com",
  // },
];
// const orderDetails: OrdersItemTypes[] = [
//   {
//     vertical: "ReadyMeals",
//     brand: "XYZ123",
//     skuid: "XYZ123",
//     createdon: "Nov 28,  2019 10:59 AM",
//     lastprocess: "Nov 10,  2019 10:59 AM",
//     status: "QC in Progress",
//     action: "Delete",
//   },
//   {
//     vertical: "ReadyMeals",
//     brand: "XYZ123",
//     skuid: "XYZ123",
//     createdon: "Nov 28,  2019 10:59 AM",
//     lastprocess: "Nov 10,  2019 10:59 AM",
//     status: "QC in Progress",
//     action: "Delete",
//   },
//   {
//     vertical: "ReadyMeals",
//     brand: "XYZ123",
//     skuid: "XYZ123",
//     createdon: "Nov 28,  2019 10:59 AM",
//     lastprocess: "Nov 10,  2019 10:59 AM",
//     status: "QC in Progress",
//     action: "Delete",
//   },
//   {
//     vertical: "ReadyMeals",
//     brand: "XYZ123",
//     skuid: "XYZ123",
//     createdon: "Nov 28,  2019 10:59 AM",
//     lastprocess: "Nov 10,  2019 10:59 AM",
//     status: "QC in Progress",
//     action: "Delete",
//   },

//   // {
//   //   vertical:4,
//   //   brand: 98751,
//   //   skuid: 345,
//   //   createdon:345,
//   //   lastprocess: 345,
//   //   status: "Delivered",
//   // },
//   // {
//   //   vertical:5,
//   //   brand: 98750,
//   //   skuid: 345,
//   //   createdon: 345,
//   //   lastprocess: 345,
//   //   status: "Declined",
//   // },
// ];

export { orderData, downarrow,downarrowsm, rightarrow, tableData, orderDetails };
