import React, { Suspense, useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Container, Row, Col, Form } from "react-bootstrap";
import talkicon from "../assets/images/talkicon.png";

// actions
import { changeSidebarType } from "../redux/actions";

// store
import { RootState, AppDispatch } from "../redux/store";

// constants
import { LayoutTypes, SideBarTypes } from "../constants";

// utils
import { changeBodyAttribute } from "../utils";
import RightSidebarNew from "./RightSidebarNew";
import BackToTop from "../components/BackToTop";


const Topbar = React.lazy(() => import("./Topbar"));
const LeftSidebar = React.lazy(() => import("./LeftSidebar"));
const Footer = React.lazy(() => import("./Footer"));
const RightSidebar = React.lazy(() => import("./RightSidebar"));

const loading = () => <div className=""></div>;

interface VerticalLayoutProps {
  children?: any;
}

const VerticalLayout = ({ children }: VerticalLayoutProps) => {
  const dispatch = useDispatch<AppDispatch>();

  const {
    layoutWidth,
    menuPosition,
    leftSideBarTheme,
    leftSideBarType,
    showSidebarUserInfo,
    showTwoToneIcons,
    topbarTheme,
    isOpenRightSideBar,
  } = useSelector((state: RootState) => ({
    layoutWidth: state.Layout.layoutWidth,
    menuPosition: state.Layout.menuPosition,
    leftSideBarTheme: state.Layout.leftSideBarTheme,
    leftSideBarType: state.Layout.leftSideBarType,
    showSidebarUserInfo: state.Layout.showSidebarUserInfo,
    showTwoToneIcons: state.Layout.showTwoToneIcons,
    topbarTheme: state.Layout.topbarTheme,
    isOpenRightSideBar: state.Layout.isOpenRightSideBar,
  }));

  const [isMenuOpened, setIsMenuOpened] = useState<boolean>(false);

  const options = [
    { label: "Option 1", value: "option1" },
    { label: "Option 2", value: "option2" },
    { label: "Option 3", value: "option3" },
  ];

  /*
    layout defaults
    */
  useEffect(() => {
    changeBodyAttribute("data-layout-mode", LayoutTypes.LAYOUT_VERTICAL);
  }, []);

  useEffect(() => {
    changeBodyAttribute("data-layout-width", layoutWidth);
  }, [dispatch, layoutWidth]);

  useEffect(() => {
    changeBodyAttribute("data-layout-menu-position", menuPosition);
  }, [menuPosition]);

  useEffect(() => {
    changeBodyAttribute("data-sidebar-color", leftSideBarTheme);
  }, [leftSideBarTheme]);

  useEffect(() => {
    changeBodyAttribute("data-sidebar-size", leftSideBarType);
  }, [leftSideBarType]);

  useEffect(() => {
    changeBodyAttribute("data-topbar-color", topbarTheme);
  }, [topbarTheme]);

  useEffect(() => {
    changeBodyAttribute(
      "data-sidebar-icon",
      showTwoToneIcons ? "twotones" : ""
    );
  }, [showTwoToneIcons]);

  useEffect(() => {
    changeBodyAttribute("data-sidebar-showuser", showSidebarUserInfo);
  }, [showSidebarUserInfo]);

  /**
   * Open the menu when having mobile screen
   */
  const openMenu = () => {
    setIsMenuOpened((prevState) => !prevState);

    if (document.body) {
      if (isMenuOpened) {
        document.body.classList.remove("sidebar-enable");
      } else {
        document.body.classList.add("sidebar-enable");
      }
    }
  };

  const updateDimensions = useCallback(() => {
    // activate the condensed sidebar if smaller devices like ipad or tablet
    if (window.innerWidth > 768 && window.innerWidth <= 1028) {
      dispatch(changeSidebarType(SideBarTypes.LEFT_SIDEBAR_TYPE_CONDENSED));
    } else if (window.innerWidth > 1028) {
      dispatch(changeSidebarType(SideBarTypes.LEFT_SIDEBAR_TYPE_DEFAULT));
    }
  }, [dispatch]);

  useEffect(() => {
    window.addEventListener("resize", updateDimensions);

    return () => {
      window.removeEventListener("resize", updateDimensions);
    };
  }, [dispatch, updateDimensions]);

  const isCondensed: boolean =
    leftSideBarType === SideBarTypes.LEFT_SIDEBAR_TYPE_CONDENSED;
  /*
   * handle date change
   */
  const dropdownName = [
    "LISTINGS",
    "INVENTORY",
    "ORDERS",
    "PAYMENTS",
    "GROWTH",
    "REPORTS",
    "ADVERTISING",
  ];

  return (
    <>
      <div id="wrapper" className="">
        <img
          src={talkicon}
          alt="talkicon"
          className="position-fixed rounded-circle shadow cursor-pointer d-none d-sm-inline-block"
          style={{
            right: "20px",
            bottom: "60px",
            zIndex: 999999,
            height: "60px",
          }}
        />
        <BackToTop />
        <Suspense fallback={loading()}>
          <Topbar openLeftMenuCallBack={openMenu} hideLogo={false} />
        </Suspense>
        <Suspense fallback={loading()}>
          <LeftSidebar isCondensed={isCondensed} />
        </Suspense>
        <div className="content-page ps-lg-4 mt-0 mt-sm-5 p-0">
          <div className="d-flex align-items-start justify-content-start mt-0 mt-sm-3">
            <div className="content" style={{ width: "100%" }}>
              <Container className="p-0 px-sm-1">
                <Suspense fallback={loading()}>{children}</Suspense>
              </Container>
            </div>
          </div>

          <Suspense fallback={loading()}>
            <Footer />
          </Suspense>
        </div>
      </div>
      {isOpenRightSideBar && (
        <Suspense fallback={loading()}>
          <RightSidebar />
        </Suspense>
      )}
    </>
  );
};
export default VerticalLayout;
