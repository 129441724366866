import { all, fork, put, takeEvery, call } from "redux-saga/effects";
import { SagaIterator } from "@redux-saga/core";
import CryptoJS from "crypto-js";

// apicore
import { APICore, setAuthorization } from "../../helpers/api/apiCore";

// helpers
import {
  login as loginApi,
  logout as logoutApi,
  signup as signupApi,
  forgotPassword as forgotPasswordApi,
} from "../../helpers/";

// actions
import { authApiResponseSuccess, authApiResponseError } from "./actions";

// constants
import { AuthActionTypes } from "./constants";
import axios, { AxiosResponse } from "axios";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { Context } from "../../pages/other/Profile/OtherDetails/Mycontext";
import { toast } from "react-toastify";

interface statusCheck {
  billing_status: boolean;
  shipping_status: boolean;
  return_status: boolean;
}
interface UserData {
  payload: {
    username: string;
    password: string;
    fullname: string;
    email: string;
    token: string;
    status: statusCheck;
  };
  type: string;
}

const api = new APICore();

/**
 * Login the user
 * @param {*} payload - username and password
 */
function* login(action: any): Generator<any, void, any> {
  try {
    const { email, password } = action.payload;
    let response: Response = yield fetch(
      `${process.env.REACT_APP_BASE_URL}/seller_login`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, password }),
      }
    );

    if (!response.ok) {
      throw new Error("Failed to update user");
    }
    const result = yield response.json();

    console.log(result);
    if (result?.status === "Success" || result?.status === "success") {
      localStorage.setItem("token", result?.token);
      // setAuthorization(result?.token);
      SellerInfo();
    } else {
      api.setLoggedInUser(null);
      setAuthorization(null);
      yield put(
        authApiResponseError(
          AuthActionTypes.LOGIN_USER,
          result?.msg || "Something went wrong"
        )
      );
    }
  } catch (error: any) {
    yield put(authApiResponseError(AuthActionTypes.LOGIN_USER, error));
    api.setLoggedInUser(null);
    setAuthorization(null);
  }
}

const SellerInfo = () => {
  const navigate = useNavigate();
  const { setOtpVerified } = useContext(Context);

  var myHeaders = new Headers();
  const token: string | null = localStorage.getItem("token");
  if (!token) {
    toast.error("User not authenticated. Redirecting to login.");
    window.location.href = "/seller";
    return;
  }
  myHeaders.append("token", token!);
  var requestOptions = {
    method: "POST",
    headers: myHeaders,
  };
  fetch(`${process.env.REACT_APP_BASE_URL}/seller_info`, requestOptions)
    .then((response) => response.json())
    .then((result) => {
      console.log(result);
      // if (result?.status === "Success") {
      //   const secretKey: any = process.env.REACT_APP_SECRET_KEY;
      //   const ciphertext: any = result?.data;

      //   const bytes = CryptoJS.AES.decrypt(ciphertext, secretKey);
      //   const plaintext = bytes.toString(CryptoJS.enc.Utf8);
      //   var dt = JSON.parse(plaintext);
      //   if (dt.signup_otp_status === true) {
      //     if (dt.gst_status === true || dt.msme_status === true) {
      //       put(authApiResponseSuccess(AuthActionTypes.LOGIN_USER, result));
      //     } else {
      //       if (dt.gst_status === false) {
      //         navigate("/onboarding");
      //       } else if (dt.msme_status === false) {
      //         navigate("/onboarding");
      //       }
      //     }
      //   } else {
      //     if (dt.signup_otp_status === false) {
      //       navigate("/register");
      //       setOtpVerified(true);
      //     }
      //   }
      // } else {
      //   console.warn("SellerInfo error");
      //   put(
      //     authApiResponseError(
      //       AuthActionTypes.LOGIN_USER,
      //       result?.msg || "Something went wrong"
      //     )
      //   );
      // }
    })
    .catch((error) => console.log("error", error));
};

// function* login(action: any): SagaIterator {
//   try {
//     const { email, password } = action.payload;
//     const response: AxiosResponse = yield call(
//       axios.post,
//       `${process.env.REACT_APP_BASE_URL}/seller_login`,
//       { email, password }
//     );
//     const user = response.data;

//     // NOTE - You can change this according to response format from your API
//     api.setLoggedInUser(user);
//     setAuthorization(user["token"]);
//     yield put(authApiResponseSuccess(AuthActionTypes.LOGIN_USER, user));
//   } catch (error: any) {
//     yield put(authApiResponseError(AuthActionTypes.LOGIN_USER, error));
//     api.setLoggedInUser(null);
//     setAuthorization(null);
//   }
// }

/**
 * Logout the user
 */
function* logout(): SagaIterator {
  try {
    yield call(logoutApi);
    api.setLoggedInUser(null);
    setAuthorization(null);
    yield put(authApiResponseSuccess(AuthActionTypes.LOGOUT_USER, {}));
  } catch (error: any) {
    yield put(authApiResponseError(AuthActionTypes.LOGOUT_USER, error));
  }
}

function* signup({
  payload: { fullname, email, password },
}: UserData): SagaIterator {
  try {
    const response = yield call(signupApi, { fullname, email, password });
    const user = response.data;
  
    yield put(authApiResponseSuccess(AuthActionTypes.SIGNUP_USER, user));
  } catch (error: any) {
    yield put(authApiResponseError(AuthActionTypes.SIGNUP_USER, error));
    api.setLoggedInUser(null);
    setAuthorization(null);
  }
}

function* forgotPassword({ payload: { email } }: UserData): SagaIterator {
  try {
    const response = yield call(forgotPasswordApi, { email });
    yield put(
      authApiResponseSuccess(AuthActionTypes.FORGOT_PASSWORD, response.data)
    );
  } catch (error: any) {
    yield put(authApiResponseError(AuthActionTypes.FORGOT_PASSWORD, error));
  }
}

// signuptoken
function* setTokenSaga({ payload: { token } }: UserData): SagaIterator {
  try {
    yield put(authApiResponseSuccess(AuthActionTypes.SET_TOKEN, token));
  } catch (error: any) {
    yield put(authApiResponseError(AuthActionTypes.SET_TOKEN, token));
  }
}
// status check
function* setStatusSaga({ payload: { status } }: any): SagaIterator {
  try {
    yield put(authApiResponseSuccess(AuthActionTypes.SET_STATUS, status));
  } catch (error: any) {
    yield put(authApiResponseError(AuthActionTypes.SET_STATUS, status));
  }
}

export function* watchTokenActions() {
  yield takeEvery(AuthActionTypes.SET_TOKEN, setTokenSaga);
}
export function* watchStatusActions() {
  yield takeEvery(AuthActionTypes.SET_STATUS, setStatusSaga);
}

export function* watchLoginUser() {
  yield takeEvery(AuthActionTypes.LOGIN_USER, login);
}

export function* watchLogout() {
  yield takeEvery(AuthActionTypes.LOGOUT_USER, logout);
}

export function* watchSignup(): any {
  yield takeEvery(AuthActionTypes.SIGNUP_USER, signup);
}

export function* watchForgotPassword(): any {
  yield takeEvery(AuthActionTypes.FORGOT_PASSWORD, forgotPassword);
}

function* authSaga() {
  yield all([
    fork(watchLoginUser),
    fork(watchLogout),
    fork(watchSignup),
    fork(watchTokenActions),
    fork(watchForgotPassword),
    fork(watchStatusActions),
  ]);
}

export default authSaga;
