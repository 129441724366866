import React from "react";
import { Route, Navigate, RouteProps } from "react-router-dom";

import { APICore } from "../helpers/api/apiCore";
import { RootState } from "../redux/store";
import { useSelector } from "react-redux";

/**
 * Private Route forces the authorization before the route can be accessed
 * @param {*} param0
 * @returns
 */
const PrivateRoute = ({ component: Component, roles, ...rest }: any) => {
  const api = new APICore();
  const { userLoggedIn } = useSelector((state: RootState) => ({
    userLoggedIn: state.Auth.userLoggedIn,
  }));

  if (userLoggedIn === undefined) {
    // delay rendering until userLoggedIn value is retrieved from API
    return null;
  }

  return (
    <Route
      {...rest}
      render={(props: RouteProps) => {
        if (userLoggedIn === false) {
          // not logged in so redirect to login page with the return url
          return (
            <Navigate
              to={{
                pathname: "/login",
                // state: { from: props["location"] },
              }}
            />
          );
        }

        const loggedInUser = !!(localStorage.getItem("token"));

        // check if route is restricted by role
        if (roles && roles.indexOf(loggedInUser) === -1) {
          // role not authorised so redirect to home page
          return <Navigate to={{ pathname: "/" }} />;
        }
        // authorised so return component
        return <Component {...props} />;
      }}
    />
  );
};


export default PrivateRoute;
