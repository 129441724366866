import React from "react";
import { Col, Dropdown, Nav, Row, Tab } from "react-bootstrap";
import { downarrowsm, search, yellowbtn } from "../data";
import { useTranslation } from "react-i18next";

function ClaimTracking() {
  const {t}=useTranslation()
  return (
    <Row>
      <Col md={12}>
        <Tab.Container id="left-tabs-example" defaultActiveKey="first">
          <Row>
            <Col md={9}>
              <Nav
                variant="pills"
                className="overflow-auto d-flex align-items-start justify-content-start flex-nowrap"
              >
                {[
                  { name: "All", index: "first" },
                  { name: "Open(0)", index: "second" },
                  { name: "Approved", index: "third" },
                  { name: "Courier Partner", index: "fouth" },
                ].map((item) => (
                  <Nav.Item key={item.index + "-claim-menu"}>
                    <Nav.Link
                      eventKey={item.index}
                      className="cursor-pointer text-left px-2 py-1 text-nowrap"
                    >
                      {item.name}
                    </Nav.Link>
                  </Nav.Item>
                ))}
              </Nav>
              <div className="d-flex align-items-center justify-content-start gap-2 py-2 flex-nowrap overflow-auto">
                <span className="text-nowrap">Filter by:</span>
                <span>
                  <Dropdown className="w-100">
                    <Dropdown.Toggle
                      variant=""
                      id="dropdown-basic"
                      style={{
                        ...search,
                        maxWidth: "400px",
                        color: "gray",
                        padding: "4px 8px",
                      }}
                      className="d-flex align-items-center justify-content-between"
                    >
                      <span style={{ marginRight: "5px" }}>Created Date</span>
                      <span>{downarrowsm}</span>
                    </Dropdown.Toggle>

                    <Dropdown.Menu
                      style={{
                        ...search,
                        padding: "0",
                        overflow: "hidden",
                        border: "1px solid #697475",
                      }}
                    >
                      <Dropdown.Item href="#/action-1">
                        Created Date 1
                      </Dropdown.Item>
                      <Dropdown.Item href="#/action-2">
                        Created Date 2
                      </Dropdown.Item>
                      <Dropdown.Item href="#/action-3">
                        Created Date 3
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </span>
                <span>
                  <Dropdown className="w-100">
                    <Dropdown.Toggle
                      variant=""
                      id="dropdown-basic"
                      style={{
                        ...search,
                        maxWidth: "400px",
                        color: "gray",
                        padding: "4px 8px",
                      }}
                      className="d-flex align-items-center justify-content-between"
                    >
                      <span style={{ marginRight: "5px" }}>Issue Type</span>
                      <span>{downarrowsm}</span>
                    </Dropdown.Toggle>

                    <Dropdown.Menu
                      style={{
                        ...search,
                        padding: "0",
                        overflow: "hidden",
                        border: "1px solid #697475",
                      }}
                    >
                      <Dropdown.Item href="#/action-1">
                        Issue Type 1
                      </Dropdown.Item>
                      <Dropdown.Item href="#/action-2">
                        Issue Type 2
                      </Dropdown.Item>
                      <Dropdown.Item href="#/action-3">
                        Issue Type 3
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </span>
              </div>
            </Col>
            <Col md={3}>
              <div
                style={{
                  border: "1px solid #697475",
                  borderRadius: "10px",
                  marginBottom: "10px",
                }}
                className="p-2"
              >
                0/0 ready for download
              </div>
              <button className="btn" style={{ ...yellowbtn }}>
                File a new SAFE-T Claim
              </button>
            </Col>
          </Row>
          <Col sm={12}>
            <Tab.Content>
              <Tab.Pane eventKey="first">
                {/* <Sonnet /> */}
                <div
                  className="p-5 d-flex align-items-center justify-content-center fw-bold"
                  style={{ borderBottom: "1px solid #CECECE" }}
                >
                  {t("No data as of now")}
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="second">
                {/* <Sonnet /> */}
                <div
                  className="p-5 d-flex align-items-center justify-content-center fw-bold"
                  style={{ borderBottom: "1px solid #CECECE" }}
                >
                  Open
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="third">
                {/* <Sonnet /> */}
                <div
                  className="p-5 d-flex align-items-center justify-content-center fw-bold"
                  style={{ borderBottom: "1px solid #CECECE" }}
                >
                  Approved
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="fouth">
                {/* <Sonnet /> */}
                <div
                  className="p-5 d-flex align-items-center justify-content-center fw-bold"
                  style={{ borderBottom: "1px solid #CECECE" }}
                >
                  Courier Partner
                </div>
              </Tab.Pane>
            </Tab.Content>
          </Col>
        </Tab.Container>
      </Col>
    </Row>
  );
}

export default ClaimTracking;
