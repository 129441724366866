import React, { useContext, useEffect, useState } from "react";
import {
  Table,
  Spinner,
  Button,
  Dropdown,
  Container,
  Alert,
} from "react-bootstrap";
import { InventoryProduct } from "./InventoryProduct";
import { toast } from "react-toastify";
import { FaEdit, FaEllipsisV, FaPause, FaPlus } from "react-icons/fa";
import AddNewVariantModal from "./AddNewVarriantModal";
import { FormDataResponse } from "./AddNewVariantModal";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Context } from "pages/other/Profile/OtherDetails/Mycontext";
import EditPriceModal from "./EditPriceModal";
import { RiCoupon3Line } from "react-icons/ri";

interface RightSectionProps {
  selectedItem: InventoryProduct | null;
  loading: boolean;
  selectedTab: string;
  setSelectedItem: React.Dispatch<
    React.SetStateAction<InventoryProduct | null>
  >;
}
interface SellerProductsVariant {
  seller_products_variants_id: string;
  inventory: number;
  dukkandar_price: number;
  seller_price: number;
}

interface ProductVariationDataField {
  productVarFields: {
    identifier: string;
    name: string;
    input_types: string;
    placeholder?: string;
    options?: string[];
  };
}

const RightSection: React.FC<RightSectionProps> = ({
  selectedItem,
  loading,
  selectedTab,
  setSelectedItem,
}) => {
  const [editableStock, setEditableStock] = useState<
    Record<string, string | undefined>
  >({});

  const [showAddVariantModal, setShowAddVariantModal] = useState(false);

  const [selectedVariant, setSelectedVariant] = useState<string | null>(null);
  const [formData, setFormData] = useState<FormDataResponse | null>(null);
  const [pausedVariants, setPausedVariants] = useState<string[]>([]);

  const [selectedPrice, setSelectedPrice] = useState(0);

  const [
    isAlreadyListedEditPriceModalOpen,
    setIsAlreadyListedEditPriceModalOpen,
  ] = useState<boolean>(false);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchProductVariationDataFields = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          throw new Error("Token not found in local storage");
        }

        const apiUrl = `${process.env.REACT_APP_BASE_URL}/prd_lst/get_new_variant_flds`;
        const requestBody = {
          category_4_id: "men-fashion-western-wear-top-wear-active-tshirts",
        };
        const myHeaders = new Headers();
        myHeaders.append("token", token!);
        myHeaders.append("Content-Type", "application/json");

        const response = await fetch(apiUrl, {
          method: "POST",
          headers: myHeaders,
          body: JSON.stringify(requestBody),
        });

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        if (data.status === "success") {
          setFormData(data);
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };

    fetchProductVariationDataFields();
  }, []);

  const editInventory = async (
    sellerProductsVariantsId: string,
    newInventory: number
  ) => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        throw new Error("Token not found in local storage");
      }
      const apiUrl = `${process.env.REACT_APP_BASE_URL}/prd_lst/edit_inventory`;

      const myHeaders = new Headers();
      myHeaders.append("token", token!);
      myHeaders.append("Content-Type", "application/json");
      const requestBody = {
        seller_products_variants_id: sellerProductsVariantsId,
        inventory: newInventory,
      };
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify(requestBody),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      toast.success("Inventory updated successfully!");

      return data;
    } catch (error) {
      console.error("Error:", error);
      toast.error("An error occurred while updating inventory.");
    }
  };

  const handleStockChange = (
    variant: SellerProductsVariant,
    newInventory: string
  ) => {
    setEditableStock((prevStocks) => ({
      ...prevStocks,
      [variant.seller_products_variants_id]: newInventory,
    }));
  };

  const handleStockBlur = (variant: SellerProductsVariant) => {
    const newInventory = parseInt(
      editableStock[variant.seller_products_variants_id] || "",
      10
    );
    if (!isNaN(newInventory)) {
      editInventory(variant.seller_products_variants_id, newInventory);
    }
    setEditableStock((prevStocks) => ({
      ...prevStocks,
      [variant.seller_products_variants_id]: undefined,
    }));
  };

  const handleAddVariant = (variantId: string) => {
    setSelectedVariant(variantId);
    setShowAddVariantModal(true);
  };

  const handleCloseAddVariantModal = () => {
    setSelectedVariant(null);
    setShowAddVariantModal(false);
  };

  const handlePause = async (sellerProductsVariantsId: string) => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        throw new Error("Token not found in local storage");
      }

      const apiUrl = `${process.env.REACT_APP_BASE_URL}/prd_lst/pause_seller_prdct_variant`;
      const myHeaders = new Headers();
      myHeaders.append("token", token!);
      myHeaders.append("Content-Type", "application/json");

      const requestBody = {
        seller_products_variants_id: [sellerProductsVariantsId],
      };

      const response = await fetch(apiUrl, {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify(requestBody),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();

      toast.success("Product paused and moved to “Paused” tab");
      setPausedVariants((prevPausedVariants) => [
        ...prevPausedVariants,
        sellerProductsVariantsId,
      ]);
    } catch (error) {
      console.error("Error pausing product variant:", error);
      toast.error("An error occurred while pausing the product variant.");
    }
  };

  const { t } = useTranslation();

  const isVariantPaused = (variantId: string) =>
    pausedVariants.includes(variantId);

  const isPausedTab = selectedTab === "paused";

  const { LoginData } = useContext(Context);

  const handleEditPrice = (price: number, sellerProductVariantId: string) => {
    setSelectedPrice(price);
    setIsAlreadyListedEditPriceModalOpen(true);
    setSelectedVariant(sellerProductVariantId);
  };

  const handleCloseEditPriceModal = () => {
    setIsAlreadyListedEditPriceModalOpen(false);
  };

  const handlePriceUpdate = (updatedPrice: number) => {
    if (selectedItem) {
      const updatedSelectedItem: InventoryProduct = {
        ...selectedItem,
        seller_products_variants: selectedItem.seller_products_variants.map(
          (variant) => {
            if (variant.seller_products_variants_id === selectedVariant) {
              return {
                ...variant,
                dukkandar_price: updatedPrice,
              };
            }
            return variant;
          }
        ),
      };
      setSelectedItem(updatedSelectedItem);
    }

    handleCloseEditPriceModal();
  };

  const handleEditButtonClick = (variant: SellerProductsVariant) => {
    if (selectedItem?.uploaded_by === "Seller") {
      const isSellerMatch =
        selectedItem?.product?.uploaded_by_seller_id === LoginData?.seller_id;
      if (isSellerMatch) {
        navigate(
          `/dashboard/inventory/edit/${selectedItem?.dsin}/${selectedItem?.catalog_id}/${variant.seller_products_variants_id}`
        );
      } else {
        handleEditPrice(
          variant.dukkandar_price,
          variant.seller_products_variants_id
        );
      }
    } else {
      handleEditPrice(
        variant.dukkandar_price,
        variant.seller_products_variants_id
      );
    }
  };

  return (
    <Container fluid className="right-section overflow-auto">
      <h4>{t("Inventory Details")}</h4>

      {!selectedItem ? (
        <Alert variant="info">
          <Alert.Heading>{t("Please Select an Item")}</Alert.Heading>
          <p>{t("To view details, please select an item from the list.")}</p>
        </Alert>
      ) : (
        <div>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>SKU</th>
                <th>Variation</th>
                <th>Stock</th>

                <th>Price</th>
                {!isPausedTab && <th>Action</th>}
              </tr>
            </thead>
            <tbody>
              {selectedItem.seller_products_variants.map((variant, index) => (
                <tr
                  key={index}
                  className={
                    isVariantPaused(variant.seller_products_variants_id)
                      ? "paused-variant"
                      : ""
                  }
                  style={{
                    position: "relative",
                  }}
                >
                  {" "}
                  <td>{selectedItem.supplier_product_id}</td>
                  <td>{variant.product_variant.variation_value}</td>
                  <td
                    contentEditable="true"
                    suppressContentEditableWarning={true}
                    onBlur={() => handleStockBlur(variant)}
                    onInput={(e) =>
                      handleStockChange(variant, e.currentTarget.innerText)
                    }
                  >
                    {editableStock[variant.seller_products_variants_id] !==
                      undefined &&
                    editableStock[variant.seller_products_variants_id] ===
                      selectedItem?.seller_products_variants[index]
                        .seller_products_variants_id
                      ? editableStock[variant.seller_products_variants_id]
                      : variant.inventory}
                  </td>
                  <td>{variant.dukkandar_price}</td>
                  {!isPausedTab && (
                    <td>
                      {isVariantPaused(variant.seller_products_variants_id) ? (
                        <>
                          <div
                            style={{
                              position: "absolute",
                              top: 0,
                              left: 0,
                              width: "100%",
                              height: "100%",
                              background: "rgba(255, 255, 255, 0.8)",
                              borderRadius: "5px",
                              zIndex: 1,
                            }}
                          />
                          <div
                            style={{
                              position: "absolute",
                              top: "50%",
                              left: "50%",
                              transform: "translate(-50%, -50%)",
                              padding: "10px",
                              borderRadius: "5px",
                              zIndex: 2,
                              color: "rgb(41, 74, 34)",
                              backgroundColor: "rgb(231, 251, 224)",
                              border: "0.0625rem solid rgb(194, 234, 180)",
                            }}
                          >
                            Moved to Paused
                          </div>
                        </>
                      ) : (
                        <>
                          <Button
                            onClick={() => handleEditButtonClick(variant)}
                            variant="link"
                          >
                            <FaEdit /> Edit
                          </Button>

                          <Dropdown>
                            <Dropdown.Toggle variant="link" id="dropdown-basic">
                              <FaEllipsisV />
                              more
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                              <Dropdown.Item
                                onClick={() =>
                                  handlePause(
                                    variant.seller_products_variants_id
                                  )
                                }
                              >
                                <FaPause /> Pause
                              </Dropdown.Item>
                              <Dropdown.Item
                                onClick={() =>
                                  handleAddVariant(
                                    variant.seller_products_variants_id
                                  )
                                }
                              >
                                <FaPlus /> Add New Variant
                              </Dropdown.Item>
                              <Dropdown.Item
                                onClick={() => {
                                  navigate(
                                    "/dashboard/inventory/sellercoupons/add-coupon",
                                    {
                                      state: {
                                        variantId:
                                          variant?.seller_products_variants_id,
                                      },
                                    }
                                  );
                                }}
                                disabled={variant.inventory === 0}
                              >
                                <RiCoupon3Line /> Add New Coupon
                              </Dropdown.Item>
                              <Dropdown.Item
                                onClick={() => {
                                  navigate(
                                    "/dashboard/inventory/create-deals",
                                    {
                                      state: {
                                        variantId:
                                          variant?.seller_products_variants_id,
                                        productName:
                                          selectedItem?.product?.product_name_n,
                                      },
                                    }
                                  );
                                }}
                              >
                                <RiCoupon3Line /> Create New Deal
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </>
                      )}
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      )}
      <AddNewVariantModal
        show={showAddVariantModal}
        onClose={handleCloseAddVariantModal}
        formData={
          formData || {
            status: "loading",
            productvariationdatafields: [],
            productvariationsfields: [],
            productpricetaxfields: [],
          }
        }
        selectedItem={selectedItem}
      />
      <EditPriceModal
        show={isAlreadyListedEditPriceModalOpen}
        onClose={handleCloseEditPriceModal}
        onSave={handlePriceUpdate}
        initialPrice={selectedPrice}
        sellerProductVariantId={selectedVariant || ""}
      />

      {loading && (
        <div className="text-center m-auto">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      )}
    </Container>
  );
};

export default RightSection;
