import React, { useEffect, useState } from "react";
import {
  Alert,
  Col,
  FormGroup,
  FormLabel,
  FormSelect,
  Row,
  Table,
} from "react-bootstrap";
import { Deal, getMyDeals } from "../dealsApi";
import { toast } from "react-toastify";
import TwoBallsLoader from "components/Loaders/TwoBallsLoader";

const MyDeals: React.FC = () => {
  const [myDeals, setMyDeals] = useState<Deal[]>([]);
  const [page, setPage] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(false);
  const [status, setStatus] = useState<string>("Upcoming");
  const [perPage, setPerPage] = useState<number>(10);
  const [totalPages, setTotalPages] = useState<number>(1);

  useEffect(() => {
    const fetchMyDeals = async () => {
      setLoading(true);

      try {
        const response = await getMyDeals(page, status, perPage);
        const { status: apiStatus, msg, data,total_pages } = response;
        if (apiStatus === "success") {
          setMyDeals(data);
          setTotalPages(total_pages)
          toast.success(`${msg}`);
        } else {
          console.error(msg);
        }
      } catch (error) {
        console.error("Error occurred:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchMyDeals();
  }, [page, status, perPage]);

  const paginationItems = [];
  for (let i = 1; i <= totalPages; i++) {
    paginationItems.push(
      <li
        key={i}
        className={`page-item ${i === page ? "active" : ""}`}
        onClick={() => setPage(i)}
      >
        <span className="page-link">{i}</span>
      </li>
    );
  }

  const handlePrevClick = () => {
    setPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleNextClick = () => {
    setPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  return (
    <div>
      <h1>My Deals</h1>
      <Row className="px-2">
        <Col md={4} className="mb-3">
          <FormGroup className="mb-3">
            <FormLabel>Status</FormLabel>
            <FormSelect
              onChange={(e) => setStatus((e.target as HTMLSelectElement).value)}
              value={status}
            >
              <option value="Upcoming">Upcoming</option>
              <option value="Expired">Expired</option>
              <option value="Active">Active</option>
            </FormSelect>
          </FormGroup>
        </Col>
        <Col md={4} className="mb-3">
          <FormGroup className="mb-3">
            <FormLabel>Items Per Page</FormLabel>
            <FormSelect
              onChange={(e) => setPerPage(Number((e.target as HTMLSelectElement).value))}
              value={perPage}
            >
              <option value={10}>10</option>
              <option value={20}>20</option>
            </FormSelect>
          </FormGroup>
        </Col>
        <Col md={4} className="mb-3">
          <FormGroup className="mb-3">
            <FormLabel>Page</FormLabel>
            <FormSelect
              onChange={(e) => setPage(Number((e.target as HTMLSelectElement).value))}
              value={page}
            >
              {paginationItems.map((item, index) => (
                <option key={index} value={index + 1}>
                  {index + 1}
                </option>
              ))}
            </FormSelect>
          </FormGroup>
        </Col>
      </Row>
      {loading && <TwoBallsLoader />}
      {!loading && myDeals.length === 0 && (
        <Alert variant="info">No deals found.</Alert>
      )}
      {myDeals.length > 0 && (
        <Table striped bordered hover responsive className="mt-4">
          <thead>
            <tr>
              <th>Deal Title</th>
              <th>Start Date</th>
              <th>End Date</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {myDeals.map((deal) => (
              <tr key={deal.deals_id}>
                <td>{deal.dukkandaar_deal.deal_title}</td>
                <td>{deal.start_date}</td>
                <td>{deal.end_date}</td>
                <td>{deal.status}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
      <nav>
        <ul className="pagination">
          <li
            className={`page-item ${page === 1 ? "disabled" : ""}`}
            onClick={handlePrevClick}
          >
            <span className="page-link">Prev</span>
          </li>
          {paginationItems}
          <li
            className={`page-item ${page === 5 ? "disabled" : ""}`}
            onClick={handleNextClick}
          >
            <span className="page-link">Next</span>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default MyDeals;
