import React, { useState } from "react";
import { graycheck, greencheck, orangecheck } from "../data";
import { useNavigate } from "react-router-dom";

const SelectBrand = () => {
  const navigate = useNavigate();
  const [brand, setbrand] = useState<{ name: string }>({ name: "Quadb" });
  const [brandinput, setbrandinput] = useState<string>("");
  const [showerror, setshowerror] = useState<boolean>(false);
  const [showMessage, setshowMessage] = useState<{
    icon: boolean;
    msg: boolean;
  }>({ icon: false, msg: false });
  const CheckBrand = () => {
    if (brandinput === "") {
      setshowerror(true);
    } else {
      setshowerror(false);
      if (brand?.name.toLowerCase() === brandinput.toLowerCase()) {
        setshowMessage({
          icon: true,
          msg: true,
        });
      } else {
        setshowMessage({
          icon: false,
          msg: false,
        });
      }
    }
  };
  return (
    <>
      <div className="d-flex align-items-center justify-content-between px-2 py-1">
        <div>{greencheck} Select Vertical</div>
        <div>{orangecheck} Select Brand</div>
        <div>{graycheck} Product info</div>
      </div>
      <div
        className="fw-bold p-2"
        style={{
          fontSize: "13px",
        }}
      >
        Check for the brand you want to sell
      </div>
      <div
        className="mx-2 overflow-hidde position-relative"
        style={{
          background: "#F6F6F6",
          borderRadius: "18px",
          boxShadow: "0px 4px 4px 0px #00000040",
        }}
      >
        {showMessage.icon && (
          <span
            style={{
              position: "absolute",
              right: "10px",
              top: "50%",
              transform: "translateY(-50%)",
            }}
          >
            {greencheck}
          </span>
        )}
        <input
          className="border-0 p-2"
          type="text"
          placeholder="Enter Brand Name"
          onChange={(e) => setbrandinput(e.target.value)}
          value={brandinput}
          style={{
            width: "100%",
            height: "100%",
            borderRadius: "18px",
            outline: "none",
          }}
        />
      </div>
      {showerror && (
        <div
          className="alert alert-danger m-2"
          style={{ borderRadius: "18px" }}
        >
          Please enter something
        </div>
      )}
      {showMessage.msg && (
        <div className="alert alert-info m-2">
          Here is the information about all your orders Here is the information
          about all your orders Here is the information about.
        </div>
      )}
      <div
        className="d-flex align-items-center justify-content-between p-3 bg-white flex-wrap"
        style={{
          position: "fixed",
          bottom: "50px",
          width: "100vw",
          borderRadius: "12px",
        }}
      >
        <button
          className="px-2 py-2 border-0 shadow mb-1"
          style={{
            // width: "auto",
            height: "auto",
            background: "#FFD814",
            borderRadius: "18px",
            width: "100%",
          }}
          onClick={() => {
            showMessage.msg === true
              ? navigate("/apps/AddProduct/productinfo")
              : CheckBrand();
          }}
        >
          {showMessage.msg === true ? "Confirm And Next" : "Check Brand"}
        </button>
      </div>
    </>
  );
};

export default SelectBrand;
