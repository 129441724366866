import React from 'react';
import { MdNotificationsActive } from 'react-icons/md';

interface NotificationCardProps {
  notification: {
    notification_id: string;
    message: string;
    module_id: string;
    createdAt: string;
  };
}

const NotificationCard: React.FC<NotificationCardProps> = ({ notification }) => {
  return (
    <div className="notification-card mx-2 mx-md-0">
      <div className="notification-header">
        <MdNotificationsActive />
        <span>{notification.module_id}</span>
      </div>
      <div className="notification-body">
        <p>{notification.message}</p>
        <small>{new Date(notification.createdAt).toLocaleString()}</small>
      </div>
    </div>
  );
};

export default NotificationCard;
