import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { graycheck, orangecheck } from "../data";
import addimg from "../../../../assets/images/addimg.png";

const ProductCreateListing = () => {
  const navigate = useNavigate();
  const products = Array(6).fill("Product");
  const location = useLocation();
  const { product } = location?.state;
  return (
    <>
      <div className="d-flex align-items-center justify-content-between py-1">
        <div>{orangecheck} Select Vertical</div>
        <div>{graycheck} Select Brand</div>
        <div>{graycheck} Product info</div>
      </div>
      <div className="d-flex p-2 gap-2">
        <div>
          <img src={addimg} alt="" />
        </div>
        <div>
          <h3>{product}</h3>
          <p className="" style={{ fontSize: "12px" }}>
            Here is the information about all your orders Here is the
            information about all your orders Here is the information about.
          </p>
        </div>
      </div>
      <div style={{ background: "#E6EDFF" }} className="p-2">
        <p>Not want you to sell?</p>
        <h5>Do you have any of these products instead?</h5>
        <div className="d-flex gap-2 overflow-auto p-2">
          {products.map((_, i) => {
            return (
              <div
                key={`${i}-productlisting`}
                className="p-1"
                style={{
                  borderRadius: "7px",
                  background: "#D9D9D9",
                }}
              >
                <div
                  style={{
                    fontSize: "9px",
                    textAlign: "center",
                  }}
                >
                  {" "}
                  Asymmetric Top
                </div>
                <div>
                  <img src={addimg} alt="" />
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div
        className="d-flex align-items-center justify-content-between p-3 bg-white flex-wrap"
        style={{
          position: "fixed",
          bottom: "50px",
          width: "100vw",
          borderRadius: "12px",
        }}
      >
        <button
          className="px-2 py-2 border-0 shadow mb-1"
          style={{
            // width: "auto",
            height: "auto",
            background: "#FFD814",
            borderRadius: "18px",
            width: "140px",
          }}
          onClick={() => navigate("/apps/AddProduct/createlisting")}
        >
          Search again
        </button>
        <button
          className="px-2 py-2 border-0 shadow mb-1"
          style={{
            // width: "auto",
            height: "auto",
            background: "#E6EDFF",
            borderRadius: "18px",
            width: "140px",
          }}
          onClick={() => navigate("/apps/AddProduct/selectbrand")}
        >
          Confirm And Next
        </button>
      </div>
    </>
  );
};

export default ProductCreateListing;
