import React from "react";
import { orangecheck, graycheck, greencheck } from "../data";
import searchlogo from "../../../../assets/images/searchlogo.png";
import { useNavigate } from "react-router-dom";

const CreateListing = () => {
  const navigate = useNavigate();
  const products = Array(16).fill("Product");
  return (
    <>
      <div className="d-flex align-items-center justify-content-between px-2 py-1">
        <div>{orangecheck} Select Vertical</div>
        <div>{graycheck} Select Brand</div>
        <div>{graycheck} Product info</div>
      </div>
      <div className="px-2">
        <div className="mysearchdiv w-100 overflow-hidden">
          <img src={searchlogo} alt="" />
          <input
            type="text"
            name="mysearch-app"
            id="mysearch"
            className="mysearch app-search w-100"
            style={{
              height: "50px",
            }}
            placeholder="Search category of a product"
          />
        </div>
      </div>
      <div
        className="p-2"
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(3, 1fr)",
          gap: "10px",
        }}
      >
        {products.map((product, index) => (
          <div
            className="p-2 d-flex fs-4 align-items-center justify-content-center fw-bold cursor-pointer"
            key={`${index}-products-createdListing`}
            style={{
              borderRadius: "10px",
              border: "1px solid #E6EDFF",
            }}
            onClick={() =>
              navigate("/apps/AddProduct/productcreatelisting", {
                state: { product: product },
              })
            }
          >
            {product}
          </div>
        ))}
      </div>
      <div
        className="d-flex align-items-center justify-content-center flex-column shadow-lg p-3 bg-white"
        style={{
          position: "fixed",
          bottom: "50px",
          width: "100vw",
          borderRadius: "12px",
        }}
      >
        <p className="text-center text-black-50">
          OR <br /> CREATE A NEW DUKAANDAAR LISITNG
        </p>
        <button
          className="create-btn"
          //   onClick={() => navigate("/apps/AddProduct/createlisting")}
        >
          Create New Listing
        </button>
      </div>
    </>
  );
};

export default CreateListing;
