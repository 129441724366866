import React from "react";
import EditProductDetails from "./EditProductDetails";
import { Link } from "react-router-dom";

const EditDraftProductPage: React.FC = () => {
  return (
    <div style={{ padding: "20px" }}>
      <Link to="/apps/AddProduct" style={{ textDecoration: "none" }}>
        <button className="btn btn-primary" style={{ marginBottom: "20px" }}>Back</button>
      </Link>
      <div style={{ border: "1px solid #ddd", padding: "20px", borderRadius: "8px" }}>
        <EditProductDetails />
      </div>
    </div>
  );
};

export default EditDraftProductPage;
