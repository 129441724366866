import React, { useEffect, useState } from "react";
import { Form, Col, Row, Spinner, Alert } from "react-bootstrap";
import NotificationListing from "./NotificationListing";
import { getSellerNotifications } from "./ApiServiceNotification";
import { toast } from "react-toastify";
import { FaRegBellSlash } from "react-icons/fa";

const SellerNotificationsPage: React.FC = () => {
  const [notifications, setNotifications] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem("token"); // Replace with the actual key used to store the token

    if (token) {
      fetchNotifications(token);
    } else {
      toast.error("Token not found in local storage");
    }
  }, [currentPage, itemsPerPage]);

  const fetchNotifications = async (token: string) => {
    try {
      setLoading(true);

      const response = await getSellerNotifications(
        currentPage,
        itemsPerPage,
        token
      );

      setNotifications(response.data);
      setTotalItems(response.total_items);
    } catch (error) {
      console.error("Error fetching notifications:", error);
    } finally {
      setLoading(false);
    }
  };

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
  };

  const handleItemsPerPageChange = (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setItemsPerPage(Number(e.target.value));
    setCurrentPage(1);
  };

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  }

  if (notifications.length === 0) {
    return (
      <Alert variant="info" className="mt-3">
        <div className="alert alert-info mt-3" role="alert">
          <FaRegBellSlash className="me-2" />
          No new notifications at the moment. Stay tuned for updates!
        </div>
      </Alert>
    );
  }

  return (
    <div>
      <h1 className="mb-4">Seller Notifications</h1>
      <Row className="mb-4 mx-2">
        <Form.Label column sm="2" style={{ width: "fit-content" }}>
          Items Per Page:
        </Form.Label>
        <Col sm="auto">
          <Form.Select
            id="itemsPerPage"
            onChange={handleItemsPerPageChange}
            value={itemsPerPage}
            className="w-auto"
          >
            <option value={10}>10</option>
            <option value={20}>20</option>
            <option value={50}>50</option>
            <option value={100}>100</option>
          </Form.Select>
        </Col>
      </Row>

      <NotificationListing
        notifications={notifications}
        onPageChange={handlePageChange}
        totalItems={totalItems}
        itemsPerPage={itemsPerPage}
      />
    </div>
  );
};

export default SellerNotificationsPage;
