import React, { Suspense } from "react";
import { Navigate, Route, RouteProps } from "react-router-dom";

// components
import PrivateRoute from "./PrivateRoute";
import Overview from "../components/ReturnsAndSpfClaims/return/Overview";
import ReturnTracking from "../components/ReturnsAndSpfClaims/return/ReturnTracking";
import ClaimTracking from "../components/ReturnsAndSpfClaims/return/ClaimTracking";
import CourierPartner from "../components/ReturnsAndSpfClaims/return/CourierPartner";
import LoadingScreen from "../components/LoadingScreen";
import Listing from "../pages/apps/AddProduct/mobile/Listing";
import AddListing from "../pages/apps/AddProduct/mobile/AddListing";
import CreateListing from "../pages/apps/AddProduct/mobile/CreateListing";
import ProductCreateListing from "../pages/apps/AddProduct/mobile/ProductCreateListing";
import SelectBrand from "../pages/apps/AddProduct/mobile/SelectBrand";
import ProductInfo from "../pages/apps/AddProduct/mobile/ProductInfo";
import MobOrder from "../pages/apps/Payment/MobOrder";
import MobReturn from "../pages/apps/Payment/MobReturn";
import FindProduct from "../PreLogin/FindProduct";
import MobBill from "../pages/apps/Payment/MobBill";
import ChangePasswordForm from "../pages/other/Profile/OtherDetails/AccountSettings/ChangePasswordForm";
import InventoryDashboard from "pages/Inventory/InventoryDashboard";
import EditDraftProductPage from "pages/apps/AddProduct/EditProduct/EditDraftProductPage";
import EditLiveProduct from "pages/Inventory/EditLiveProduct/EditLiveProduct";
import SellerNotificationsPage from "pages/Notifications/SellerNotificationsPage";
import EditProfileDetails from "pages/other/Profile/OtherDetails/AccountSettings/EditProfileDetails";
import ChangeEmail from "pages/other/Profile/OtherDetails/AccountSettings/ChangeEmail";
import DisClaimer from "pages/Advertising/Coupons/DisClaimer";
import CreateCoupon from "pages/Advertising/Coupons/CreateCoupon";
import AddCouponForm from "pages/Advertising/Coupons/AddCouponForm";
import ContactUs from "pages/ContactUs";
import MyDeals from "pages/Advertising/Deals/mydeals/MyDeals";
import AllDeals from "pages/Advertising/Deals/allDeals/AllDeals";
import AddDealForm from "pages/Advertising/Deals/AddDealForm";
import Document from "pages/document/Document";
import ViewSingleCoupon from "pages/Advertising/Coupons/ViewSingleCoupon";
// PreLogin
const PreLogin = React.lazy(() => import("../PreLogin/PreLogin"));

// seller market place components
const ReturnsAndSpf = React.lazy(
  () => import("../components/ReturnsAndSpfClaims/ReturnsAndSpf")
);
const Spf = React.lazy(() => import("../components/ReturnsAndSpfClaims/Spf"));
const Return = React.lazy(
  () => import("../components/ReturnsAndSpfClaims/Return")
);
const SafeClaim = React.lazy(
  () => import("../components/ReturnsAndSpfClaims/SafeClaim")
);
const Orderdetails = React.lazy(
  () => import("../pages/apps/orders/Orderdetails")
);
const ProductDetail = React.lazy(
  () => import("../pages/apps/AddProduct/ProductDetail")
);
const Onhold = React.lazy(() => import("../pages/apps/orders/Onhold"));
const AfterFilter = React.lazy(
  () => import("../pages/apps/orders/AfterFilter")
);
const ReadyToShip = React.lazy(
  () => import("../pages/apps/orders/ReadyToShip")
);
const Pending = React.lazy(() => import("../pages/apps/orders/Pending"));
const Tobedispatch = React.lazy(
  () => import("../pages/apps/orders/Tobedispatch")
);
const Payment = React.lazy(() => import("../pages/apps/Payment"));
const Shiped = React.lazy(() => import("../pages/apps/orders/Shiped"));
const Cancelled = React.lazy(() => import("../pages/apps/orders/Cancelled"));
const Download = React.lazy(() => import("../pages/apps/orders/Download"));

const Support = React.lazy(() => import("../components/SupportPage/Support"));
// import Root from "./Root";

// lazy load all the views

// auth
const Login = React.lazy(() => import("../pages/auth/Login"));
const Logout = React.lazy(() => import("../pages/auth/Logout"));
const Confirm = React.lazy(() => import("../pages/auth/Confirm"));
const OnboardingForm = React.lazy(() => import("../pages/auth/OnboardingForm"));
const ForgetPassword = React.lazy(() => import("../pages/auth/ForgetPassword"));
const Register = React.lazy(() => import("../pages/auth/Register"));
const UpdateProfile = React.lazy(() => import("../pages/auth/UpdateProfile"));
const OtpPassword = React.lazy(() => import("../pages/auth/login/Otp"));
const Signin = React.lazy(() => import("../pages/auth/login/Signin"));
const LockScreen = React.lazy(() => import("../pages/auth/LockScreen"));
// landing
const Landing = React.lazy(() => import("../pages/landing/"));

// dashboard
const EcommerceDashboard = React.lazy(
  () => import("../pages/dashboard/Ecommerce/")
);
const AnalyticsDashboard = React.lazy(
  () => import("../pages/dashboard/Analytics/")
);

// apps
const CalendarApp = React.lazy(() => import("../pages/apps/Calendar/"));
const Projects = React.lazy(() => import("../pages/apps/Projects/"));
const ProjectDetail = React.lazy(
  () => import("../pages/apps/Projects/Detail/")
);
// - chat
const ChatApp = React.lazy(() => import("../pages/apps/Chat/"));
// - orders
const OrdersApp = React.lazy(() => import("../pages/apps/orders"));
// - AddProduct
const AddProductApp = React.lazy(() => import("../pages/apps/AddProduct/"));
// - email
const Inbox = React.lazy(() => import("../pages/apps/Email/Inbox"));
const EmailDetail = React.lazy(() => import("../pages/apps/Email/Detail"));
const EmailCompose = React.lazy(() => import("../pages/apps/Email/Compose"));
// - tasks
const TaskList = React.lazy(() => import("../pages/apps/Tasks/List/"));
const Kanban = React.lazy(() => import("../pages/apps/Tasks/Board/"));
// - file
const FileManager = React.lazy(() => import("../pages/apps/FileManager"));

// extra pages
const Error404 = React.lazy(() => import("../pages/error/Error404"));
const Error500 = React.lazy(() => import("../pages/error/Error500"));
// -other
const Starter = React.lazy(() => import("../pages/other/Starter"));
const Profile = React.lazy(() => import("../pages/other/Profile"));
const Activity = React.lazy(() => import("../pages/other/Activity"));
const Invoice = React.lazy(() => import("../pages/other/Invoice"));
const Maintenance = React.lazy(() => import("../pages/other/Maintenance"));
const Pricing = React.lazy(() => import("../pages/other/Pricing"));

// uikit
const UIElements = React.lazy(() => import("../pages/uikit"));

// widgets
const Widgets = React.lazy(() => import("../pages/widgets/"));

// icons
const Unicons = React.lazy(() => import("../pages/icons/Unicons"));
const FeatherIcons = React.lazy(() => import("../pages/icons/Feather/"));
const BootstrapIcon = React.lazy(() => import("../pages/icons/Bootstrap/"));

// forms
const BasicForms = React.lazy(() => import("../pages/forms/Basic"));
const FormAdvanced = React.lazy(() => import("../pages/forms/Advanced"));
const FormValidation = React.lazy(() => import("../pages/forms/Validation"));
const FormWizard = React.lazy(() => import("../pages/forms/Wizard"));
const FileUpload = React.lazy(() => import("../pages/forms/FileUpload"));
const Editors = React.lazy(() => import("../pages/forms/Editors"));

// charts
const Charts = React.lazy(() => import("../pages/charts/"));

// tables
const BasicTables = React.lazy(() => import("../pages/tables/Basic"));
const AdvancedTables = React.lazy(() => import("../pages/tables/Advanced"));

// maps
const GoogleMaps = React.lazy(() => import("../pages/maps/GoogleMaps"));
const VectorMaps = React.lazy(() => import("../pages/maps/VectorMaps"));

export interface RoutesProps {
  path: RouteProps["path"];
  name?: string;
  element?: RouteProps["element"];
  route?: any;
  exact?: boolean;
  icon?: string;
  header?: string;
  roles?: string[];
  children?: RoutesProps[];
}

// dashboards
const dashboardRoutes: RoutesProps = {
  path: "/dashboard",
  name: "Dashboards",
  icon: "airplay",
  header: "Navigation",
  children: [
    {
      path: "/",
      name: "Root",
      element: <Navigate to="/dashboard/ecommerce" />,
      route: PrivateRoute,
    },
    {
      path: "/dashboard/ecommerce",
      name: "Ecommerce",
      element: (
        <Suspense fallback={<LoadingScreen />}>
          <EcommerceDashboard />
        </Suspense>
      ),
      route: PrivateRoute,
    },
    {
      path: "/dashboard/analytics",
      name: "Analytics",
      element: (
        <Suspense fallback={<LoadingScreen />}>
          <AnalyticsDashboard />
        </Suspense>
      ),
      route: PrivateRoute,
    },
    {
      path: "/dashboard/returns",
      name: "Returns",
      element: (
        <Suspense fallback={<LoadingScreen />}>
          <Return />
        </Suspense>
      ),
      route: PrivateRoute,
      children: [
        { path: "/dashboard/returns", element: <Overview /> },
        {
          path: "/dashboard/returns/returntracking",
          element: (
            <Suspense fallback={<LoadingScreen />}>
              <ReturnTracking />
            </Suspense>
          ),
        },
        {
          path: "/dashboard/returns/claimtracking",
          element: (
            <Suspense fallback={<LoadingScreen />}>
              <ClaimTracking />
            </Suspense>
          ),
        },
        {
          path: "/dashboard/returns/courierpartner",
          element: (
            <Suspense fallback={<LoadingScreen />}>
              <CourierPartner />
            </Suspense>
          ),
        },
      ],
    },
    {
      path: "/dashboard/support",
      name: "support",
      element: (
        <Suspense fallback={<LoadingScreen />}>
          <Support />
        </Suspense>
      ),
      route: PrivateRoute,
    },
  ],
};

const calendarAppRoutes: RoutesProps = {
  path: "/apps/calendar",
  name: "Calendar",
  route: PrivateRoute,
  roles: ["Admin"],
  icon: "calendar",
  element: (
    <Suspense fallback={<LoadingScreen />}>
      <CalendarApp />
    </Suspense>
  ),
  header: "Apps",
};

const chatAppRoutes: RoutesProps = {
  path: "/apps/chat",
  name: "Chat",
  route: PrivateRoute,
  roles: ["Admin"],
  icon: "message-square",
  element: (
    <Suspense fallback={<LoadingScreen />}>
      <ChatApp />
    </Suspense>
  ),
};

const ordersAppRoutes: RoutesProps = {
  path: "/apps/orders",
  name: "Orders",
  route: PrivateRoute,
  roles: ["Admin"],
  icon: "truck",
  element: <OrdersApp />,
  children: [
    {
      path: "/apps/orders/orderdetails",
      name: "Orderdetails",
      element: <Orderdetails />,
      route: PrivateRoute,
    },
    {
      path: "/apps/orders/Onhold",
      name: "Onhold",
      element: <Onhold />,
      route: PrivateRoute,
    },
    {
      path: "/apps/orders/Pending",
      name: "Pending",
      element: <Pending />,
      route: PrivateRoute,
    },
    {
      path: "/apps/orders/Tobedispatch",
      name: "Tobedispatch",
      element: <Tobedispatch />,
      route: PrivateRoute,
    },
    {
      path: "/apps/orders/Shiped",
      name: "Shiped",
      element: <Shiped />,
      route: PrivateRoute,
    },
    {
      path: "/apps/orders/AfterFilter",
      name: "AfterFilter",
      element: <AfterFilter />,
      route: PrivateRoute,
    },
    {
      path: "/apps/orders/ReadyToShip",
      name: "ReadyToShip",
      element: <ReadyToShip />,
      route: PrivateRoute,
    },
    {
      path: "/apps/orders/Cancelled",
      name: "Cancelled",
      element: <Cancelled />,
      route: PrivateRoute,
    },
    {
      path: "/apps/orders/Download",
      name: "Download",
      element: <Download />,
      route: PrivateRoute,
    },
    {
      path: "/apps/orders/mobbill",
      name: "MobBill",
      element: <MobBill />,
      route: PrivateRoute,
    },
  ],
};

const AddProductAppRoutes: RoutesProps = {
  path: "/apps/AddProduct",
  name: "Add Product",
  route: PrivateRoute,
  roles: ["Admin"],
  icon: "bag",
  element: <AddProductApp />,
  children: [
    {
      path: "/apps/AddProduct/ProductDetail",
      name: "ProductDetail",
      element: <ProductDetail />,
      route: PrivateRoute,
    },
    {
      path: "/apps/AddProduct/listing",
      name: "ProductDetail",
      element: <Listing />,
      route: PrivateRoute,
    },
    {
      path: "/apps/AddProduct/addlisting",
      name: "Add Listing",
      element: <AddListing />,
      route: PrivateRoute,
    },
    {
      path: "/apps/AddProduct/createlisting",
      name: "Create Listing",
      element: <CreateListing />,
      route: PrivateRoute,
    },
    {
      path: "/apps/AddProduct/productcreatelisting",
      name: "Product CreateListing",
      element: <ProductCreateListing />,
      route: PrivateRoute,
    },
    {
      path: "/apps/AddProduct/selectbrand",
      name: "Select Brand",
      element: <SelectBrand />,
      route: PrivateRoute,
    },
    {
      path: "/apps/AddProduct/productinfo",
      name: "Product Info",
      element: <ProductInfo />,
      route: PrivateRoute,
    },
    // payment
    {
      path: "/apps/AddProduct/payment",
      name: "Payment",
      element: <Payment />,
      route: PrivateRoute,
    },
    {
      path: "/apps/AddProduct/MobOrder",
      name: "MobOrder",
      element: <MobOrder />,
      route: PrivateRoute,
    },
    {
      path: "/apps/AddProduct/MobReturn",
      name: "MobReturn",
      element: <MobReturn />,
      route: PrivateRoute,
    },
    {
      path: "/apps/AddProduct/editDraftProduct",
      name: "EditDraftProduct",
      element: <EditDraftProductPage />,
      route: PrivateRoute,
    },
  ],
};

const ReturnsAndSpfRoute: RoutesProps = {
  roles: ["Admin"],
  icon: "truck",
  path: "/dashboard/returns",
  name: "Returns",
  element: <ReturnsAndSpf />,
  route: PrivateRoute,
  children: [
    {
      path: "/dashboard/spf",
      name: "spf",
      element: <Spf />,
      route: PrivateRoute,
    },
    {
      path: "/dashboard/returns",
      name: "Return",
      element: <Return />,
      route: PrivateRoute,
    },
    {
      path: "/dashboard/returns/SafeClaim",
      name: "SafeClaim",
      element: <SafeClaim />,
      route: PrivateRoute,
    },
  ],
};

const InventoryRoutes: RoutesProps = {
  roles: ["Admin"],
  icon: "truck",
  path: "/dashboard/inventory",
  name: "inventory",
  element: <InventoryDashboard />,
  route: PrivateRoute,
  children: [
    {
      path: "/dashboard/inventory/active",
      name: "active",
      element: <Spf />,
      route: PrivateRoute,
    },
    {
      path: "/dashboard/inventory/blocked",
      name: "blocked",
      element: <Return />,
      route: PrivateRoute,
    },
    {
      path: "/dashboard/inventory/paused",
      name: "paused",
      element: <SafeClaim />,
      route: PrivateRoute,
    },
    {
      path: "/dashboard/inventory/edit/:dsin/:catalog_id/:seller_products_variants_id",
      name: "editProduct",
      element: <EditLiveProduct />,
      route: PrivateRoute,
    },
    {
      path: "/dashboard/inventory/sellercoupons/add-coupon",
      name: "active",
      element: <AddCouponForm />,
      route: PrivateRoute,
    },
    {
      path: "/dashboard/inventory/sellercoupons/edit-coupon/:couponId",
      name: "active",
      element: <AddCouponForm />,
      route: PrivateRoute,
    },
    {
      path: "/dashboard/inventory/create-deals",
      name: "active",
      element: <AddDealForm />,
      route: PrivateRoute,
    },
  ],
};

const AdVertisingRoutes: RoutesProps = {
  roles: ["Admin"],
  icon: "gift",
  path: "/pages/coupons/sellercoupons",
  name: "Advertising",
  element: <DisClaimer />,
  route: PrivateRoute,
  children: [
    {
      path: "/pages/coupons/sellercoupons/create-coupon",
      name: "active",
      element: <CreateCoupon />,
      route: PrivateRoute,
    },
    {
      path: "/pages/coupons/sellercoupons/view-coupon/:couponId",
      name: "active",
      element: <ViewSingleCoupon />,
      route: PrivateRoute,
    },
    {
      path: "/pages/deals/mydeals/",
      name: "active",
      element: <MyDeals />,
      route: PrivateRoute,
    },
    {
      path: "/pages/deals/alldeals",
      name: "active",
      element: <AllDeals />,
      route: PrivateRoute,
    },
  ],
};

const ContactUsRoutes: RoutesProps = {
  roles: ["Admin"],
  icon: "mail",
  path: "/pages/contact-us",
  name: "ContactUs",
  element: <ContactUs />,
  route: PrivateRoute,
};

const emailAppRoutes: RoutesProps = {
  path: "/apps/email",
  name: "Email",
  route: PrivateRoute,
  roles: ["Admin"],
  icon: "mail",
  children: [
    {
      path: "/apps/email/inbox",
      name: "Inbox",
      element: <Inbox />,
      route: PrivateRoute,
    },
    {
      path: "/apps/email/details",
      name: "Email Details",
      element: <EmailDetail />,
      route: PrivateRoute,
    },
    {
      path: "/apps/email/compose",
      name: "Compose Email",
      element: <EmailCompose />,
      route: PrivateRoute,
    },
  ],
};

const projectAppRoutes: RoutesProps = {
  path: "/apps/projects",
  name: "Projects",
  route: PrivateRoute,
  roles: ["Admin"],
  icon: "uil-briefcase",

  children: [
    {
      path: "/apps/projects/list",
      name: "List",
      element: <Projects />,
      route: PrivateRoute,
    },
    {
      path: "/apps/projects/details",
      name: "Detail",
      element: <ProjectDetail />,
      route: PrivateRoute,
    },
  ],
};

const taskAppRoutes: RoutesProps = {
  path: "/apps/tasks",
  name: "Tasks",
  route: PrivateRoute,
  roles: ["Admin"],
  icon: "clipboard",
  children: [
    {
      path: "/apps/tasks/list",
      name: "Task List",
      element: <TaskList />,
      route: PrivateRoute,
    },
    {
      path: "/apps/tasks/kanban",
      name: "Kanban",
      element: <Kanban />,
      route: PrivateRoute,
    },
  ],
};

const fileAppRoutes: RoutesProps = {
  path: "/apps/file-manager",
  name: "File Manager",
  route: PrivateRoute,
  roles: ["Admin"],
  icon: "folder-plus",
  element: <FileManager />,
};

const appRoutes = [
  calendarAppRoutes,
  chatAppRoutes,
  ordersAppRoutes,
  AddProductAppRoutes,
  ReturnsAndSpfRoute,
  emailAppRoutes,
  projectAppRoutes,
  taskAppRoutes,
  fileAppRoutes,
  InventoryRoutes,
  AdVertisingRoutes,
  ContactUsRoutes,
];

// pages
const extrapagesRoutes: RoutesProps = {
  path: "/pages",
  name: "Pages",
  icon: "package",
  header: "Custom",
  children: [
    {
      path: "/pages/starter",
      name: "Starter",
      element: <Starter />,
      route: PrivateRoute,
    },
    {
      path: "/pages/notification",
      name: "Notification",
      element: <SellerNotificationsPage />,
      route: PrivateRoute,
    },
    {
      path: "/pages/profile",
      name: "Profile",
      element: <Profile />,
      route: PrivateRoute,
      children: [
        {
          path: "/pages/profile/ui-elements",
          name: "UIElements",
          element: <UIElements />,
          route: PrivateRoute,
        },
      ],
    },
    {
      path: "/pages/activity",
      name: "Activity",
      element: <Activity />,
      route: PrivateRoute,
    },
    {
      path: "/pages/invoice",
      name: "Invoice",
      element: <Invoice />,
      route: PrivateRoute,
    },
    {
      path: "/pages/pricing",
      name: "Pricing",
      element: <Pricing />,
      route: PrivateRoute,
    },
    {
      path: "/pages/change-password",
      name: "change-password",
      element: <ChangePasswordForm />,
      route: Route,
    },
    {
      path: "/pages/edit-profile",
      name: "edit-profile",
      element: <EditProfileDetails />,
      route: Route,
    },
    {
      path: "/pages/change-email",
      name: "change-email",
      element: <ChangeEmail />,
      route: Route,
    },
  ],
};

// ui
const uiRoutes: RoutesProps = {
  path: "/components",
  name: "Components",
  icon: "package",
  header: "UI Elements",
  children: [
    {
      path: "/components/ui-elements",
      name: "UIElements",
      element: <UIElements />,
      route: PrivateRoute,
    },
    {
      path: "/components/widgets",
      name: "Widgets",
      element: <Widgets />,
      route: PrivateRoute,
    },
    {
      path: "/icons",
      name: "Icons",
      children: [
        {
          path: "/icons/unicons",
          name: "Unicons",
          element: <Unicons />,
          route: PrivateRoute,
        },
        {
          path: "/icons/feather",
          name: "Feather",
          element: <FeatherIcons />,
          route: PrivateRoute,
        },
        {
          path: "/icons/bootstrap",
          name: "Bootstrap Icon",
          element: <BootstrapIcon />,
          route: PrivateRoute,
        },
      ],
    },
    {
      path: "/forms",
      name: "Forms",
      children: [
        {
          path: "/forms/basic",
          name: "Basic Elements",
          element: <BasicForms />,
          route: PrivateRoute,
        },
        {
          path: "/forms/advanced",
          name: "Form Advanced",
          element: <FormAdvanced />,
          route: PrivateRoute,
        },
        {
          path: "/forms/validation",
          name: "Form Validation",
          element: <FormValidation />,
          route: PrivateRoute,
        },
        {
          path: "/forms/wizard",
          name: "Form Wizard",
          element: <FormWizard />,
          route: PrivateRoute,
        },
        {
          path: "/forms/upload",
          name: "File Upload",
          element: <FileUpload />,
          route: PrivateRoute,
        },
        {
          path: "/forms/editors",
          name: "Editors",
          element: <Editors />,
          route: PrivateRoute,
        },
      ],
    },
    {
      path: "/components/charts",
      name: "Charts",
      element: <Charts />,
      route: PrivateRoute,
    },
    {
      path: "/tables",
      name: "Tables",
      children: [
        {
          path: "/tables/basic",
          name: "Basic",
          element: <BasicTables />,
          route: PrivateRoute,
        },
        {
          path: "/tables/advanced",
          name: "Advanced",
          element: <AdvancedTables />,
          route: PrivateRoute,
        },
      ],
    },
    {
      path: "/maps",
      name: "Maps",
      children: [
        {
          path: "/maps/googlemaps",
          name: "Google Maps",
          element: <GoogleMaps />,
          route: PrivateRoute,
        },
        {
          path: "/maps/vectorMaps",
          name: "Google Maps",
          element: <VectorMaps />,
          route: PrivateRoute,
        },
      ],
    },
  ],
};

// auth
const authRoutes: RoutesProps[] = [
  {
    path: "/seller",
    name: "PreLogin",
    element: <PreLogin />,
    route: Route,
  },
  {
    path: "/findproduct",
    name: "FindProduct",
    element: <FindProduct />,
    route: Route,
  },

  {
    path: "/login",
    name: "Login",
    element: <Login />,
    route: Route,
  },
  {
    path: "/signin",
    name: "Signin",
    element: <Signin />,
    route: Route,
  },
  {
    path: "/update_profile",
    name: "Update_profile",
    element: <UpdateProfile />,
    route: Route,
  },
  {
    path: "/otp",
    name: "Otp",
    element: <OtpPassword />,
    route: Route,
  },
  {
    path: "/register",
    name: "Register",
    element: <Register />,
    route: Route,
  },
  {
    path: "/confirm",
    name: "Confirm",
    element: <Confirm />,
    route: Route,
  },
  {
    path: "/Onboarding",
    name: "Onboarding",
    element: (
      <Suspense fallback={<LoadingScreen />}>
        <OnboardingForm />
      </Suspense>
    ),
    route: Route,
  },
  {
    path: "/forget-password",
    name: "Forget Password",
    element: <ForgetPassword />,
    route: Route,
  },
  {
    path: "/lock-screen",
    name: "Lock Screen",
    element: <LockScreen />,
    route: Route,
  },
  {
    path: "/logout",
    name: "Logout",
    element: <Logout />,
    route: Route,
  },
  {
    path: "/doc",
    name: "doc",
    element: <Document />,
    route: Route,
  },
];

// public routes
const otherPublicRoutes: RoutesProps[] = [
  {
    path: "/landing",
    name: "landing",
    element: <Landing />,
    route: Route,
  },
  {
    path: "/maintenance",
    name: "Maintenance",
    element: <Maintenance />,
    route: Route,
  },
  {
    path: "/error-404",
    name: "Error - 404",
    element: <Error404 />,
    route: Route,
  },
  {
    path: "/error-500",
    name: "Error - 500",
    element: <Error500 />,
    route: Route,
  },
];

// flatten the list of all nested routes
const flattenRoutes = (routes: RoutesProps[]) => {
  let flatRoutes: RoutesProps[] = [];

  routes = routes || [];
  routes.forEach((item: RoutesProps) => {
    flatRoutes.push(item);

    if (typeof item.children !== "undefined") {
      flatRoutes = [...flatRoutes, ...flattenRoutes(item.children)];
    }
  });
  return flatRoutes;
};

// All routes
const authProtectedRoutes = [
  dashboardRoutes,
  ...appRoutes,
  extrapagesRoutes,
  uiRoutes,
];
const publicRoutes = [...authRoutes, ...otherPublicRoutes];

const authProtectedFlattenRoutes = flattenRoutes([...authProtectedRoutes]);
const publicProtectedFlattenRoutes = flattenRoutes([...publicRoutes]);
export {
  publicRoutes,
  authProtectedRoutes,
  authProtectedFlattenRoutes,
  publicProtectedFlattenRoutes,
};
