import { APICore } from "./apiCore";

const api = new APICore();

// account
function login(params: { email: string; password: string }) {
  const baseUrl = `${process.env.REACT_APP_BASE_URL}/seller_login`;
  return api.create(baseUrl, params);
}

function logout() {
  const baseUrl = `${process.env.REACT_APP_BASE_URL}/logout/`;
  return api.create(baseUrl, {});
}

function signup(params: { fullname: string; email: string; password: string }) {
  const baseUrl = `${process.env.REACT_APP_BASE_URL}/seller_signup`;
  return api.create(baseUrl, params);
}

function forgotPassword(params: { email: string }) {
  const baseUrl = `${process.env.REACT_APP_BASE_URL}/forget-password/`;
  return api.create(baseUrl, params);
}

export { login, logout, signup, forgotPassword };
