import { createContext, useState } from "react";

export const Context = createContext();

const MyContextProvider = ({ children }) => {
  // token
  const [token, settoken] = useState("");

  // logindata
  const [LoginData, setLoginData] = useState({});

  // seller id
  const [sellerAddressId, setsellerAddressId] = useState("");

  // seller id from billing detail
  const [billingAddress, setBillingAddress] = useState(null);

  // for otp
  const [otpVerified, setOtpVerified] = useState(false);

  // user
  const [userdetail, setuserdetail] = useState({
    fullname: "",
    email: "",
    password: "",
  });

  if (!children) {
    console.log("MyContextProvider must have children prop.");
  }
  return (
    <Context.Provider
      value={{
        token,
        settoken,
        billingAddress,
        setBillingAddress,
        sellerAddressId,
        setsellerAddressId,
        userdetail,
        setuserdetail,
        otpVerified,
        setOtpVerified,
        LoginData,
        setLoginData,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export default MyContextProvider;
