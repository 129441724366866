import React, { useState, useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { toast } from "react-toastify";

interface EditPriceModalProps {
  show: boolean;
  onClose: () => void;
  onSave: (updatedPrice: number) => void;
  initialPrice: number;
  sellerProductVariantId: string; 

}

const EditPriceModal: React.FC<EditPriceModalProps> = ({
  show,
  onClose,
  onSave,
  initialPrice,
  sellerProductVariantId
}) => {
  const [price, setPrice] = useState(initialPrice);

  useEffect(() => {
    setPrice(initialPrice);
  }, [show, initialPrice]);

  const handleSave = async () => {
    try {
      const apiUrl = `${process.env.REACT_APP_BASE_URL}/prd_lst/edit_inventory_price`;
      const token = localStorage.getItem("token");

      if (!token) {
        throw new Error("Token not found in local storage");
      }

      const myHeaders = new Headers();
      myHeaders.append("token", token);
      myHeaders.append("Content-Type", "application/json");

      const requestBody = {
        price: price.toString(),
        seller_products_variants_id:sellerProductVariantId,
      };

      const response = await fetch(apiUrl, {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify(requestBody),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const res = await response.json();

      if (res.status === "success") {
        toast.success(`${res.msg}`);
      } else {
        toast.error(`${res.msg}`);
      }

      onSave(price);

      onClose();
    } catch (error) {
      console.error("Error updating price:", error);
      toast.error("An error occurred while updating the price.");
    }
  };

  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Edit Price</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group controlId="formPrice">
          <Form.Label>Price</Form.Label>
          <Form.Control
            type="number"
            value={price}
            onChange={(e) => setPrice(parseFloat(e.target.value))}
          />
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Close
        </Button>
        <Button variant="primary" onClick={handleSave}>
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditPriceModal;
