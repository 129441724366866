import React, { useState } from "react";
import { leftarrow, deleteicon } from "../data";
import { useLocation, useNavigate } from "react-router-dom";
import searchicon from "../../../../assets/images/searchlogo.png";
import shoes from "../../../../assets/images/shoes.png";

const tags = {
  borderRadius: "20px",
  background: "#D9D9D9",
  boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
  // width: " 91px",
  height: "33px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  cursor: "pointer",
  fontWeight: "bold",
  padding: "5px 20px",
} as React.CSSProperties;

const Listing = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [showfilter, setshowfilter] = useState<boolean>(false);

  const { name, count } = location.state;
  return (
    <>
      <div
        className="position-related"
        style={{
          minHeight: "20vh",
        }}
      >
        <button
          className="addlistingbtn fw-bold"
          style={{ position: "fixed", bottom: "80px" }}
          onClick={() => navigate("/apps/AddProduct/addlisting")}
        >
          <svg
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9 1V17"
              stroke="#2A353D"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M1 9H17"
              stroke="#2A353D"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          Add Listing
        </button>
        <div
          className="d-flex align-items-center justify-content-between "
          style={{ background: "#E6EDFF", position: "sticky", top: "0px" }}
        >
          <div
            className="d-flex align-items-center gap-2 py-2 px-3 cursor-pointer"
            onClick={() => navigate(-1)}
          >
            {leftarrow}
            {name}
          </div>
          <div className="d-flex align-items-center gap-3 py-2 px-3 cursor-pointer">
            <img src={searchicon} alt="" />
            <span onClick={() => setshowfilter(true)}>{deleteicon}</span>
          </div>
        </div>
        <div className="p-2 shadow">
          <div>Listing in Progress ({count})</div>
          <div className="d-flex gap-2 py-2">
            <div style={tags}>Fassured</div>
            <div style={tags}>Out of stock</div>
          </div>
        </div>

        <div
          className="m-2"
          style={{
            borderRadius: "10px",
            border: "1px solid rgba(0, 0, 0, 0.10)",
          }}
        >
          <div
            className="d-flex align-items-start gap-2 p-2"
            style={{
              paddingBottom: "20px",
              borderBottom: "1px solid rgba(0, 0, 0, 0.10)",
            }}
          >
            <div>
              <div
                style={{
                  borderRadius: "10px",
                  border: "1px solid #FFE5C7",
                  background: "#FEF6EB",
                  padding: "0 5px",
                  marginBottom: "15px",
                }}
              >
                !&nbsp;Inactive
              </div>
              <img src={shoes} alt="" className="w-100" />
            </div>
            <div>
              <h4>QUADB Solid Men Round Neck Blue T-Shirt</h4>
              <div>
                <span className="fw-bold">Brand: </span>QUADB
              </div>
              <div>
                <span className="fw-bold">SKU ID: </span>MC-110103-3
              </div>
              <div>
                <span className="fw-bold">FSN: </span>TSHG5EHQ7ACGR88S
              </div>
            </div>
          </div>
          <div className="d-flex align-items-center justify-content-between p-2">
            <div
              className="p-1 text-center"
              style={{
                height: "100%",
                borderRight: "1px solid rgba(0, 0, 0, 0.10)",
                width: "33.3%",
              }}
            >
              Fulfilled by SELLER
            </div>
            <div
              className="p-1 text-center"
              style={{
                height: "100%",

                width: "33.3%",
              }}
            >
              49
            </div>
            <div
              className="p-1 text-center"
              style={{
                height: "100%",
                borderLeft: "1px solid rgba(0, 0, 0, 0.10)",
                width: "33.3%",
              }}
            >
              Listing Price 219
            </div>
          </div>
        </div>
      </div>

      {/* filter */}
      {showfilter && (
        <div
          className="position-absolute bg-white"
          style={{
            top: "70px",
            left: "0",
            height: "100vh",
            width: "100vw",
          }}
        >
          <div
            className="d-flex align-items-center justify-content-between "
            style={{ background: "#E6EDFF", position: "sticky", top: "0px" }}
          >
            <div
              className="d-flex align-items-center gap-2 py-2 px-3 cursor-pointer"
              onClick={() => setshowfilter(false)}
            >
              {leftarrow}
              Filter
            </div>
            <div className="d-flex align-items-center gap-3 py-2 px-3 cursor-pointer">
              Clear all
            </div>
          </div>

          <div className="d-flex" style={{ height: "70vh" }}>
            <div style={{ background: "#E6EDFF", width: "35%" }}>
              <div className="p-2 text-center bg-white">Short by</div>
              <div className="p-2 text-center">Catagory</div>
              <div className="p-2 text-center">Status</div>
            </div>
            <div className="p-3">
              <div className="d-flex align-items-start gap-3 p-2">
                <input type="checkbox" name="" id="" />
                Last Modified - Low to High
              </div>
              <div className="d-flex align-items-start gap-3 p-2">
                <input type="checkbox" name="" id="" />
                Last Modified - High to Low
              </div>
              <div className="d-flex align-items-start gap-3 p-2">
                <input type="checkbox" name="" id="" />
                Created On - Low to High
              </div>
              <div className="d-flex align-items-start gap-3 p-2">
                <input type="checkbox" name="" id="" />
                Created On - High to Low
              </div>
            </div>
          </div>

          <div className="p-3 float-end">
            <button
              className="px-4 py-1 border-0"
              style={{
                borderRadius: "20px",
                background: " #D9D9D9",
                boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
              }}
              onClick={() => setshowfilter(false)}
            >
              Apply
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default Listing;
