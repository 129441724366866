import React, { useEffect, useState } from "react";
import {
  Form,
  Button,
  Col,
  Container,
  Row,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import "./coupon.css";
import CouponHeader from "./CouponHeader";

const AddCouponForm: React.FC = () => {
  const location = useLocation();
  const { state } = location;
  const [loading, setLoading] = useState(false);
  const couponId = useParams();

  const variantId = state?.variantId;
  const [formData, setFormData] = useState({
    start_date: "",
    end_date: "",
    is_money_off: true,
    is_per_off: false,
    amount: 0,
    discount: 0,
    multi_redemption: false,
    redemption_limit: 20,
    coupon_title: "",
    seller_products_variants_id: variantId,
  });

  const navigate = useNavigate();
  const isEditMode = !!couponId.couponId;
  useEffect(() => {
    const fetchCouponDetails = async () => {
      try {
        const token = localStorage.getItem("token") || "";

        const headers: HeadersInit = {
          "Content-Type": "application/json",
          token: token,
        };

        const response = await fetch(
          `${process.env.REACT_APP_BASE_URL}/get_single_coupon/${couponId?.couponId}`,
          {
            method: "GET",
            headers: headers,
          }
        );

        const responseData = await response.json();

        if (responseData.status.toLowerCase() === "success") {
          const {
            is_money_off,
            is_per_off,
            amount,
            discount,
            multi_redemption,
            redemption_limit,
            start_date,
            end_date,
            coupon_title,
          } = responseData.data;
          setFormData({
            is_money_off,
            is_per_off,
            amount,
            discount,
            multi_redemption,
            redemption_limit,
            start_date,
            end_date,
            coupon_title,
            seller_products_variants_id: undefined,
          });
        } else {
          toast.error(`${responseData.msg}`);
        }
      } catch (error) {
        console.error("Error occurred:", error);
      }
    };

    if (isEditMode === true) {
      fetchCouponDetails();
    } else {
      return;
    }
  }, [couponId, isEditMode]);

  const handleEdit = async () => {
    try {
      setLoading(true);

      const token = localStorage.getItem("token") || "";

      const headers: HeadersInit = {
        "Content-Type": "application/json",
        token: token,
      };

      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/edit_coupon/${couponId?.couponId}`,
        {
          method: "POST",
          headers: headers,
          body: JSON.stringify(formData),
        }
      );

      const responseData = await response.json();

      if (responseData.status.toLowerCase() === "success") {
        toast.success("Coupon edited successfully!");
        navigate("/dashboard/inventory");
      } else {
        toast.error(`${responseData.msg}`);
      }
    } catch (error) {
      console.error("Error occurred:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (key: string, value: string | number | boolean) => {
    if (key === "is_money_off" || key === "is_per_off") {
      setFormData((prevData) => ({
        ...prevData,
        [key]: !!value,
        is_money_off: key === "is_money_off" ? !!value : !prevData.is_money_off,
        is_per_off: key === "is_per_off" ? !!value : !prevData.is_per_off,
        amount: 0,
        discount: 0,
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [key]: value,
      }));
    }
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);

      const token = localStorage.getItem("token") || "";

      const headers: HeadersInit = {
        "Content-Type": "application/json",
        token: token,
      };

      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/add_coupon`,
        {
          method: "POST",
          headers: headers,
          body: JSON.stringify(formData),
        }
      );
      const responseData = await response.json();

      if (responseData.status.toLowerCase() === "success") {
        toast.success("Coupon added successfully!");
        navigate("/dashboard/inventory");
      } else {
        toast.error(`${responseData.msg}`);
      }
    } catch (error) {
      console.error("Error occurred:", error);
    } finally {
      setLoading(false);
    }
  };

  console.log({ formData });

  return (
    <Container>
      <CouponHeader />
      <h4>{isEditMode ? "Edit Coupon" : "Set Schedule"}</h4>{" "}
      <p className="badge bg-primary rounded-pill p-2">
        Set a duration for your coupon between 1 to 50 days.
      </p>
      <Form>
        <Row>
          <Col>
            <Form.Group controlId="start_date">
              <Form.Label>Start Date</Form.Label>
              <Form.Control
                type="date"
                value={formData.start_date}
                onChange={(e) =>
                  handleInputChange("start_date", e.target.value)
                }
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="end_date">
              <Form.Label>End Date</Form.Label>
              <Form.Control
                type="date"
                value={formData.end_date}
                onChange={(e) => handleInputChange("end_date", e.target.value)}
              />
            </Form.Group>
          </Col>
        </Row>

        <h4>Set Discount</h4>
        <p>
          Please enter the discount amount that you want to apply to the product
        </p>
        <Row className="mb-3">
          <Col>
            <Form.Group controlId="discountType">
              <Form.Label>Discount Type</Form.Label>
              <div className="d-flex">
                <Form.Check
                  type="radio"
                  label="Money Off"
                  checked={formData.is_money_off}
                  onChange={() => handleInputChange("is_money_off", true)}
                  className="mx-2"
                />
                <Form.Check
                  type="radio"
                  label="Percentage Off"
                  checked={formData.is_per_off}
                  onChange={() => handleInputChange("is_per_off", true)}
                />
              </div>
            </Form.Group>
          </Col>
        </Row>

        {formData.is_money_off ? (
          <Form.Group controlId="amount" className="mb-2">
            <Form.Label>
              {formData.is_money_off ? "Amount (₹)" : "Discount (%)"}
            </Form.Label>
            <div className="input-group">
              <span className="input-group-text">
                {formData.is_money_off ? "₹" : "%"}
              </span>
              <Form.Control
                type="number"
                value={formData.amount}
                onChange={(e) => handleInputChange("amount", +e.target.value)}
                className="form-control"
              />
            </div>
          </Form.Group>
        ) : (
          <Form.Group controlId="discount">
            <Form.Label>Discount (%)</Form.Label>
            <Form.Control
              type="number"
              value={formData.discount}
              onChange={(e) => handleInputChange("discount", +e.target.value)}
            />
          </Form.Group>
        )}
        <Row>
          <Col md={6}>
            <Form.Group controlId="coupon_title">
              <Form.Label>Coupon Title</Form.Label>{" "}
              <span>(what customers will see)</span>
              <div className="input-group mb-3">
                <span className="input-group-text bg-black text-white fw-bold">
                  Save{" "}
                  {formData.is_money_off
                    ? `₹${formData.amount}`
                    : `${formData.discount}%`}{" "}
                  on
                </span>
                <Form.Control
                  type="text"
                  value={formData.coupon_title}
                  onChange={(e) =>
                    handleInputChange("coupon_title", e.target.value)
                  }
                  className="form-control"
                />
              </div>
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group controlId="redemption_limit">
              <Form.Label>
                Redemption Limit{" "}
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip id="tooltip-redemption-limit">
                      Number of customers who can redeem this coupon. The coupon
                      will expire after reaching this limit.
                    </Tooltip>
                  }
                >
                  <span className="badge bg-info">?</span>
                </OverlayTrigger>
              </Form.Label>
              <Form.Control
                type="number"
                value={formData.redemption_limit}
                onChange={(e) =>
                  handleInputChange("redemption_limit", +e.target.value)
                }
                min={20}
              />
            </Form.Group>
          </Col>
        </Row>

        <Form.Group controlId="multi_redemption">
          <Form.Label className="fw-bold text-dark">
            Do you want to limit the redemption of your coupon to 1 per
            customer?
          </Form.Label>
          <div>
            <Form.Check
              type="radio"
              className="text-secondary"
              label="Yes, limit redemption to one per customer"
              id="yesOption"
              checked={formData.multi_redemption === true}
              onChange={() => handleInputChange("multi_redemption", true)}
            />
            <Form.Check
              type="radio"
              className="text-secondary"
              label="No, allow my coupon to be redeemed multiple times by the same customer"
              id="noOption"
              checked={formData.multi_redemption === false}
              onChange={() => handleInputChange("multi_redemption", false)}
            />
          </div>
        </Form.Group>

        {isEditMode ? (
          <Button
            className="my-2"
            variant="primary"
            onClick={handleEdit}
            disabled={loading}
          >
            {loading ? "Editing..." : "Edit Coupon"}
          </Button>
        ) : (
          <Button
            className="my-2"
            variant="primary"
            onClick={handleSubmit}
            disabled={loading}
          >
            {loading ? "Submitting..." : "Submit"}
          </Button>
        )}
      </Form>
    </Container>
  );
};

export default AddCouponForm;
