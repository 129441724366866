import React, { useState } from "react";
import { leftarrow } from "../AddProduct/data";
import { downloadsvg } from "./data";
import { useNavigate } from "react-router-dom";

const MobReturn = () => {
  const navigate = useNavigate();
  const [inProgress, setinProgress] = useState<boolean>(true);
  return (
    <>
      <div
        className="position-absolute bg-white"
        style={{
          top: "70px",
          left: "0",
          height: "100vh",
          width: "100vw",
        }}
      >
        <div
          className="d-flex align-items-center justify-content-between "
          style={{ background: "#E6EDFF", position: "sticky", top: "0px" }}
        >
          <div
            className="d-flex align-items-center gap-2 py-2 px-3 cursor-pointer"
            onClick={() => navigate(-1)}
          >
            {leftarrow}
            Return
          </div>
          <div className="d-flex align-items-center gap-3 py-2 px-3 cursor-pointer">
            {downloadsvg}
          </div>
        </div>
        <div
          className="d-flex align-items-center justify-content-evenly p-2 shadow"
          style={{ background: "#F6F6F6", borderBottom: "1px solid #D9D9D9" }}
        >
          <div
            className="cursor-pointer"
            onClick={() => setinProgress(true)}
            style={
              inProgress
                ? { borderBottom: "2px solid orange", fontWeight: "bold" }
                : { borderBottom: "2px solid transparent" }
            }
          >
            IN-PROGRESS
          </div>
          <div
            className="cursor-pointer"
            onClick={() => setinProgress(false)}
            style={
              !inProgress
                ? { borderBottom: "2px solid orange", fontWeight: "bold" }
                : { borderBottom: "2px solid transparent" }
            }
          >
            COMPLETED
          </div>
        </div>
        {inProgress ? (
          <div className="p-2">In Progress</div>
        ) : (
          <div className="p-2">Completed</div>
        )}
      </div>
    </>
  );
};

export default MobReturn;
