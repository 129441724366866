import React from "react";

interface ContactUsProps {
  // Define any props if needed
}

const DocContactUs: React.FC<ContactUsProps> = () => {
  return (
    <div>
      <h1>Contact Us</h1>
      <p>
        At Dukkandaar, we value your feedback and are committed to providing excellent customer support. Whether you have a question, concern, or simply want to share your experience with us, we're here to help.
      </p>

      {/* Contact Information */}
      <section>
        <h2>Contact Information</h2>
        <p>
          You can reach us using the following contact details:
        </p>
        <ul>
          <li>Email: support@Dukkandaar.com</li>
          <li>Phone: +1 (123) 456-7890</li>
          <li>Address: 123 Main Street, City, Country, ZIP</li>
        </ul>
      </section>

      {/* Contact Form */}
      <section>
        <h2>Contact Form</h2>
        <p>
          Alternatively, you can fill out the form below to send us a message directly. Our team will get back to you as soon as possible.
        </p>
        {/* Placeholder for contact form */}
      </section>

      {/* Business Hours */}
      <section>
        <h2>Business Hours</h2>
        <p>
          Our support team is available during the following hours:
        </p>
        <ul>
          <li>Monday to Friday: 9:00 AM - 5:00 PM (EST)</li>
          <li>Saturday: 10:00 AM - 2:00 PM (EST)</li>
          <li>Sunday: Closed</li>
        </ul>
      </section>

      {/* Additional Information */}
      <section>
        <h2>Additional Information</h2>
        <p>
          If you have specific questions about our products, services, or policies, please visit our FAQ page or refer to our Terms and Conditions.
        </p>
        <p>
          For press inquiries or business partnerships, please contact our PR team at pr@Dukkandaar.com.
        </p>
      </section>
    </div>
  );
};

export default DocContactUs;
