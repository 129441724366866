export interface Product {
  product_id: string;
  dsin: string;
  catalog_id: string;
  category_4_id: string;
  product_sku: string | null;
  uploaded_by_seller_id: string;
  uploaded_by: string;
  uploaded_by_id: string | null;
  product_weight_in_gms: number;
  product_details: {
    en: {
      Color: string;
      Fabric: string;
      "Packer Details": string;
      "Net Quantity (N)": string;
      "COUNTRY OF ORIGIN": string;
      "Manufacturer Details": string;
    };
    hi: {
      रंग: string;
      कपड़ा: string;
      "उद्गम देश": string;
      "पैकर विवरण": string;
      "शुद्ध मात्रा (n)": string;
      "निर्माता विवरण": string;
    };
    pa: {
      ਰੰਗ: string;
      ਫੈਬਰਿਕ: string;
      "ਉਦਗਮ ਦੇਸ਼": string;
      "ਗੁਪਤ ਵੇਰਵੇ": string;
      "ਸ਼ੁੱਧ ਮਾਤਰਾ (ਐਨ)": string;
      "ਨਿਰਮਾਤਾ ਦੇ ਵੇਰਵੇ": string;
    };
  };
  product_details_attr: {
    name: string;
    value: string;
    identifier: string;
  }[];
  product_name: {
    en: string;
    hi: string;
    pa: string;
  };
  product_description: {
    en: string;
    hi: string;
    pa: string;
  };
  product_name_n: string;
  product_description_n: string;
  other_details: {
    en: {
      Brand: string;
      Length: string;
      Pattern: string;
      "Neck/Collar": string;
      "Sleeve Length": string;
      "Sleeve Styling": string;
      "Surface Styling": string;
      "Importer Details": string;
      "Print or Pattern Type": string;
    };
    hi: {
      "Neck/Collar": string;
      नमूना: string;
      लंबाई: string;
      ब्रांड: string;
      "आयातक विवरण": string;
      "बांह की लंबाई": string;
      "आस्तीन स्टाइल": string;
      "सतह स्टाइलिंग": string;
      "प्रिंट या पैटर्न प्रकार": string;
    };
    pa: {
      "Neck/Collar": string;
      ਪੈਟਰਨ: string;
      ਲੰਬਾਈ: string;
      ਬ੍ਰਾਂਡ: string;
      "ਆਸਤੀਨ ਲੰਮਾਈ": string;
      "ਸਤਹ ਸਟਾਈਲਿੰਗ": string;
      "ਸਲੀਵ ਸਟਾਈਲਿੰਗ": string;
      "ਆਯਾਤ ਕਰਨ ਵਾਲੇ ਦੇ ਵੇਰਵੇ": string;
      "ਪ੍ਰਿੰਟ ਜਾਂ ਪੈਟਰਨ ਦੀ ਕਿਸਮ": string;
    };
  };
  other_details_attri: {
    name: string;
    value: string;
    identifier: string;
  }[];
  status: string;
  active: boolean;
  blocked: boolean;
  is_pause: boolean;
  default: boolean;
  reviewed_comment: string | null;
  reviewed_by: string;
  is_edited: boolean;
  blocked_by: string | null;
  blocked_reason: string | null;
  deactivated_by: string | null;
  createdAt: string;
  updatedAt: string;
  product_variants: {
    product_variants_id: string;
    product_id: string;
    dsin: string;
    catalog_id: string;
    variation_name: string;
    variation_value: string;
    variation_name_sku: string;
    variation_value_sku: string;
    sku: string;
    product_mrp: number;
    seller_products_variants: {
      seller_products_variants_id: string;
      seller_products_id: string;
      seller_id: string;
      supplier_sku_id: string;
      product_variants_id: string;
      price: number;
      inventory: number;
      variation_attributes_attri: {
        name: string;
        value: string;
        identifier: string;
      }[];
      createdAt: string;
      seller_product: {
        seller_products_id: string;
        supplier_product_id: string;
        seller: {
          seller_name: string;
          shop_name: string;
          verified: boolean;
          activity_status: boolean;
          account_status: boolean;
        };
      };
    }[];
  }[];
  products_images: {
    url: string;
    is_front_img: boolean;
  }[];
}

export interface ProductDetailField {
  name: string;
  identifier: string;
  value: string;
}

export interface ProductSizeInvtFields {
  product_weight_in_gms: string;
  supplier_product_id: string;
  product_name: string;
  comment: string;
}

export interface ProductVariation {
  seller_products_variants_id: string;
  seller_price: any;
}

export interface ProductOtherField {
  name: string;
  identifier: string;
  value: string;
}

export interface ImageData {
  front_image_url: string;
  other_images: string[];
}

export interface ProductsData {
  productdetailfields: ProductDetailField[];
  productsizeinvtfields: ProductSizeInvtFields;
  product_variations: ProductVariation[];
  productotherfields: ProductOtherField[];
  image_data: ImageData;
}
export  interface ProductImage {
  url: string;
  is_front_img: boolean;
}


export const productdetailfieldsName: ProductDetailField[] = [
  { name: "Color", identifier: "color", value: "" },
  { name: "Fabric", identifier: "fabric", value: "" },
  { name: "Net Quantity (N)", identifier: "multipack", value: "" },
  { name: "COUNTRY OF ORIGIN", identifier: "country_of_origin", value: "" },
  { name: "Manufacturer Details", identifier: "manufacturer_details", value: "" },
  { name: "Packer Details", identifier: "packer_details", value: "" },
];


export interface activationPendingProduct {
  edit_products_req_id: string;
  product_id: string;
  dsin: string;
  catalog_id: string;
  category_4_id: string;
  product_weight_in_gms: number;
  product_details_attr: Array<{ name: string; value: string; identifier: string }>;
  other_details_attri: Array<{ name: string; value: string; identifier: string }>;
  product_name_n: string;
  product_description_n: string;
  seller_products_id: string;
  supplier_product_id: string;
  status: string;
  updated_by: string;
  updated_by_id: string | null;
  updated_by_seller_id: string;
  reviewed_by: string | null;
  reviewed_comment: string | null;
  createdAt: string;
  updatedAt: string;
  category_4: {
    category_4_id: string;
    category_4_p_id: string;
    category_3_id: string;
    name: string;
    category_4_img: string;
  };
  edit_products_variants: Array<{
    edit_products_variants_id: string;
    product_id: string;
    seller_products_variants_id: string;
    dukkandar_price: number;
    seller_price: number;
  }>;
  edit_products_images: any[]; // Update this type based on your actual data
}
