export interface InventoryProduct {
  seller_products_id: string;
  seller_id: string;
  uploaded_by_seller_id: string;
  uploaded_by: string;
  uploaded_by_id: string | null;
  supplier_product_id: string;
  product_id: string;
  dsin: string;
  catalog_id: string;
  status: string;
  active: boolean;
  blocked: boolean;
  is_pause: boolean;
  createdAt: string;

  product: {
    product_id: string;
    category_4_id: string;
    product_name_n: string;
    status: string; 
    active: boolean;
    blocked: boolean;
    is_pause: boolean;
    uploaded_by: string; 
    uploaded_by_id: string | null;
    uploaded_by_seller_id: string | null;
    
    category_4: {
      category_4_id: string;
      name: string;
      category_4_img: string;
    };
    
    products_images: Array<{
      url: string;
    }>;
  };
  seller: {
    seller_name: string;
    shop_name: string;
  };
  seller_products_variants: Array<{
    seller_products_variants_id: string;
    seller_products_id: string;
    seller_id: string;
    supplier_sku_id: string;
    product_id: string;
    product_variants_id: string;
    inventory: number;
    dukkandar_price: number;
    seller_price: number;
    variation_attributes: Record<string, Record<string, string>>;
    variation_attributes_attri: Array<{
      name: string;
      value: string;
      identifier: string;
    }>;
    product_variant: {
      product_variants_id: string;
      product_id: string;
      dsin: string;
      catalog_id: string;
      variation_name: string;
      variation_value: string;
      variation_name_sku: string;
      variation_value_sku: string;
      sku: string;
      product_mrp: number;
    };
  }>;
}

export const MAX_CATEGORY_LENGTH = 10; // Adjust the maximum length as needed

export const TYPES_PARAMS_ARRAY = ["Today", "L7D", "Month", "Year"];

export interface Category {
  name: string;
  category_1_id: string;
}