import React from "react";

interface InventoryProps {
  // Define any props if needed
}

const Step1: React.FC = () => (
  <div>
    <h2>Step 1: Identify products from Inventory Screen</h2>
    <p>
      Identify products that you wish to edit from the Inventory Screen.
    </p>
  </div>
);

const Step2: React.FC = () => (
  <div>
    <h2>Step 2: Click on Edit Product CTA</h2>
    <p>
      Click on the "Edit Product" Call-to-Action (CTA) button.
    </p>
  </div>
);

const Step3: React.FC = () => (
  <div>
    <h2>Step 3: Edit Product Details</h2>
    <p>
      You will land on a form where all details about your product are present.
      You can edit product information and product image (change primary image,
      add more images, or delete existing image) on this screen and click on
      Submit.
    </p>
  </div>
);

const DocInventory: React.FC<InventoryProps> = () => {
  return (
    <div>
      <h1>Inventory</h1>
      <Step1 />
      <Step2 />
      <Step3 />
    </div>
  );
};

export default DocInventory;
