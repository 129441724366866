import React, { useState } from "react";
import { Container, Row, Col, Card, ListGroup, Button } from "react-bootstrap";
import DcoRightSection from "./DcoRightSection";
import { FaBars, FaArrowLeft } from "react-icons/fa";
import "./document.css";
import { useNavigate } from "react-router-dom";

const Dashboard: React.FC = () => {
  const [selectedItem, setSelectedItem] = useState<string | null>("Analytic");
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const navigate = useNavigate();
  const menuItems = [
    "Analytic",
    "Products",
    "Orders",
    "Settings",
    "Inventory",
    "Advertising",
    "Contact Us",
  ];

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleItemClick = (item: string) => {
    setSelectedItem(item);
    setIsOpen(false);
  };

  const handleBackToHomeClick = () => {
    navigate("/dashboard/ecommerce");
  };
  return (
    <Container fluid>
      <Row>
        <Card.Header className="d-flex justify-content-between align-items-center cursor-pointer">
          <div onClick={handleToggle}>
            <FaBars className="mx-xs-1 mx-2" /> <span  className="fw-bold font-weight-mobile">Dukkandaar's Guide</span>
          </div>
          {selectedItem && (
             <button onClick={handleBackToHomeClick}  className="back-btn">
             <FaArrowLeft className="back-icon" />
             Back to Home
           </button>
          )}
        </Card.Header>

        <Col className={isOpen ? "sidebar open" : "sidebar"}>
          <Card>
            {isOpen && (
              <Card.Body style={{ transition: "height 0.5s" }}>
                <ListGroup>
                  {menuItems.map((item, index) => (
                    <ListGroup.Item
                      key={index}
                      action
                      onClick={() => handleItemClick(item)}
                    >
                      {item}
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              </Card.Body>
            )}
          </Card>
        </Col>
        <Col xs={12}>
          <DcoRightSection selectedItem={selectedItem} />
        </Col>
      </Row>
    </Container>
  );
};

export default Dashboard;
