import React from "react";
import { Modal, Form, Button } from "react-bootstrap";
import { FormDataResponse } from "./AddNewVariantModal";
import { InventoryProduct } from "./InventoryProduct";
import { toast } from "react-toastify";

interface AddNewVariantModalProps {
  show: boolean;
  onClose: () => void;
  formData: FormDataResponse;
  selectedItem: InventoryProduct | null;
}

const AddNewVariantModal: React.FC<AddNewVariantModalProps> = ({
  show,
  onClose,
  formData,
  selectedItem,
}) => {
  const [selectedVariation, setSelectedVariation] = React.useState<string>("");

  const handleVariationChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setSelectedVariation(event.target.value);
  };
  type FormControlElement =
    | HTMLInputElement
    | HTMLSelectElement
    | HTMLTextAreaElement;

  const handleFormVariationChange: React.ChangeEventHandler<FormControlElement> =
    (event) => {
      handleVariationChange(event as React.ChangeEvent<HTMLSelectElement>);
    };

  const handleAddVariantSubmit = async () => {
    const formInputs: Record<string, string | number> = {};

    formData.productvariationdatafields.forEach((field) => {
      const inputId = `form${field.productVarFields.identifier}`;
      const inputValue =
        field.productVarFields.input_types === "select"
          ? (document.getElementById(inputId) as HTMLSelectElement).value
          : (document.getElementById(inputId) as HTMLInputElement).value;

      formInputs[field.productVarFields.identifier] = inputValue;
    });

    // Add other form fields as needed
    formInputs["StockNumber"] = (
      document.getElementById("formStockNumber") as HTMLInputElement
    ).value;
    formInputs["SkuId"] = (
      document.getElementById("formSkuId") as HTMLInputElement
    ).value;

    // Capture seller price and product MRP
    formInputs["SellerPrice"] = +(
      document.getElementById("formseller_price") as HTMLInputElement
    ).value;

    formInputs["ProductMRP"] = +(
      document.getElementById("formproduct_mrp") as HTMLInputElement
    ).value;

    const selectedVariationObject = formData.productvariationsfields.find(
      (field) => field.sku === selectedVariation
    )?.variation;
    // Get selected variation value from the form
    const selectedVariationValue = (
      document.getElementById("formVariationValues") as HTMLSelectElement
    ).value;
    // Construct the API payload
    const apiPayload = {
      seller_products_id: selectedItem?.seller_products_id,
      product_variations: {
        variation_value: selectedVariationValue,
        variation_name: selectedVariationObject?.name || "",
        variation_name_sku: selectedVariation,
        variation_value_sku: selectedVariationValue,
        inventory: formInputs["StockNumber"],
        seller_price: formInputs["SellerPrice"], 
        supplier_sku_id: formInputs["SkuId"],
        product_mrp: formInputs["ProductMRP"],
        variation_attributes: formData.productvariationdatafields.map(
          (field) => ({
            name: field.productVarFields.name,
            value: formInputs[field.productVarFields.identifier],
            identifier: field.productVarFields.identifier,
          })
        ),
      },
    };

    try {
      const token = localStorage.getItem("token");
      if (!token) {
        throw new Error("Token not found in local storage");
      }
      const myHeaders = new Headers();
      myHeaders.append("token", token!);
      myHeaders.append("Content-Type", "application/json");
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/prd_lst/add_new_variant`,
        {
          method: "POST",
          headers: myHeaders,
          body: JSON.stringify(apiPayload),
        }
      );

      if (response.ok) {
        const responseData = await response.json();

        if (responseData.status === "success") {
          toast.success("Variant added successfully!");
          onClose();
        } else if (responseData.status === "failure") {
          console.error("Failed to add variant");
          if (responseData.msg) {
            toast.error(responseData.msg);
          }
        }
      } else {
        console.error("Failed to add variant");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Add Variation</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          {formData.productvariationdatafields.map((field) => (
            <Form.Group
              controlId={`form${field.productVarFields.identifier}`}
              key={field.productVarFields.identifier}
            >
              <Form.Label>{field.productVarFields.name}</Form.Label>
              {field.productVarFields.input_types === "select" ? (
                <Form.Control as="select">
                  <option value="" disabled>
                    Select
                  </option>
                  {field.productVarFields.options?.map((option: any) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </Form.Control>
              ) : (
                <Form.Control
                  type={field.productVarFields.input_types}
                  placeholder={
                    field.productVarFields.placeholder ||
                    `Enter ${field.productVarFields.name}`
                  }
                />
              )}
            </Form.Group>
          ))}
          <Form.Group controlId="formVariation">
            <Form.Label>Variation</Form.Label>
            <Form.Control as="select" onChange={handleFormVariationChange}>
              <option value="" disabled>
                Select
              </option>
              {formData.productvariationsfields.map((field) => (
                <option key={field.sku} value={field.sku}>
                  {field.variation.name}
                </option>
              ))}
            </Form.Control>
          </Form.Group>

          {selectedVariation &&
            formData.productvariationsfields.find(
              (field) => field.sku === selectedVariation
            )?.variation.variation_values?.length && (
              <Form.Group controlId={`formVariationValues`}>
                <Form.Label>Variation Values</Form.Label>
                <Form.Control as="select">
                  <option value="" disabled>
                    Select
                  </option>
                  {formData.productvariationsfields
                    .find((field) => field.sku === selectedVariation)
                    ?.variation.variation_values.map((variationValue) => (
                      <option
                        key={variationValue.sku}
                        value={variationValue.sku}
                      >
                        {variationValue.name}
                      </option>
                    ))}
                </Form.Control>
              </Form.Group>
            )}

          {formData.productpricetaxfields.map((field) => (
            <Form.Group
              controlId={`form${field.identifier}`}
              key={field.identifier}
            >
              <Form.Label>{field.name}</Form.Label>
              {field.input_types === "select" ? (
                <Form.Control as="select">
                  <option value="" disabled>
                    Select
                  </option>
                  {field.options?.map((option: any) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </Form.Control>
              ) : (
                <Form.Control
                  type={field.input_types}
                  placeholder={field.placeholder || `Enter ${field.name}`}
                  name={field.identifier} // Add name attribute to capture input value
                />
              )}
            </Form.Group>
          ))}
          <Form.Group controlId="formStockNumber">
            <Form.Label>Stock Number</Form.Label>
            <Form.Control type="number" placeholder="Enter stock number" />
          </Form.Group>
          <Form.Group controlId="formSkuId">
            <Form.Label>SKU ID</Form.Label>
            <Form.Control type="text" placeholder="Enter SKU ID" />
          </Form.Group>
          <Button
            variant="primary"
            type="button"
            onClick={handleAddVariantSubmit}
            className="mt-2"
          >
            Add Variation
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default AddNewVariantModal;
