import React from "react";
import "./TwoBallsLoader.css";


const TwoBallsLoader: React.FC = () => {
  return (
    <div className="d-flex justify-content-center align-items-center">
      <span className="loader"></span>
    </div>
  );
};

export default TwoBallsLoader;
