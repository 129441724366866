export interface NewProductTemplate {
  catalog_id: string;
  category_4_id: string;
  product_sku: string | null;
  uploaded_by_seller_id: string;
  uploaded_by: string;
  uploaded_by_id: string | null;
  product_weight_in_gms: number;
  product_details_attr: {
    name: string;
    value: string;
    identifier: string;
  }[];
  product_name_n: string;
  product_description_n: string;
  other_details_attri: {
    name: string;
    value: string;
    identifier: string;
  }[];
  status: string;
  active: boolean;
  blocked: boolean;
  is_pause: boolean;
  default: boolean;
  reviewed_comment: string | null;
  reviewed_by: string | null;
  is_edited: boolean;
  blocked_by: string | null;
  blocked_reason: string | null;
  deactivated_by: string | null;
  product_variants: any[];
  products_images: {
    url: string;
    is_front_img: boolean;
  }[];
}

export const NEWPRODUCTTEMPLATEDATA: NewProductTemplate = {
  catalog_id: "",
  category_4_id: "",
  product_sku: null,
  uploaded_by_seller_id: "",
  uploaded_by: "Seller",
  uploaded_by_id: null,
  product_weight_in_gms: 0,
  product_details_attr: [
    {
      name: "Bottom Color",
      value: "",
      identifier: "bottom_color",
    },
    {
      name: "Bottom Fabric",
      value: "",
      identifier: "bottom_fabric",
    },
    {
      name: "Net Quantity (N)",
      value: "",
      identifier: "multipack",
    },
    {
      name: "Top Color",
      value: "",
      identifier: "top_color",
    },
    {
      name: "Top Fabric",
      value: "",
      identifier: "top_fabric",
    },
    {
      name: "COUNTRY OF ORIGIN",
      value: "",
      identifier: "country_of_origin",
    },
    {
      name: "Manufacturer Details",
      value: "",
      identifier: "manufacturer_details",
    },
    {
      name: "Packer Details",
      value: "",
      identifier: "packer_details",
    },
  ],
  product_name_n: "",
  product_description_n: "",
  other_details_attri: [
    {
      name: "Add On",
      value: "Shrug",
      identifier: "add_on",
    },
    {
      name: "Bottom Pattern",
      value: "",
      identifier: "bottom_pattern",
    },
    {
      name: "Bottom Type",
      value: "",
      identifier: "bottom_type",
    },
    {
      name: "Brand",
      value: "",
      identifier: "brand",
    },
    {
      name: "Neck",
      value: "",
      identifier: "neck",
    },
    {
      name: "Occasion",
      value: "",
      identifier: "occasion",
    },
    {
      name: "Sleeve Length",
      value: "",
      identifier: "sleeve_length",
    },
    {
      name: "Top Pattern",
      value: "",
      identifier: "top_pattern",
    },
    {
      name: "Top Type",
      value: "",
      identifier: "top_type",
    },
    {
      name: "Importer Details",
      value: "",
      identifier: "importer_details",
    },
  ],
  status: "DRAFT",
  active: true,
  blocked: false,
  is_pause: false,
  default: true,
  reviewed_comment: null,
  reviewed_by: null,
  is_edited: false,
  blocked_by: null,
  blocked_reason: null,
  deactivated_by: null,
  product_variants: [],
  products_images: [
    {
      url: "",
      is_front_img: true,
    },
  ],
};
